import {isDev} from '#/lib/constants'

const OperationList = !isDev
  ? [
      // production
      'did:plc:k2et2dwt5xtzsts5vjdjl6ig',
    ]
  : [
      // test
      'did:plc:n6xwaei6nmj4aaneumxph44d',
      'did:plc:3tae7hns3dy24svvven7h36x',
    ]

export function getIsOpreration(did: string) {
  return OperationList.includes(did)
}

export function getOperationName(operation: string) {
  switch (operation) {
    case '1':
      return 'Spilling'
    case '2':
      return 'Invitation'
    case '3':
      return 'Circle'
    default:
      return 'None'
  }
}
