import React, {useEffect} from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  StyleSheet,
  View,
} from 'react-native'
import {AppBskyActorDefs, AppBskyFeedDefs} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {ScrollProvider} from '#/lib/ScrollContext'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {updatePostShadow, usePostShadow} from '#/state/cache/post-shadow'
import {useModalControls} from '#/state/modals'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {Button} from '#/view/com/util/forms/Button'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {Text} from 'view/com/util/text/Text'
import {CenteredView} from 'view/com/util/Views'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useBreakpoints, useTheme} from '#/alf'
import {ListHeaderDesktop} from '#/components/Lists'
import {EmptyState} from '../com/util/EmptyState'

export const PostGuarantorList = ({
  did,
  rkey,
  loading,
  post,
}: {
  did: string
  rkey: string
  loading: boolean
  post: AppBskyFeedDefs.PostView
}) => {
  const t = useTheme()
  const {_} = useLingui()
  const pal = usePalette('default')
  const {currentAccount} = useSession()
  const {gtTablet} = useBreakpoints()
  const {isDesktop} = useWebMediaQueries()
  const {openModal} = useModalControls()
  const uri = makeRecordUri(did ?? '', 'app.bsky.feed.post', rkey)
  const {data: postProfile} = useProfileQuery({did})
  const queryClient = useQueryClient()
  const {data: currentProfile} = useProfileQuery({did: currentAccount?.did})

  const postShadowed = usePostShadow(post)

  const [guarantorList, setGuarantorList] = React.useState<
    AppBskyActorDefs.ProfileViewBasic[]
  >([])

  const renderGuarantorItem = React.useCallback(
    ({item}: {item: AppBskyActorDefs.ProfileViewBasic}) => {
      const displayName =
        item.displayName && item.displayName !== ''
          ? item.displayName
          : formatHandler(item?.handle)
      const isYou = item?.did === currentAccount?.did
      return (
        <View style={[styles.listRecordContaner]} key={item.did}>
          <View style={[styles.listRecord]}>
            <View>
              <UserAvatar type="user" size={44} avatar={item.avatar} />
            </View>
            <View style={styles.listUser}>
              <View style={[a.mb_xs, a.flex_row, a.align_center, a.gap_sm]}>
                {displayName && (
                  <Text style={[t.atoms.text, a.text_md, a.font_semibold]}>
                    {displayName}
                  </Text>
                )}
                {isYou && (
                  <View
                    style={[
                      a.px_xs,
                      {
                        paddingVertical: 2,
                        backgroundColor: t.palette.green_500,
                        borderRadius: 4,
                      },
                    ]}>
                    <Text
                      style={[
                        a.text_xs,
                        a.font_semibold,
                        {color: t.palette.white},
                      ]}>
                      You
                    </Text>
                  </View>
                )}
              </View>
              <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                {item?.handle ? `@${formatHandler(item?.handle)}` : ''}
              </Text>
            </View>
          </View>
        </View>
      )
    },
    [
      t.atoms.text,
      t.palette.white,
      currentAccount?.did,
      t.palette.gray_10,
      t.palette.green_500,
    ],
  )

  const isJoinedGuar =
    guarantorList &&
    guarantorList?.length > 0 &&
    guarantorList?.findIndex(o => o?.did === currentAccount?.did) >= 0
  const joinedGuarText = isJoinedGuar
    ? _(msg`Joined`)
    : _(msg`Join as a guarantor`)

  useEffect(() => {
    // @ts-ignore
    setGuarantorList(postShadowed?.guarantors ?? [])
  }, [postShadowed])

  return (
    <CenteredView
      testID="betListPage"
      style={[a.flex_1]}
      sideBorders={isDesktop ? true : false}>
      <ViewHeader title={'Guarantors'} showBorder />
      <ListHeaderDesktop title={'Guarantors'} />
      <ScrollProvider>
        <View
          style={[
            styles.contentContainer,
            pal.border,
            {borderTopWidth: gtTablet ? 1 : 0},
          ]}>
          {loading ? (
            <ActivityIndicator size="large" color={t.palette.primary} />
          ) : guarantorList && guarantorList?.length > 0 ? (
            <RNFlatList
              testID={`guarantors-flatlist`}
              data={guarantorList}
              renderItem={renderGuarantorItem}
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <View>
              <EmptyState
                icon="list-ul"
                message={_(msg`List is empty.`)}
                style={[{borderWidth: 0}]}
              />
            </View>
          )}
        </View>
      </ScrollProvider>
      {did !== currentAccount?.did && (
        <View style={[styles.actionContainer]}>
          <Button
            testID="confirmBtn"
            type="primary"
            accessibilityLabel={joinedGuarText}
            accessibilityHint=""
            label={joinedGuarText}
            disabled={isJoinedGuar}
            onPress={() => {
              openModal({
                name: 'join-guar',
                rKey: rkey,
                cid: post?.cid ?? '',
                transPostAuthor: {
                  did: postProfile?.did ?? '',
                },
                onCallBack: () => {
                  const currentGuar = {
                    did: currentAccount?.did ?? '',
                    avatar: currentProfile?.avatar ?? '',
                    displayName: currentProfile?.displayName ?? '',
                    handle: currentAccount?.handle ?? '',
                  }
                  setGuarantorList(
                    // @ts-ignore
                    postShadowed?.guarantors?.concat([currentGuar]),
                  )
                  updatePostShadow(queryClient, uri, {
                    guarantor: currentGuar,
                  })
                },
              })
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, a.font_semibold, {color: t.palette.black}]}
            style={[
              styles.actionBtn,
              {backgroundColor: t.palette.primary},
              isJoinedGuar && {opacity: 0.5},
            ]}
          />
        </View>
      )}
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 15,
  },
  actionContainer: {
    paddingHorizontal: 10,
    height: 100,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#97979733',
  },
  actionBtn: {
    flex: 1,
  },
  listRecordContaner: {
    marginBottom: 15,
  },
  listRecord: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  childRecord: {
    marginLeft: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listUser: {
    flex: 1,
  },
})
