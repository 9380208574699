import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
} from 'react-native'

import {useFreeSave} from '#/lib/hooks/Tools'
import {emitter} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useAgent} from '#/state/session'
import {useTgStore} from '../util/sdlStore/TgStore'

const screenHeight = Dimensions.get('window').height

const config = {
  circle: {
    defaultHeight: 600,
    offsetTop: 170,
    index: 0,
  },
  public: {
    defaultHeight: 20000,
    offsetTop: 85,
    index: 1,
  },
}

const ScrollListener = ({
  children,
  type,
}: {
  children: React.ReactNode
  type: keyof typeof config
}) => {
  const scrollRef = useRef<ScrollView>(null)

  const [shouldDisableWrap, setShouldDisableWrap] = useState(true)

  const {getFreeValue} = useFreeSave()

  const [allowHeight, setAllowHeight] = useState(config[type].defaultHeight)

  const [lastOffset, setLastOffset] = useState(0)

  const {bind} = useTgStore()

  const {openModal} = useModalControls()

  useEffect(() => {
    // saveFreeValue('disableCircleSwipe', '')
    requestSwipe()
    emitter.on('fixedAuthorization', () => {
      setShouldDisableWrap(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestSwipe = useCallback(async () => {
    const res = await getFreeValue('disableCircleSwipe')
    if (res) {
      setShouldDisableWrap(false)
      emitter.emit('fixedAuthorization')
    }
  }, [getFreeValue])

  const open = useMemo(() => {
    return debounce(() => openModal({name: 'circle-link'}), 1000)
  }, [openModal])

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const currentOffset = event.nativeEvent.contentOffset.y

      if (!bind && currentOffset > lastOffset && currentOffset > allowHeight) {
        if (type === 'circle' && !shouldDisableWrap) return
        open()
        setTimeout(() => {
          scrollRef.current?.scrollTo({
            x: 0,
            y: allowHeight - 100,
            animated: true,
          })
        }, 1000)
      }
      setLastOffset(currentOffset)
    },
    [allowHeight, bind, lastOffset, open, shouldDisableWrap, type],
  )

  const agent = useAgent()

  const requestAllowHeight = useCallback(() => {
    agent.com.atproto.server
      .getTranslation({
        uri: 'sipzViewableHeight',
        lang: 'en',
      })
      .then(viewableHeight => {
        if (viewableHeight.data.ok) {
          const heightConfig = viewableHeight.data.translation?.split(',') || []
          setAllowHeight(pre => {
            if (heightConfig[config[type].index]) {
              return Number(heightConfig[config[type].index])
            } else {
              return pre
            }
          })
        }
      })
  }, [agent.com.atproto.server, type])

  useEffect(() => {
    requestAllowHeight()
  }, [requestAllowHeight])

  return (
    <ScrollView
      ref={scrollRef}
      style={[
        styles.scrollView,
        {height: screenHeight - config[type].offsetTop},
      ]}
      onScroll={handleScroll}
      scrollEventThrottle={15}>
      {children}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    width: '100%',
  },
})

export default ScrollListener

function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let lastTime = 0
  return function (...args: Parameters<T>) {
    const now = Date.now()
    if (now - lastTime >= wait) {
      lastTime = now
      func(...args)
    }
  }
}
