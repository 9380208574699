import {Image as RNImage} from 'react-native-image-crop-picker'
import {binarize, Decoder, Detector, grayscale} from '@nuintun/qrcode'

import {Dimensions} from './types'
import {blobToDataUri, getDataUriSize} from './util'

export async function compressIfNeeded(
  img: RNImage,
  maxSize: number,
): Promise<RNImage> {
  if (img.size < maxSize) {
    return img
  }
  return await doResize(img.path, {
    width: img.width,
    height: img.height,
    mode: 'stretch',
    maxSize,
  })
}

export const parserMnemonic = async (img: RNImage) => {
  const image = new Image()
  image.src = img.path
  await new Promise(resolve => image.addEventListener('load', resolve))

  const {width, height} = image
  const canvas = new OffscreenCanvas(width, height)
  const context = canvas.getContext('2d')!

  context.drawImage(image, 0, 0)

  const luminances = grayscale(context.getImageData(0, 0, width, height))
  const binarized = binarize(luminances, width, height)
  const detector = new Detector()
  const detected = detector.detect(binarized)
  const decoder = new Decoder()

  let current = detected.next()

  while (!current.done) {
    let succeed = false
    let code

    const detect = current.value

    try {
      const {size, finder, alignment} = detect
      const decoded = decoder.decode(detect.matrix)
      // Finder
      const {topLeft, topRight, bottomLeft} = finder
      // Corners
      const topLeftCorner = detect.mapping(0, 0)
      const topRightCorner = detect.mapping(size, 0)
      const bottomRightCorner = detect.mapping(size, size)
      const bottomLeftCorner = detect.mapping(0, size)
      // Timing
      const topLeftTiming = detect.mapping(6.5, 6.5)
      const topRightTiming = detect.mapping(size - 6.5, 6.5)
      const bottomLeftTiming = detect.mapping(6.5, size - 6.5)

      console.log({
        content: decoded.content,
        finder: [topLeft, topRight, bottomLeft],
        alignment: alignment ? alignment : null,
        timing: [topLeftTiming, topRightTiming, bottomLeftTiming],
        corners: [
          topLeftCorner,
          topRightCorner,
          bottomRightCorner,
          bottomLeftCorner,
        ],
      })
      code = decoded.content

      succeed = true
    } catch {
      // Decode failed, skipping...
    }

    // Notice: pass succeed to next() is very important,
    // This can significantly reduce the number of detections.
    current = detected.next(succeed)
    return code
  }
}

export interface DownloadAndResizeOpts {
  uri: string
  width: number
  height: number
  mode: 'contain' | 'cover' | 'stretch'
  maxSize: number
  timeout: number
}

export async function downloadAndResize(opts: DownloadAndResizeOpts) {
  const controller = new AbortController()
  const to = setTimeout(() => controller.abort(), opts.timeout || 5e3)
  const res = await fetch(opts.uri)
  const resBody = await res.blob()
  clearTimeout(to)

  const dataUri = await blobToDataUri(resBody)
  return await doResize(dataUri, opts)
}

export async function shareImageModal(_opts: {uri: string}) {
  // TODO
  throw new Error('TODO')
}

export async function saveImageToAlbum(_opts: {uri: string; album: string}) {
  // TODO
  throw new Error('TODO')
}

export async function getImageDim(path: string): Promise<Dimensions> {
  var img = document.createElement('img')
  const promise = new Promise((resolve, reject) => {
    img.onload = resolve
    img.onerror = reject
  })
  img.src = path
  await promise
  return {width: img.width, height: img.height}
}

// internal methods
// =

interface DoResizeOpts {
  width: number
  height: number
  mode: 'contain' | 'cover' | 'stretch'
  maxSize: number
}

async function doResize(dataUri: string, opts: DoResizeOpts): Promise<RNImage> {
  let newDataUri

  for (let i = 0; i <= 10; i++) {
    newDataUri = await createResizedImage(dataUri, {
      width: opts.width,
      height: opts.height,
      quality: 1 - i * 0.1,
      mode: opts.mode,
    })
    if (getDataUriSize(newDataUri) < opts.maxSize) {
      break
    }
  }
  if (!newDataUri) {
    throw new Error('Failed to compress image')
  }
  return {
    path: newDataUri,
    mime: 'image/jpeg',
    size: getDataUriSize(newDataUri),
    width: opts.width,
    height: opts.height,
  }
}

function createResizedImage(
  dataUri: string,
  {
    width,
    height,
    quality,
    mode,
  }: {
    width: number
    height: number
    quality: number
    mode: 'contain' | 'cover' | 'stretch'
  },
): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      if (!ctx) {
        return reject(new Error('Failed to resize image'))
      }

      let scale = 1
      if (mode === 'cover') {
        scale = img.width < img.height ? width / img.width : height / img.height
      } else if (mode === 'contain') {
        scale = img.width > img.height ? width / img.width : height / img.height
      }
      let w = img.width * scale
      let h = img.height * scale

      canvas.width = w
      canvas.height = h

      ctx.drawImage(img, 0, 0, w, h)
      resolve(canvas.toDataURL('image/jpeg', quality))
    })
    img.addEventListener('error', ev => {
      reject(ev.error)
    })
    img.src = dataUri
  })
}

export async function saveBytesToDisk(
  filename: string,
  bytes: Uint8Array,
  type: string,
) {
  const blob = new Blob([bytes], {type})
  const url = URL.createObjectURL(blob)
  await downloadUrl(url, filename)
  // Firefox requires a small delay
  setTimeout(() => URL.revokeObjectURL(url), 100)
  return true
}

async function downloadUrl(href: string, filename: string) {
  const a = document.createElement('a')
  a.href = href
  a.download = filename
  a.click()
}

export async function safeDeleteAsync() {
  // no-op
}
