import React, {useState} from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  Image,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {claimAll} from '#/lib/ethers/bet'
import {stakeBatchWithdrawAll} from '#/lib/ethers/stake'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  TRANS_BET_WITHDRAW_RQKEY,
  TRANS_STAKE_WITHDRAW_RQKEY,
} from '#/state/queries/transaction-assets'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {EmptyState} from '../util/EmptyState'
import {Button} from '../util/forms/Button'
import {simplifyMsg} from '../util/Transaction'

export type WithdrawModalListType = {
  title: string
  type: 'stake' | 'bet' | 'bet-request'
  amount?: number
  address: string
}
export type WithdrawModalType = {
  list: WithdrawModalListType[]
}

export const snapPoints = ['90%']
const UsdcIcon = require('../../../../assets/imgs/usdc.png')

export const Component = function WithdrawModalComponent(
  props: WithdrawModalType,
) {
  const {list} = props
  const t = useTheme()
  const {_} = useLingui()
  const {openModal, closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {provider, currentWallet} = useWallet()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()
  const queryClient = useQueryClient()

  const [pending, setPending] = useState<boolean>(false)

  const onBetWithdraw = React.useCallback(
    async (amountValue?: number) => {
      try {
        if (!currentWallet?.publicKey) {
          openModal({name: 'connect-wallet', type: 'connect'})
          return
        }
        console.log('onBetWithdraw', currentWallet)
        setPending(true)
        // const remainAmount = await getBetAvailableWithdraw(
        //   PUBLIC_ENV_CHAINID,
        //   provider,
        // )
        if (!amountValue) {
          Toast.show(`No balance available for withdrawal`)
          return
        }
        const tx = await claimAll(provider, PUBLIC_ENV_CHAINID)
        console.log('onBetWithdraw', tx)
        if (tx) {
          try {
            const res = await createTransMutation.mutateAsync({
              param: {
                id: tx?.hash,
                payer: currentAccount?.did ?? '',
                method: 'insider-stake.withdraw', // TODOS
              },
            })
            console.log('createTransMutation', res)
          } catch (error) {}
          const reception = await tx.wait()
          console.log('onBetWithdraw-reception', reception)
          // if (reception?.transactionHash) { // ethers5
          if (reception?.hash) {
            // ethers6
            try {
              const res = await updateTransMutation.mutateAsync({
                param: {
                  repo: currentAccount?.did ?? '',
                  collection: 'app.bsky.feed.bet',
                  record: {
                    transId: reception?.hash,
                    method: 'insider-bet.withdraw', //TODOS
                    amount: Number(amountValue) * 1000000,
                  },
                },
              })
              console.log('updateTransMutation', res)
            } catch (error) {}
            queryClient.invalidateQueries({
              queryKey: TRANS_BET_WITHDRAW_RQKEY(),
            })
            setPending(false)
            closeModal()
          }
        }
      } catch (err) {
        logger.error(`bet-withdraw`, {message: err})
        setPending(false)
        let reason =
          (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
        reason = simplifyMsg(reason)
        if (reason) {
          Toast.show(`Transaction failed: ${reason}`, 'xmark')
        } else {
          Toast.show(
            _(msg`Transaction failed: this operation caused some error`),
            'xmark',
          )
        }
      }
    },
    [
      _,
      closeModal,
      createTransMutation,
      currentAccount?.did,
      currentWallet,
      openModal,
      provider,
      updateTransMutation,
      queryClient,
    ],
  )

  const onStakeWithdraw = React.useCallback(
    async (amountValue?: number) => {
      try {
        if (!currentWallet?.publicKey) {
          openModal({name: 'connect-wallet', type: 'connect'})
          return
        }
        console.log('onStakeWithdraw', currentWallet)
        setPending(true)
        // const remainAmount = await getRemainAmount(provider, PUBLIC_ENV_CHAINID)
        if (!amountValue) {
          Toast.show(`No balance available for withdrawal`)
          return
        }
        const tx = await stakeBatchWithdrawAll(provider, PUBLIC_ENV_CHAINID)
        console.log('onStakeWithdraw', tx)
        if (tx) {
          try {
            const res = await createTransMutation.mutateAsync({
              param: {
                id: tx?.hash,
                payer: currentAccount?.did ?? '',
                method: 'insider-stake.withdraw-all',
                payload: JSON.stringify({
                  amount: Number(amountValue) * 1000000,
                }),
              },
            })
            console.log('createTransMutation', res)
          } catch (error) {}
          const reception = await tx.wait()
          console.log('onStakeWithdraw-reception', reception)
          if (reception?.hash) {
            try {
              const res = await updateTransMutation.mutateAsync({
                param: {
                  repo: currentAccount?.did ?? '',
                  collection: 'app.bsky.feed.stake',
                  record: {
                    uri: 'default',
                    transId: reception?.hash,
                    method: 'insider-stake.withdraw-all',
                    amount: Number(amountValue) * 1000000,
                  },
                },
              })
              console.log('updateTransMutation', res)
            } catch (error) {}
            queryClient.invalidateQueries({
              queryKey: TRANS_STAKE_WITHDRAW_RQKEY(),
            })
            setPending(false)
            closeModal()
          }
        }
      } catch (err) {
        logger.error(`stake-withdraw`, {message: err})
        setPending(false)
        let reason =
          (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
        reason = simplifyMsg(reason)
        if (reason) {
          Toast.show(`Transaction failed: ${reason}`, 'xmark')
        } else {
          Toast.show(
            _(msg`Transaction failed: this operation caused some error`),
            'xmark',
          )
        }
      }
    },
    [
      _,
      closeModal,
      createTransMutation,
      currentAccount?.did,
      currentWallet,
      openModal,
      provider,
      updateTransMutation,
      queryClient,
    ],
  )

  const renderItemInner = React.useCallback(
    ({item}: {item: WithdrawModalListType}) => {
      return (
        <View
          style={[
            a.p_lg,
            a.mb_lg,
            a.border,
            a.rounded_sm,
            a.flex_row,
            a.justify_between,
            a.align_center,
            {
              borderColor: '#97979733',
            },
          ]}>
          <View>
            <Text
              style={[
                a.text_sm,
                a.font_semibold,
                a.mb_sm,
                {color: t.palette.gray_10},
              ]}>
              {item?.title}
            </Text>
            <View style={[a.flex_row, a.align_center]}>
              <Image
                testID="usdcLogo"
                source={UsdcIcon}
                style={styles.transBtnIcon}
                accessibilityIgnoresInvertColors
              />
              <Text style={[a.text_lg, a.font_extrabold, t.atoms.text]}>
                {item?.amount}
              </Text>
            </View>
          </View>
          <Pressable
            accessibilityRole="button"
            onPress={() => {
              if (item.type === 'bet') {
                onBetWithdraw(item?.amount)
              } else if (item.type === 'stake') {
                onStakeWithdraw(item?.amount)
              }
            }}
            style={[
              styles.withdrawBtn,
              a.flex_row,
              {backgroundColor: t.palette.primary},
            ]}>
            {pending && <ActivityIndicator size={15} />}
            <Text style={[a.text_xs, a.font_semibold]}>Withdraw</Text>
          </Pressable>
        </View>
      )
    },
    [
      t.palette.gray_10,
      t.palette.primary,
      onBetWithdraw,
      onStakeWithdraw,
      pending,
      t.atoms.text,
    ],
  )

  // console.log('withdraw', list)

  return (
    <SafeAreaView testID="withdrawModal">
      <View style={[isMobile && {paddingHorizontal: 18}]}>
        <Text
          style={[
            a.text_lg,
            a.font_bold,
            a.text_center,
            a.mb_sm,
            t.atoms.text,
          ]}>
          Withdraw
        </Text>
        <View style={[a.mt_lg]}>
          {list?.length > 0 ? (
            <RNFlatList
              testID={'transassetsflatlist'}
              data={list}
              renderItem={renderItemInner}
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <EmptyState
              icon="list-ul"
              message={_(msg`List is empty.`)}
              style={[{borderWidth: 0}]}
            />
          )}
        </View>
        <View style={[a.mt_xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
          <Button
            testID="cancelBtn"
            type="default"
            onPress={() => {
              closeModal()
            }}
            accessibilityLabel={_(msg`Cancel`)}
            accessibilityHint=""
            label={_(msg`Cancel`)}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md]}
            style={[isDesktop ? a.flex_1 : {}]}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  withdrawBtn: {
    paddingHorizontal: 10,
    height: 29,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
  transBtnIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: 5,
  },
})
