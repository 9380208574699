import React from 'react'
import {Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {Tag} from '#/view/com/tags/TagItem'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Loader} from '#/components/Loader'
import {useTagList} from '../Circle/hooks'
import {TagListItem} from './TagListItem'

export function TagListPanel() {
  const t = useTheme()
  const {isMobile} = useWebMediaQueries()
  const {data, error, status} = useTagList()

  const [query, setQuery] = React.useState('')
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([])

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }

  const onPressCancelSearch = () => {
    setQuery('')
  }

  const onSubmitQuery = () => {}

  const handlerDelete = () => {}

  const selectedHandle = (selected: boolean, tag: Tag) => {
    if (selected) {
      setSelectedTags(s => [...s, tag])
    } else {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
    }
  }

  let content
  if (status === 'pending') {
    content = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (error) {
    content = (
      <View>
        <Text style={[t.atoms.text]}>{error?.message}</Text>
      </View>
    )
  } else if (data?.length === 0) {
    content = (
      <EmptyBox
        icon="heart"
        message={'You currently have no tags.'}
        viewStyles={{paddingTop: 20, paddingBottom: 20}}
      />
    )
  } else {
    content = (
      <View style={[a.gap_xs, a.mt_md]}>
        {data?.map(item => {
          return (
            <TagListItem
              key={item.id}
              tag={item}
              selected={!!selectedTags.find(o => o.name === item.name)}
              onSelected={selectedHandle}
            />
          )
        })}
      </View>
    )
  }

  const renderButton = () => {
    if (!isMobile) {
      return (
        <Button
          color={selectedTags.length > 0 ? 'negative' : 'secondary'}
          style={[{height: 28, right: 16}, a.absolute]}
          variant="solid"
          size="small"
          label="Delete"
          onPress={handlerDelete}>
          <Text style={[{color: t.palette.black}, a.font_bold]}>
            <Trans>Delete</Trans>
          </Text>
        </Button>
      )
    }
    return null
  }

  return (
    <CenteredView>
      <ViewHeader
        title={`Manage tags`}
        canGoBack
        showOnDesktop
        showBorder={false}
        renderButton={renderButton}
      />
      <View style={[a.mt_md]}>
        <SearchInput
          query={query}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
          onSubmitQuery={onSubmitQuery}
          style={[
            a.border,
            t.atoms.input_bg,
            t.atoms.input_border,
            a.mx_lg,
            {borderRadius: 10},
          ]}
        />
      </View>
      <View style={[a.px_lg, isMobile && {paddingBottom: 90}]}>{content}</View>
      {isMobile && (
        <View
          style={[
            a.flex_row,
            a.fixed,
            a.w_full,
            a.px_lg,
            {bottom: 0, height: 90},
            a.align_center,
            a.justify_between,
            t.atoms.bg,
          ]}>
          <View style={[a.flex_1]}>
            <Text style={[a.text_md, a.font_semibold, t.atoms.text_sub]}>
              Seletced: {selectedTags.length}
            </Text>
          </View>
          <View>
            <Button
              color={selectedTags.length > 0 ? 'negative' : 'secondary'}
              style={[{height: 28}]}
              variant="solid"
              size="small"
              label="Delete"
              onPress={handlerDelete}>
              <Text style={[{color: t.palette.black}, a.text_md, a.font_bold]}>
                <Trans>Delete</Trans>
              </Text>
            </Button>
          </View>
        </View>
      )}
    </CenteredView>
  )
}
