export function isIframe(): boolean {
  try {
    return window.self !== window.top
  } catch {
    return true
  }
}

export function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export function hasExternalNotify<T extends object>(
  value: T,
): value is T & {
  external: {
    notify: (...args: any) => any
  }
} {
  return (
    'external' in value &&
    isRecord(value.external) &&
    'notify' in value.external &&
    typeof value.external.notify === 'function'
  )
}

export function hasWebviewProxy<T extends {}>(
  value: T,
): value is T & {
  TelegramWebviewProxy: {
    postEvent: (...args: unknown[]) => unknown
  }
} {
  return (
    'TelegramWebviewProxy' in value &&
    isRecord(value.TelegramWebviewProxy) &&
    'postEvent' in value.TelegramWebviewProxy &&
    typeof value.TelegramWebviewProxy.postEvent === 'function'
  )
}
