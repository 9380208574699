import React, {useState} from 'react'
import {Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import dayjs from 'dayjs'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
import DatePicker from 'react-datepicker'

import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Dialog from '#/components/Dialog'

// dayjs.extend(utc)
// dayjs.extend(timezone)

export function useDatePickerDialog() {
  const t = useTheme()
  const control = Dialog.useDialogControl()
  const [startDate, setStartDate] = useState<Date | undefined>(new Date())
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [hasRange, setHasRange] = useState<boolean>(false)
  const handleChange = (dates: any) => {
    let [start, end] = dates
    setHasRange(false)
    setStartDate(start)
    setEndDate(end)
  }

  const onClear = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setHasRange(false)
    control.close()
  }

  const onConfirm = () => {
    if (startDate || endDate) {
      setHasRange(true)
    }
    control.close()
  }

  const dialog = (
    <Dialog.Outer
      control={control}
      nativeOptions={{sheet: {snapPoints: ['80%']}}}>
      <Dialog.Handle />
      <Dialog.Close />
      <Dialog.ScrollableInner
        accessibilityDescribedBy="dialog-description"
        accessibilityLabelledBy="dialog-title"
        style={[a.flex_1, a.align_center, a.justify_center, a.gap_md]}>
        <View style={[{height: 300, transform: 'scale(1.5)'}]}>
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </View>

        <View style={[a.flex_row, a.align_center]}>
          <Text style={[t.atoms.text]}>
            <Trans>Start Date:</Trans>{' '}
          </Text>
          <Text style={[t.atoms.text]}>
            {dayjs(startDate).format('MMM/DD/YYYY')}
          </Text>
        </View>
        <View style={[a.flex_row, a.align_center]}>
          <Text style={[t.atoms.text]}>
            <Trans>End Date:</Trans>{' '}
          </Text>
          <Text style={[t.atoms.text]}>
            {dayjs(endDate || new Date()).format('MMM/DD/YYYY')}
          </Text>
        </View>
        <Button
          variant="solid"
          size="medium"
          color="primary"
          label="Confirm"
          onPress={onConfirm}
          style={[{width: '80%'}]}>
          <Text>
            <Trans>Confirm</Trans>
          </Text>
        </Button>
        <Button
          variant="solid"
          size="medium"
          color="secondary_inverted"
          label="Clear"
          onPress={onClear}
          style={[{width: '80%'}]}>
          <Text>
            <Trans>Clear</Trans>
          </Text>
        </Button>
      </Dialog.ScrollableInner>
    </Dialog.Outer>
  )
  if (hasRange) {
    return {
      dialog,
      control,
      startDate: startDate ? new Date(startDate.getTime()) : undefined,
      endDate: endDate ? new Date(endDate.getTime()) : undefined,
      hasRange,
    }
  }
  return {
    dialog,
    control,
    hasRange,
  }
}
