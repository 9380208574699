import {
  AppBskyFeedDefs,
  AppBskyFeedGetActorLikes as GetActorLikes,
  BskyAgent,
} from '@atproto/api'
import {FeedViewPost} from '@atproto/api/dist/client/types/app/bsky/feed/defs'

import {FeedAPI, FeedAPIResponse} from './types'

export class LikesFeedAPI implements FeedAPI {
  agent: BskyAgent
  params: GetActorLikes.QueryParams

  constructor({
    agent,
    feedParams,
  }: {
    agent: BskyAgent
    feedParams: GetActorLikes.QueryParams
  }) {
    this.agent = agent
    this.params = feedParams
  }

  async peekLatest(): Promise<AppBskyFeedDefs.FeedViewPost> {
    const res = await this.agent.getActorLikes({
      ...this.params,
      limit: 1,
    })
    return res.data.feed[0]
  }

  async fetch({
    cursor,
  }: {
    cursor: string | undefined
    limit: number
  }): Promise<FeedAPIResponse> {
    const currentOffset = cursor ? parseInt(cursor) : 0

    const temp = await this.agent.com.atproto.identity.getFavorites({
      limit: 3,
      offset: currentOffset,
    })
    const events = temp.data.favorites
      .map(post => {
        if (post.uri) {
          return new Promise(async resolve => {
            this.agent.app.bsky.feed
              .getPostThread({
                uri: post.uri,
                depth: 0,
              })
              .then(res => {
                resolve(res.data.thread)
              })
              .catch(() => {
                resolve(null)
              })
          })
        } else {
          return null
        }
      })
      .filter(item => item !== null)

    const array = await Promise.all(events)

    if (array.length) {
      return {
        cursor: String(currentOffset + 3),
        feed: array.filter(Boolean) as FeedViewPost[],
      }
    }
    return {
      feed: [],
    }
  }
}
