import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import Animated from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {Trans} from '@lingui/macro'

import {HITSLOP_20} from '#/lib/constants'
import {useWebBodyScrollLock} from '#/lib/hooks/useWebBodyScrollLock'
import {isWeb} from '#/platform/detection'
import {useSession} from '#/state/session'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {Text} from '#/components/Typography'

const AnimatedView = Animated.createAnimatedComponent(View)

export function SwitchTip({
  onPress,
  notifyDid,
  active,
  isAnonymousPost,
}: {
  onPress: () => void
  notifyDid?: string
  active: boolean
  isAnonymousPost: boolean
}) {
  const insets = useSafeAreaInsets()
  const t = useTheme()
  const {accounts} = usePostPreState()
  const {currentAccount} = useSession()
  const isCurrentMain =
    accounts?.find(o => o?.did === currentAccount?.did)?.parentDid === undefined
      ? true
      : false
  const notiyInfo = isAnonymousPost
    ? accounts?.find(o => !o?.parentDid)
    : accounts?.find(o => o?.did === notifyDid)

  useWebBodyScrollLock(active, 'SwitchTip')

  if (!active || (isAnonymousPost && isCurrentMain)) {
    return null
  }

  return (
    <View style={styles.overlay}>
      <View
        style={[styles.switchContainer, {top: isWeb ? 60 : 52 + insets.top}]}>
        <View style={[styles.arrow, {borderBottomColor: t.palette.primary}]} />
        <AnimatedView
          hitSlop={HITSLOP_20}
          style={[styles.switchContent, {backgroundColor: t.palette.primary}]}>
          <Text style={[styles.switchText, {color: t.palette.black}]}>
            <Trans>
              Remember to switch your identity{' '}
              {`${notiyInfo ? `to @${notiyInfo?.name}` : ''}`} before
              interacting
            </Trans>
          </Text>
          <TouchableOpacity
            onPress={onPress}
            accessibilityRole="button"
            accessibilityLabel={'Close'}
            accessibilityHint=""
            style={{position: 'absolute', top: 8, right: 10}}>
            <IconCircle
              style={[styles.closeBtn]}
              icon={Close}
              iconStyle={{color: ''}}
              size="xs"
            />
          </TouchableOpacity>
        </AnimatedView>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  overlay: {
    //@ts-ignore
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  },
  switchContainer: {
    zIndex: 1,
    // @ts-ignore web only
    // position: isWeb ? 'fixed' : 'absolute',
    flex: 1,
  },
  switchContent: {
    width: '92%',
    padding: 16,
    paddingRight: 30,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  switchText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  closeBtn: {
    width: 20,
    height: 20,
    backgroundColor: '#00000080',
  },
  arrow: {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
  },
})
