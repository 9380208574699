import React from 'react'
import {AppBskyActorDefs, moderateProfile, ModerationOpts} from '@atproto/api'
import {keepPreviousData, useQuery, useQueryClient} from '@tanstack/react-query'

import {isJustAMute} from '#/lib/moderation'
import {logger} from '#/logger'
import {STALE} from '#/state/queries'
import {useAgent} from '#/state/session'
import {CircleFromType} from '#/view/screens/Circle'
import {useModerationOpts} from '../preferences/moderation-opts'
import {DEFAULT_LOGGED_OUT_PREFERENCES} from './preferences'

const DEFAULT_MOD_OPTS = {
  userDid: undefined,
  prefs: DEFAULT_LOGGED_OUT_PREFERENCES.moderationPrefs,
}

const RQKEY_ROOT = 'actor-autocomplete'
export const RQKEY = (prefix: string) => [RQKEY_ROOT, prefix]
export interface SearchedActor extends AppBskyActorDefs.ProfileViewBasic {
  degree?: number
  sourceType?: CircleFromType
}

export function useActorAutocompleteQuery(
  prefix: string,
  maintainData?: boolean,
  limit?: number,
) {
  const moderationOpts = useModerationOpts()
  const agent = useAgent()

  prefix = prefix.toLowerCase().trim()
  if (prefix.endsWith('.')) {
    // Going from "foo" to "foo." should not clear matches.
    prefix = prefix.slice(0, -1)
  }

  return useQuery<AppBskyActorDefs.ProfileViewBasic[]>({
    staleTime: STALE.MINUTES.ONE,
    queryKey: RQKEY(prefix || ''),
    async queryFn() {
      const res = prefix
        ? await agent.searchActorsTypeahead({
            q: prefix,
            limit: limit || 8,
          })
        : undefined
      return res?.data.actors || []
    },
    select: React.useCallback(
      (data: AppBskyActorDefs.ProfileViewBasic[]) => {
        return computeSuggestions({
          q: prefix,
          searched: data,
          moderationOpts: moderationOpts || DEFAULT_MOD_OPTS,
        })
      },
      [prefix, moderationOpts],
    ),
    placeholderData: maintainData ? keepPreviousData : undefined,
  })
}

export type ActorAutocompleteFn = ReturnType<typeof useActorAutocompleteFn>
export function useActorAutocompleteFn() {
  const queryClient = useQueryClient()
  const moderationOpts = useModerationOpts()
  const agent = useAgent()

  return React.useCallback(
    async ({query, limit = 5}: {query: string; limit?: number}) => {
      query = query.toLowerCase()
      let res: SearchedActor[] = []
      if (query) {
        try {
          const spizFn = async () => {
            const res = await queryClient.fetchQuery({
              staleTime: STALE.MINUTES.ONE,
              queryKey: RQKEY('spiz' + query),
              queryFn: () =>
                agent.searchActorsTypeahead({
                  q: query,
                  limit,
                }),
            })
            return (res?.data.actors || [])?.map(item => {
              return {
                ...item,
                degree: item?.degree,
                sourceType: 'sipz',
              } as SearchedActor
            })
          }

          const telegramFn = async () => {
            const res = await queryClient.fetchQuery({
              staleTime: STALE.MINUTES.ONE,
              queryKey: RQKEY('telegram' + query),
              queryFn: () =>
                agent.app.bsky.actor.searchTelegrams({
                  q: query,
                  limit,
                }),
            })
            return (res?.data.telegrams || [])?.map(item => {
              return {
                did: item?.userId,
                handle: item?.handle,
                displayName: item?.displayName,
                avatar: item?.avatar,
                degree: item?.degree,
                sourceType: 'telegram',
              } as SearchedActor
            })
          }
          const responses = await Promise.all([spizFn(), telegramFn()])
          res = responses[0].concat(responses[1])
        } catch (e) {
          logger.error('useActorSearch: searchActorsTypeahead failed', {
            message: e,
          })
        }
      }

      return computeSuggestions({
        q: query,
        searched: res,
        moderationOpts: moderationOpts || DEFAULT_MOD_OPTS,
      })
    },
    [queryClient, moderationOpts, agent],
  )
}

function computeSuggestions({
  q,
  searched = [],
  moderationOpts,
}: {
  q?: string
  searched?: SearchedActor[]
  moderationOpts: ModerationOpts
}) {
  let items: AppBskyActorDefs.ProfileViewBasic[] = []
  for (const item of searched) {
    if (!items.find(item2 => item2.handle === item.handle)) {
      items.push(item)
    }
  }
  return items.filter(profile => {
    const modui = moderateProfile(profile, moderationOpts).ui('profileList')
    const isExactMatch = q && profile.handle.toLowerCase() === q
    return isExactMatch || !modui.filter || isJustAMute(modui)
  })
}
