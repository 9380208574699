import {TW_CLIENT_ID, TW_TARGET_URL} from '../constants'
import {windowFeatures} from './popupWinConf'

export const openXAuthWindow = (state = 'twitter') => {
  const searchParams = new URLSearchParams({
    response_type: 'code',
    client_id: TW_CLIENT_ID,
    code_challenge: 'challenge',
    code_challenge_method: 'plain',
    state: state,
  })

  const scope = [
    'tweet.read',
    'tweet.write',
    'users.read',
    'follows.write',
    'follows.read',
    'offline.access',
  ].join(' ')

  const queryString = [
    `redirect_uri=${TW_TARGET_URL}`,
    `scope=${encodeURIComponent(scope)}`,
    searchParams.toString(),
  ].join('&')

  window.open(
    `https://twitter.com/i/oauth2/authorize?${queryString}`,
    'SendingMeAuthWin',
    windowFeatures,
  )
}
