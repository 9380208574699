import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import Animated from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

import {HITSLOP_20} from '#/lib/constants'
import {isWeb} from '#/platform/detection'
import {Text} from '#/components/Typography'

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity)

export function LoadFeedBtn({
  onPress,
  label,
}: {
  onPress: () => void
  label: string
}) {
  const insets = useSafeAreaInsets()

  return (
    <View style={[styles.pos, {top: isWeb ? 100 : 100 + insets.top}]}>
      <AnimatedTouchableOpacity
        style={[styles.loadLatest]}
        onPress={onPress}
        hitSlop={HITSLOP_20}
        accessibilityRole="button"
        accessibilityLabel={label}
        accessibilityHint="">
        <FontAwesomeIcon icon="arrow-up" size={16} color="#000" />
        <Text style={{color: '#000', marginLeft: 10}}>
          <Trans>New tea</Trans>
        </Text>
      </AnimatedTouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  pos: {
    // @ts-ignore web only
    position: isWeb ? 'fixed' : 'absolute',
    // @ts-ignore web only
    left: 'calc(50vw - 60px)',
    flex: 1,
  },
  loadLatest: {
    width: 120,
    height: 32,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    backgroundColor: '#FCD010',
    boxShadow: '0px 2px 10px 0px #00000033',
  },
  loadLatestInline: {
    // @ts-ignore web only
    left: 'calc(50vw - 282px)',
  },
  loadLatestOutOfLine: {
    // @ts-ignore web only
    left: 'calc(50vw - 382px)',
  },
  indicator: {
    position: 'absolute',
    top: 3,
    right: 3,
    width: 12,
    height: 12,
    borderRadius: 6,
    borderWidth: 1,
  },
})
