import React from 'react'
import {Pressable, Text, View, ViewStyle} from 'react-native'
import {useQueryClient} from '@tanstack/react-query'

import {SHARE_URL} from '#/lib/constants'
import {shareUrl} from '#/lib/sharing'
import {isMobileWeb} from '#/platform/detection'
import TagFilter from '#/view/com/tags/TagFilter'
import {Tag} from '#/view/com/tags/TagItem'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {CopyCircleLinkIcon, RightIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {Loader} from '#/components/Loader'
import {SmallContact} from '../Telegram/ContactItem'
import ContactList from '../Telegram/ContactList'
import {CircleFromType, ContactItemProps} from '.'
import {CircleMemberStackAvatar} from './CircleMemberStackAvatar'
import {
  removeDuplicatesWithSet,
  telegramContactList,
  useSipzContactList,
  useTelegramContactList,
} from './hooks'

type Props = {
  circleId: number
  selected?: ContactItemProps[]
  historyMembers?: ContactItemProps[]
  onSelected?: (selected: ContactItemProps[]) => void
  style?: ViewStyle
  showCheckbox?: boolean
  isDisabled?: boolean
  show1stFriends?: boolean
}
export function SelectFriend({
  circleId,
  selected = [],
  historyMembers = [],
  onSelected,
  style,
  showCheckbox,
  isDisabled,
  show1stFriends,
}: Props) {
  const t = useTheme()
  const queryClient = useQueryClient()

  const [query, setQuery] = React.useState('')
  const [filterTags, setFilterTags] = React.useState<Tag[]>([])
  const [filterTeles, setFilterTeles] = React.useState<string[]>([])
  const [selectedFromType, setSelectedFromType] =
    React.useState<CircleFromType>('telegram')
  const [isExpand, setIsExpand] = React.useState(false)

  const {data: teleContacts = [], isFetching: teleIsFetching} =
    useTelegramContactList({
      circleId: circleId ?? -1,
      query,
      tags: filterTags,
    })
  const {isFetching: sipzIsFetching, data: sipzContacts = []} =
    useSipzContactList({
      circleId: circleId ?? -1,
      query,
      tags: filterTags,
    })
  const isFetching =
    selectedFromType === 'telegram' ? teleIsFetching : sipzIsFetching
  const data = selectedFromType === 'telegram' ? teleContacts : sipzContacts
  const contacts = React.useMemo(() => {
    if (data) {
      return removeDuplicatesWithSet(data, 'id')
        .filter(
          member => !historyMembers?.find(_member => _member.id === member.id),
        )
        .filter(o => (show1stFriends ? o?.degree === 1 : true))
    }
    return []
  }, [data, historyMembers, show1stFriends])

  const onSubmitQuery = () => {}
  const onPressCancelSearch = () => {
    setQuery('')
  }

  const handleSelected = (isSelected: boolean, _item: ContactItemProps) => {
    let list: ContactItemProps[] = selected
    if (!isSelected) {
      list = selected.filter(item => item.id !== _item.id)
    } else {
      list = [...selected, _item]
    }
    onSelected?.(list)
  }

  const onConfirmFilterTags = (_tags: Tag[], teles?: string[]) => {
    setFilterTags(_tags)
    setFilterTeles(teles ?? [])
    queryClient.invalidateQueries({
      queryKey: [telegramContactList, {tags: _tags}],
    })
  }

  let content = <EmptyBox icon="heart" message="No contacts yet" />
  if (isFetching) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_5xl]}>
        <Loader size="lg" />
      </View>
    )
  } else if (contacts.length) {
    let dataValue = contacts
    if (selectedFromType === 'telegram') {
      dataValue = contacts
        .filter(o => {
          if (filterTeles?.length === 1) {
            if (filterTeles.indexOf('registered') >= 0) {
              return o?.isAdded && o?.degree === 1
            } else {
              return (
                !(o?.isAdded && o?.degree === 1) && !o?.isAdded && o?.isInvite
              )
            }
          } else {
            return (
              (o?.isAdded && o?.degree === 1) || (!o?.isAdded && o?.isInvite)
            )
          }
        })
        ?.sort((a, b) => (b?.isAdded ? 1 : 0) - (a?.isAdded ? 1 : 0))
    }
    content = (
      <ContactList
        selected={selected}
        users={dataValue}
        historyMembers={historyMembers}
        onSelected={handleSelected}
        filterTags={filterTags}
        showCheckbox={showCheckbox}
        isDisabled={isDisabled}
      />
    )
  }

  return (
    <View style={[a.px_lg, {paddingBottom: isMobileWeb ? 90 : 0}, style]}>
      <View style={[a.my_md]}>
        <SearchInput
          query={query}
          onSubmitQuery={onSubmitQuery}
          onChangeQuery={setQuery}
          onPressCancelSearch={onPressCancelSearch}
          style={[
            a.border,
            t.atoms.input_bg,
            t.atoms.input_border,
            {borderRadius: 10},
          ]}
        />
      </View>
      <View
        style={[
          a.mb_xs,
          a.flex_col,
          a.py_lg,
          {borderBottomWidth: 1},
          t.atoms.input_border,
        ]}>
        <Pressable
          accessibilityRole="button"
          style={[a.flex_row, a.align_center, a.justify_between]}
          onPress={() => {
            setIsExpand(!isExpand)
          }}>
          <View style={[a.flex_row, a.align_center, a.gap_md]}>
            {selected?.length === 0 ? (
              <View
                style={[
                  {width: 34, height: 34, borderRadius: 17, borderWidth: 1},
                  t.atoms.input_bg,
                  t.atoms.input_border,
                  a.align_center,
                  a.justify_center,
                ]}>
                <Text style={[t.atoms.text_sub, a.font_bold]}>0</Text>
              </View>
            ) : (
              <CircleMemberStackAvatar
                data={selected ?? []}
                maxCount={8}
                size={30}
                bgColor={t.atoms.bg.backgroundColor}
              />
            )}
            <Text
              style={[
                a.font_semibold,
                selected?.length ? t.atoms.text : t.atoms.text_sub,
              ]}>
              {selected?.length} Selected
            </Text>
          </View>
          <RightIcon
            style={[
              !isExpand
                ? {transform: 'scale(1.2) rotate(90deg)'}
                : {transform: 'scale(1.2) rotate(270deg)'},
            ]}
            color={t.palette.gray_10}
          />
        </Pressable>
        {isExpand && (
          <View style={[a.mt_lg, a.mr_md]}>
            {selected?.length > 0 && (
              <View
                style={[a.flex_row, a.flex_wrap, a.gap_sm, a.overflow_hidden]}>
                {selected.map((contact: ContactItemProps) => {
                  const disabled =
                    isDisabled ??
                    !!historyMembers?.find(_item => _item.id === contact.id)

                  return (
                    <SmallContact
                      key={contact.id}
                      user={contact}
                      disabled={disabled}
                      onSelected={handleSelected}
                    />
                  )
                })}
              </View>
            )}
          </View>
        )}
      </View>
      <TagFilter
        selectedFromType={selectedFromType}
        onSelected={setSelectedFromType}
        onConfirm={onConfirmFilterTags}
      />
      {Number(circleId) > 0 && (
        <Pressable
          accessibilityRole="button"
          style={[a.flex_row, a.align_center, a.gap_sm]}
          role="button"
          onPress={() => {
            shareUrl(`${SHARE_URL}circle/detail/${circleId}`)
          }}>
          <View
            style={[{width: 44, height: 44}, a.align_center, a.justify_center]}>
            <CopyCircleLinkIcon />
          </View>
          <Text style={[a.font_semibold, {color: t.palette.primary_active}]}>
            Invite to Circle via Link
          </Text>
        </Pressable>
      )}
      <View style={[{marginHorizontal: -4}]}>{content}</View>
    </View>
  )
}
