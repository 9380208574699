import React from 'react'
import {View} from 'react-native'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {atoms as a} from '#/alf'
import {TelegramBindStep} from '../com/modals/TelegramModal/config'
import * as TelegramModal from '../com/modals/TelegramModal/TelegramModal.web'

export const TelegramBindScreen = () => {
  const navigator = useNavigation<NavigationProp>()

  const params = navigator.getState().routes[0].params as any

  const step =
    params?.step >= 1 && params?.step <= 6
      ? (params?.step as TelegramBindStep)
      : TelegramBindStep.Init

  return (
    <View style={[a.align_center, a.justify_center, a.pt_2xl]}>
      <View style={[{maxWidth: 420}, a.w_full]}>
        <TelegramModal.Component name="telegram" currentStep={+step} />
      </View>
    </View>
  )
}
