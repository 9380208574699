import {createMultiPathSVG} from './TEMPLATE'

export const Circle_Left_Nav = createMultiPathSVG({
  viewBox: '0 0 24 24',
  paths: [
    'M17.91 2.75C16.8942 2.75 16.08 3.56421 16.08 4.58C16.08 5.55792 16.8321 6.34607 17.7842 6.40782C17.8641 6.40073 17.9458 6.40073 18.0257 6.40782C18.9762 6.34613 19.7316 5.559 19.74 4.57733C19.7385 3.57086 18.9229 2.75 17.91 2.75ZM14.58 4.58C14.58 2.73579 16.0657 1.25 17.91 1.25C19.7561 1.25 21.24 2.74768 21.24 4.58V4.58536H21.2399C21.2271 6.38428 19.811 7.84488 18.0271 7.90951C17.9863 7.91099 17.9454 7.90913 17.905 7.90396C17.8645 7.90913 17.8236 7.91099 17.7828 7.90951C15.9998 7.84491 14.58 6.38403 14.58 4.58Z',
    'M16.8105 8.85124C18.3308 8.58491 20.0644 8.83883 21.3246 9.67506L21.326 9.67596C22.1874 10.2502 22.7174 11.0823 22.7174 12.01C22.7174 12.9377 22.1874 13.7698 21.326 14.344C20.0751 15.1813 18.3623 15.4342 16.8458 15.1796C16.4373 15.1111 16.1617 14.7243 16.2303 14.3158C16.2989 13.9073 16.6856 13.6318 17.0941 13.7003C18.3171 13.9057 19.6236 13.6789 20.4926 13.0969L20.4939 13.096C21.0425 12.7302 21.2174 12.3223 21.2174 12.01C21.2174 11.6978 21.0427 11.2901 20.4946 10.9245C19.6149 10.3411 18.2887 10.1151 17.0694 10.3287C16.6614 10.4002 16.2727 10.1274 16.2012 9.71941C16.1297 9.31141 16.4025 8.92271 16.8105 8.85124Z',
    'M2.72998 4.58C2.72998 2.74768 4.21388 1.25 6.05998 1.25C7.9042 1.25 9.38998 2.73579 9.38998 4.58C9.38998 6.38403 7.97013 7.84491 6.18714 7.90951C6.14632 7.91099 6.10547 7.90913 6.06498 7.90396C6.0245 7.90913 5.98364 7.91099 5.94283 7.90951C4.15889 7.84488 2.74285 6.38429 2.73 4.58536L2.72998 4.58ZM4.22998 4.57733C4.23836 5.559 4.99376 6.34613 5.94423 6.40782C6.02409 6.40073 6.10588 6.40073 6.18574 6.40782C7.13785 6.34607 7.88998 5.55792 7.88998 4.58C7.88998 3.56421 7.07577 2.75 6.05998 2.75C5.04698 2.75 4.23142 3.57087 4.22998 4.57733Z',
    'M3.47529 10.9245C4.35505 10.3411 5.68115 10.1151 6.90052 10.3287C7.30852 10.4002 7.69721 10.1274 7.76869 9.71941C7.84016 9.31141 7.56736 8.92271 7.15935 8.85124C5.63904 8.58491 3.90551 8.83883 2.64527 9.67506L2.64526 9.67506L2.64392 9.67596C1.78253 10.2502 1.25244 11.0823 1.25244 12.01C1.25244 12.9374 1.78225 13.7693 2.64324 14.3436C3.8942 15.1812 5.60735 15.4343 7.12411 15.1796C7.53261 15.1111 7.80817 14.7243 7.73959 14.3158C7.67101 13.9073 7.28426 13.6318 6.87577 13.7003C5.6528 13.9057 4.3463 13.6789 3.47732 13.0969L3.47597 13.096C2.92735 12.7302 2.75244 12.3223 2.75244 12.01C2.75244 11.6978 2.92721 11.2901 3.47529 10.9245Z',
    'M11.91 10.2197C10.8942 10.2197 10.08 11.0339 10.08 12.0497C10.08 13.0276 10.8321 13.8158 11.7842 13.8775C11.8652 13.8704 11.9481 13.8705 12.0291 13.8779C12.9718 13.8238 13.7315 13.034 13.74 12.0471C13.7385 11.0406 12.923 10.2197 11.91 10.2197ZM8.57996 12.0497C8.57996 10.2055 10.0657 8.71973 11.91 8.71973C13.7561 8.71973 15.24 10.2174 15.24 12.0497V12.0551H15.2399C15.2271 13.8492 13.8147 15.3274 12.0217 15.3794C11.9827 15.3805 11.9436 15.3786 11.905 15.3737C11.8645 15.3789 11.8236 15.3807 11.7828 15.3792C9.9998 15.3146 8.57996 13.8538 8.57996 12.0497Z',
    'M12.0037 16.2344C13.1722 16.2344 14.381 16.5254 15.326 17.1553C16.1874 17.7296 16.7175 18.5617 16.7175 19.4894C16.7175 20.4169 16.1875 21.2489 15.3263 21.8232C14.3774 22.4576 13.169 22.7519 12 22.7519C10.831 22.7519 9.62258 22.4576 8.67362 21.8232C7.81243 21.2489 7.28247 20.4169 7.28247 19.4894C7.28247 18.5617 7.81256 17.7296 8.67395 17.1553L8.67575 17.1541L8.67575 17.1541C9.62473 16.5254 10.835 16.2344 12.0037 16.2344ZM9.50511 18.404C8.95719 18.7696 8.78247 19.1772 8.78247 19.4894C8.78247 19.8017 8.95738 20.2096 9.506 20.5753L9.5069 20.5759C10.1578 21.0113 11.0592 21.2519 12 21.2519C12.9407 21.2519 13.8421 21.0113 14.493 20.5759L14.4939 20.5753C15.0426 20.2096 15.2175 19.8017 15.2175 19.4894C15.2175 19.177 15.0426 18.7692 14.4939 18.4034C13.8489 17.9734 12.9477 17.7344 12.0037 17.7344C11.0604 17.7344 10.1561 17.9731 9.50511 18.404Z',
  ],
})
export const Circle_Left_Nav_Fill = createMultiPathSVG({
  viewBox: '0 0 24 24',
  paths: [
    'M6.05998 1.25C4.21388 1.25 2.72998 2.74768 2.72998 4.58L2.73 4.58536C2.74285 6.38429 4.15889 7.84488 5.94283 7.90951C5.98364 7.91099 6.0245 7.90913 6.06498 7.90396C6.10546 7.90913 6.14632 7.91099 6.18714 7.90951C7.97013 7.84491 9.38998 6.38403 9.38998 4.58C9.38998 2.73579 7.9042 1.25 6.05998 1.25Z',
    'M14.58 4.58C14.58 2.73579 16.0657 1.25 17.91 1.25C19.7561 1.25 21.24 2.74768 21.24 4.58V4.58536C21.2271 6.38428 19.811 7.84488 18.0271 7.90951C17.9863 7.91099 17.9454 7.90913 17.905 7.90396C17.8645 7.90913 17.8236 7.91099 17.7828 7.90951C15.9998 7.84491 14.58 6.38403 14.58 4.58Z',
    'M16.8105 8.85124C18.3308 8.58491 20.0644 8.83883 21.3246 9.67506L21.326 9.67596C22.1874 10.2502 22.7174 11.0823 22.7174 12.01C22.7174 12.9377 22.1874 13.7698 21.326 14.344C20.0751 15.1813 18.3623 15.4342 16.8458 15.1796C16.4373 15.1111 16.1617 14.7243 16.2303 14.3158C16.2816 14.0105 16.5106 13.7794 16.7936 13.7108C16.9416 13.1803 16.9933 12.6443 17 12.0004C17 11.3494 16.9661 10.8381 16.8265 10.3316C16.517 10.2843 16.2582 10.0449 16.2012 9.71941C16.1297 9.31141 16.4025 8.92271 16.8105 8.85124Z',
    'M7.4155 13.8156C7.5819 13.9264 7.70388 14.1031 7.73959 14.3158C7.80817 14.7243 7.53261 15.1111 7.12411 15.1796C5.60735 15.4343 3.8942 15.1812 2.64324 14.3436C1.78225 13.7693 1.25244 12.9374 1.25244 12.01C1.25244 11.0823 1.78253 10.2502 2.64392 9.67596L2.64526 9.67506C3.90551 8.83883 5.63904 8.58491 7.15935 8.85124C7.56736 8.92271 7.84016 9.31141 7.76869 9.71941C7.72673 9.95895 7.57542 10.1519 7.37506 10.2559C7.07114 10.8836 7 11.1016 7 11.9996C7 12.8937 7.10195 13.1336 7.4155 13.8156Z',
    'M11.91 8.71973C10.0657 8.71973 8.57996 10.2055 8.57996 12.0497C8.57996 13.8538 9.9998 15.3146 11.7828 15.3792C11.8236 15.3807 11.8645 15.3789 11.905 15.3737C11.9436 15.3786 11.9827 15.3805 12.0217 15.3794C13.8147 15.3274 15.2271 13.8492 15.2399 12.0551L15.24 12.0497C15.24 10.2174 13.7561 8.71973 11.91 8.71973Z',
    'M12.0037 16.2344C13.1722 16.2344 14.381 16.5254 15.326 17.1553C16.1874 17.7296 16.7175 18.5617 16.7175 19.4894C16.7175 20.4169 16.1875 21.2489 15.3263 21.8232C14.3774 22.4576 13.169 22.7519 12 22.7519C10.831 22.7519 9.62258 22.4576 8.67362 21.8232C7.81243 21.2489 7.28247 20.4169 7.28247 19.4894C7.28247 18.5617 7.81256 17.7296 8.67395 17.1553L8.67575 17.1541C9.62473 16.5254 10.835 16.2344 12.0037 16.2344Z',
  ],
})
export const Circle_Avatar = createMultiPathSVG({
  viewBox: '0 0 24 24',
  paths: [
    'M11.922 9.8693C12.5051 9.86818 13.0821 9.75166 13.6199 9.52644C14.1576 9.30123 14.6455 8.97179 15.0554 8.55711C15.4652 8.14242 15.7889 7.65071 16.0077 7.11033C16.2266 6.56994 16.3364 5.99157 16.3306 5.40857C16.3306 4.23934 15.8661 3.11801 15.0394 2.29124C14.2126 1.46447 13.0913 1 11.922 1C10.7528 1 9.63148 1.46447 8.80471 2.29124C7.97794 3.11801 7.51347 4.23934 7.51347 5.40857C7.50888 5.99126 7.6194 6.56911 7.83869 7.10898C8.05797 7.64885 8.3817 8.1401 8.7913 8.55455C9.2009 8.969 9.68832 9.29849 10.2256 9.52411C10.7628 9.74973 11.3393 9.86702 11.922 9.8693Z',
    'M16.8175 22.5382C16.8836 22.5419 16.9497 22.5317 17.0116 22.5081C17.0734 22.4846 17.1296 22.4482 17.1764 22.4014C17.2232 22.3546 17.2596 22.2984 17.2831 22.2366C17.3067 22.1747 17.3169 22.1086 17.3132 22.0425V14.5297C17.3398 13.5541 16.9866 12.6063 16.328 11.8861C15.6694 11.1658 14.7569 10.7295 13.7828 10.669H10.0612C9.08791 10.7295 8.17628 11.1662 7.51908 11.8866C6.86188 12.6071 6.51063 13.5549 6.5396 14.5297V22.0425C6.53581 22.1086 6.54604 22.1747 6.56961 22.2366C6.59318 22.2984 6.62956 22.3546 6.67636 22.4014C6.72316 22.4482 6.77933 22.4846 6.84117 22.5081C6.90302 22.5317 6.96915 22.5419 7.03523 22.5382H16.8175Z',
    'M4.60228 22.5084C4.54084 22.532 4.47504 22.5422 4.40933 22.5383V22.5209H0.487691C0.422324 22.5234 0.357149 22.5123 0.296346 22.4881C0.235542 22.464 0.180452 22.4274 0.134606 22.3808C0.0887602 22.3341 0.0531707 22.2784 0.030119 22.2171C0.0070673 22.1559 -0.00293795 22.0906 0.000744648 22.0253V16.5993C0.00981917 15.8235 0.322654 15.0821 0.872123 14.5342C1.42159 13.9864 2.16391 13.6758 2.9398 13.669H4.4528C4.51847 13.6631 4.58458 13.6734 4.64526 13.6992C4.70594 13.725 4.75931 13.7654 4.80061 13.8168C4.85211 13.868 4.88833 13.9326 4.90522 14.0032C4.92211 14.0739 4.91901 14.1478 4.89625 14.2168V22.0426C4.90134 22.1083 4.8923 22.1743 4.86975 22.2361C4.8472 22.298 4.81166 22.3543 4.76552 22.4012C4.71938 22.4482 4.66372 22.4847 4.60228 22.5084Z',
    'M23.5506 22.5083C23.4888 22.5318 23.4227 22.5421 23.3566 22.5383L19.4436 22.4948C19.3779 22.4987 19.3121 22.4885 19.2507 22.4649C19.1892 22.4412 19.1336 22.4047 19.0874 22.3577C19.0413 22.3108 19.0058 22.2545 18.9832 22.1926C18.9607 22.1308 18.9516 22.0648 18.9567 21.9992V14.1733C18.9605 14.0348 18.9931 13.8986 19.0523 13.7733C19.1045 13.669 19.2958 13.6255 19.4002 13.6255H20.9132C21.3014 13.6278 21.6854 13.7065 22.0432 13.8572C22.401 14.0079 22.7257 14.2276 22.9986 14.5037C23.2715 14.7799 23.4874 15.1071 23.6338 15.4666C23.7803 15.8262 23.8545 16.2111 23.8522 16.5993V22.0426C23.856 22.1087 23.8458 22.1749 23.8222 22.2367C23.7986 22.2986 23.7623 22.3547 23.7155 22.4015C23.6686 22.4483 23.6125 22.4847 23.5506 22.5083Z',
    'M22.4161 12.3344C21.8373 12.7103 21.1597 12.9054 20.4697 12.8951C20.0157 12.894 19.5665 12.8029 19.1479 12.6271C18.7293 12.4512 18.3498 12.1942 18.0312 11.8707C17.7126 11.5473 17.4613 11.1639 17.2918 10.7427C17.1223 10.3215 17.038 9.87092 17.0437 9.41695C17.0438 8.72684 17.2492 8.05234 17.6337 7.47929C18.0182 6.90624 18.5646 6.46053 19.2031 6.19888C19.8417 5.93723 20.5437 5.87146 21.2198 6.00995C21.8959 6.14844 22.5155 6.48491 22.9998 6.97657C23.4841 7.46823 23.8111 8.09284 23.9394 8.77093C24.0677 9.44902 23.9913 10.1499 23.72 10.7845C23.4488 11.4191 22.9949 11.9586 22.4161 12.3344Z',
    'M4.80214 12.6294C4.38304 12.8048 3.93324 12.8952 3.47891 12.8951C3.02458 12.8951 2.57476 12.8048 2.15567 12.6294C1.73658 12.454 1.35656 12.1969 1.03774 11.8733C0.718918 11.5496 0.46766 11.1657 0.29859 10.744C0.12952 10.3223 0.0460133 9.87123 0.0529311 9.41695C0.0529311 8.50833 0.413881 7.63692 1.05638 6.99442C1.69887 6.35192 2.57028 5.99097 3.47891 5.99097C4.38754 5.99097 5.25895 6.35192 5.90144 6.99442C6.54394 7.63692 6.90489 8.50833 6.90489 9.41695C6.91181 9.87123 6.8283 10.3223 6.65923 10.744C6.49016 11.1657 6.2389 11.5496 5.92008 11.8733C5.60125 12.197 5.22123 12.454 4.80214 12.6294Z',
  ],
})
