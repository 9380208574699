import React, {useMemo} from 'react'
import {View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useProfileQuery} from '#/state/queries/profile'
import {type SessionAccount, useSession} from '#/state/session'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button} from './Button'
import {Text} from './Typography'

export function AccountList({
  onSelectAccount,
  // onSelectOther,
  // otherLabel,
  pendingDid,
}: {
  onSelectAccount: (account: SessionAccount) => void
  onSelectOther: () => void
  otherLabel?: string
  pendingDid: string | null
}) {
  const {currentAccount, accounts} = useSession()
  const t = useTheme()
  const showAccounts = useMemo(
    () => accounts.filter(account => account.accessJwt && account.mnemonic),
    [accounts],
  )

  return (
    <>
      {showAccounts.length > 0 && (
        <Text
          style={[
            {color: t.palette.gray_10, fontWeight: '500'},
            a.mb_md,
            a.text_md,
          ]}>
          Recent
        </Text>
      )}
      <View
        pointerEvents={pendingDid ? 'none' : 'auto'}
        style={[
          a.rounded_md,
          a.overflow_hidden,
          // {borderWidth: 1},
          t.atoms.border_contrast_low,
        ]}>
        {showAccounts.map(account => (
          <React.Fragment key={account.did}>
            <AccountItem
              account={account}
              onSelect={onSelectAccount}
              isCurrentAccount={account.did === currentAccount?.did}
              isPendingAccount={account.did === pendingDid}
            />
          </React.Fragment>
        ))}
      </View>
    </>
  )
}

function AccountItem({
  account,
  onSelect,
  isCurrentAccount,
  isPendingAccount,
}: {
  account: SessionAccount
  onSelect: (account: SessionAccount) => void
  isCurrentAccount: boolean
  isPendingAccount: boolean
}) {
  const t = useTheme()
  const {_} = useLingui()
  const {data: profile} = useProfileQuery({did: account.did})

  const onPress = React.useCallback(() => {
    onSelect(account)
  }, [account, onSelect])

  return (
    <Button
      testID={`chooseAccountBtn-${account.handle}`}
      key={account.did}
      style={[
        a.flex_1,
        a.border,
        a.rounded_full,
        a.overflow_hidden,
        a.mb_lg,
        t.atoms.border_contrast_medium,
      ]}
      onPress={onPress}
      label={
        isCurrentAccount
          ? _(
              msg`Continue as ${formatHandler(
                account.handle,
              )} (currently signed in)`,
            )
          : _(msg`Sign in as ${formatHandler(account.handle)}`)
      }>
      {({hovered, pressed}) => (
        <View
          style={[
            a.flex_1,
            a.flex_row,
            a.rounded_md,
            a.align_center,
            {height: 56},
            (hovered || pressed || isPendingAccount) && t.atoms.bg_contrast_25,
          ]}>
          <View style={a.p_sm}>
            <UserAvatar avatar={profile?.avatar} size={44} />
          </View>
          <View style={[a.flex_1, a.py_sm]}>
            <View style={[a.flex_1]}>
              <Text style={[a.font_bold]}>
                {profile?.displayName || formatHandler(account.handle)}
              </Text>
            </View>
            <View>
              <Text style={[t.atoms.text_contrast_medium]}>
                {formatHandler(account.handle)}
              </Text>
            </View>
          </View>
          {/* {isCurrentAccount ? (
            <Check
              size="sm"
              style={[{color: t.palette.positive_600}, a.mr_md]}
            />
          ) : (
            <Chevron size="sm" style={[t.atoms.text, a.mr_md]} />
          )} */}
        </View>
      )}
    </Button>
  )
}
