import React from 'react'
import {View} from 'react-native'

import {CircleListType} from '#/view/com/modals/PostModal/PostSelectCircle'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {atoms as a} from '#/alf'
import {ContactItemProps} from '.'
import {CircleAvatar} from './CircleAvatar'

export const CircleMemberStackAvatar = ({
  data,
  maxCount = 3,
  emptyIcon,
  size = 24,
  bgColor = '#fff',
  type = 'user',
}: {
  data: ContactItemProps[] | CircleListType[]
  maxCount?: number
  emptyIcon?: React.ReactNode
  size?: number
  bgColor?: string
  type?: 'circle' | 'user'
}) => {
  const avatars: ContactItemProps[] | CircleListType[] = []
  const absoluteWidth = (size / 3) * 2
  let width = 15
  data?.forEach(item => {
    if (item && avatars.length < maxCount) {
      avatars.push(item)
      width += absoluteWidth
    }
  })

  return (
    <View
      style={[a.flex_row, a.relative, {width: avatars.length ? width : 20}]}>
      {avatars.length > 0
        ? avatars?.map((item, index: number) => {
            return (
              <View
                style={[
                  a.align_center,
                  a.justify_center,
                  {width: size + 4, height: size + 4, backgroundColor: bgColor},
                  index > 0
                    ? {position: 'absolute', left: absoluteWidth * index}
                    : {},
                  type === 'user' && {borderRadius: (size + 4) / 2},
                  type === 'circle' && {borderRadius: size / 5},
                ]}
                key={index}>
                {type === 'user' && (
                  <UserAvatar
                    size={size}
                    avatar={item?.avatar}
                    name={
                      (item as ContactItemProps)?.from === 'telegram'
                        ? (item as ContactItemProps)?.nickname ||
                          (item as ContactItemProps)?.username
                        : undefined
                    }
                  />
                )}
                {type === 'circle' && (
                  <CircleAvatar size={size} circle={item} />
                )}
              </View>
            )
          })
        : emptyIcon}
    </View>
  )
}
