import React, {useState} from 'react'
import {Text, View} from 'react-native'
import {ComAtprotoServerCircleQueryApproveInfos} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {makeProfileLink} from '#/lib/routes/links'
import {TextLink} from '#/view/com/util/Link'
import * as Toast from '#/view/com/util/Toast'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {Link} from '#/components/Link'
import {CircleAvatar} from './CircleAvatar'
import {useCircleAgreeOrRejectMutation} from './hooks'

export default function CircleRequestItem(
  props: ComAtprotoServerCircleQueryApproveInfos.ApproveInfo,
) {
  const t = useTheme()
  const {_} = useLingui()
  const agreeCircleMutation = useCircleAgreeOrRejectMutation()

  const [loading, setLoading] = useState<boolean>(false)

  const isSendOwner =
    props?.isSendCircleOwner && props?.approveType === 'invite_sipz'
  const showUser =
    props?.type === 'send' || props?.approveType === 'apply' || isSendOwner

  const onAccept = async () => {
    if (!loading && props?.approveId) {
      try {
        setLoading(true)
        await agreeCircleMutation.mutateAsync({
          approveId: props?.approveId,
        })
        let suMsg = _(msg`Welcome to the`) + ' ' + props?.circleName
        if (props?.approveType === 'invite_sipz') {
          suMsg = _(msg`The request has been sent, please wait.`)
        }
        Toast.show(suMsg)
      } catch (error: any) {
        Toast.show(
          error?.errorMessage ||
            error?.message ||
            _(msg`cause some error, please try again later.`),
          'xmark',
        )
      } finally {
        setLoading(false)
      }
    }
  }

  const getHref = () => {
    let linkHref = ''
    if (showUser) {
      linkHref = `/profile/${props?.actor?.handle}`
    } else {
      linkHref = `/circle/detail/${props?.circleId}`
    }
    if (props?.type === 'receive' && props?.approveStatus === 0) {
      linkHref +=
        `?approveId=` + props?.approveId + '&approveType=' + props?.approveType
    }

    return linkHref
  }

  return (
    <View
      style={[
        a.p_md,
        a.flex_row,
        a.justify_between,
        t.atoms.input_bg,
        a.rounded_md,
        a.gap_md,
      ]}>
      {/* Avatar & Name & Description*/}
      <View style={[a.flex_row, a.flex_1, a.gap_md]}>
        {/* Avatar */}
        <Link to={getHref()} label="">
          {showUser ? (
            <UserAvatar
              style={[a.rounded_full, a.overflow_hidden]}
              avatar={props?.actor?.avatar}
              size={48}
              name={
                props?.actor?.userType === 'sipz'
                  ? undefined
                  : props?.actor?.displayName
              }
            />
          ) : (
            <CircleAvatar
              size={48}
              circle={{
                id: props?.circleId + '',
                name: props?.circleName,
                avatar: props?.circlePhoto,
              }}
            />
          )}
        </Link>
        <View style={[a.flex_1]}>
          {/* Name */}
          {showUser ? (
            <View style={[a.flex_row, a.align_center, a.gap_xs]}>
              <Text
                style={[a.font_bold, a.text_md, t.atoms.text]}
                numberOfLines={1}>
                {props?.actor?.displayName}
              </Text>
              <Text style={[a.text_xs, t.atoms.text_sub]} numberOfLines={1}>
                {props?.actor?.handle
                  ? `@${formatHandler(props?.actor?.handle)}`
                  : ''}
              </Text>
            </View>
          ) : (
            <Text
              style={[a.font_bold, a.text_md, t.atoms.text]}
              numberOfLines={1}>
              {props?.circleName}
            </Text>
          )}
          {/* Description */}
          <View>
            {props?.approveType === 'apply' && (
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <Text
                  style={[a.text_xs, a.my_2xs, t.atoms.text_sub]}
                  numberOfLines={1}>
                  <Trans>Request to join</Trans>
                </Text>
                <TextLink
                  type="sm"
                  href={`/circle/detail/${props?.circleId}`}
                  text={`#${props?.circleName}`}
                  style={[a.text_xs, t.atoms.text_sub]}
                />
              </View>
            )}
            {/* receive invitation */}
            {props?.type === 'receive' &&
              props?.approveType &&
              ['invite', 'invite_sipz'].indexOf(props?.approveType) >= 0 && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <Text
                    style={[a.text_xs, a.my_2xs, t.atoms.text_sub]}
                    numberOfLines={1}>
                    {isSendOwner ? (
                      <Trans>Request to Join</Trans>
                    ) : (
                      <Trans>Accept to Join</Trans>
                    )}
                  </Text>
                  <TextLink
                    type="sm"
                    href={`/circle/detail/${props?.circleId}`}
                    text={`#${props?.circleName}`}
                    style={[a.text_xs, t.atoms.text_sub]}
                  />
                </View>
              )}
            {props?.type === 'receive' ? (
              <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                <Text
                  style={[a.text_xs, a.my_2xs, t.atoms.text_sub]}
                  numberOfLines={1}>
                  <Trans>From: invited by</Trans>
                </Text>
                {props?.inviter &&
                  props?.inviter?.userId &&
                  props?.inviter?.handle && (
                    <TextLink
                      type="sm"
                      href={makeProfileLink({
                        handle: props?.inviter?.handle,
                        did: props?.inviter?.userId,
                      })}
                      text={`@${formatHandler(props?.inviter?.handle)}`}
                      style={[a.text_xs, t.atoms.text_sub]}
                    />
                  )}
              </View>
            ) : (
              props?.approveType &&
              ['invite', 'invite_sipz'].indexOf(props?.approveType) >= 0 && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <Text
                    style={[a.text_xs, a.my_2xs, t.atoms.text_sub]}
                    numberOfLines={1}>
                    <Trans>Invite to join</Trans>
                  </Text>
                  <TextLink
                    type="sm"
                    href={`/circle/detail/${props?.circleId}`}
                    text={`#${props?.circleName}`}
                    style={[a.text_xs, t.atoms.text_sub]}
                  />
                </View>
              )
            )}
          </View>
        </View>
      </View>
      {/* right content*/}
      <View style={[a.flex_row, a.justify_end]}>
        {props?.type === 'send' && (
          <View style={[a.flex_row, a.gap_sm]}>
            <ArrowUpLeftIcon style={{marginTop: 2}} />
            <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
              {props?.approveStatus === 0 && <Trans>Request sent</Trans>}
              {props?.approveStatus === 1 && <Trans>Added</Trans>}
            </Text>
          </View>
        )}
        {props?.type === 'receive' && props?.approveStatus === 0 && (
          <Button
            style={[{marginLeft: 'auto'}, a.self_start, a.mt_md]}
            label="circle detail"
            color="primary"
            variant="solid"
            size="small"
            onPress={onAccept}>
            <ButtonText>
              <Trans>Accept</Trans>
            </ButtonText>
          </Button>
        )}
        {props?.type === 'receive' && props?.approveStatus === 1 && (
          <View style={[a.flex_row, a.gap_sm]}>
            <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
              {props?.circleUserStatus === 'join' ? (
                <Trans>Added</Trans>
              ) : (
                <Trans>Awaiting approval</Trans>
              )}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}
