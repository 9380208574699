import {useEffect} from 'react'

import {isWeb} from '#/platform/detection'

let refCount = 0

function incrementRefCount() {
  // console.log(
  //   'scroll-incrementRefCount',
  //   refCount,
  //   refCount === 0 ? 'hideScroll' : '',
  // )
  if (refCount === 0) {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.scrollbarGutter = 'auto'
  }
  refCount++
}

export function decrementRefCount(isReset?: boolean) {
  if (isReset) {
    refCount = 0
  } else {
    refCount--
  }
  // console.log(
  //   'scroll-decrementRefCount',
  //   refCount,
  //   refCount === 0 ? 'showScroll' : '',
  // )
  if (refCount === 0) {
    document.body.style.overflow = ''
    document.documentElement.style.scrollbarGutter = ''
  }
}

export function useWebBodyScrollLock(isLockActive: boolean, from?: string) {
  useEffect(() => {
    if (!isWeb || !isLockActive) {
      return
    }
    console.log('scroll-useWebBodyScrollLock', isLockActive, refCount, from)
    incrementRefCount()
    return () => decrementRefCount()
  })
}
