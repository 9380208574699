/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import React, {useRef, useState} from 'react'
import {Image, TextInput, View} from 'react-native'
import {StyleSheet, Text} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {capitalize} from '#/lib/strings/capitalize'
import {useModalControls} from '#/state/modals'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon} from '#/components/Button'
import {Loader} from '#/components/Loader'

const phonecodeImg = require('../../../../../assets/telegram/phonecode.png')

interface IProps {
  phone?: string
  onLogin: (code: string, cb?: () => void) => void
}
export function LoginCode(props: IProps) {
  const {phone, onLogin} = props
  const {isDesktop} = useWebMediaQueries()
  const {closeModal} = useModalControls()
  const t = useTheme()
  const [code, setCode] = useState<string[]>(Array.from({length: 5}, () => ''))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const input1Ref = useRef<TextInput>(null)
  const input2Ref = useRef<TextInput>(null)
  const input3Ref = useRef<TextInput>(null)
  const input4Ref = useRef<TextInput>(null)
  const input5Ref = useRef<TextInput>(null)

  const inputRef = [input1Ref, input2Ref, input3Ref, input4Ref, input5Ref]

  const onConfirm = () => {
    if (code.filter(Boolean).length === 5 && /\d+/.test(code.join(''))) {
      setLoading(true)
      onLogin(code.join(''), () => {
        setLoading(false)
        setError(true)
      })
    }
  }

  return (
    <View style={[a.flex_1, a.py_5xl, a.px_xl]}>
      <View>
        <View style={[a.justify_center, a.align_center]}>
          <Image
            source={phonecodeImg}
            style={[{width: 90, height: 83}]}
            accessibilityIgnoresInvertColors
          />
        </View>
        <Text style={[styles.title, t.atoms.text, a.text_center, a.mt_4xl]}>
          <Trans>{phone}</Trans>
        </Text>
        <Text style={[a.mb_2xl, a.text_center, t.atoms.text_sub]}>
          <Trans>
            We’ve sent the code to the Telegram app on your other device.
          </Trans>
        </Text>
        {error && (
          <Text style={[error ? t.atoms.text_danger : t.atoms.text, a.m_md]}>
            <Trans>{capitalize('PHONE_CODE_INVALID', '_', ' ')}</Trans>
          </Text>
        )}
        <View style={[a.mb_lg, a.justify_between, a.flex_row, a.align_center]}>
          {code.map((item, index) => (
            <View key={index}>
              <TextInput
                style={[
                  a.border,
                  error
                    ? {borderColor: t.atoms.text_danger.color}
                    : t.atoms.input_border,
                  t.atoms.input_bg,
                  a.text_center,
                  a.font_bold,
                  a.rounded_sm,
                  a.text_lg,
                  t.atoms.text,
                  {lineHeight: 20, width: 50, height: 50},
                ]}
                ref={inputRef[index]}
                value={item}
                inputMode="numeric"
                maxLength={1}
                keyboardType="numeric"
                onKeyPress={e => {
                  if ((e as any).code === 'Backspace' && !code[index]) {
                    inputRef[index - 1]?.current?.focus()
                  }
                }}
                onChangeText={val => {
                  if (val && index < 4) {
                    inputRef[index + 1].current?.focus()
                  }
                  setError(false)
                  setCode(c => {
                    c.splice(index, 1, val)
                    return [...c]
                  })
                }}
              />
            </View>
          ))}
        </View>
      </View>

      <View
        style={[{gap: 24, marginTop: 16}, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          variant="solid"
          size="large"
          disabled={loading}
          label="Confirm"
          style={[a.flex_1, a.p_0, {gap: 12, backgroundColor: '#3390ED'}]}
          onPress={onConfirm}>
          <Text style={[a.font_bold, a.text_md, {color: '#ffffff'}]}>
            <Trans>Confirm</Trans>
          </Text>
          {loading && <ButtonIcon size="md" icon={Loader} />}
        </Button>
        <Button
          style={[t.atoms.bg_btn_default]}
          disabled={loading}
          onPress={closeModal}
          label="Cancel"
          variant="solid"
          size="large">
          <Text style={[a.font_bold, t.atoms.text_sub, a.text_md]}>
            <Trans>Cancel</Trans>
          </Text>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
