import React from 'react'
import {ScrollView, View} from 'react-native'

import {atoms as a} from '#/alf'
import TagItem, {Tag} from './TagItem'

type Props = {
  tags: Tag[]
  max?: number
  selected?: Tag[]
  showEditor?: boolean
  showCheckbox?: boolean
  onSelected?: (selected: boolean, tag: Tag) => void
}

export default function TagList({
  tags,
  selected = [],
  showEditor,
  showCheckbox,
  onSelected,
}: Props) {
  return (
    <ScrollView>
      <View style={[a.gap_md, a.flex_row, a.flex_wrap]}>
        {tags &&
          tags.length > 0 &&
          tags.map(t => {
            const isSelected = !!selected.find(tag => tag.id === t.id)
            return (
              <TagItem
                selected={isSelected}
                showEditor={showEditor}
                showCheckbox={showCheckbox}
                key={t.id}
                tag={t}
                onSelected={onSelected}
              />
            )
          })}
      </View>
    </ScrollView>
  )
}
