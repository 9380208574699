import ReactGA from 'react-ga4'

const id = process.env.REACT_APP_GA_TRACKING_ID || ''

class AnalyticsService {
  constructor() {
    ReactGA.initialize(id, {
      gaOptions: {
        cookie_flags: 'SameSite=None; Secure',
      },
    })
  }

  public pageview(pathname: string) {
    ReactGA.send({
      hitType: 'pageview',
      page_path: pathname,
    })
  }

  public event(
    category: string,
    action: string,
    label?: string,
    value?: number,
  ) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    })
  }

  public inviteEvent(handle: string) {
    this.event('button_click', '注册事件', `${handle}`, 1)
  }

  public loginEvent(handle: string) {
    this.event('button_click', '登录事件', `${handle}`, 1)
  }

  public timing(
    category: string,
    variable: string,
    time: number,
    label?: string,
  ) {
    ReactGA.event({
      category: category,
      action: 'timing',
      label: label || variable,
      value: time,
    })
  }
}

export const GA = new AnalyticsService()
