import {useCallback, useState} from 'react'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {createBet} from '#/lib/ethers/bet'
import {createBetRequest} from '#/lib/ethers/bet_request'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {
  BetModalType,
  BetType,
  TransPostAuthorProps,
} from '#/view/com/modals/BetToEarn'
import {simplifyMsg} from '#/view/com/util/Transaction'
import * as Toast from 'view/com/util/Toast'
import {useModalControls} from '../modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '../queries/transaction-bet'
import {useWallet} from '../shell/wallet'

export type BetProps = {
  type: BetModalType
  rKey?: string
  transPostAuthor?: TransPostAuthorProps
  betOnOwnerTotal?: number // 1: challenge;
}

export interface IOnBet {
  rKey: string
  transPostAuthor: TransPostAuthorProps
}
export type JudgeModeType = 'committee_ruling' | 'popularity'

export const useBet = (props: BetProps) => {
  const {type} = props
  const {provider, currentWallet} = useWallet()
  const {openModal, closeModal} = useModalControls()
  const {_} = useLingui()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()

  const INIT_CONFIG: {[key: string]: {amount: number; betType: BetType}} = {
    'join-bet': {
      amount: 5,
      betType: 'on',
    },
    challenge: {
      amount: props?.betOnOwnerTotal ?? 5,
      betType: 'against',
    },
    'create-bet': {
      amount: 5,
      betType: 'on',
    },
    'create-bet-request': {
      amount: 5,
      betType: 'on',
    },
  }

  const [parentBetType, setParentBetType] = useState<'on' | 'against'>(
    type ? INIT_CONFIG?.[type]?.betType ?? 'against' : 'against',
  )
  const [parentAmount, setParentAmount] = useState<number | undefined>(
    undefined,
  )
  const [parentError, setParentError] = useState<string | undefined>(undefined)
  const [parentPending, setParentPending] = useState<boolean>(false)
  const [response, setResponse] = useState<
    {success: boolean; hash?: string} | undefined
  >(undefined)
  const [parentEndTime, setParentEndTime] = useState<number | undefined>(
    undefined,
  )
  const [parentJudgeMode, setParentJudgeMode] =
    useState<JudgeModeType>('popularity')
  const [parentPublicSide, setParentPublicSide] = useState<boolean>(false)

  const onCreateBet = useCallback(
    async (betProps: IOnBet) => {
      console.log('onCreateBet-click', betProps, parentAmount, parentEndTime)
      const postId = `${betProps.transPostAuthor?.createDid?.split(':')?.[2]}/${
        betProps.rKey
      }`
      const urIParam = makeRecordUri(
        betProps.transPostAuthor?.createDid ?? betProps.transPostAuthor?.did,
        'app.bsky.feed.post',
        betProps.rKey,
      )
      try {
        if (!currentWallet?.publicKey) {
          openModal({name: 'connect-wallet', type: 'connect'})
          return
        }
        if (!parentAmount || parentAmount < 5 || !parentEndTime) {
          return
        }
        setParentPending(true)
        const tx = await createBet(
          provider,
          parentAmount,
          PUBLIC_ENV_CHAINID,
          postId,
          currentWallet?.publicKey,
          parentPublicSide ? parentBetType : 'private',
          parentEndTime,
        )
        console.log('onCreateBet', tx)
        if (tx) {
          try {
            const res = await createTransMutation.mutateAsync({
              param: {
                id: tx?.hash,
                payer:
                  betProps?.transPostAuthor?.createDid ??
                  betProps?.transPostAuthor?.did,
                method: 'insider-bet.create',
                payload: JSON.stringify({
                  judge_mode: parentJudgeMode,
                  public_side: parentPublicSide,
                  side: parentBetType === 'on' ? true : false,
                }),
              },
            })
            console.log('createTransMutation', res)
          } catch (error) {
            console.log('createTransMutation-error', error)
          }
          const reception = await tx.wait()
          console.log('onCreateBet-reception', reception)
          // if (reception?.transactionHash) { // ethers5
          if (reception?.hash) {
            // ethers6
            try {
              const res = await updateTransMutation.mutateAsync({
                param: {
                  repo: betProps.transPostAuthor.did,
                  collection: 'app.bsky.feed.bet',
                  record: {
                    uri: urIParam,
                    transId: reception?.hash,
                    method: 'insider-bet.create',
                    amount: Number(parentAmount) * 1000000,
                    createDid: betProps?.transPostAuthor?.createDid,
                  },
                },
              })
              console.log('updateTransMutation', res)
            } catch (error) {
              console.log('updateTransMutation-error', error)
            }
            setParentPending(false)
            setResponse({
              success: true,
              hash: reception?.hash,
            })
            closeModal()
          }
        }
      } catch (err) {
        logger.error(`onCreateBet:`, {message: err})
        setParentPending(false)
        setResponse({
          success: false,
        })
        let reason =
          (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
        reason = simplifyMsg(reason)
        if (reason) {
          Toast.show(`Transaction failed: ${reason}`, 'xmark')
        } else {
          Toast.show(
            _(msg`Transaction failed: this operation caused some error`),
            'xmark',
          )
        }
      }
    },
    [
      _,
      openModal,
      currentWallet,
      parentAmount,
      parentEndTime,
      provider,
      closeModal,
      createTransMutation,
      parentBetType,
      parentJudgeMode,
      parentPublicSide,
      updateTransMutation,
    ],
  )

  async function onCreateRequest(betProps: IOnBet) {
    console.log('onCreateRequest-click', currentWallet)
    const postId = `${betProps.transPostAuthor?.did?.split(':')?.[2]}/${
      betProps.rKey
    }`
    try {
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      if (!parentAmount || parentAmount < 5) {
        return
      }
      setParentPending(true)
      const tx = await createBetRequest(
        provider,
        parentAmount,
        PUBLIC_ENV_CHAINID,
        postId,
        parentBetType,
      )
      console.log('onCreateRequest', tx)
      if (tx) {
        const reception = await tx.wait()
        console.log('onCreateRequest-reception', reception)
        // if (reception?.transactionHash) { // ethers5
        if (reception?.hash) {
          // ethers6
          setParentPending(false)
          setResponse({
            success: true,
            hash: reception?.hash,
          })
          closeModal()
          // openModal({name: 'trans-result', type: 'earn-bet'})
        }
      }
    } catch (err) {
      logger.error(`onCreateRequest:`, {message: err})
      setParentPending(false)
      setResponse({
        success: false,
      })
      let reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      reason = simplifyMsg(reason)
      if (reason) {
        Toast.show(`Transaction failed: ${reason}`, 'xmark')
      } else {
        Toast.show(
          _(msg`Transaction failed: this operation caused some error`),
          'xmark',
        )
      }
    }
  }

  const showBetModal = () => {
    openModal({
      name: 'set-bet',
      type,
      betType: type ? INIT_CONFIG?.[type]?.betType ?? 'against' : 'against',
      setParentBetType,
      amount: type ? INIT_CONFIG?.[type]?.amount : undefined,
      setParentAmount,
      error: undefined,
      setParentError,
      pending: false,
      setParentPending,
      setResponse,
      parentEndTime,
      setParentEndTime,
      parentJudgeMode,
      setParentJudgeMode,
      parentPublicSide,
      setParentPublicSide,
    })
  }

  function closeBetModal() {
    closeModal()
  }

  // console.log('use-bet', parentEndTime)

  return {
    endTime: parentEndTime,
    betType: parentBetType,
    amount: parentAmount,
    error: parentError,
    pending: parentPending,
    response,
    // onJoinBet,
    onCreateBet,
    onCreateRequest,
    showBetModal,
    closeBetModal,
  }
}
