import {
  BskyAgent,
  ComAtprotoServerTelegramChannels,
  ComAtprotoServerTelegramParticipants,
} from '@atproto/api'
import {Api, TelegramClient} from '@sipz/telegram'
import type {Dialog} from 'telegram/tl/custom/dialog'

import DialogQueue from './DialogQueue'
import ParticipantsQueue from './ParticipantsQueue'

export async function getContacts(
  client: TelegramClient,
): Promise<Api.contacts.Contacts> {
  await client.connect()
  const res = await client.invoke(
    new Api.contacts.GetContacts({
      hash: BigInt('-4156887774564') as any,
    }),
  )
  console.log('getContacts', res)
  return res as Api.contacts.Contacts
}

const requestFullChannel = (id: bigInt.BigInteger) => {
  return new Api.channels.GetFullChannel({
    channel: id,
  })
}

const requestFullChat = (id: bigInt.BigInteger) => {
  return new telegram.tl.Api.messages.GetFullChat({
    chatId: id,
  })
}

const requestParticipants = (id: bigInt.BigInteger) => {
  return new telegram.tl.Api.channels.GetParticipants({
    channel: id,
    filter: new telegram.tl.Api.ChannelParticipantsRecent({}),
    offset: 0,
    limit: 200,
    hash: BigInt('-4156887774564'),
  })
}

async function saveParticipants(
  agent: BskyAgent,
  fullChat: Api.messages.ChatFull | Api.channels.ChannelParticipants,
  chat?: ComAtprotoServerTelegramParticipants.Chat,
) {
  try {
    await agent.com.atproto.server.telegramParticipants(
      getChatFullData(fullChat, chat),
    )
  } catch (err) {
    console.log('telegramParticipants-error:', err)
    console.log('telegramParticipants-error-fullChat:', fullChat)
    console.log('telegramParticipants-error-chat:', chat)
  }
}

export async function getTelegramDialogs(
  client: TelegramClient,
  agent: BskyAgent,
) {
  await client.connect()
  const allDialogs = await client.getDialogs()

  const dialogs = allDialogs
    .filter(d => d.isChannel || d.isGroup)
    .filter(d => ((d.message?._chat as any)?.participantsCount || 0) <= 200)

  const task = async (dialog: Dialog) => {
    if (dialog.isChannel) {
      const fullChannel: Api.messages.ChatFull = await client.invoke(
        requestFullChannel(
          (dialog.inputEntity as Api.InputPeerChannel).channelId,
        ),
      )
      const channeFull = await client.invoke(
        requestParticipants(fullChannel.fullChat.id),
      )
      console.log('Participants:', channeFull)
      if (channeFull.count > 0) {
        await saveParticipants(agent, channeFull, {
          id: +(dialog.id || 0).toString(),
          title: dialog.title || dialog.name,
          participants_count: channeFull.participants?.length || 0,
        })
      }
      console.log('fullChannel:', fullChannel)
      const subChats = await Promise.allSettled(
        fullChannel.chats.map(chat =>
          client.invoke(requestParticipants(chat.id)),
        ),
      )

      console.log('Participants-sub-channel:', subChats)

      try {
        const fulfilledChats = subChats
          .filter(item => item.status === 'fulfilled')
          .map(item => item.value)
        await Promise.all(
          fulfilledChats.map((chat, index) =>
            saveParticipants(agent, chat, {
              id: +fullChannel.chats[index].id.toString(),
              title: (fullChannel.chats[index] as Api.Chat).title,
              participants_count: chat.participants?.length || 0,
            }),
          ),
        ).catch(err => console.log(err))

        // await saveChannels(
        //   agent,
        //   fulfilledChats.map((chat, index) => ({
        //     id: +fullChannel.chats[index].id.toString(),
        //     title: (fullChannel.chats[index] as Api.Chat).title,
        //     participants_count: chat.participants?.length || 0,
        //     /** is this a channel */
        //     broadcast: (fullChannel.chats[index] as Api.Channel).broadcast,
        //     /** is this a super group */
        //     megagroup: (fullChannel.chats[index] as Api.Channel).megagroup,
        //   })),
        // )
      } catch (err) {
        console.log('sub channel chat Participant save error:', err)
      }
      return true
    } else if (dialog.isGroup) {
      const fullChat = await client.invoke(
        requestFullChat((dialog.inputEntity as Api.InputPeerChat).chatId),
      )
      // console.log('Participant-group:', fullChat)
      if (fullChat.users.length > 0) {
        await saveParticipants(agent, fullChat)
        // await saveChannels(
        //   agent,
        //   fulfilledChats.map((chat, index) => ({
        //     id: +fullChannel.chats[index].id.toString(),
        //     title: (fullChannel.chats[index] as Api.Chat).title,
        //     participants_count: chat.participants?.length || 0,
        //     /** is this a channel */
        //     broadcast: (fullChannel.chats[index] as Api.Channel).broadcast,
        //     /** is this a super group */
        //     megagroup: (fullChannel.chats[index] as Api.Channel).megagroup,
        //   })),
        // )
      }
      return true
    }
    console.log('lose:', dialog)
    return true
  }
  const queue = new DialogQueue({
    queue: dialogs,
    task,
  })

  queue.run()
}

export function getChatFullData(
  chat: Api.messages.ChatFull | Api.channels.ChannelParticipants,
  chatData?: ComAtprotoServerTelegramParticipants.Chat,
) {
  let participants
  if (chat.className === 'messages.ChatFull') {
    participants = (
      (chat.fullChat as Api.ChatFull).participants as Api.ChatParticipants
    ).participants
  } else {
    participants = (chat as Api.channels.ChannelParticipants).participants
  }
  const chats = (chat.chats as Api.Chat[]).map(item => ({
    id: +item.id.toString(),
    title: item.title,
    participants_count: item.participantsCount,
  }))
  return {
    count: participants.length,
    participants: participants.map(p => ({
      user_id: +(p as any).userId.toString(),
    })),
    chats: chats.length > 0 ? chats : chatData ? [chatData] : [],
    users: (chat.users as Api.User[]).map(u => ({
      id: +u.id.toString(),
    })),
    // users: (chat.users as Api.User[]).map(u => ({
    //   id: +u.id.toString(),
    //   username: u.username || '',
    //   firstName: u.firstName || '',
    //   lastName: u.lastName || '',
    //   /** The telegram user number. */
    //   phone_number: u.phone || '',
    // })),
  }
}

export function getChannelFullData(
  agent: BskyAgent,
  client: TelegramClient,
  channelFull: Api.messages.ChatFull[],
) {
  const task = async (
    id: bigInt.BigInteger,
  ): Promise<Api.channels.ChannelParticipants | null> => {
    try {
      return client.invoke(
        new telegram.tl.Api.channels.GetParticipants({
          channel: id,
          filter: new telegram.tl.Api.ChannelParticipantsRecent({}),
          offset: 0,
          limit: 200,
          hash: BigInt('-4156887774564'),
        }),
      )
    } catch (err) {
      return null
    }
  }

  const queue = new ParticipantsQueue({
    queue: channelFull,
    task,
    resultFormat: (
      chat: Api.messages.ChatFull,
      participants: Api.channels.ChannelParticipants,
    ) => {
      const _participants = participants.participants.map(p => ({
        user_id: (p as Api.ChannelParticipant).userId,
      }))

      agent.com.atproto.server.telegramParticipants({
        count: (chat.fullChat as Api.ChannelFull).participantsCount || 0,
        participants: _participants.map(item => ({
          user_id: +item.user_id.toString(),
        })),
        chats: (chat.chats as Api.Channel[]).map(item => ({
          id: +item.id.toString(),
          title: item.title,
          participants_count: participants.participants.length || 0,
        })),
      })
    },
  })
  queue.run()
}

async function saveChannels(
  agent: BskyAgent,
  chats: ComAtprotoServerTelegramChannels.Chat[],
) {
  try {
    await agent.com.atproto.server.telegramChannels({
      channels: {
        chats: chats,
      },
    })
  } catch (err) {
    console.log('telegramChannels-error:', err)
  }
}

export async function getChannelChats(
  client: TelegramClient,
  agent: BskyAgent,
) {
  await client.connect()
  const allDialogs = await client.getDialogs()

  const dialogs = allDialogs
    .filter(d => d.isChannel || d.isGroup)
    .filter(d => ((d.message?._chat as any)?.participantsCount || 0) <= 200)

  console.log(dialogs)

  const task = async (dialog: Dialog) => {
    if (dialog.isChannel) {
      const fullChannel: Api.messages.ChatFull = await client.invoke(
        requestFullChannel(
          (dialog.inputEntity as Api.InputPeerChannel).channelId,
        ),
      )

      await saveChannels(
        agent,
        (fullChannel.chats as Api.Channel[]).map(chat => ({
          id: parseInt(chat.id.toString(), 10),
          title: chat?.title,
          participants_count: 1,
          /** is this a channel */
          broadcast: chat.broadcast,
          /** is this a super group */
          megagroup: chat.megagroup,
        })),
      )
    } else if (dialog.isGroup) {
      try {
        const fullChat = await client.invoke(
          requestFullChat((dialog.inputEntity as Api.InputPeerChat).chatId),
        )
        console.log('save-group:', fullChat)
        await saveChannels(agent, [
          {
            id: parseInt(fullChat.chats[0].id.toString(), 10),
            title: fullChat.chats[0].title,
            participants_count: fullChat.fullChat.participants.length,
          },
        ])
      } catch (err) {
        console.log('getChannelChats', err)
      }
      return true
    } else {
    }
    return true
  }
  const queue = new DialogQueue({
    queue: dialogs,
    task,
  })

  queue.run()
}

export function normalizePhone(phone: string) {
  return phone?.replace(/\s+/g, '')
}

export function userAuthParamCallback<T>(param: T): () => Promise<T> {
  return async function () {
    return await new Promise<T>(resolve => {
      resolve(param)
    })
  }
}
