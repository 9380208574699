import React, {useEffect, useState} from 'react'
import {
  ActivityIndicator,
  Image,
  Pressable,
  SafeAreaView,
  StyleSheet,
  View,
} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {onSignMessage} from '#/lib/ethers'
import {isMobile, isNative} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useWalletLinkMutation} from '#/state/queries/link-wallet'
import {useWallet, WalletNameType} from '#/state/shell/wallet'
import * as Toast from '#/view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {Text} from '../util/text/Text'
import {simplifyMsg} from '../util/Transaction'

export const snapPoints = ['45%']
const metamaskIcon = 'https://metamask.io/images/metamask-logo.png'
const walletconnectIcon =
  'https://cloud.walletconnect.com/images/walletconnect-icon.svg'
const addLinkedIcon = require('../../../../assets/imgs/add_link_wallet.png')

export function Component({type}: {type: 'connect' | 'link'}) {
  const {_} = useLingui()
  const {onSelectConnectWallet, currentWallet, onDisConnectWallet} = useWallet()
  const {closeModal} = useModalControls()
  const linkMutation = useWalletLinkMutation()

  const [activeWallet, setActiveWallet] = useState<WalletNameType | undefined>(
    undefined,
  )
  const [linking, setLinking] = useState<boolean>(false)

  async function onPressWallet(walletName: WalletNameType) {
    if (type === 'connect') {
      onSelectConnectWallet(walletName)
    } else if (type === 'link') {
      setActiveWallet(walletName)
      const provderParam = await onSelectConnectWallet(walletName, 'link')
      toLinkWallet(walletName, provderParam)
    }
  }

  const toLinkWallet = async (walletName: string, provderParam: any) => {
    try {
      console.log('toLinkWallet', walletName, provderParam)
      if (linking || !provderParam) return
      let address = ''
      setLinking(true)
      if (walletName === 'metamask') {
        const permissions = await provderParam?.request({
          method: 'wallet_requestPermissions',
          params: [{eth_accounts: {}}],
        })
        console.log('permissions', permissions)
        const accountsPermission = permissions?.find(
          (permission: any) => permission.parentCapability === 'eth_accounts',
        )
        if (accountsPermission) {
          console.log('eth_accounts permission successfully requested!')
          const perValues = permissions?.[0]?.caveats?.[0]?.value?.[0]
          address = perValues?.address ?? perValues
        }
      } else {
        address = provderParam?.accounts?.[0] ?? currentWallet?.publicKey
      }
      console.log('selectProvider-address', address)
      const signMsg = `Welcome to Sipz! Please sign this message to prove you own this wallet.\nThis action won't cost you or trigger any on-chain transactions.\nWallet address: ${address}\nIssued At: ${new Date().toISOString()}`
      const signRes = await onSignMessage(provderParam, signMsg)
      console.log('sign', signRes)
      if (signRes) {
        const res = await linkMutation
          .mutateAsync({
            param: {
              verifySource: '',
              verifySourceLogo:
                walletName === 'metamask' ? metamaskIcon : walletconnectIcon,
              message: signMsg,
              signature: signRes,
              walletAddress: address,
            },
          })
          .finally(() => {
            setLinking(false)
            setActiveWallet(undefined)
          })
        if (res && res?.success) {
          closeModal()
          if (walletName === 'walletconnect') {
            onDisConnectWallet(walletName)
          }
        }
      }
    } catch (err) {
      setLinking(false)
      setActiveWallet(undefined)
      console.log('toLinkWallet-err', err)
      let reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      if (walletName === 'walletconnect') {
        onDisConnectWallet(walletName)
      }
      if (reason) {
        reason = simplifyMsg(reason)
        Toast.show(`${reason}`, 'xmark')
      } else {
        Toast.show(
          _(msg`This opration cause some error. Please log in again`),
          'xmark',
        )
      }
    }
  }

  useEffect(() => {
    if (type === 'connect' && currentWallet?.publicKey) {
      closeModal()
    }
  }, [currentWallet, closeModal, type])

  // console.log('connect-wallet', currentWallet, activeWallet)

  return (
    <SafeAreaView testID="walletConnectModal">
      {isNative &&
      type === 'link' &&
      currentWallet?.walletName === 'walletconnect' &&
      currentWallet?.publicKey ? (
        <SignWallet toLinkWallet={toLinkWallet} closeModal={closeModal} />
      ) : (
        <SelectWallet
          onPressWallet={onPressWallet}
          activeWallet={activeWallet}
          linking={linking}
          closeModal={closeModal}
        />
      )}
    </SafeAreaView>
  )
}

const SelectWallet = ({
  activeWallet,
  onPressWallet,
  linking,
  closeModal,
}: any) => {
  const t = useTheme()
  const isMetaMaskBrower = navigator.userAgent?.indexOf('MetaMaskMobile') >= 0
  const showMetaMask = isMetaMaskBrower || !isMobile
  const showWalletConnect = !isMetaMaskBrower

  return (
    <View style={[a.justify_between]}>
      <Text type="lg-medium" style={[styles.title, t.atoms.text]}>
        Connect Wallet
      </Text>
      <View style={styles.titleSection}>
        {showMetaMask && (
          <Pressable
            accessibilityRole="button"
            testID="connectMetaMask"
            // color="secondary"
            // variant="outline"
            // label="Connect"
            onPress={() => {
              onPressWallet('metamask')
            }}
            style={[
              a.flex_row,
              a.align_center,
              a.justify_center,
              a.rounded_xs,
              a.py_lg,
              styles.connectBtn,
            ]}>
            <Image
              testID="metamaskLogo"
              source={require('../../../../assets/imgs/metamask.png')}
              style={styles.connectBtnIcon}
              accessibilityIgnoresInvertColors
            />
            <ButtonText style={[a.text_md, t.atoms.text_title_1]}>
              MetaMask
            </ButtonText>
            {activeWallet === 'metamask' && linking && (
              <ActivityIndicator size={12} style={[a.ml_lg]} />
            )}
          </Pressable>
        )}
        {showWalletConnect && (
          <Pressable
            accessibilityRole="button"
            testID="connectWalletConnect"
            // color="secondary"
            // variant="outline"
            // label="Connect"
            onPress={() => {
              onPressWallet('walletconnect')
            }}
            style={[
              a.flex_row,
              a.align_center,
              a.justify_center,
              a.rounded_xs,
              a.py_lg,
              styles.connectBtn,
            ]}>
            <Image
              testID="walletconnectLogo"
              source={require('../../../../assets/imgs/walletconnect.png')}
              style={styles.connectBtnIcon}
              accessibilityIgnoresInvertColors
            />
            <ButtonText style={[a.text_md, t.atoms.text_title_1]}>
              WalletConnect
            </ButtonText>
            {activeWallet === 'walletconnect' && linking && (
              <ActivityIndicator size={12} style={[a.ml_lg]} />
            )}
          </Pressable>
        )}
      </View>
      <View style={styles.actionContent}>
        <Button
          testID="closeConnect"
          color="secondary"
          size="large"
          shape="default"
          variant="solid"
          label="Close"
          onPress={() => closeModal()}>
          <ButtonText style={[{color: t.palette.gray_13}]}>Close</ButtonText>
        </Button>
      </View>
    </View>
  )
}

const SignWallet = ({toLinkWallet, closeModal}: any) => {
  const {provider} = useWallet()
  return (
    <View>
      <View style={[a.align_center, a.mx_md]}>
        <Image
          source={addLinkedIcon}
          style={{width: 151, height: 151}}
          accessibilityIgnoresInvertColors
        />
        <Text style={[a.text_lg, a.font_semibold, a.text_center]}>
          Sign to complete the linking in your wallet
        </Text>
      </View>
      <View style={[a.mt_md, a.gap_md, a.mx_xl]}>
        <Button
          testID="closeConnect"
          color="primary"
          size="large"
          shape="default"
          variant="solid"
          label="Go"
          onPress={() => toLinkWallet('walletconnect', provider)}>
          <ButtonText>Go</ButtonText>
        </Button>
        <Button
          testID="closeConnect"
          color="secondary"
          size="large"
          shape="default"
          variant="solid"
          label="Close"
          onPress={() => closeModal()}>
          <ButtonText>Close</ButtonText>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    textAlign: 'center',
    lineHeight: 30,
  },
  titleSection: {
    flexDirection: 'column',
    gap: 15,
    margin: 20,
  },
  actionContent: {
    marginHorizontal: 20,
    marginTop: 30,
  },
  connectBtn: {
    height: 56,
    borderRadius: 6,
    borderColor: '#97979733',
    borderWidth: 1,
  },
  connectBtnIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
})
