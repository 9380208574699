import React, {useCallback, useEffect, useMemo} from 'react'
import {StyleSheet, View} from 'react-native'

import {UpdateResidueAvailablePost} from '#/lib/api/feed/custom'
import {batchedUpdates} from '#/lib/batchedUpdates'
import {useFreeSave} from '#/lib/hooks/Tools'
import {useNonReactiveCallback} from '#/lib/hooks/useNonReactiveCallback'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {emitter} from '#/state/events'
import {FeedPostSliceItem} from '#/state/queries/post-feed'
import {useAgent} from '#/state/session'
import {ReFreshContainer} from '../util/ReFreshContainer'
import {useNewPostStore} from '../util/sdlStore/newPostStore'
import {useTgStore} from '../util/sdlStore/TgStore'
import {CenteredView} from '../util/Views'
import {FeedItem} from './Feed'
import {MemoCard} from './MemoCard'
import ScrollListener from './ScrollListener'
import {addVisible, getVisibleArray} from './VisibleCircleHandler'

interface ICircleFeedList {
  data: FeedItem[]
  onEndReached: (data: {distanceFromEnd: number}) => void
  refreshing: boolean
  onRefresh: () => void
  inviteDom?: React.ReactNode
}

export default function CircleFeedList({
  data,
  onEndReached,
  refreshing,
  onRefresh,
  inviteDom,
}: ICircleFeedList) {
  const {bind: hasBindTg} = useTgStore()
  const [visibleUris, setVisibleUris] = React.useState<string[]>(() =>
    getVisibleArray(),
  )
  const arrayData = useMemo((): FeedPostSliceItem[] => {
    const array = data.map(ele => {
      if (ele.type === 'slice') {
        return ele.slice.items
      } else {
        return []
      }
    })
    return array.flat()
  }, [data])
  const {isWideList} = useWebMediaQueries()

  const {resetCircle} = useNewPostStore()

  const getAccessPostArr = useCallback(
    (index: number) => {
      return arrayData.slice(index, 5).map(item => item.post.uri)
    },
    [arrayData],
  )

  const validateVisible = useCallback(
    (uri: string) => {
      return hasBindTg || visibleUris.includes(uri)
    },
    [hasBindTg, visibleUris],
  )

  // useEffect(() => {
  //   const defaultVisibleUris = arrayData.slice(0, 3).map(item => item.post.uri)
  //   addVisible(defaultVisibleUris)
  //   setVisibleUris(getVisibleArray())
  // }, [arrayData])

  const agent = useAgent()
  const {saveFreeValue, getFreeValue} = useFreeSave()

  const [unlockLevelAmount, setUnlockLevelAmount] = React.useState(3)

  useEffect(() => {
    getFreeValue('unlockLevelAmount').then((res: string) => {
      if (res) {
        setUnlockLevelAmount(Number(res))
      }
    })
  }, [getFreeValue])

  const updateUnlockLevelAmount = useCallback(() => {
    let target = null
    switch (unlockLevelAmount) {
      case 3:
        target = 5
        break
      case 5:
        target = 7
        break
      case 7:
        target = 10
        break
      default:
        break
    }
    if (target) {
      setUnlockLevelAmount(target)
      saveFreeValue('unlockLevelAmount', target + '')
    }
  }, [saveFreeValue, unlockLevelAmount])

  useEffect(() => {
    const addVisibleFromIndex = (i: number) => {
      let residue = unlockLevelAmount
      for (let j = i; j < i + arrayData.length; j++) {
        if (residue === 0) break
        const currentPost = arrayData[j].post
        if (!currentPost.viewer?.unlock && !validateVisible(currentPost.uri)) {
          addVisible([currentPost.uri])
          agent.unlock(currentPost.uri, currentPost.cid)
          residue--
        }
      }
      UpdateResidueAvailablePost(residue)
      updateUnlockLevelAmount()
      setVisibleUris(getVisibleArray())
    }
    emitter.on('unlockPostFromIndex', addVisibleFromIndex)
    return () => {
      emitter.off('unlockPostFromIndex', addVisibleFromIndex)
    }
  }, [
    agent,
    arrayData,
    unlockLevelAmount,
    updateUnlockLevelAmount,
    validateVisible,
  ])

  useEffect(() => {
    const addVisibleUris = (uri: string) => {
      addVisible([uri])
      setVisibleUris(getVisibleArray())
    }
    emitter.on('unlockPost', addVisibleUris)
    return () => {
      emitter.off('unlockPost', addVisibleUris)
    }
  }, [])

  return (
    <CenteredView>
      <View style={{height: 85}} />
      <ReFreshContainer
        refreshing={refreshing}
        onRefresh={() => {
          onRefresh()
          resetCircle()
        }}>
        <ScrollListener type="circle">
          <View style={[styles.container]}>
            {inviteDom}
            {arrayData.map((item, index) => (
              <MemoCard
                index={index}
                key={item.uri}
                post={item.post}
                record={item.record}
                accessPostArr={getAccessPostArr(index)}
                visible={validateVisible(item.post.uri)}
                style={{width: isWideList ? '50%' : '100%'}}
              />
            ))}
            <View style={{width: 23, height: 90}} />
            <Visibility
              onVisibleChange={isVisible => {
                isVisible && onEndReached({distanceFromEnd: 0})
              }}
            />
          </View>
        </ScrollListener>
      </ReFreshContainer>
    </CenteredView>
  )
}

let Visibility = ({
  onVisibleChange,
}: {
  onVisibleChange: (isVisible: boolean) => void
}): React.ReactNode => {
  const tailRef = React.useRef(null)
  const isIntersecting = React.useRef(false)

  const handleIntersection = useNonReactiveCallback(
    (entries: IntersectionObserverEntry[]) => {
      batchedUpdates(() => {
        entries.forEach(entry => {
          if (entry.isIntersecting !== isIntersecting.current) {
            isIntersecting.current = entry.isIntersecting
            onVisibleChange(entry.isIntersecting)
          }
        })
      })
    },
  )

  React.useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: `0px`,
    })
    const tail: Element | null = tailRef.current!
    observer.observe(tail)
    return () => {
      observer.unobserve(tail)
    }
  }, [handleIntersection])

  return <View ref={tailRef} style={styles.visibilityDetector} />
}
Visibility = React.memo(Visibility)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  listContainer: {
    justifyContent: 'flex-start',
    paddingTop: 30,
    marginBottom: 100,
  },
  item: {
    flex: 1,
    margin: 5,
    height: 100,
    backgroundColor: '#4CAF50',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#fff',
  },
  visibilityDetector: {
    height: 80,
    width: 50,
    pointerEvents: 'none',
    zIndex: -1,
    position: 'absolute',
    bottom: 200,
    left: 39,
  },
})
