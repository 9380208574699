import {BskyAgent} from '@atproto/api'
import {Buffer} from 'buffer'
import {HDNodeWallet} from 'ethers'

import {devicePlatform} from '#/platform/detection'

const prefix = 'did:pkh:eip155:1:'

export type SignLoginProps = {
  serviceUrl: string
  handle?: string
  address: string
  wallet: HDNodeWallet
}

export class SendingNetworkAgent {
  private sdnServerUrl: string
  private state: SignLoginProps
  private agent: BskyAgent
  constructor({
    sdnServerUrl,
    state,
  }: {
    sdnServerUrl: string
    state: SignLoginProps
  }) {
    this.sdnServerUrl = sdnServerUrl
    this.state = state
    this.agent = new BskyAgent({service: state.serviceUrl})
  }

  public login = async ({deviceId}: {deviceId?: string} = {}) => {
    if (!this.state.address) return
    const didAddress = `did:eth:mainnet:${this.state.address}_did`
    const res = await this.getSDMRandom(didAddress, 'did')

    const random = res.data.random

    let {
      data: [did],
    } = await this.queryDiDList(this.state.address)

    const preLoginParams = did
      ? {did, device_id: deviceId, random}
      : {address: `${prefix}${this.state.address}`, device_id: deviceId, random}

    const {
      message: lMessage,
      updated,
      random_server,
    } = await this.preDiDLogin1(preLoginParams)

    const sign = await this.state.wallet.signMessage(lMessage)

    const res2 = await this.getAppToken(this.state.address, lMessage, sign)

    const app_token = res2.data.app_token

    const identifier = {
      did,
      address: did || `${prefix}${this.state.address}`,
      token: sign,
      signature: sign,
      medium: 'did',
      message: lMessage,
      random_sdm: random,
      random_sdn: random_server,
      app_token,
    }

    const loginParams: any = {
      type: 'm.login.did.identity',
      updated,
      identifier,
      random,
      random_server,
      initial_device_display_name: devicePlatform,
    }

    const res3 = await this.didLogin(loginParams)
    console.log(res3)
    if (this.state.handle) {
      const hostname = new URL(this.state.serviceUrl).hostname
      loginParams.handle = this.state.handle + '.' + hostname
    }
    loginParams.identifier.address = didAddress
    // const res4 = await this.agent.signIn(loginParams)
    return loginParams
  }

  private getAppToken = async (
    address: string,
    message: string,
    sign: string,
  ) => {
    let token = sign.substring(2)
    token = Buffer.from(token, 'hex')
      .toString('base64')
      .replace(/\+/g, '-') // Convert '+' to '-'
      .replace(/\//g, '_')
    const data = {
      wallet_address: address,
      message,
      token,
    }
    return this.agent.com.atproto.server.sdnAppToken(data)
  }

  private queryDiDList = async (address: string) => {
    return fetch(
      `${this.sdnServerUrl}/_api/client/unstable/address/${address}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(res => res.json())
  }
  private preDiDLogin1 = async (data: any) => {
    return fetch(`${this.sdnServerUrl}/_api/client/unstable/did/pre_login1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
  }
  private didLogin = async (data: any) => {
    return fetch(`${this.sdnServerUrl}/_api/client/unstable/did/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
  }

  getFendInfo(): Promise<any> {
    return fetch(`${this.sdnServerUrl}/api/v1/fend/info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.json())
  }

  private getSDMRandom = async (address: string, medium: string) => {
    if (!this.state.serviceUrl) {
      throw new Error('No server url')
    }
    return this.agent.com.atproto.server.getRandom({
      address,
      medium,
    })
  }
}
