import {createMultiPathSVG} from './TEMPLATE'

export const Refresh = createMultiPathSVG({
  viewBox: '0 0 24 24',
  paths: [
    'M4.76844 4.92608C6.36166 3.597 8.77581 2.25 12 2.25C17.4202 2.25 21.75 6.61974 21.75 12C21.75 16.5254 18.6679 20.3267 14.486 21.4283C14.0855 21.5338 13.6752 21.2946 13.5697 20.894C13.4642 20.4935 13.7034 20.0832 14.104 19.9777C17.6441 19.0453 20.25 15.8266 20.25 12C20.25 7.44426 16.5878 3.75 12 3.75C9.22119 3.75 7.13534 4.905 5.72931 6.07792C5.23947 6.48655 4.83489 6.89533 4.51612 7.25391H6.996C7.41021 7.25391 7.746 7.58969 7.746 8.00391C7.746 8.41812 7.41021 8.75391 6.996 8.75391H3C2.87137 8.75391 2.7503 8.72152 2.6445 8.66446C2.54382 8.6103 2.45797 8.53432 2.39232 8.44357C2.30599 8.32447 2.25383 8.17899 2.2502 8.02152C2.25007 8.01567 2.25 8.00979 2.25 8.00391',
    'M2.25 8.00391V3.9C2.25 3.48579 2.58579 3.15 3 3.15C3.41421 3.15 3.75 3.48579 3.75 3.9V5.87405C4.04268 5.5706 4.38209 5.24838 4.76844 4.92608',
    'M3 11.25C3.41421 11.25 3.75 11.5858 3.75 12C3.75 12.5518 3.80416 13.0902 3.90722 13.6106C3.9877 14.0169 3.72355 14.4115 3.31723 14.492C2.91091 14.5725 2.51628 14.3083 2.43581 13.902C2.31384 13.2862 2.25 12.6502 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25Z',
    'M5.13998 16.5824C4.90949 16.2383 4.44364 16.1461 4.09948 16.3766C3.75532 16.6071 3.66318 17.073 3.89368 17.4171C4.60535 18.4797 5.52025 19.3946 6.58286 20.1063C6.92702 20.3368 7.39287 20.2447 7.62336 19.9005C7.85386 19.5564 7.76172 19.0905 7.41756 18.86C6.51772 18.2574 5.74265 17.4823 5.13998 16.5824Z',
    'M9.508 20.6828C9.58848 20.2764 9.98311 20.0123 10.3894 20.0928C10.9098 20.1958 11.4482 20.25 12 20.25C12.4142 20.25 12.75 20.5858 12.75 21C12.75 21.4142 12.4142 21.75 12 21.75C11.3498 21.75 10.7138 21.6862 10.098 21.5642C9.69167 21.4837 9.42752 21.0891 9.508 20.6828Z',
  ],
})
