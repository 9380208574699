import * as React from 'react'
import Svg, {Path} from 'react-native-svg'

type Props = React.ComponentProps<typeof Svg>
export const SvgComponent = (props: Props) => (
  <Svg width={34} height={35} fill="none" {...props}>
    <Path fill="#FCD010" d="M9.453-11 45.34 24.886l-9.453 9.454L0-1.547z" />
    <Path
      fill="#000"
      d="m17.49.894 1.14 1.14-2.2 5.253.06.06 5.254-2.2 1.14 1.14-5.143 5.143-.894-.894 3.533-3.533-.047-.048-4.94 2.097-.668-.668 2.105-4.947-.048-.047-3.54 3.54-.895-.894L17.49.894Zm1.225 11.51 3.857-3.857.91.91-3.858 3.856-.909-.909Zm4.862-3.947a.75.75 0 0 1-.229-.515.652.652 0 0 1 .194-.505.645.645 0 0 1 .504-.193c.201.007.374.082.518.226a.734.734 0 0 1 .226.517.645.645 0 0 1-.194.505.653.653 0 0 1-.505.193.742.742 0 0 1-.514-.228Zm.151 4.44-2.26 2.26-.909-.91 3.857-3.856.869.869-.655.655.045.045c.304-.127.617-.157.939-.09.323.069.628.247.916.535.267.266.441.555.525.866.086.313.075.632-.032.957-.106.326-.314.643-.623.949l-2.456 2.456-.909-.91 2.315-2.314c.258-.258.393-.527.404-.807.014-.277-.097-.534-.331-.768a1.195 1.195 0 0 0-.53-.319 1.034 1.034 0 0 0-.587.01c-.2.062-.392.186-.578.372ZM26.5 20.34c-.386-.387-.64-.801-.76-1.243a2.31 2.31 0 0 1 .042-1.334c.147-.448.423-.874.826-1.278.397-.397.82-.67 1.27-.818.454-.15.901-.171 1.342-.066.442.104.847.34 1.215.708.238.238.424.5.558.789.137.288.207.593.21.914a2.33 2.33 0 0 1-.22.994c-.151.338-.396.677-.734 1.015l-.278.278-3.162-3.16.613-.614 2.29 2.29c.172-.175.29-.368.351-.577.064-.211.069-.422.015-.633-.051-.21-.164-.4-.338-.575a1.252 1.252 0 0 0-.626-.354 1.322 1.322 0 0 0-.68.023c-.221.067-.419.185-.593.356l-.535.535a1.779 1.779 0 0 0-.454.7 1.242 1.242 0 0 0-.023.711c.064.231.194.445.392.643.132.132.27.234.414.304.146.069.295.105.447.11a.992.992 0 0 0 .452-.095l.754.944c-.278.17-.576.265-.894.281a2.112 2.112 0 0 1-.96-.185 3.1 3.1 0 0 1-.934-.663Z"
    />
  </Svg>
)
