import {createSinglePathSVG} from './TEMPLATE'

export const UserCircle_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a8 8 0 0 0-5.935 13.365C7.56 15.895 9.612 15 12 15c2.388 0 4.44.894 5.935 2.365A8 8 0 0 0 12 4Zm4.412 14.675C15.298 17.636 13.792 17 12 17c-1.791 0-3.298.636-4.412 1.675A7.96 7.96 0 0 0 12 20a7.96 7.96 0 0 0 4.412-1.325ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10a9.98 9.98 0 0 1-3.462 7.567A9.965 9.965 0 0 1 12 22a9.965 9.965 0 0 1-6.538-2.433A9.98 9.98 0 0 1 2 12Zm10-4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z',
})

export const UserCircle_Filled_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm3-12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3 10a7.976 7.976 0 0 1-5.714-2.4C7.618 16.004 9.605 15 12 15c2.396 0 4.383 1.005 5.714 2.6A7.976 7.976 0 0 1 12 20Z',
})

export const ProfileOpen = createSinglePathSVG({
  path: 'M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.4267 23.19 7.04258 21.8111 5.08868L23.4068 3.45456L23.4368 3.41739C24.5435 2.04354 23.5655 0 21.8014 0H12.5V0.0102278C12.3342 0.00343142 12.1675 0 12 0ZM18.6356 2C19.3154 2.45201 19.9465 2.97149 20.5193 3.54889L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2H18.6356ZM6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504ZM12.55 7.66504C12.2462 7.66504 12 7.91128 12 8.21504V11.515C12 11.8188 12.2462 12.065 12.55 12.065H13.65C13.9538 12.065 14.2 11.8188 14.2 11.515V8.21504C14.2 7.91128 13.9538 7.66504 13.65 7.66504H12.55Z',
})

export const ProfileOpen_Fill = createSinglePathSVG({
  path: 'M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 9.4267 23.19 7.04258 21.8111 5.08868L23.4068 3.45456L23.4368 3.41739C24.5435 2.04354 23.5655 0 21.8014 0H12.5V0.0102278C12.3342 0.00343142 12.1675 0 12 0ZM18.6356 2C19.3154 2.45201 19.9465 2.97149 20.5193 3.54889L21.8939 2.1412C21.901 2.12816 21.9025 2.11892 21.9031 2.11263C21.9044 2.09896 21.9021 2.07888 21.8916 2.05683C21.881 2.03479 21.8668 2.02042 21.8554 2.01285C21.8476 2.0077 21.8335 2 21.8014 2H18.6356ZM6.5 8.21504C6.5 7.91128 6.74624 7.66504 7.05 7.66504H8.15C8.45376 7.66504 8.7 7.91128 8.7 8.21504V11.515C8.7 11.8188 8.45376 12.065 8.15 12.065H7.05C6.74624 12.065 6.5 11.8188 6.5 11.515V8.21504ZM12.55 7.66504C12.2462 7.66504 12 7.91128 12 8.21504V11.515C12 11.8188 12.2462 12.065 12.55 12.065H13.65C13.9538 12.065 14.2 11.8188 14.2 11.515V8.21504C14.2 7.91128 13.9538 7.66504 13.65 7.66504H12.55Z',
})
