import {useQuery} from '@tanstack/react-query'

import {useAgent, useSession} from '#/state/session'
import {MaskAccount} from '#/view/screens/Mask'

// const subAccounts: MaskAccount[] = []
export const useSubAccounts = () => {
  const {currentAccount} = useSession()
  const agent = useAgent()
  const res = useQuery({
    queryKey: ['SubAccounts'],
    queryFn: () => {
      return agent.com.atproto.server
        .getSubAccountLIst()
        .then(_res => {
          return (
            _res.data.subAccounts?.map(account => {
              return {
                ...account,
                handle: account.handle,
                avatar: account.avatar,
                service: currentAccount?.service,
              } as MaskAccount
            }) || []
          )
        })
        .catch(() => {})
    },
  })

  return {subAccounts: res.data || [], ...res}
}

export const useIsMe = (did: string): boolean => {
  const {subAccounts} = useSubAccounts()
  const {currentAccount} = useSession()

  if (currentAccount?.did === did) {
    return true
  }
  const hasSub = subAccounts?.find(sub => sub.did === did)
  if (hasSub) {
    return true
  }
  return false
}
