/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Pressable, StyleSheet, Text, TextInput, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {useModalControls} from '#/state/modals'
import {RightIcon} from '#/view/icons/ModalIcons'
import {ContactItemProps} from '#/view/screens/Circle'
import {CircleMemberStackAvatar} from '#/view/screens/Circle/CircleMemberStackAvatar'
import {
  telegramContactList,
  useTelegramBindWithSpiz,
  useTelegramContactsWithSpiz,
} from '#/view/screens/Circle/hooks'
import {SmallContact} from '#/view/screens/Telegram/ContactItem'
import ContactList from '#/view/screens/Telegram/ContactList'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {IconCircle} from '#/components/IconCircle'
import {ChevronBottom_Stroke2_Corner0_Rounded as ChevronBottom} from '#/components/icons/Chevron'
import {ChevronTop_Stroke2_Corner0_Rounded as ChevronTop} from '#/components/icons/Chevron'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {Refresh} from '#/components/icons/Refresh'
import {Search_Filled_Corner0_Rounded as SearchIcon} from '#/components/icons/Search'
import {Loader} from '#/components/Loader'
import {EmptyBox} from '../../util/EmptyBox'
import {ScrollView} from '../../util/Views'
import {useTelegramLink} from './hooks'

interface IProps {
  onSelected: (ids: string[]) => void
  contacts?: ContactItemProps[]
}

export function TelegramContacts(props: IProps) {
  const {onSelected, contacts} = props
  const [selectedItems, setSelectedItems] = useState<ContactItemProps[]>([])
  const [filterText, setFilterText] = useState<string>('')
  const [openInvite, setOpenInvite] = useState<boolean>(true)
  const [openInSpiz, setOpenInSpiz] = useState<boolean>(true)
  const [selectAllMutual, setSelectAllMutual] = useState<boolean>(false)
  const [isExpand, setIsExpand] = React.useState(false)
  const [refreshing, setRefreshing] = React.useState(false)

  const t = useTheme()
  const {_} = useLingui()
  const {closeAllModals, openModal} = useModalControls()
  const queryClient = useQueryClient()
  const {saveContacts} = useTelegramLink()

  const {
    data,
    error,
    isFetching: fetchingInSpiz,
  } = useTelegramContactsWithSpiz({
    enabled: !contacts?.length,
  })

  const list = useMemo(() => contacts || data || [], [contacts, data])
  const userIds = list.map(item => item.id)

  const {data: bindUsers, isFetching: fetchingBind} =
    useTelegramBindWithSpiz(userIds)

  const loading = useMemo(
    () => fetchingInSpiz || fetchingBind,
    [fetchingInSpiz, fetchingBind],
  )

  const filteredList = useMemo(() => {
    const _filteText = filterText?.toLowerCase().trim() ?? ''
    return list.filter(
      item =>
        item.nickname?.toLowerCase().includes(_filteText) ||
        item.username?.toLowerCase().includes(_filteText),
    )
  }, [filterText, list])

  const [inSpiz, canInvite] = useMemo(() => {
    const _inSpiz: ContactItemProps[] = []
    const _canInvite: ContactItemProps[] = []
    filteredList.forEach(item => {
      if (bindUsers?.includes(item.id)) {
        _inSpiz.push(item)
      } else {
        _canInvite.push(item)
      }
    })
    return [_inSpiz, _canInvite]
  }, [bindUsers, filteredList])

  const enableSelectMutual = useMemo(
    () => !!canInvite?.find(item => item.isMutual),
    [canInvite],
  )

  const selectHandle = useCallback(
    (selected: boolean, item: ContactItemProps) => {
      console.log('selectHandle:', selected, item)
      if (selected) {
        setSelectedItems(c => [...c, item])
      } else {
        const _selectedItems = [...selectedItems]
        const index = _selectedItems.findIndex(i => i.id === item.id)
        if (index > -1) {
          _selectedItems.splice(index, 1)
          setSelectedItems(_selectedItems)
        }
      }
    },
    [selectedItems],
  )

  useEffect(() => {
    if (selectAllMutual) {
      setSelectedItems(list => [
        ...canInvite.filter(item => item.isMutual),
        ...list.filter(item => !item.isMutual),
      ])
    } else {
      setSelectedItems(list => [...list.filter(item => !item.isMutual)])
    }
  }, [selectAllMutual, canInvite])

  let content
  if (loading) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (error) {
    content = (
      <View>
        <Text>
          <Trans>{error.message}</Trans>
        </Text>
      </View>
    )
  } else if (!filteredList.length) {
    content = (
      <EmptyBox
        icon="heart"
        viewStyles={{paddingTop: 50}}
        message={'No contacts yet'}
      />
    )
  } else {
    content = (
      <View>
        <View style={[a.flex_row, a.justify_between, a.align_center]}>
          <Button
            label="invite to spiz"
            onPress={() => setOpenInvite(b => !b)}
            style={[a.justify_start, a.m_md]}>
            <ButtonText style={[t.atoms.text]}>
              <Trans>Invite To SipZ</Trans>
              <Text style={[t.atoms.text_contrast_low, a.font_normal]}> (</Text>
              {selectedItems.length}
              <Text style={[t.atoms.text_contrast_low, a.font_normal]}>
                / {canInvite.length})
              </Text>
            </ButtonText>
            <ChevronStatus status={openInvite} />
          </Button>
          {enableSelectMutual && (
            <Button
              style={[
                t.name === 'dark'
                  ? t.atoms.bg_btn_default
                  : {backgroundColor: t.palette.black},
              ]}
              label="Select all"
              variant="solid"
              size="xsmall"
              onPress={() => setSelectAllMutual(b => !b)}>
              <ButtonText
                style={[
                  {color: t.atoms.bg_white.backgroundColor, fontSize: 12},
                  a.font_semibold,
                ]}>
                {selectAllMutual ? (
                  <Trans>Clear mutual</Trans>
                ) : (
                  <Trans>Select all mutual</Trans>
                )}
              </ButtonText>
              {/* <ChevronBottom fill={t.atoms.bg_white.backgroundColor} size="xs" /> */}
            </Button>
          )}
        </View>
        {openInvite && (
          <ContactList
            users={canInvite}
            onSelected={selectHandle}
            selected={selectedItems}
            showTag={false}
          />
        )}

        {inSpiz.length > 0 && (
          <>
            <Button
              label="invite to spiz"
              onPress={() => setOpenInSpiz(b => !b)}
              style={[a.justify_start, a.m_md]}>
              <ButtonText style={[t.atoms.text]}>
                Already On SipZ{' '}
                <Text style={[t.atoms.text_contrast_low, a.font_normal]}>
                  ({inSpiz.length})
                </Text>
              </ButtonText>
              <ChevronStatus status={openInSpiz} />
            </Button>
            {openInSpiz && (
              <ContactList
                users={inSpiz}
                onSelected={selectHandle}
                showCheckbox={false}
                selected={selectedItems}
                showTag={false}
              />
            )}
          </>
        )}
      </View>
    )
  }

  const reloadTelegramContacts = React.useCallback(async () => {
    if (!refreshing) {
      try {
        setRefreshing(true)
        await saveContacts()
        queryClient.invalidateQueries({queryKey: telegramContactList})
      } catch (err: any) {
        if (err?.code === 401) {
          openModal({name: 'telegram'})
        }
        console.log(err)
      } finally {
        setRefreshing(false)
      }
    }
  }, [refreshing, openModal, queryClient, saveContacts])

  return (
    <View style={[styles.page, a.flex_1, a.gap_lg, {minHeight: 500}]}>
      <View style={[a.flex_row, a.align_center, a.justify_between]}>
        <Pressable role="button" onPress={() => closeAllModals()}>
          <Text style={[a.font_semibold, t.atoms.text]}>Close</Text>
        </Pressable>
        <Text style={[styles.title, t.atoms.text]}>
          <Trans>Invite Friends</Trans>
        </Text>
        <Button label="refresh" onPress={reloadTelegramContacts}>
          <Refresh width={24} height={24} style={[t.atoms.text]} />
        </Button>
      </View>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.gap_sm,
          a.px_md,
          a.py_sm,
          a.border,
          // t.atoms.border_contrast_low,
          t.atoms.input_border,
          t.atoms.input_bg,
          {borderRadius: 10},
        ]}>
        <SearchIcon
          fill={t.name === 'light' ? '#979797' : '#8D8D92'}
          width={14}
          height={14}
        />
        <TextInput
          style={[a.flex_1, t.atoms.text, {height: 26}]}
          value={filterText}
          clearButtonMode="always"
          onChangeText={v => setFilterText(v)}
          placeholder={_(msg`Search`)}
          placeholderTextColor={t.atoms.text_sub.color}
          returnKeyType="search"
        />
        {filterText.length > 0 && (
          <Button label="clear" onPress={() => setFilterText('')}>
            <IconCircle
              style={[
                {width: 16, height: 16, backgroundColor: t.palette.black},
              ]}
              icon={Close}
              iconStyle={{color: t.palette.white}}
              size="xs"
            />
          </Button>
        )}
      </View>
      {!refreshing && (
        <View
          style={[
            a.mb_xs,
            a.flex_col,
            a.pb_lg,
            {borderBottomWidth: 1},
            t.atoms.input_border,
          ]}>
          <Pressable
            accessibilityRole="button"
            style={[a.flex_row, a.align_center, a.justify_between]}
            onPress={() => {
              setIsExpand(!isExpand)
            }}>
            <View style={[a.flex_row, a.align_center, a.gap_md]}>
              {selectedItems?.length === 0 ? (
                <View
                  style={[
                    {width: 34, height: 34, borderRadius: 17, borderWidth: 1},
                    t.atoms.input_bg,
                    t.atoms.input_border,
                    a.align_center,
                    a.justify_center,
                  ]}>
                  <Text style={[t.atoms.text_sub, a.font_bold]}>0</Text>
                </View>
              ) : (
                <CircleMemberStackAvatar
                  data={selectedItems ?? []}
                  maxCount={8}
                  size={30}
                  bgColor={t.atoms.bg.backgroundColor}
                />
              )}
              <Text
                style={[
                  a.font_semibold,
                  selectedItems?.length ? t.atoms.text : t.atoms.text_sub,
                ]}>
                {selectedItems?.length} Selected
              </Text>
            </View>
            <RightIcon
              style={[
                !isExpand
                  ? {transform: 'scale(1.2) rotate(90deg)'}
                  : {transform: 'scale(1.2) rotate(270deg)'},
              ]}
              color={t.palette.gray_10}
            />
          </Pressable>
          {isExpand && (
            <View style={[a.mt_lg, a.mr_md]}>
              {!!selectedItems.length && (
                <View style={[a.flex_row, a.gap_xs, a.pb_xs, a.flex_wrap]}>
                  {selectedItems.map(item => (
                    <SmallContact
                      key={item.id}
                      user={item}
                      onSelected={selectHandle}
                      style={{
                        backgroundColor: '#CAE8FF',
                        borderColor: 'transparent',
                      }}
                    />
                  ))}
                </View>
              )}
            </View>
          )}
        </View>
      )}
      <ScrollView contentContainerStyle={[{borderWidth: 0, minHeight: '100%'}]}>
        {refreshing ? (
          <View style={[a.align_center, a.justify_center, a.p_2xl]}>
            <Loader size="xl" />
          </View>
        ) : (
          <View style={[a.pr_xl]}>{content}</View>
        )}
      </ScrollView>

      <Button
        style={[
          a.p_0,
          {paddingVertical: 14, backgroundColor: '#007AFF', borderRadius: 12},
        ]}
        color="primary"
        variant="solid"
        size="small"
        label="Invite"
        disabled={refreshing || !selectedItems?.length}
        onPress={() => onSelected(selectedItems.map(s => s.id))}>
        <Text style={[a.font_bold, a.text_md, {color: t.palette.white}]}>
          <Trans>Invite</Trans>
          {selectedItems?.length ? ` ${selectedItems?.length} Contacts` : ''}
        </Text>
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: 6,
    alignSelf: 'center',
  },
})

function ChevronStatus({status}: {status: boolean}) {
  const t = useTheme()
  return status ? (
    <ChevronTop fill={t.atoms.text_contrast_low.color} size="sm" />
  ) : (
    <ChevronBottom fill={t.atoms.text_contrast_low.color} size="sm" />
  )
}
