// import { Text } from 'react-native'
import React from 'react'

import {CommonNavigatorParams, NativeStackScreenProps} from '#/lib/routes/types'
import {CenteredView} from '#/view/com/util/Views'
// import {atoms as a, useTheme} from '#/alf'
import {QRCode} from './qrcode'

type Props = NativeStackScreenProps<
  CommonNavigatorParams,
  'ActivityQRCodeScreen'
>
export const ActivityQRCodeScreen = ({route}: Props) => {
  console.log('route', route)
  // const t = useTheme();

  return (
    <CenteredView>
      <QRCode />
    </CenteredView>
  )
}
