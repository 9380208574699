import React from 'react'
import {Text, View} from 'react-native'
import {AppBskyActorDefs} from '@atproto/api'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {getRootNavigation, getTabState, TabState} from '#/lib/routes/helpers'
import {logEvent} from '#/lib/statsig/statsig'
import {isNative} from '#/platform/detection'
import {emitter, listenSoftReset} from '#/state/events'
import {FeedFeedbackProvider, useFeedFeedback} from '#/state/feed-feedback'
import {useModalControls} from '#/state/modals'
import {RQKEY as FEED_RQKEY} from '#/state/queries/post-feed'
import {FeedDescriptor, FeedParams} from '#/state/queries/post-feed'
import {truncateAndInvalidate} from '#/state/queries/util'
import {useSession} from '#/state/session'
import {useSetMinimalShellMode} from '#/state/shell'
import {useAnalytics} from 'lib/analytics/analytics'
import {AllNavigatorParams} from 'lib/routes/types'
import {s} from 'lib/styles'
import {TGTagIcon} from '#/view/icons/FeedIcons'
import {useHeaderOffset} from '#/components/hooks/useHeaderOffset'
import {useCircle} from '../posts/CircleProvider'
import {Feed} from '../posts/Feed'
import {ListMethods} from '../util/List'
import {LoadFeedBtn} from '../util/load-latest/LoadFeedBtn'
import {LoadLatestBtn} from '../util/load-latest/LoadLatestBtn'
import {MainScrollProvider} from '../util/MainScrollProvider'
import {SdlButton} from '../util/SdlButton'
import {useTgStore} from '../util/sdlStore/TgStore'

const POLL_FREQ = 60e3 // 60sec

export function FeedPage({
  testID,
  isPageFocused,
  feed,
  feedParams,
  renderEmptyState,
  renderEndOfFeed,
  savedFeedConfig,
}: {
  testID?: string
  feed: FeedDescriptor
  feedParams?: FeedParams
  isPageFocused: boolean
  renderEmptyState: () => JSX.Element
  renderEndOfFeed?: () => JSX.Element
  renderLinkTelegram?: () => JSX.Element
  savedFeedConfig?: AppBskyActorDefs.SavedFeed
}) {
  const {hasSession} = useSession()
  const {_} = useLingui()
  const navigation = useNavigation<NavigationProp<AllNavigatorParams>>()
  const queryClient = useQueryClient()
  const [isScrolledDown, setIsScrolledDown] = React.useState(false)
  const setMinimalShellMode = useSetMinimalShellMode()
  const {screen} = useAnalytics()
  const headerOffset = useHeaderOffset()
  const feedFeedback = useFeedFeedback(feed, hasSession)
  const scrollElRef = React.useRef<ListMethods>(null)
  const [hasNew, setHasNew] = React.useState(false)

  const scrollToTop = React.useCallback(() => {
    scrollElRef.current?.scrollToOffset({
      animated: isNative,
      offset: -headerOffset,
    })
    setMinimalShellMode(false)
  }, [headerOffset, setMinimalShellMode])

  const onSoftReset = React.useCallback(() => {
    const isScreenFocused =
      getTabState(getRootNavigation(navigation).getState(), 'Home') ===
      TabState.InsideAtRoot
    if (isScreenFocused && isPageFocused) {
      scrollToTop()
      truncateAndInvalidate(queryClient, FEED_RQKEY(feed))
      setHasNew(false)
      logEvent('feed:refresh:sampled', {
        feedType: feed.split('|')[0],
        feedUrl: feed,
        reason: 'soft-reset',
      })
    }
  }, [navigation, isPageFocused, scrollToTop, queryClient, feed, setHasNew])

  // fires when page within screen is activated/deactivated
  React.useEffect(() => {
    if (!isPageFocused) {
      return
    }
    screen('Feed')
    return listenSoftReset(onSoftReset)
  }, [onSoftReset, screen, isPageFocused])

  const onPressLoadLatest = React.useCallback(() => {
    scrollToTop()
    truncateAndInvalidate(queryClient, FEED_RQKEY(feed))
    setHasNew(false)
    logEvent('feed:refresh:sampled', {
      feedType: feed.split('|')[0],
      feedUrl: feed,
      reason: 'load-latest',
    })
  }, [scrollToTop, feed, queryClient, setHasNew])

  const isCircle = useCircle()
  const {bind: hasBindTelegram} = useTgStore()
  const {openModal} = useModalControls()
  const [fixedAuthorization, setFixedAuthorization] = React.useState(false)

  React.useEffect(() => {
    const temp = () => setFixedAuthorization(true)
    emitter.on('fixedAuthorization', temp)
    return () => {
      emitter.off('fixedAuthorization', temp)
    }
  }, [])

  return (
    <div className="feed-page" style={{position: 'relative'}}>
      <View testID={testID} style={s.h100pct}>
        <MainScrollProvider>
          <FeedFeedbackProvider value={feedFeedback}>
            <Feed
              testID={testID ? `${testID}-feed` : undefined}
              enabled={isPageFocused}
              feed={feed}
              feedParams={feedParams}
              pollInterval={POLL_FREQ}
              disablePoll={hasNew}
              scrollElRef={scrollElRef}
              onScrolledDownChange={setIsScrolledDown}
              onHasNew={setHasNew}
              renderEmptyState={renderEmptyState}
              renderEndOfFeed={renderEndOfFeed}
              headerOffset={headerOffset}
              savedFeedConfig={savedFeedConfig}
            />
          </FeedFeedbackProvider>
        </MainScrollProvider>
        {isScrolledDown && (
          <LoadLatestBtn
            onPress={onPressLoadLatest}
            label={_(msg`Load new posts`)}
            showIndicator={false}
          />
        )}
        {hasNew && (
          <LoadFeedBtn
            onPress={onPressLoadLatest}
            label={_(msg`Load new posts`)}
          />
        )}
      </View>

      {isCircle && !hasBindTelegram && fixedAuthorization && (
        <View
          style={{
            height: 50,
            width: '100%',
            position: 'fixed',
            bottom: 77,
            left: 0,
          }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 5,
              backgroundColor: '#242424',
              elevation: 5,
              paddingRight: 25,
            }}>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <TGTagIcon size={28} style={{marginRight: 10}} />
              <Text>
                <Text style={{color: '#f5d249', marginRight: 5}}>
                  {_(msg`Authorize TG friends`)}
                </Text>
                <Text style={{color: 'white'}}>
                  {_(msg`to unlock secrets`)}
                </Text>
              </Text>
            </View>
            <SdlButton
              text={_(msg`Go`)}
              onPress={() => openModal({name: 'telegram'})}
              style={{flex: 'none'}}
              bgStyle={{padding: 0, height: 28, paddingHorizontal: 18}}
            />
          </View>
        </View>
      )}
    </div>
  )
}
