import {useCallback, useSyncExternalStore} from 'react'

import {createStore} from './SdlStore'

const store = createStore(false)

const {subscribe, getState, setState: setIdentity} = store

export function useIdentityStore() {
  const isOfficial = useSyncExternalStore(subscribe, getState)

  const changeIdentity = useCallback(() => {
    setIdentity(pre => !pre)
  }, [])
  return {
    changeIdentity,
    isOfficial,
  }
}
