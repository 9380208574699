import {createMultiPathSVG, createSinglePathSVG} from './TEMPLATE'

export const ArrowOutOfBox_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12.707 3.293a1 1 0 0 0-1.414 0l-4.5 4.5a1 1 0 0 0 1.414 1.414L11 6.414v8.836a1 1 0 1 0 2 0V6.414l2.793 2.793a1 1 0 1 0 1.414-1.414l-4.5-4.5ZM5 12.75a1 1 0 1 0-2 0V20a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-7.25a1 1 0 1 0-2 0V19H5v-6.25Z',
})

export const ArrowOutOfBoxRound_Stroke2_Corner0_Rounded = createMultiPathSVG({
  paths: [
    'M12.53 1.97a.75.75 0 0 0-1.06 0L8.12 5.32a.75.75 0 0 0 1.06 1.06l2.07-2.069v10.69a.75.75 0 0 0 1.5 0V4.31l2.07 2.07a.75.75 0 1 0 1.06-1.061l-3.35-3.35Z',
    'M15.693 8.835a.75.75 0 0 1 .812-.683c1.912.165 3.41.753 4.403 1.977.977 1.204 1.352 2.887 1.352 4.98v.13c0 2.31-.457 4.121-1.673 5.337-1.216 1.216-3.027 1.674-5.336 1.674H8.74c-2.31 0-4.121-.458-5.337-1.674-1.216-1.216-1.674-3.027-1.674-5.337v-.13c0-2.078.371-3.75 1.332-4.952.979-1.223 2.454-1.819 4.339-1.994a.75.75 0 1 1 .14 1.493c-1.656.155-2.676.649-3.307 1.438-.65.811-1.004 2.074-1.004 4.016v.13c0 2.16.438 3.478 1.235 4.275.796.797 2.115 1.235 4.275 1.235h6.51c2.161 0 3.48-.438 4.276-1.235.797-.797 1.235-2.115 1.235-4.276v-.13c0-1.956-.36-3.223-1.018-4.034-.641-.79-1.679-1.283-3.367-1.428a.75.75 0 0 1-.683-.812Z',
  ],
})
