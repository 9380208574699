import React, {useRef} from 'react'
import {Text, View} from 'react-native'
import ViewShot from 'react-native-view-shot'
import {Trans} from '@lingui/macro'

// import {useNavigation} from '@react-navigation/native'
// import {NavigationProp} from '#/lib/routes/types'
// import {useFriendQueryPendingReqNum} from '#/state/queries/connections'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
// import {MessageNotif} from '#/components/icons/MessageNotif'
import {QrCodeInner} from '#/components/StarterPack/QrCode'
import {useTelegramBotUrl} from './hooks'

export const QRCode = () => {
  const {currentAccount} = useSession()
  const t = useTheme()
  const qrcodeRef = useRef<ViewShot>(null)
  //   const navigation = useNavigation<NavigationProp>()
  //   const {data: reqNum} = useFriendQueryPendingReqNum()

  const {
    data: profile,
    // error: profileError,
    // refetch: refetchProfile,
    // isLoading: isLoadingProfile,
    // isPlaceholderData: isPlaceholderProfile,
  } = useProfileQuery({
    did: currentAccount?.did,
  })

  const link = useTelegramBotUrl()

  return (
    <View>
      <ViewHeader
        showBorder={false}
        showOnDesktop
        title=""
        canGoBack={true}
        // renderButton={notifiIcon}
      />
      <ViewShot ref={qrcodeRef} style={[a.gap_2xl, a.p_5xl]}>
        <View style={[a.align_center, a.justify_center]}>
          <UserAvatar type="user" avatar={profile?.avatar} size={60} />
        </View>
        <View style={[a.gap_xs]}>
          <Text
            style={[
              a.text_xl,
              a.text_center,
              t.atoms.text,
              {fontSize: 24, fontWeight: 600},
            ]}>
            {profile?.displayName}
          </Text>
          <Text
            style={[
              a.text_xl,
              a.text_center,
              t.atoms.text_sub,
              {fontSize: 14},
            ]}>
            @{formatHandler(currentAccount?.handle || '')}
          </Text>
        </View>
        <View style={[a.align_center]}>
          <View style={[a.overflow_hidden, {borderRadius: 32}]}>
            <QrCodeInner link={link} style={{padding: 10}} />
          </View>
        </View>
        <View>
          <Text style={[t.atoms.text_contrast_low, a.text_center]}>
            <Trans>Scan the QR code pattern above and add me as a friend</Trans>
          </Text>
        </View>
        {/* <View>
          <Text
            style={[
              t.atoms.text_contrast_low,
              a.text_center,
              {color: t.palette.primary},
            ]}>
            <Trans>Save QR code</Trans>
          </Text>
        </View> */}
      </ViewShot>
    </View>
  )
}
