import React from 'react'
import {StyleSheet, View} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {msg, Plural, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import dayjs from 'dayjs'

import {Shadow} from '#/state/cache/post-shadow'
import {useThemePrefs} from '#/state/shell'
import {POST_CTRL_HITSLOP} from 'lib/constants'
import {usePalette} from 'lib/hooks/usePalette'
import {makeProfileLink} from 'lib/routes/links'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {sanitizeHandle} from 'lib/strings/handles'
import {s} from 'lib/styles'
import {PostStakeDonateItem} from 'view/com/post-thread/PostStakeDonateItem'
import {formatHandler} from '#/screens/Onboarding/util'
import {FONT_SIZE_CONFIG} from '#/screens/Settings/TextSizeSettings'
import {atoms as a, useTheme} from '#/alf'
import {RichText} from '#/components/RichText'
import {ContentHider} from '../../../components/moderation/ContentHider'
import {LabelsOnMyPost} from '../../../components/moderation/LabelsOnMe'
import {PostAlerts} from '../../../components/moderation/PostAlerts'
import {TargetDisplay} from '../posts/TargetDisplay'
import {
  AutoTranslate,
  TranslationWidget,
} from '../posts/TranslationWidget/TranslationWidget'
import {ErrorMessage} from '../util/error/ErrorMessage'
import {PostDropdownBtn} from '../util/forms/PostDropdownBtn'
import {Link} from '../util/Link'
import {formatCount} from '../util/numeric/format'
import {PostEmbeds} from '../util/post-embeds'
import {Text} from '../util/text/Text'
import {AnonSpillerAvatar, PreviewableUserAvatar} from '../util/UserAvatar'
import {PostBetChallenge} from './PostBetChallenge'

export function BigFigurePostThreadItem({
  post,
  record,
  richText,
  moderation,
  // onPostReply,
  hideTopBorder,
  translate,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  moderation: ModerationDecision
  onPostReply: (postUri: string | undefined) => void
  hideTopBorder?: boolean
  translate: {
    displayOriginalText: () => void
    translatePost: () => void
    hasTranslated: boolean
    isLanguageConsistent: boolean
    fromMe: boolean
  }
}): React.ReactNode {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const authorHref = makeProfileLink(post.author)
  const authorTitle = sanitizeHandle(post.author.handle)
  const {fontSizeMode} = useThemePrefs()
  const detailFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.detail].detail.public
  }, [fontSizeMode])

  const likesHref = React.useMemo(() => {
    const urip = new AtUri(post.uri)
    return makeProfileLink(post.author, 'post', urip.rkey, 'liked-by')
  }, [post.uri, post.author])

  const repostsHref = React.useMemo(() => {
    const urip = new AtUri(post.uri)
    return makeProfileLink(post.author, 'post', urip.rkey, 'reposted-by')
  }, [post.uri, post.author])

  if (!record) {
    return <ErrorMessage message={_(msg`Invalid or unsupported post record`)} />
  }

  const targets = record?.finEntities || []
  const isAnonymousPost = record?.hideAuthor === 'true' ? true : false
  const isRootPost = (record.reply?.root?.uri || post.uri) === post.uri

  return (
    <>
      {!isRootPost && (
        <View style={{paddingLeft: 16, flexDirection: 'row', height: 16}}>
          <View style={{width: 38}}>
            <View
              style={[
                styles.replyLine,
                {flexGrow: 1, backgroundColor: pal.colors.replyLine},
              ]}
            />
          </View>
        </View>
      )}

      <View
        testID={`bigFigure-PostThreadItem-by-${post.author.handle}`}
        style={[
          pal.border,
          a.px_lg,
          isRootPost && styles.outerHighlightedRoot,
          hideTopBorder && styles.noTopBorder,
        ]}
        accessible={false}>
        {/* Author */}
        <View style={[a.flex_row, a.align_center, a.mb_lg]}>
          <View style={[styles.layoutAvi]}>
            {isAnonymousPost ? (
              <AnonSpillerAvatar size={34} profile={post.author} />
            ) : (
              <PreviewableUserAvatar
                size={34}
                profile={post.author}
                moderation={moderation.ui('avatar')}
                type={post.author.associated?.labeler ? 'labeler' : 'user'}
              />
            )}
          </View>
          <View style={styles.layoutContent}>
            <View style={[a.flex_row, styles.metaExpandedLine1, {zIndex: 1}]}>
              {isAnonymousPost ? (
                <View>
                  <Text
                    type="xl-bold"
                    style={[pal.text, a.self_start, a.text_xs, a.font_heavy]}
                    numberOfLines={1}
                    lineHeight={1}>
                    Anonymous
                  </Text>
                </View>
              ) : (
                <Link style={s.flex1} href={authorHref} title={authorTitle}>
                  <Text
                    type="xl-bold"
                    style={[pal.text, a.self_start, a.text_xs]}
                    numberOfLines={1}
                    lineHeight={1}>
                    {sanitizeDisplayName(
                      post.author.displayName ||
                        sanitizeHandle(formatHandler(post.author.handle)),
                      moderation.ui('displayName'),
                    )}
                  </Text>
                  <Text style={[a.text_xs, {color: t.palette.gray_16}]}>
                    {sanitizeHandle(formatHandler('@' + post.author.handle))}
                  </Text>
                </Link>
              )}
            </View>
          </View>
          <PostDropdownBtn
            testID="postDropdownBtn"
            post={post}
            postFeedContext={''}
            record={record}
            richText={richText}
            style={{padding: 5}}
            hitSlop={POST_CTRL_HITSLOP}
            timestamp={post.indexedAt}
          />
        </View>
        {/* Spill abount */}
        {targets.length > 0 && (
          <View style={[a.mb_lg]}>
            <TargetDisplay targets={targets} />
          </View>
        )}
        <LabelsOnMyPost post={post} />

        {/* Title */}
        {record?.title && (
          <Text
            style={[
              pal.text,
              t.atoms.post_thread_text,
              {
                fontSize: detailFontSize.title,
                fontWeight: '700',
                marginBottom: 16,
                fontFamily: 'BigFigure',
              },
            ]}>
            {record.title}
          </Text>
        )}
        {/* Content */}
        <ContentHider
          modui={moderation.ui('contentView')}
          ignoreMute
          style={styles.contentHider}
          childContainerStyle={styles.contentHiderChild}>
          <PostAlerts
            modui={moderation.ui('contentView')}
            size="lg"
            includeMute
            style={[a.pt_2xs, a.pb_sm]}
          />
          {richText?.text ? (
            <>
              <View
                style={[
                  styles.postTextContainer,
                  styles.postTextLargeContainer,
                ]}>
                <RichText
                  enableTags
                  selectable
                  value={richText}
                  style={[
                    a.flex_1,
                    {
                      fontSize: detailFontSize.text,
                      lineHeight: 28,
                    },
                    t.atoms.post_thread_text,
                  ]}
                  authorHandle={sanitizeHandle(post.author.handle)}
                />
              </View>
            </>
          ) : undefined}
          {/* Time & Translation */}
          <View
            style={[a.flex_row, a.align_center, a.justify_between, a.mb_sm]}>
            <Text style={[a.text_xs, {color: '#696969'}]}>
              <Trans>
                {dayjs(post.indexedAt).format('MMM DD, YYYY')} at{' '}
                {dayjs(post.indexedAt).format('HH:mm')}
              </Trans>
            </Text>
            <View style={[a.flex_row, a.align_center]}>
              {!translate.fromMe && (
                <AutoTranslate style={[a.relative, {top: 2, right: -15}]} />
              )}
              <TranslationWidget
                translate={translate}
                textStyle={[a.text_xs, {color: '#8D8D8D'}]}
              />
            </View>
          </View>
          {post.embed && (
            <View style={[a.pb_sm]}>
              <PostEmbeds embed={post.embed} moderation={moderation} />
            </View>
          )}
        </ContentHider>
        {/* Stake */}
        {isRootPost && (
          <PostStakeDonateItem post={post} isAnonymousPost={isAnonymousPost} />
        )}
      </View>
      {/* likeCount & repostCount */}
      {post.repostCount !== 0 || post.likeCount !== 0 ? (
        // Show this section unless we're *sure* it has no engagement.
        <View style={[styles.expandedInfo, pal.border]}>
          {post.repostCount != null && post.repostCount !== 0 ? (
            <Link
              style={styles.expandedInfoItem}
              href={repostsHref}
              title={_(msg`Reposts of this post`)}>
              <Text
                testID="repostCount-expanded"
                type="sm"
                style={{color: t.palette.gray_17}}>
                <Text type="sm-bold" style={pal.text}>
                  {formatCount(post.repostCount)}
                </Text>{' '}
                <Plural value={post.repostCount} one="Repost" other="Reposts" />
              </Text>
            </Link>
          ) : null}
          {post.likeCount != null && post.likeCount !== 0 ? (
            <Link
              style={styles.expandedInfoItem}
              href={likesHref}
              title={_(msg`Likes on this post`)}>
              <Text
                testID="likeCount-expanded"
                type="sm"
                style={{color: t.palette.gray_17}}>
                <Text type="sm-bold" style={pal.text}>
                  {formatCount(post.likeCount)}
                </Text>{' '}
                <Plural value={post.likeCount} one="Like" other="Likes" />
              </Text>
            </Link>
          ) : null}
        </View>
      ) : null}
      {isRootPost && <PostBetChallenge post={post} />}
      {post.replyCount ? (
        <View
          style={[
            a.p_lg,
            pal.border,
            {borderTopWidth: StyleSheet.hairlineWidth},
          ]}>
          <Text style={[t.atoms.text, a.text_md, a.font_heavy]}>
            Comments ({post.replyCount})
          </Text>
        </View>
      ) : (
        ''
      )}
    </>
  )
}

const styles = StyleSheet.create({
  outer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingLeft: 8,
  },
  outerHighlighted: {
    borderTopWidth: 0,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  outerHighlightedRoot: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: 16,
  },
  noTopBorder: {
    borderTopWidth: 0,
  },
  layout: {
    flexDirection: 'row',
    paddingHorizontal: 8,
  },
  layoutAvi: {},
  layoutContent: {
    flex: 1,
    marginLeft: 10,
  },
  layoutContentThreaded: {
    flex: 1,
  },
  meta: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  metaExpandedLine1: {
    paddingVertical: 0,
  },
  alert: {
    marginBottom: 6,
  },
  postTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: 4,
    paddingRight: 10,
    overflow: 'hidden',
  },
  postTextLargeContainer: {
    paddingHorizontal: 0,
    paddingRight: 0,
    paddingBottom: 10,
  },
  translateLink: {
    marginBottom: 6,
  },
  contentHider: {
    marginBottom: 6,
  },
  contentHiderChild: {
    marginTop: 6,
  },
  expandedInfo: {
    flexDirection: 'row',
    marginHorizontal: 16,
    paddingVertical: 16,
    borderTopWidth: StyleSheet.hairlineWidth,
    // borderBottomWidth: StyleSheet.hairlineWidth,
    // marginTop: 5,
    // marginBottom: 10,
  },
  expandedInfoItem: {
    marginRight: 10,
  },
  loadMore: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4,
    paddingHorizontal: 20,
  },
  replyLine: {
    width: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cursor: {
    // @ts-ignore web only
    cursor: 'pointer',
  },
})
