import React, {useState} from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {ComAtprotoServerDefs} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {usePalette} from '#/lib/hooks/usePalette'
import {ScrollProvider} from '#/lib/ScrollContext'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {useBetScoreListQuery} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {NavigationProp} from 'lib/routes/types'
import {EmptyState} from '#/view/com/util/EmptyState'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {CenteredView} from 'view/com/util/Views'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {TabBar} from '../com/pager/TabBar'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'BetScoreList'>

const BACK_HITSLOP = {left: 20, top: 20, right: 50, bottom: 20}

export function BetScoreListScreen(props: Props) {
  /* eslint-disable */
  const {did, dataType, rkey} = props.route.params
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const {isDesktop} = useWebMediaQueries()
  const navigation = useNavigation<NavigationProp>()
  const items = ['Bet on it', 'Bet against it']
  // const postId = `${did?.split(':')?.[2]}/${rkey}`
  const uri = makeRecordUri(did ?? '', 'app.bsky.feed.post', rkey)

  const {currentAccount} = useSession()
  const {data: betOnData, isLoading: betOnLoading} = useBetScoreListQuery({
    uri,
    dataType: 'bet-on',
    did: currentAccount?.did,
    postDid: did,
  })
  const {data: betAgainstData, isLoading: betAgainstLoading} =
    useBetScoreListQuery({
      uri,
      dataType: 'bet-against',
      did: currentAccount?.did,
      postDid: did,
    })

  const [currentItem, setCurrentItem] = useState<number>(
    dataType === 'bet-on' ? 0 : 1,
  )

  const activeColor = currentItem === 0 ? '#B27AFF' : t.palette.yellow_25

  const transLoading = currentItem === 0 ? betOnLoading : betAgainstLoading
  const transList = currentItem === 0 ? betOnData?.data : betAgainstData?.data
  const transTotal =
    currentItem === 0 ? betOnData?.total : betAgainstData?.total

  const renderItemInner = React.useCallback(
    ({item}: {item: ComAtprotoServerDefs.BetTransDetailGroup}) => {
      const isYou = item?.sortKey === 2
      const isSpiller = item.sortKey === 3
      return (
        <View style={[styles.listRecordContaner]} key={item?.payer}>
          <View style={[styles.listRecord]}>
            <View>
              <UserAvatar
                type="user"
                size={44}
                avatar={item?.profile?.avatar}
              />
            </View>
            <View style={styles.listUser}>
              <View style={[a.mb_xs, a.flex_row, a.align_center, a.gap_sm]}>
                {item?.profile?.displayName && (
                  <Text style={[t.atoms.text, a.text_md, a.font_semibold]}>
                    {item?.profile?.displayName}
                  </Text>
                )}
                {isSpiller ? (
                  <View
                    style={[
                      a.px_xs,
                      {
                        paddingVertical: 2,
                        backgroundColor: t.palette.primary,
                        borderRadius: 4,
                      },
                    ]}>
                    <Text style={[a.text_xs, a.font_semibold]}>Spiller</Text>
                  </View>
                ) : (
                  isYou && (
                    <View
                      style={[
                        a.px_xs,
                        {
                          paddingVertical: 2,
                          backgroundColor: t.palette.green_500,
                          borderRadius: 4,
                        },
                      ]}>
                      <Text
                        style={[
                          a.text_xs,
                          a.font_semibold,
                          {color: t.palette.white},
                        ]}>
                        You
                      </Text>
                    </View>
                  )
                )}
              </View>
              <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                {item?.profile?.handle
                  ? `@${formatHandler(item?.profile?.handle)}`
                  : ''}
              </Text>
            </View>
            <View>
              <Text
                style={[
                  t.atoms.text,
                  a.text_md,
                  a.font_heavy,
                  a.text_right,
                  {color: activeColor},
                ]}>
                {item?.total}
              </Text>
            </View>
          </View>
        </View>
      )
    },
    [t.atoms.text, t.palette.contrast_200, activeColor],
  )

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  console.log('betscorelist', transList)

  return (
    <CenteredView
      testID="betScoreModal"
      style={[{backgroundColor: activeColor}]}>
      <View
        style={[
          styles.header,
          pal.view,
          a.align_center,
          {backgroundColor: activeColor},
        ]}>
        {!isDesktop && (
          <TouchableOpacity
            testID="betScoreBackBtn"
            onPress={onPressBack}
            hitSlop={BACK_HITSLOP}
            style={styles.backBtn}
            accessibilityRole="button"
            accessibilityLabel={_(msg`Back`)}
            accessibilityHint="">
            <FontAwesomeIcon
              size={18}
              icon="angle-left"
              color="white"
              style={[styles.backIcon, pal.text]}
            />
          </TouchableOpacity>
        )}
        <View style={[a.flex_1, a.w_full]}>
          <TabBar
            key={items?.join(',')}
            onPressSelected={setCurrentItem}
            selectedPage={currentItem}
            onSelect={setCurrentItem}
            testID={'betScoreModalTab'}
            items={items}
            textStyle={[{color: t.palette.white}, a.text_md, a.font_normal]}
            selectedTextStyle={[a.text_xl, a.font_bold]}
            bgColor={activeColor}
            hideBorder={true}
            isCentered={true}
          />
        </View>
      </View>
      <ScrollProvider>
        <View
          style={[
            a.flex_row,
            a.justify_between,
            a.align_center,
            a.px_lg,
            a.pt_3xl,
            a.pb_xs,
            {backgroundColor: activeColor},
            styles.contentHeader,
          ]}>
          <Text style={[a.font_semibold, a.text_md, {color: t.palette.white}]}>
            Weighted Score (Total)
          </Text>
          <Text
            style={[a.font_extrabold, a.text_4xl, {color: t.palette.white}]}>
            {transTotal}
          </Text>
        </View>
        <View
          style={[styles.contentContainer, {backgroundColor: t.palette.white}]}>
          {transLoading ? (
            <ActivityIndicator size="large" color={t.palette.primary} />
          ) : transList && transList?.length > 0 ? (
            <RNFlatList
              testID="bet-score-flatlist"
              data={transList}
              renderItem={renderItemInner}
              // refreshControl={
              //   <RefreshControl
              //     refreshing={isPTRing}
              //     onRefresh={onRefresh}
              //     tintColor={pal.colors.text}
              //     titleColor={pal.colors.text}
              //   />
              // }
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <EmptyState
              icon="list-ul"
              message={_(msg`List is empty.`)}
              style={[{borderWidth: 0}]}
            />
          )}
        </View>
      </ScrollProvider>
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 6,
    width: '100%',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 15,
    borderRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  listRecordContaner: {
    marginBottom: 15,
  },
  listRecord: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  listUser: {
    flex: 1,
  },
  backBtn: {
    width: 30,
    height: 30,
  },
  backIcon: {
    marginTop: 6,
  },
  contentHeader: {},
})
