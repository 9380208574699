import React from 'react'
import {SafeAreaView, Text, View} from 'react-native'

import {atoms as a, useTheme} from '#/alf'
import {DonateTab} from '../post-transaction/DonateTabPanel'
import {StakeTab} from '../post-transaction/StakeTabPanel'
import {TransPostAuthorProps} from './BetToEarn'

export const snapPoints = ['55%']

export type StakeData = {
  amount: string | undefined
  pending: boolean
  error: string | undefined
}

export type StakeDonateModalType = 'stake' | 'donate' | 'create-post-stake'
export type StakeDonateModalProps = {
  type: StakeDonateModalType
  rKey?: string
  transPostAuthor?: TransPostAuthorProps
  amount?: string | undefined
  pending?: boolean
  error?: string | undefined

  setParentAmount?: (value: string | undefined) => void
  setParentError?: (value: string | undefined) => void
  setParentPending?: (value: boolean) => void
  setResponse?: (value: {success: boolean; hash?: string} | undefined) => void
}
export const Component = function StakeDonateModalComponet(
  props: StakeDonateModalProps,
) {
  const t = useTheme()
  return (
    <SafeAreaView testID="stakeDonateModal">
      <View>
        <Text
          style={[
            a.text_lg,
            a.font_bold,
            a.text_center,
            a.mb_sm,
            t.atoms.text,
          ]}>
          {props.type === 'donate'
            ? 'Tipping'
            : props.type === 'create-post-stake'
            ? 'Stake to Validate'
            : 'Stake to Endorse'}
        </Text>
      </View>
      {props.type === 'donate' ? (
        <DonateTab {...props} />
      ) : (
        <StakeTab {...props} />
      )}
    </SafeAreaView>
  )
}
