import {ComAtprotoServerTelegramParticipants} from '@atproto/api'
import type {Api} from '@sipz/telegram'

type TaskType = (
  id: bigInt.BigInteger,
) => Promise<Api.channels.ChannelParticipants | null>

interface IQueue<T extends Api.messages.ChatFull> {
  queue: T[]
  task: TaskType
  count?: number
  resultFormat: (
    chat: Api.messages.ChatFull,
    participants: Api.channels.ChannelParticipants,
  ) => any
}

export default class ParticipantsQueue<T extends Api.messages.ChatFull> {
  count: number
  queue: T[]
  index = 0
  result: ComAtprotoServerTelegramParticipants.InputSchema[] = []
  task: TaskType
  resultFormat: (
    chat: Api.messages.ChatFull,
    participants: Api.channels.ChannelParticipants,
  ) => any

  constructor(opts: IQueue<T>) {
    this.count = opts.count ?? 2
    this.queue = opts.queue ?? []
    this.task = opts.task
    this.resultFormat = opts.resultFormat
  }

  run() {
    return new Promise<ComAtprotoServerTelegramParticipants.InputSchema[]>(
      resolve => {
        const len = this.queue.length
        if (len > 0) {
          for (let i = 0; i < Math.min(this.count, len); i++) {
            const item = this.queue[i]
            if (item) {
              this.index = i
              this.next(item, resolve)
            }
          }
        } else {
          resolve(this.result)
        }
      },
    )
  }

  next(
    item: T,
    resolve: (
      value:
        | ComAtprotoServerTelegramParticipants.InputSchema[]
        | PromiseLike<ComAtprotoServerTelegramParticipants.InputSchema[]>,
    ) => void,
  ) {
    this.task(item.fullChat.id)
      .then(res => {
        if (res) {
          this.result.push(this.resultFormat(item, res))
        }
        this.index++
        if (this.index >= this.queue.length) {
          resolve(this.result)
        }
        const nextTask = this.queue[this.index]
        if (nextTask) {
          this.next(nextTask, resolve)
        }
      })
      .catch(() => {
        this.index++
        if (this.index >= this.queue.length) {
          resolve(this.result)
        }
        const nextTask = this.queue[this.index]
        if (nextTask) {
          this.next(nextTask, resolve)
        }
      })
  }
}
