import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {SendFriendsRequestFrom} from '#/view/com/modals/CircleModal/SendFriendsRequest'
import {ContactItemProps} from '#/view/screens/Circle'
import {FriendRequestItemProps} from '#/view/screens/Circle/FriendRequestItem'
import {RecommendItemProps} from '#/view/screens/Circle/RecommendItem'
import {useAgent} from '../session'

export const RQKEY_ROOT = 'connections'
export const RQKEY = () => [RQKEY_ROOT]

export const CONNECTIONS_RQKEY = {
  recommendList: RQKEY_ROOT + '-recommendlist',
  requestList: RQKEY_ROOT + '-requestlist',
  isFriend: RQKEY_ROOT + '-isFriend',
}

export function useFriendsReqsQuery({
  limit = 1000,
  offset = 0,
  startDate,
  endDate,
}: {
  limit?: number
  offset?: number
  startDate?: Date
  endDate?: Date
}) {
  const agent = useAgent()
  startDate?.setUTCHours(0, 0, 0, 0)
  // endDate?.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset())
  endDate?.setDate(endDate.getDate() + 1)
  const timeZoneEndDate = endDate ? new Date(endDate?.getTime()) : undefined
  timeZoneEndDate?.setUTCHours(23, 59, 59, 999)

  return useQuery<FriendRequestItemProps[], Error>({
    queryKey: [
      CONNECTIONS_RQKEY.requestList,
      'list',
      startDate,
      timeZoneEndDate,
    ],
    queryFn: async () => {
      const res = await agent.com.atproto.server.friendQueryReqs({
        limit,
        offset,
        startTime: startDate?.toISOString(),
        endTime: timeZoneEndDate?.toISOString(),
      })
      return res.data.friendReqs?.map(item => {
        return {
          id: item?.requestId ?? '',
          nickname: item?.displayName,
          username: item?.handle,
          avatar: item?.avatar,
          source: item?.source,
          reason: item?.reason,
          status: item?.status,
          type: item?.type,
          // from: item?.userType,
          isMutual: item?.mutual,
          createdAt: item?.createdAt,
          isRecipientBandTg: item?.isRecipientBandTg,
        } as FriendRequestItemProps
      })
    },
  })
}

export function useFriendQueryPendingReqNum() {
  const agent = useAgent()
  return useQuery<number, Error>({
    queryKey: [CONNECTIONS_RQKEY.requestList, 'reqNum'],
    queryFn: async () => {
      const res = await agent.com.atproto.server.friendQueryPendingReqNum()
      return res.data.count
    },
  })
}

export function useFriendQueryRecommendsQuery({
  includeUnbindTgUser,
}: {
  includeUnbindTgUser?: boolean
}) {
  const agent = useAgent()
  return useQuery<ContactItemProps[], Error>({
    queryKey: [CONNECTIONS_RQKEY.recommendList, {includeUnbindTgUser}],
    queryFn: async () => {
      const res = await agent.com.atproto.server.friendQueryRecommends({
        includeUnbindTgUser,
      })
      return res.data.recommends?.map(item => {
        return {
          id: item?.userId,
          nickname: item?.displayName,
          username: item?.handle,
          avatar: item?.avatar,
          from: item?.userType,
          source: item?.source,
          isMutual: item?.mutual,
          isPending: item?.isPending,
        } as RecommendItemProps
      })
    },
  })
}

export function useFriendAddReqMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async ({
      recipientDid,
      source,
      reason,
    }: {
      recipientDid: string
      source: string
      reason: string
      from?: SendFriendsRequestFrom
    }) => {
      await agent.com.atproto.server.friendAddReq({
        recipientDid,
        source,
        reason,
      })
    },
    onSuccess(data, variables) {
      if (variables?.from === 'recommend') {
        queryClient.invalidateQueries({
          queryKey: [CONNECTIONS_RQKEY.recommendList],
        })
        queryClient.invalidateQueries({
          queryKey: [CONNECTIONS_RQKEY.requestList],
        })
      } else if (variables?.from === 'profile') {
        queryClient.invalidateQueries({
          queryKey: [CONNECTIONS_RQKEY.isFriend, variables.recipientDid],
        })
      }
    },
  })
}

export function useFriendAgreeReqMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async (params: {requestId: number; from?: 'request'}) => {
      await agent.com.atproto.server.friendAgreeReq({
        requestId: params?.requestId,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [CONNECTIONS_RQKEY.requestList],
      })
    },
  })
}

export function useFriendQueryIsFriend(did: string) {
  const agent = useAgent()
  return useQuery<number, Error>({
    queryKey: [CONNECTIONS_RQKEY.isFriend, did],
    queryFn: async () => {
      const res = await agent.com.atproto.server.friendQueryIsFriend({did})
      return res.data.status
    },
  })
}
