import React, {memo} from 'react'
import {StyleSheet, Text, TouchableWithoutFeedback, View} from 'react-native'
import {AppBskyActorDefs, ModerationDecision} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {useAnalytics} from '#/lib/analytics/analytics'
import {useIsMe} from '#/lib/hooks/useIsMe'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {Shadow} from '#/state/cache/types'
import {ProfileImageLightbox, useLightboxControls} from '#/state/lightbox'
import {useModalControls} from '#/state/modals'
import {useFriendQueryIsFriend} from '#/state/queries/connections'
import {useMaybeConvoForUser} from '#/state/queries/messages/get-convo-for-members'
import {useRequireAuth} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
// import {BACK_HITSLOP} from 'lib/constants'
// import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {NavigationProp} from 'lib/routes/types'
import {isIOS} from 'platform/detection'
import {useIdentityStore} from '#/view/com/util/sdlStore/IdentityStore'
import {AddFriendsIcon} from '#/view/icons/ModalIcons'
import {
  LeftIcon,
  MessageIcon,
  SettingIcon,
  UnFollowIcon,
  UpLoadIcon,
} from '#/view/icons/ProfileIcons'
import {LoadingPlaceholder} from 'view/com/util/LoadingPlaceholder'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {UserBanner} from 'view/com/util/UserBanner'
import {atoms as a, useTheme} from '#/alf'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {LabelsOnMe} from '#/components/moderation/LabelsOnMe'
import {ProfileHeaderAlerts} from '#/components/moderation/ProfileHeaderAlerts'
import {profileShare} from '#/utils/ShareUtil'

interface Props {
  profile: Shadow<AppBskyActorDefs.ProfileViewDetailed>
  moderation: ModerationDecision
  hideBackButton?: boolean
  isPlaceholderProfile?: boolean
}

let ProfileHeaderShell = ({
  children,
  profile,
  moderation,
  hideBackButton = false,
  isPlaceholderProfile,
}: React.PropsWithChildren<Props>): React.ReactNode => {
  const {isDesktop} = useWebMediaQueries()
  const t = useTheme()
  const {_} = useLingui()
  const {openLightbox} = useLightboxControls()
  const navigation = useNavigation<NavigationProp>()
  const requireAuth = useRequireAuth()
  const {data: convo} = useMaybeConvoForUser(profile.did)
  // const {isDesktop} = useWebMediaQueries()
  const isMe = useIsMe(profile.did)
  const {data: isFriend} = useFriendQueryIsFriend(profile.did)
  // const isCurrent = React.useMemo(() => {
  //   return profile.did === currentAccount?.did
  // }, [currentAccount?.did, profile.did])
  const setDrawerOpen = useSetDrawerOpen()
  const {track} = useAnalytics()
  const {changeIdentity} = useIdentityStore()

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  const onPressSettings = React.useCallback(() => {
    track('Menu:ItemClicked', {url: 'Settings'})
    navigation.navigate('Settings')
    setDrawerOpen(false)
  }, [navigation, track, setDrawerOpen])

  // const onPressMask = React.useCallback(() => {
  //   navigation.navigate('Mask', {time: Date.now()})
  // }, [navigation])

  // const onPressSetting = React.useCallback(() => {
  //   navigation.navigate('MaskSetting', {handle: profile.handle})
  // }, [navigation, profile.handle])

  // const onPressLinkedWallet = React.useCallback(() => {
  //   navigation.navigate('LinkedWallet')
  // }, [navigation])

  const onPressMassage = React.useCallback(() => {
    if (convo) {
      navigation.navigate('Messages', {})
    }
  }, [convo, navigation])

  const onPressAvi = React.useCallback(() => {
    const modui = moderation.ui('avatar')
    if (profile.avatar && !(modui.blur && modui.noOverride)) {
      openLightbox(new ProfileImageLightbox(profile))
    }
  }, [openLightbox, profile, moderation])

  const {openModal} = useModalControls()

  const onShare = () => {
    profileShare()
  }

  const onAddFriend = () => {
    requireAuth(async () => {
      openModal({
        name: 'send-friends-request',
        selectedUser: {
          id: profile.did,
          source: 'profile',
        },
        from: 'profile',
        onCallBack: () => {},
      })
    })
  }

  return (
    <>
      <View style={t.atoms.bg} pointerEvents={isIOS ? 'auto' : 'box-none'}>
        <View pointerEvents={isIOS ? 'auto' : 'none'}>
          {isPlaceholderProfile ? (
            <LoadingPlaceholder
              width="100%"
              height={150}
              style={{borderRadius: 0}}
            />
          ) : (
            <UserBanner
              type={profile.associated?.labeler ? 'labeler' : 'default'}
              banner={profile.banner}
              moderation={moderation.ui('banner')}
            />
          )}
        </View>

        {children}

        {!isPlaceholderProfile && (
          <View
            style={[a.px_lg, a.py_xs]}
            pointerEvents={isIOS ? 'auto' : 'box-none'}>
            {isMe ? (
              <LabelsOnMe
                details={{did: profile.did}}
                labels={profile.labels}
              />
            ) : (
              <ProfileHeaderAlerts moderation={moderation} />
            )}
          </View>
        )}

        {/* top button ================ */}
        <View style={styles.backBtnWrapper}>
          {!hideBackButton && !isDesktop && (
            <TouchableWithoutFeedback
              accessibilityRole="button"
              onPress={onPressBack}>
              <View style={styles.sdlBt}>
                <LeftIcon size={25} />
              </View>
            </TouchableWithoutFeedback>
          )}
          <View
            style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end'}}>
            {isMe ? (
              <>
                {/* <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onPressMask}>
                <View style={styles.sdlBt}>
                  <MaskIcon />
                </View>
              </TouchableWithoutFeedback> */}

                {/* {isCurrent && (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onPressLinkedWallet}>
                  <View style={styles.sdlBt}>
                    <WalletIcon style={{color: 'white'}} />
                  </View>
                </TouchableWithoutFeedback>
              )} */}
              </>
            ) : profile.viewer?.following ? (
              <>
                {convo && (
                  <TouchableWithoutFeedback
                    accessibilityRole="button"
                    onPress={onPressMassage}>
                    <View style={styles.sdlBt}>
                      <MessageIcon />
                    </View>
                  </TouchableWithoutFeedback>
                )}
              </>
            ) : null}
            {!isMe &&
              (!isFriend ? (
                <TouchableWithoutFeedback
                  accessibilityRole="button"
                  onPress={onAddFriend}>
                  <View style={styles.sdlBt}>
                    <AddFriendsIcon
                      size={24}
                      color={t.palette.white}
                      style={{marginLeft: 2}}
                    />
                  </View>
                </TouchableWithoutFeedback>
              ) : isFriend === 2 ? (
                <View
                  style={[
                    styles.btnBg,
                    a.flex_row,
                    a.gap_xs,
                    a.px_md,
                    {height: 32, borderRadius: 40, marginLeft: 10},
                  ]}>
                  <ArrowUpLeftIcon
                    style={{marginTop: 2}}
                    primaryColor={t.palette.white}
                  />
                  <Text
                    style={[
                      a.text_xs,
                      a.font_semibold,
                      {color: t.palette.white},
                    ]}>
                    <Trans>Request sent</Trans>
                  </Text>
                </View>
              ) : (
                <View style={styles.sdlBt}>
                  <UnFollowIcon />
                </View>
              ))}

            <TouchableWithoutFeedback
              accessibilityRole="button"
              onPress={onShare}>
              <View style={styles.sdlBt}>
                <UpLoadIcon />
              </View>
            </TouchableWithoutFeedback>

            {isMe && (
              <TouchableWithoutFeedback
                accessibilityRole="button"
                onPress={onPressSettings}
                onLongPress={changeIdentity}>
                <View style={styles.sdlBt}>
                  <SettingIcon />
                </View>
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>

        <TouchableWithoutFeedback
          testID="profileHeaderAviButton"
          onPress={onPressAvi}
          accessibilityRole="image"
          accessibilityLabel={_(msg`View ${profile.handle}'s avatar`)}
          accessibilityHint="">
          <View
            style={[
              t.atoms.bg,
              {borderColor: t.atoms.bg.backgroundColor},
              styles.avi,
              profile.associated?.labeler && styles.aviLabeler,
            ]}>
            <UserAvatar
              type={profile.associated?.labeler ? 'labeler' : 'user'}
              size={68}
              avatar={profile.avatar}
              moderation={moderation.ui('avatar')}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </>
  )
}
ProfileHeaderShell = memo(ProfileHeaderShell)
export {ProfileHeaderShell}

const styles = StyleSheet.create({
  backBtnWrapper: {
    position: 'absolute',
    top: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 32,
    overflow: 'hidden',
    paddingEnd: 10,
  },
  backBtn: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avi: {
    position: 'absolute',
    top: 129,
    left: 10,
    width: 72,
    height: 72,
    borderRadius: 47,
    borderWidth: 2,
  },
  aviLabeler: {
    borderRadius: 10,
  },
  sdlBt: {
    width: 32,
    height: 32,
    borderRadius: 100,
    marginStart: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  btnBg: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  followBt: {
    overflow: 'hidden',
    borderRadius: 15,
    justifyContent: 'center',
    cursor: 'pointer',
  },
  followBtText: {
    fontSize: 12,
    fontWeight: '500',
  },
})
