import * as React from 'react'
export const Development_and_Technical = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#21B841"
        fillRule="evenodd"
        d="M8 24a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M17.625 6H6.375C5.625 6 5 6.594 5 7.307v7.461c0 .713.625 1.307 1.375 1.307h11.25c.75 0 1.375-.594 1.375-1.307V7.307C19 6.594 18.375 6 17.625 6ZM9.4 12.748c.2.19.2.476 0 .666a.507.507 0 0 1-.35.142.507.507 0 0 1-.35-.142l-1.85-1.759a.444.444 0 0 1 0-.665L8.7 9.232a.5.5 0 0 1 .7 0c.2.19.2.475 0 .665l-1.5 1.426 1.5 1.425Zm3.675-3.326L11.9 13.224a.5.5 0 0 1-.475.332c-.05 0-.1 0-.15-.023a.473.473 0 0 1-.35-.594L12.1 9.137c.075-.262.35-.404.625-.333.275.095.425.38.35.618Zm4.075 2.233-1.85 1.759a.507.507 0 0 1-.35.142.507.507 0 0 1-.35-.142.444.444 0 0 1 0-.665l1.5-1.426-1.5-1.426a.444.444 0 0 1 0-.665.5.5 0 0 1 .7 0l1.85 1.758c.2.166.2.475 0 .665ZM15.95 18h-7.9a.49.49 0 0 1-.5-.475.49.49 0 0 1 .5-.476h7.9c.275 0 .5.214.5.476a.49.49 0 0 1-.5.475Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export const Product_and_Design = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#FF7300"
      fillRule="evenodd"
      d="M8 24a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="m6.296 13.856 6.735-6.734L14.754 5.4a.906.906 0 0 1 1.283 0l2.565 2.565a.908.908 0 0 1 0 1.283L15.85 12 12 15.848l-2.396 2.395a.907.907 0 0 1-.517.257l-3.572.496a.455.455 0 0 1-.512-.511l.496-3.573a.907.907 0 0 1 .258-.517l.538-.539Zm1.536-2.176L5.266 9.113a.907.907 0 0 1 0-1.283l2.566-2.564a.907.907 0 0 1 1.282 0L7.992 6.388a.227.227 0 1 0 .32.32l1.123-1.122.642.641L8.954 7.35a.227.227 0 0 0 .32.32l1.123-1.122.641.642-1.122 1.122a.227.227 0 0 0 .32.32L11.36 7.51l.321.321-3.848 3.848Zm4.49 4.489 3.848-3.849.32.32-1.122 1.123a.228.228 0 0 0-.003.323.227.227 0 0 0 .323-.002l1.123-1.123.64.642-1.122 1.122a.227.227 0 0 0 .321.32l1.122-1.121.642.64-1.123 1.123a.227.227 0 1 0 .321.32l1.122-1.122a.906.906 0 0 1 0 1.283l-2.565 2.565a.908.908 0 0 1-1.283 0l-2.565-2.564Z"
    />
  </svg>
)

export const Operations_and_Community = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#FF7300"
      fillRule="evenodd"
      d="M8 24a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M17.145 17.992a.746.746 0 0 1-1.125.735l-2.595-1.544c-.18-.105-.225-.33-.127-.51.375-.69.577-1.47.577-2.25 0-2.745-2.355-4.98-5.25-4.98-.592 0-1.17.09-1.71.27a.379.379 0 0 1-.48-.45C7.118 6.532 9.743 4.5 12.878 4.5c3.66 0 6.622 2.768 6.622 6.18 0 2.025-1.042 3.818-2.647 4.943l.292 2.37Z"
    />
    <path
      fill="#fff"
      d="M11.865 16.791c-.742.9-1.92 1.478-3.24 1.478L6.668 19.43c-.33.203-.75-.075-.705-.457l.187-1.478C5.145 16.8 4.5 15.681 4.5 14.421c0-1.32.705-2.482 1.785-3.172a4.262 4.262 0 0 1 2.34-.683c2.28 0 4.125 1.725 4.125 3.855 0 .893-.33 1.718-.885 2.37Z"
    />
  </svg>
)

export const Business_and_Expansion = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#FF7300"
      fillRule="evenodd"
      d="M8 24a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8Z"
      clipRule="evenodd"
    />
    <rect width={3} height={9} x={6} y={10} fill="#fff" rx={1.5} />
    <rect width={3} height={14} x={11} y={5} fill="#fff" rx={1.5} />
    <rect width={3} height={10} x={16} y={9} fill="#fff" rx={1.5} />
  </svg>
)

export const Management = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#006FFF"
      fillRule="evenodd"
      d="M8 24a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M15.91 8.016v-.752a2.283 2.283 0 0 0-2.279-2.287H10.37A2.29 2.29 0 0 0 8.08 7.264v.752a3.02 3.02 0 0 0-2.847 3.017v4.965a3.023 3.023 0 0 0 3.024 3.025h7.483a3.023 3.023 0 0 0 3.023-3.025v-4.965a3.019 3.019 0 0 0-2.853-3.017Zm-6.529-.752c0-.548.44-.987.988-.987h3.26c.55 0 .988.439.988.987v.744H9.381v-.744Zm6.306 4.594H8.314a.648.648 0 0 1-.643-.65c0-.36.29-.65.643-.65h7.374c.352 0 .643.29.643.65 0 .36-.29.65-.642.65Z"
    />
  </svg>
)

export const Medal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={110} height={111} fill="none">
    <g filter="url(#a)">
      <path
        fill="#FCD010"
        d="M81.721 60.08c-4.95 7.333-12.787 12.512-21.908 13.84a27.644 27.644 0 0 1-4.813.413c-1.65 0-3.254-.137-4.812-.412-9.121-1.33-16.959-6.508-21.909-13.842-3.391-5.087-5.362-11.183-5.362-17.829 0-17.737 14.346-32.083 32.083-32.083 17.738 0 32.084 14.346 32.084 32.083 0 6.646-1.971 12.742-5.363 17.83Z"
      />
    </g>
    <path
      fill="#8D47FF"
      d="M89.83 87.441c-1.697.413-3.026 1.696-3.392 3.392l-1.605 6.737c-.87 3.667-5.545 4.767-7.974 1.88L54.996 74.333 33.133 99.495c-2.429 2.888-7.104 1.788-7.975-1.879l-1.604-6.737c-.412-1.696-1.741-3.025-3.391-3.392L12.6 85.7c-3.483-.825-4.72-5.18-2.2-7.7l17.875-17.876c4.95 7.334 12.788 12.513 21.909 13.842 1.558.275 3.162.413 4.812.413 1.65 0 3.254-.138 4.813-.413 9.12-1.329 16.958-6.508 21.908-13.841l17.875 17.874c2.521 2.476 1.283 6.83-2.2 7.655l-7.563 1.787Z"
    />
    <path
      fill="#fff"
      stroke="#000"
      strokeWidth={2}
      d="M60.362 33.817c.367.733 1.33 1.466 2.2 1.604l4.904.825c3.117.504 3.85 2.796 1.605 5.041l-3.805 3.805c-.641.641-1.008 1.879-.779 2.795l1.1 4.721c.871 3.713-1.1 5.18-4.4 3.209l-4.583-2.705c-.825-.504-2.2-.504-3.025 0l-4.584 2.704c-3.3 1.925-5.27.505-4.4-3.208l1.1-4.72c.184-.871-.137-2.155-.779-2.796l-3.804-3.805c-2.246-2.245-1.512-4.491 1.604-5.041l4.904-.825c.826-.138 1.788-.871 2.155-1.604l2.704-5.409c1.329-2.933 3.712-2.933 5.179 0l2.704 5.409Z"
    />
    <defs>
      <filter
        id="a"
        width={104.167}
        height={104.167}
        x={2.917}
        y={0.167}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={10} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.988235 0 0 0 0 0.815686 0 0 0 0 0.0627451 0 0 0 0.37 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_786_6068"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_786_6068"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
