import React from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import {AppBskyActorDefs, ModerationDecision} from '@atproto/api'

import {useIsMe} from '#/lib/hooks/useIsMe'
import {Shadow} from '#/state/cache/types'
import {useModalControls} from '#/state/modals'
import {useSession} from '#/state/session'
import {useAnalytics} from 'lib/analytics/analytics'
import {sanitizeDisplayName} from 'lib/strings/display-names'
// import {sanitizeHandle} from 'lib/strings/handles'
import {EditIcon} from '#/view/icons/ProfileIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {Text} from '#/components/Typography'
import { getIsOpreration } from '#/view/com/util/access/OperationUtil'
import { VipIcon } from '#/view/com/posts/OperationFeedItem'

export function ProfileHeaderDisplayName({
  profile,
  moderation,
}: {
  profile: Shadow<AppBskyActorDefs.ProfileViewDetailed>
  moderation: ModerationDecision
}) {
  const {track} = useAnalytics()
  const {currentAccount} = useSession()
  const {openModal} = useModalControls()
  const t = useTheme()

  const isMe = useIsMe(profile.did)

  const isCurrent = profile.did === currentAccount?.did

  const onPressEditProfile = React.useCallback(() => {
    track('ProfileHeader:EditProfileButtonClicked')
    openModal({
      name: 'edit-profile',
      profile,
    })
  }, [track, openModal, profile])

  return (
    <View style={styles.page}>
      <View style={styles.nameSpace}>
        <View
          testID="profileHeaderDisplayName"
          style={[
            {display: 'flex'},
            a.flex_row,
            a.align_center,
          ]}>
            <Text style={[
              t.atoms.text,
              a.text_xl,
              a.self_start,
              {fontWeight: '800', lineHeight: 1.2},
            ]}>
              {sanitizeDisplayName(
                profile.displayName || formatHandler(profile.handle),
                moderation.ui('displayName'),
              )}
            </Text>
          {getIsOpreration(profile.did) && <View style={styles.vipIcon}><VipIcon /></View>}
          {isMe && isCurrent && (
            <TouchableWithoutFeedback
              accessibilityRole="button"
              onPress={onPressEditProfile}>
              <View style={styles.editContainer}>
                <EditIcon style={styles.editIcon} size={22} />
              </View>
            </TouchableWithoutFeedback>
          )}
        </View>

        <Text style={[styles.address, a.text_sm]}>
          {`@${formatHandler(profile.handle)}`}
        </Text>
      </View>
      {/* <View style={[styles.winContainer, a.rounded_md, { borderColor: t.palette.primary}]}>
        <View style={[a.rounded_md, styles.winItem]}>
          <Text style={[a.text_lg, styles.winAccount, {color: t.palette.primary}]}>24.7k</Text>
        </View>
        <View style={[styles.winItem, t.atoms.bg_primary]}>
          <Text style={[styles.winText, a.text_xs]}>Winnings</Text>
        </View>
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    marginTop: 15,
  },
  nameSpace: {
    flex: 1,
    width: '100%',
    marginTop: 10,
    marginRight: 30,
  },
  vipIcon: {
    width: 24,
    height: 24,
    paddingTop: 3,
    paddingLeft: 3,
  },
  editIcon: {
    marginLeft: 8,
    marginTop: -2,
  },
  editContainer: {
    transform: [{translateY: 1}],
    cursor: 'pointer',
  },
  winContainer: {
    borderWidth: 1,
    width: 80,
    height: 60,
    overflow: 'hidden',
  },
  winItem: {
    height: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  winText: {
    color: '#fff',
  },
  winAccount: {
    fontWeight: '800',
  },
  address: {
    lineHeight: 18.2,
    fontWeight: '400',
    marginVertical: 5,
    color: '#979797',
  },
})
