/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import 'rc-select/assets/index.css'

import React, {useEffect, useMemo, useState} from 'react'
import {StyleSheet, TextInput, View} from 'react-native'
import RcSelect, {Option, SelectProps} from 'rc-select'

import {atoms as a, useTheme} from '#/alf'

function Select(props: SelectProps) {
  const {style, children, value, placeholder, ...rest} = props
  const [_value, setValue] = useState<string>()
  const [inputValue, setInputValue] = useState<string>('')
  const t = useTheme()

  useEffect(() => {
    setValue(value)
    setInputValue(value)
  }, [value])

  const InputEle = useMemo(
    () => (
      <TextInput
        style={[
          a.flex_1,
          a.border,
          t.atoms.input_border,
          t.atoms.input_bg,
          a.py_md,
          a.px_sm,
          a.rounded_sm,
          t.atoms.text,
          {lineHeight: 20},
        ]}
        value={inputValue}
        clearButtonMode="always"
        onChangeText={v => setInputValue(v)}
        placeholder={placeholder as string}
        placeholderTextColor={t.name === 'light' ? '#979797' : '#8D8D92'}
        returnKeyType="search"
      />
      // <TextInput
      //   value={inputValue}
      //   placeholder={placeholder as string}
      //   onChangeText={val => setInputValue(val)}
      //   clearButtonMode="while-editing"
      // />
    ),
    [
      inputValue,
      placeholder,
      t.atoms.input_bg,
      t.atoms.input_border,
      t.atoms.text,
      t.name,
    ],
  )

  return (
    <View style={(a.relative, a.z_10)}>
      <RcSelect
        style={StyleSheet.flatten([styles.select, style])}
        onFocus={() => console.log(34)}
        searchValue={inputValue}
        getRawInputElement={() => InputEle}
        {...rest}>
        {children}
      </RcSelect>
    </View>
  )
}

export {Option, Select}

const styles = StyleSheet.create({
  select: {
    width: '100%',
    height: '100%',
  },
})
