import React from 'react'

import {ScrollView} from '#/view/com/modals/util'
import {List} from '#/view/com/util/List'
import {CircleItemProps} from '.'
import {CircleItem} from './CircleItem'

type Props = {
  data?: CircleItemProps[]
  onEdit?: (circle: CircleItemProps) => void
  onInvite?: (circle: CircleItemProps) => void
}
export function CircleList({data, onEdit, onInvite}: Props) {
  const renderItem = ({item}: {item: CircleItemProps}) => {
    return <CircleItem circle={item} onEdit={onEdit} onInvite={onInvite} />
  }

  const keyExtractor = (item: CircleItemProps) => {
    return item.id
  }
  return (
    <ScrollView>
      <List
        sideBorders={false}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </ScrollView>
  )
}
