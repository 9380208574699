import {createSinglePathSVG} from './TEMPLATE'

export const HomeOpen_Stoke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M11.37 1.724a1 1 0 0 1 1.26 0l8 6.5A1 1 0 0 1 21 9v11a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-5h-2v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 .37-.776l8-6.5ZM5 9.476V19h4v-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5h4V9.476l-7-5.688-7 5.688Z',
})

export const HomeOpen_Filled_Corner0_Rounded = createSinglePathSVG({
  path: 'M12.63 1.724a1 1 0 0 0-1.26 0l-8 6.5A1 1 0 0 0 3 9v11a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-6h4v6a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V9a1 1 0 0 0-.37-.776l-8-6.5Z',
})

export const HomeOpen = createSinglePathSVG({
  path: 'M13.5935 0.900923C12.6881 0.267146 11.5563 -0.00610898 10.4778 0.0191801C9.39933 0.0444685 8.27999 0.371077 7.40535 1.05135L2.01589 5.25094C1.41433 5.71887 0.921267 6.40234 0.579739 7.09842C0.238374 7.79417 0 8.60119 0 9.36015V16.7702C0 19.6394 2.33463 21.9902 5.21 21.9902H16.79C19.6638 21.9902 22 19.6409 22 16.7802V9.50015C22 8.69221 21.7365 7.84292 21.3577 7.11741C20.9793 6.39233 20.4339 5.69153 19.7728 5.23047L19.7718 5.22976L13.5935 0.900923ZM3.24465 6.82896L8.63394 2.62951C9.10928 2.25995 9.79085 2.03584 10.5247 2.01863C11.2585 2.00142 11.952 2.19332 12.4465 2.53939L18.6262 6.86914L18.6282 6.87055C18.9567 7.09951 19.3109 7.51835 19.5848 8.0429C19.8585 8.56739 20 9.0981 20 9.50015V16.7802C20 18.5394 18.5562 19.9902 16.79 19.9902H5.21C3.44537 19.9902 2 18.541 2 16.7702V9.36015C2 8.98912 2.12663 8.48614 2.37526 7.97939C2.6237 7.47304 2.94628 7.06102 3.24465 6.82896ZM12 13.9902C12 13.4379 11.5523 12.9902 11 12.9902C10.4477 12.9902 10 13.4379 10 13.9902V16.9902C10 17.5425 10.4477 17.9902 11 17.9902C11.5523 17.9902 12 17.5425 12 16.9902V13.9902Z',
})

export const HomeOpen_Fill = createSinglePathSVG({
  path: 'M13.5935 0.900923C12.6881 0.267146 11.5563 -0.00610895 10.4778 0.0191801C9.39933 0.0444685 8.27999 0.371077 7.40535 1.05135L2.01589 5.25094C1.41433 5.71887 0.921267 6.40234 0.579739 7.09842C0.238374 7.79417 0 8.60119 0 9.36015V16.7702C0 19.6394 2.33463 21.9902 5.21 21.9902H16.79C19.6638 21.9902 22 19.6409 22 16.7802V9.50015C22 8.69221 21.7365 7.84292 21.3577 7.11741C20.9793 6.39233 20.4339 5.69153 19.7728 5.23047L19.7718 5.22976L13.5935 0.900923ZM12 13.99C12 13.4377 11.5523 12.99 11 12.99C10.4477 12.99 10 13.4377 10 13.99V16.99C10 17.5423 10.4477 17.99 11 17.99C11.5523 17.99 12 17.5423 12 16.99V13.99Z',
})
