import React, {useState} from 'react'
import {Text, View} from 'react-native'
import {DefaultOptionType} from 'rc-select/lib/Select'

import {atoms as a, useTheme} from '#/alf'
import {Option, Select} from '#/components/forms/Select'
import {countries} from './countries'
import {ApiCountryCode} from './types'

interface IProps {
  updateCountry: (val: ApiCountryCode) => void
}
export function CountryDropdown(props: IProps) {
  const {updateCountry} = props
  const t = useTheme()

  // const [countryCode, setCountryCode] = useState<string>()
  const [value, setValue] = useState<string>()

  const handleChange = (val: string) => {
    console.log(val)
    const [countryCode, iso2] = val.split('-')
    const country = countries.find(
      item => item.countryCode === countryCode && item.iso2 === iso2,
    )
    // setCountryCode(country?.countryCode)
    setValue(`${country?.name ?? country?.defaultName}`)
    updateCountry(country!)
  }

  const filter = (
    filterVal: string,
    option?: DefaultOptionType, //{value: string; label: string},
  ) => {
    const val = filterVal.startsWith('(')
      ? filterVal.replace(/^[^a-zA-Z]*/, '')
      : filterVal
    return (
      (option?.value as string).includes(val) ||
      (option?.label as string).toLowerCase().includes(val.toLowerCase())
    )
  }

  return (
    <View style={a.relative}>
      <Select
        placeholder="Country"
        allowClear
        onChange={handleChange}
        filterOption={filter}
        dropdownClassName={`rc-select-dropdown-${t.name}`}
        dropdownStyle={{
          borderColor: t.atoms.border_contrast_low.borderColor,
          borderRadius: 6,
          backgroundColor: t.atoms.modalBg2.backgroundColor,
        }}
        value={value}>
        {countries.map(item => {
          return (
            <Option
              key={item.countryCode}
              value={`${item.countryCode}-${item.iso2}`}
              label={item.name ?? item.defaultName}
              patterns={item.patterns}>
              <Text style={[t.atoms.text]}>
                (+{item.countryCode}) {item.name ?? item.defaultName}
              </Text>
            </Option>
          )
        })}
      </Select>
    </View>
  )
}
