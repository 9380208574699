// Regex from the go implementation
// https://github.com/Aquaverse/indigo/blob/main/atproto/syntax/handle.go#L10
import {forceLTR} from 'lib/strings/bidi'
import {formatHandler} from '#/screens/Onboarding/util'

const VALIDATE_REGEX =
  /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/

export function makeValidHandle(str: string): string {
  if (str.length > 20) {
    str = str.slice(0, 20)
  }
  str = str.toLowerCase()
  return str.replace(/^[^a-z0-9]+/g, '').replace(/[^a-z0-9-]/g, '')
}

export function createFullHandle(name: string, domain: string): string {
  name = (name || '').replace(/[.]+$/, '')
  domain = (domain || '').replace(/^[.]+/, '')
  if (domain) {
    return `${name}.${domain}`
  }
  return name
}

export function isInvalidHandle(handle: string): boolean {
  return handle === 'handle.invalid'
}

export function sanitizeHandle(handle: string, prefix = ''): string {
  return isInvalidHandle(handle)
    ? '⚠Invalid Handle'
    : formatHandler(forceLTR(`${prefix}${handle}`))
}

export interface IsValidHandle {
  handleChars: boolean
  hyphenStartOrEnd: boolean
  frontLength: boolean
  totalLength: boolean
  overall: boolean
}

// More checks from https://github.com/Aquaverse/atproto/blob/main/packages/pds/src/handle/index.ts#L72
export function validateHandle(str: string, userDomain: string): IsValidHandle {
  const fullHandle = createFullHandle(str, userDomain)

  const results = {
    handleChars: !str || VALIDATE_REGEX.test(fullHandle),
    hyphenStartOrEnd: !str.startsWith('-') && !str.endsWith('-'),
    frontLength: str.length >= 3,
    totalLength: str.length <= 20,
  }

  return {
    ...results,
    overall: !Object.values(results).includes(false),
  }
}

export function validateInviteCode(str: string): {inviteCodeLength: boolean} {
  return {inviteCodeLength: str.length < 11}
}
export function validateCircleName(str: string): IsValidHandle {
  const results = {
    handleChars: !!str,
    hyphenStartOrEnd: !str.startsWith('-') && !str.endsWith('-'),
    frontLength: str.length >= 3,
    totalLength: str.length <= 20,
  }
  return {...results, overall: !Object.values(results).includes(false)}
}

export function generateColor(value: string) {
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    hash += value.charCodeAt(i)
  }

  const r = hash % 256
  const g = (hash >> 8) % 256
  const b = (hash >> 16) % 256

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}
