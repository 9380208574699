const visibleSet = new Set<string>()

export const getVisibleArray = () => Array.from(visibleSet)

export const addVisible = (uris: string[]) => {
  uris.forEach(uri => visibleSet.add(uri))
}

type TResole = () => void

class VisibleCircleHandler {
  constructor() {}

  private resolve: TResole | null = null
  private reject: TResole | null = null

  public unlock() {
    return new Promise<void>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  public approveUnlock() {
    if (this.resolve) {
      this.resolve()
      this.resolve = null
    }
  }

  public denyUnlock() {
    if (this.reject) {
      this.reject()
      this.reject = null
    }
  }
}

export const visibleCircleHandler = new VisibleCircleHandler()
