import React, {useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'

import {isMobile, isWeb} from '#/platform/detection'
import {SessionAccount, useSession} from '#/state/session'
import {useProfileQuery} from 'state/queries/profile'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import * as Toggle from '#/components/forms/Toggle'
import {Link} from '#/components/Link'

type Props = {
  account: SessionAccount
  onChange?: (account: SessionAccount, selected: boolean) => void
}

export const AccountItem = ({account, onChange}: Props) => {
  const t = useTheme()
  const {currentAccount} = useSession()
  const {data: profile} = useProfileQuery({did: account.did})
  const backgroundColor = '#ff0'
  const isCurrent = React.useMemo(() => {
    return currentAccount?.did === account.did
  }, [currentAccount?.did, account.did])
  const [values] = useState<string[]>(() => {
    return isCurrent ? [account.did] : []
  })

  const onChangeMask = () => {
    const isSelected = values.length > 0
    onChange?.(account, !isSelected)
  }

  return (
    <View
      style={[
        a.mt_xl,
        t.atoms.bg_block_gray,
        a.rounded_md,
        {position: 'relative'},
      ]}>
      <View style={styles.tag}>
        <View style={styles.infoIcon}>
          <FontAwesomeIcon icon="info" size={10} color="#fff" />
        </View>
        <Text style={{color: '#fff'}}>
          <Trans>Main account</Trans>
        </Text>
      </View>
      <Link to={`/profile/${account.handle}`} label="link account profile">
        <View style={[styles.container, a.rounded_md, {backgroundColor}]}>
          <View style={styles.summary}>
            <UserAvatar size={58} avatar={profile?.avatar} />
            <View style={styles.summary_right}>
              <View style={[a.w_full, a.flex_2]}>
                <Text style={[{fontSize: 20, fontWeight: '600'}]}>
                  {profile?.displayName || formatHandler(account.handle)}
                </Text>
                <Text style={[{fontSize: 14, fontWeight: '400'}]}>
                  @{formatHandler(profile?.handle || account.handle)}
                </Text>
              </View>
              {/* <View style={[a.w_full, a.flex_1]}>
                <Text style={[a.text_right, {fontSize: 20, fontWeight: 600}]}>
                  $98.7k
                </Text>
                <Text style={[a.text_right, {fontSize: 14, fontWeight: 400}]}>
                  Winnings
                </Text>
              </View> */}
            </View>
          </View>
          <View style={styles.info}>
            <View style={{width: '15%'}}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.postsCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Tea</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>0</Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Bookmarks</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.followersCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Followers</Text>
              </View>
            </View>
            <View style={[styles.info_item, t.atoms.border_contrast_medium]}>
              <View>
                <Text style={[styles.info_value]}>
                  {profile?.followsCount || 0}
                </Text>
              </View>
              <View>
                <Text style={[styles.info_text]}>Following</Text>
              </View>
            </View>
          </View>
        </View>
      </Link>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          a.p_lg,
          t.atoms.border_contrast_medium,
        ]}>
        <View style={[a.flex_row, a.gap_sm, a.align_center]}>
          <Toggle.Group
            disabled={isCurrent}
            type="checkbox"
            label="Mask"
            values={values}
            onChange={onChangeMask}>
            <Toggle.Item name={account.did} label="Switch Mask">
              <Toggle.Switch />
            </Toggle.Item>
          </Toggle.Group>
          {currentAccount?.did === account.did ? (
            <Text style={[t.atoms.text]}>
              <Trans>On</Trans>
            </Text>
          ) : (
            <Text style={[t.atoms.text_contrast_low]}>
              <Trans>Off</Trans>
            </Text>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // flexDirection: 'row',
    flexDirection: 'column',
    paddingHorizontal: isWeb && !isMobile ? 30 : 20,
    paddingVertical: 20,
    // marginTop: 20,
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  summary_right: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  info: {
    display: 'flex',
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  info_item: {
    paddingHorizontal: 8,
    borderLeftWidth: 1,
    flex: 1,
  },
  info_value: {
    fontSize: 14,
    fontWeight: '800',
  },
  info_text: {
    fontSize: 14,
    fontWeight: '400',
  },
  tag: {
    position: 'absolute',
    top: 2,
    right: 2,
    zIndex: 1,
    backgroundColor: '#000',
    borderRadius: 11,
    padding: 7,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoIcon: {
    width: 16,
    height: 16,
    borderRadius: 10,
    borderColor: '#fff',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
})
