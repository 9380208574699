import {
  ComAtprotoRepoCreateRecord,
  ComAtprotoServerDefs,
  ComAtprotoServerPrepareTransaction,
} from '@atproto/api'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {ethers} from 'ethers'

import {useAgent, useSession} from '#/state/session'
import {BetListType} from '#/view/screens/BetList'

const STAKE_RQKEY_ROOT = 'stake'
const DONATE_RQKEY_ROOT = 'donate'
const BET_RQKEY_ROOT = 'bet'
const BET_SCORE_RQKEY_ROOT = 'bet-score'
const STAKE_RQKEY = () => [STAKE_RQKEY_ROOT]
const DONATE_RQKEY = () => [DONATE_RQKEY_ROOT]
export const BET_RQKEY = () => [BET_RQKEY_ROOT]
const BET_SCORE_RQKEY = () => [BET_SCORE_RQKEY_ROOT]
export interface StakeDonateListProps
  extends ComAtprotoServerDefs.StakeTransDetailGroup {
  total: number
  sortKey: number
}

export interface BetListProps extends ComAtprotoServerDefs.BetTransDetailGroup {
  total: number
  sortKey: number
}

export function useStakeListQuery({
  uri,
  did,
  dataType,
  postDid,
}: {
  uri: string | undefined
  did?: string
  dataType: BetListType
  postDid?: string
}) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<StakeDonateListProps[], Error>({
    // refetchOnWindowFocus: true,
    queryKey: [STAKE_RQKEY_ROOT, uri, did],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getStakeTransDetails({
        uri,
        // actor: did,
      })
      const data = res.data.trans?.map(item => {
        return {
          ...item,
          total: Number(
            ethers.formatUnits(
              item?.trans?.reduce((v, i) => v + i.amount, 0),
              6,
            ),
          ),
          sortKey: item.payer === postDid ? 3 : item.payer === did ? 2 : 1,
        }
      })
      data.sort((a, b) => b.total - a.total)
      data.sort((a, b) => b.sortKey - a.sortKey)
      return data
    },
    enabled: !!uri && dataType === 'stake' && !!currentAccount,
  })
}

export function useCreateTransMutation() {
  // const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<
    ComAtprotoServerPrepareTransaction.Response,
    Error,
    {param: ComAtprotoServerPrepareTransaction.InputSchema}
  >({
    mutationFn: async ({param}) => {
      return await agent.com.atproto.server.prepareTransaction(param)
    },
    onSuccess() {},
  })
}

export function useUpdateTransMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation<
    ComAtprotoRepoCreateRecord.Response,
    Error,
    {param: ComAtprotoRepoCreateRecord.InputSchema}
  >({
    mutationFn: async ({param}) => {
      return await agent.com.atproto.repo.createRecord(param)
    },
    onSuccess(data, variables) {
      if (variables.param.collection === 'app.bsky.feed.stake') {
        queryClient.invalidateQueries({queryKey: STAKE_RQKEY()})
      } else if (variables.param.collection === 'app.bsky.feed.bet') {
        queryClient.invalidateQueries({queryKey: BET_RQKEY()})
      } else if (variables.param.collection === 'app.bsky.feed.donate') {
        queryClient.invalidateQueries({queryKey: DONATE_RQKEY()})
      } else if (
        variables.param.collection === 'app.bsky.graph.guaranteeJoin'
      ) {
        queryClient.invalidateQueries({
          queryKey: [variables.param?.record?.subject?.uri],
        })
      }
    },
  })
}

export function useStakeTransSummaryQuery(
  uri: string,
  fromPostDetail: boolean,
  count: number,
) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<ComAtprotoServerDefs.StakeTransSummary, Error>({
    queryKey: [STAKE_RQKEY_ROOT, uri],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getStakeTransSummary({
        uri,
      })
      return res.data
    },
    enabled: !!uri && !!currentAccount && (fromPostDetail || count > 0),
  })
}

export function useDonateTransSummaryQuery(
  uri: string,
  fromPostDetail: boolean,
  count: number,
) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  // console.log('useDonateTransSummary', uri)
  return useQuery<ComAtprotoServerDefs.DonateTransSummary, Error>({
    queryKey: [DONATE_RQKEY_ROOT, uri],
    queryFn: async () => {
      // console.log('useDonateTransSummary')
      const res = await agent.com.atproto.server.getDonateTransSummary({
        uri,
      })
      return res.data
    },
    enabled: !!uri && !!currentAccount && (fromPostDetail || count > 0),
  })
}

export function useDonateListQuery({
  uri,
  did,
  dataType,
  postDid,
}: {
  uri: string | undefined
  did?: string
  dataType: BetListType
  postDid?: string
}) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<StakeDonateListProps[], Error>({
    queryKey: [DONATE_RQKEY_ROOT, uri, did],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getDonateTransDetails({
        uri,
        // actor: did,
      })
      const data = res.data.trans?.map(item => {
        return {
          ...item,
          total: Number(
            ethers.formatUnits(
              item?.trans?.reduce((v, i) => v + i.amount, 0),
              6,
            ),
          ),
          sortKey: item.payer === postDid ? 3 : item.payer === did ? 2 : 1,
        }
      })
      data
        .sort((a, b) => b.total - a.total)
        .sort((a, b) => b.sortKey - a.sortKey)
      return data
    },
    enabled: !!uri && dataType === 'donate' && !!currentAccount,
  })
}

export function useBetTransSummary(uri: string) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<ComAtprotoServerDefs.BetTransSummary, Error>({
    queryKey: [BET_RQKEY_ROOT, uri],
    queryFn: async () => {
      // try {
      const res = await agent.com.atproto.server.getBetTransSummary({uri})
      return {
        ...res.data,
        totalAmount: res.data.totalAmount
          ? Number(ethers.formatUnits(res.data.totalAmount, 6))
          : 0,
        totalBetAgainstAmount: res.data.totalBetAgainstAmount
          ? Number(ethers.formatUnits(res.data.totalBetAgainstAmount, 6))
          : 0,
        totalBetOnAmount: res.data.totalBetOnAmount
          ? Number(ethers.formatUnits(res.data.totalBetOnAmount, 6))
          : 0,
      }
      // } catch (error) {
      //     console.log('getBetTransSummary-error', error);
      //     return {}
      // }
    },
    enabled: !!uri && !!currentAccount,
  })
}

export function useBetAmountListQuery({
  uri,
  did,
  dataType,
  postDid,
}: {
  uri: string
  did?: string
  dataType: BetListType
  postDid?: string
}) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<BetListProps[], Error>({
    queryKey: [BET_RQKEY_ROOT, uri, dataType],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getBetTransAmountDetails({
        uri,
        side: dataType === 'bet-on' ? true : false,
      })
      const data = res.data.trans?.map(item => {
        return {
          ...item,
          total: Number(ethers.formatUnits(item?.total, 6)),
          sortKey: item.payer === postDid ? 3 : item.payer === did ? 2 : 1,
        }
      })
      data
        .sort((a, b) => b.total - a.total)
        .sort((a, b) => b.sortKey - a.sortKey)
      return data
    },
    enabled:
      !!uri &&
      (dataType === 'bet-on' || dataType === 'bet-against') &&
      !!currentAccount,
  })
}

type BetScoreType = {
  total?: number
  data: ComAtprotoServerDefs.BetTransDetailGroup[]
}

export function useBetScoreListQuery({
  uri,
  did,
  dataType,
  postDid,
}: {
  uri: string
  did?: string
  dataType: BetListType
  postDid?: string
}) {
  console.log('useBetScoreListQuery', uri, dataType, did, postDid)
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<BetScoreType, Error>({
    queryKey: [BET_SCORE_RQKEY, uri, dataType],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getBetTransScoreDetails({
        uri,
        side: dataType === 'bet-on' ? true : false,
      })
      const data = res.data.trans?.map(item => {
        return {
          ...item,
          sortKey: item.payer === postDid ? 3 : item.payer === did ? 2 : 1,
        }
      })
      data
        .sort((a, b) => b.total - a.total)
        .sort((a, b) => b.sortKey - a.sortKey)

      return {
        data,
        total: res.data.total,
      }
    },
    enabled:
      !!uri &&
      (dataType === 'bet-on' || dataType === 'bet-against') &&
      !!currentAccount,
  })
}
