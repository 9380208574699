import React, {useEffect, useState} from 'react'
import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {ComAtprotoServerBindTwitter} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {openXAuthWindow} from '#/lib/authorize/twitter'
import {useTwitter} from '#/lib/hooks/useSocial'
import {isWeb} from '#/platform/detection'
import {ILinkSocialModal, useModalControls} from '#/state/modals'
import * as Toast from '#/view/com/util/Toast'
import {TGTagIcon} from '#/view/icons/FeedIcons'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
import {Xicon} from '#/components/icons/Xicon'
import {useTwStore} from '../util/sdlStore/TwStore'

export const snapPoints = ['fullscreen']

export function Component({hideTelegramBt}: ILinkSocialModal) {
  const t = useTheme()
  const {closeModal} = useModalControls()
  const {_} = useLingui()
  const {bindTwitter: bindTwitterStore} = useTwStore()
  const [twitter, setTwitter] = useState({
    twitterUserName: '',
    twitterUserId: '',
  })

  const {setBindTwitter} = useTwitter()

  useEffect(() => {
    const channel = new BroadcastChannel('channel')
    channel.onmessage = function (event) {
      const xAccount = event.data
      if (xAccount?.success) {
        setTwitter(xAccount)
        setBindTwitter({
          twitterUserName: xAccount.twitterUserName,
          twitterUserId: xAccount.twitterUserId,
          init: true,
          bindTwitter: true,
        })
        bindTwitterStore({
          twitterUserId: xAccount.twitterUserId,
          twitterUserName: xAccount.twitterUserName,
        })
      } else if (xAccount.errorMsg) {
        Toast.show(xAccount.errorMsg, 'info', 5000)
      }
    }
  }, [_, bindTwitterStore, setBindTwitter])

  //   const [hint, setHint] = useState<string>()

  const handleStorage = async (ev: StorageEvent) => {
    if (ev.key === 'task_x_bind') {
      try {
        const xAccount = JSON.parse(localStorage.getItem('task_x_bind') || '')
        if (xAccount?.twitterUserId) {
          setTwitter(xAccount)
        }
      } catch (err) {
        // TODO : dear with error
        // const _err = err as any
        // if (_err.status === 401) {
        //   setHint('Login status lost. Please reload page and login again')
        //   return
        // }
        // setHint(
        //   _err?.error ||
        //     _err?.message ||
        //     "We can't connect your twitter account. Please try again.",
        // )
      }
    }

    window.removeEventListener('storage', handleStorage)
  }

  const bindSocial = () => {
    window.removeEventListener('storage', handleStorage)
    openXAuthWindow('task_x_bind')
    window.addEventListener('storage', handleStorage, false)
  }

  const {openModal} = useModalControls()

  const openBindTelegram = () => {
    closeModal()
    openModal({name: 'telegram'})
  }

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      onPress={() => !isWeb && Keyboard.dismiss()}>
      <View style={[styles.page, a.flex_1]}>
        <View>
          <Text style={[styles.title, t.atoms.text]}>
            <Trans>Link social account</Trans>
          </Text>
          <View style={[a.flex, a.flex_col, a.gap_md]}>
            <Text style={[{color: t.palette.black_50}, t.atoms.text_sub]}>
              <Trans>
                To comply with relevant laws and regulations, we require you to
                link your Sipz account to a mainstream social account.
              </Trans>
            </Text>
            {!hideTelegramBt && (
              <Button
                variant="solid"
                label={_(msg`Link Telegram`)}
                onPress={() => openBindTelegram()}
                size="large"
                style={[
                  a.rounded_sm,
                  a.mt_md,
                  {paddingVertical: 10, backgroundColor: '#56b7e9'},
                ]}>
                <View
                  style={{
                    width: 180,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}>
                  <TGTagIcon size={30} />
                  <ButtonText
                    style={[
                      a.p_md,
                      {fontSize: 16, fontWeight: '600', marginLeft: 10},
                    ]}>
                    <Trans>Link Telegram</Trans>
                  </ButtonText>
                </View>
              </Button>
            )}

            {!twitter?.twitterUserId && (
              <Button
                variant="solid"
                label={_(msg`Link X (Twitter)`)}
                onPress={() => bindSocial()}
                size="large"
                style={[
                  a.rounded_sm,
                  a.mt_md,
                  {paddingVertical: 10, backgroundColor: t.atoms.text.color},
                ]}>
                <Xicon style={{color: t.atoms.text_inverted.color}} />
                <ButtonText
                  style={[
                    a.p_md,
                    {fontSize: 16, fontWeight: '600'},
                    t.atoms.text_inverted,
                  ]}>
                  <Trans>Link X (Twitter)</Trans>
                </ButtonText>
              </Button>
            )}
            {twitter?.twitterUserId && <TwitterAccount {...twitter} />}
            <Text style={[{color: t.palette.black_25}, t.atoms.text_sub]}>
              <Trans>
                Your privacy is important to us. Your linked identity will
                remain private.
              </Trans>
            </Text>
            <Button
              variant="solid"
              label={_(msg`Link X (Twitter)`)}
              onPress={() => closeModal()}
              size="large"
              style={[
                a.rounded_full,
                a.mt_2xl,
                a.mx_5xl,
                {
                  paddingVertical: 10,
                  backgroundColor: twitter.twitterUserId
                    ? t.palette.primary
                    : t.palette.gray_11,
                },
                t.atoms.bg_btn_default,
              ]}>
              {twitter.twitterUserId ? (
                <ButtonText
                  style={[
                    a.p_md,
                    {color: t.palette.black_50},
                    t.atoms.text_sub,
                  ]}>
                  <Trans>Confirm</Trans>
                </ButtonText>
              ) : (
                <ButtonText
                  style={[
                    a.p_md,
                    {color: t.palette.black_50},
                    t.atoms.text_sub,
                  ]}>
                  <Trans>Cancel</Trans>
                </ButtonText>
              )}
            </Button>
          </View>
          <View>{/* <Text>{hint}</Text> */}</View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

function TwitterAccount(props: ComAtprotoServerBindTwitter.OutputSchema) {
  const {twitterUserName} = props
  const t = useTheme()

  return (
    <View
      style={[
        a.flex_row,
        a.align_center,
        a.justify_between,
        a.rounded_md,
        a.border,
        a.p_md,
        {backgroundColor: t.atoms.text.color},
      ]}>
      <View style={[a.flex_row, a.align_center]}>
        <Xicon style={{color: t.atoms.text_inverted.color}} />
        <Text
          style={[
            a.p_md,
            {fontSize: 16, fontWeight: '600'},
            t.atoms.text_inverted,
          ]}>
          <Trans>Link X (Twitter)</Trans>
        </Text>
      </View>
      <View style={[a.flex_row, a.align_center, a.gap_md]}>
        <Text style={[t.atoms.text_inverted]}>@{twitterUserName}</Text>
        <View
          style={[
            a.align_center,
            a.justify_center,
            a.rounded_full,
            {backgroundColor: '#0ABF52', width: 24, height: 24},
          ]}>
          <Check style={{color: t.palette.white}} size="sm" />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    padding: 10,
    // backgroundColor: 'white',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
