import React, {useEffect, useMemo, useRef} from 'react'
import {Animated, Easing, StyleSheet, Text, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {PullStatus, PullToRefreshify} from 'react-pull-to-refreshify'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'

// import {Loader} from '#/components/Loader'

function _renderText(pullStatus: PullStatus, _percent: number, scene?: string) {
  const {_} = useLingui()
  const rotateAnim = useRef(new Animated.Value(0)).current
  const {isMobile} = useWebMediaQueries()

  const scenePosition = useMemo(() => {
    let fromProfile = false
    if (
      scene &&
      (scene.includes('author') ||
        scene.includes('likes') ||
        scene.includes('engagement') ||
        scene.includes('circleids') ||
        scene.includes('following'))
    ) {
      fromProfile = true
    }
    return {
      marginTop: isMobile ? (fromProfile ? 30 : 85) : null,
      marginBottom: fromProfile ? 30 : 0,
    }
  }, [scene, isMobile])

  useEffect(() => {
    const startRotation = () => {
      rotateAnim.setValue(0)
      Animated.timing(rotateAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
        easing: Easing.linear,
      }).start(() => startRotation())
    }

    startRotation()
  }, [rotateAnim])

  const array = [
    _(msg`Sip something transparent here`),
    _(msg`Sip something trustworthy here`),
    _(msg`Sip something ethical here`),
    _(msg`Industry chaos is exposed here`),
    _(msg`Valuable insights are shared here`),
  ]

  function getRandomMessage() {
    const randomIndex = Math.floor(Math.random() * 5)
    return array[randomIndex]
  }

  const messageRef = useRef(getRandomMessage())

  switch (pullStatus) {
    case 'refreshing':
      return (
        <View style={[scenePosition, styles.body]}>
          <Animated.Image
            style={{
              width: 64,
              height: 64,
              alignSelf: 'center',
              borderRadius: 10,
            }}
            source={{
              uri: '/sipzloading.gif',
            }}
            accessibilityIgnoresInvertColors={true}
          />
          <Text style={[styles.text, {marginTop: 16}]}>
            {messageRef.current}
          </Text>
        </View>
      )

    default:
      messageRef.current = getRandomMessage()
      return <View style={[scenePosition, styles.body]} />
  }
}

type Props = {
  children: React.ReactNode
  refreshing: boolean
  handleRefresh?: () => void
  renderText?: (status: PullStatus, percent: number) => React.ReactNode
  scene?: string
  webFresh?: boolean
}
export function ScrollView({
  children,
  refreshing,
  handleRefresh,
  scene,
  webFresh,
}: Props) {
  //   useEffect(() => {
  //     const handleTouchMove = (event: TouchEvent) => {
  //       // 阻止默认行为以禁用下拉刷新
  //       event.preventDefault()
  //     }

  //     // 添加事件监听器
  //     globalThis.addEventListener('touchmove', handleTouchMove, {
  //       passive: false,
  //     })

  //     // 清理函数
  //     return () => {
  //         globalThis.removeEventListener('touchmove', handleTouchMove)
  //     }
  //   }, [])

  if (!webFresh) {
    return children
  }
  return (
    <PullToRefreshify
      refreshing={refreshing}
      onRefresh={handleRefresh}
      renderText={(status: PullStatus, percent: number) =>
        _renderText(status, percent, scene)
      }
      startDistance={50}
      headHeight={115}
      threshold={0}>
      {children}
    </PullToRefreshify>
  )
}

const styles = StyleSheet.create({
  text: {color: '#666666', fontSize: 14, textAlign: 'center'},
  body: {
    height: 115,
    paddingTop: 12,
    paddingBottom: 50,
    justifyContent: 'space-between',
    // backgroundColor: '#FCD010',
    width: '100%',
    transform: 'translateY(-10)',
  },
})
