import React, {useEffect} from 'react'
import {AppState, Image, StyleSheet, View} from 'react-native'
import AtpAgent from '@atproto/api' // ModerationDecision
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import {TelegramClient} from '@sipz/telegram'

import {
  BSKY_SERVICE,
  FEED_CIRCLE_URI,
  localTgUserData,
  PROD_DEFAULT_FEED,
  SQUARE_FEED_URI,
} from '#/lib/constants'
import {useNonReactiveCallback} from '#/lib/hooks/useNonReactiveCallback'
import {useSetTitle} from '#/lib/hooks/useSetTitle'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {logEvent, LogEvents} from '#/lib/statsig/statsig'
import {useGate} from '#/lib/statsig/statsig'
import {isMobileWeb, isWeb} from '#/platform/detection'
import {
  emitSoftReset,
  // emitter
} from '#/state/events'
import {SavedFeedSourceInfo, usePinnedFeedsInfos} from '#/state/queries/feed'
import {FeedDescriptor, FeedParams} from '#/state/queries/post-feed'
import {usePreferencesQuery} from '#/state/queries/preferences'
import {UsePreferencesQueryResponse} from '#/state/queries/preferences/types'
import {useSession, useSessionApi} from '#/state/session'
import {maskToSessionAccount} from '#/state/session/agent'
import {
  useMinimalShellMode,
  useSetDrawerSwipeDisabled,
  useSetMinimalShellMode,
} from '#/state/shell'
import {useLoggedOutViewControls} from '#/state/shell/logged-out'
import {useSelectedFeed, useSetSelectedFeed} from '#/state/shell/selected-feed'
import {useOTAUpdates} from 'lib/hooks/useOTAUpdates'
import {useRequestNotificationsPermission} from 'lib/notifications/notifications'
import {
  HomeTabNavigatorParams,
  NativeStackScreenProps,
  NavigationProp,
} from 'lib/routes/types'
// import { useNewPostStore } from '../com/util/sdlStore/newPostStore'
import * as Toast from '#/view/com/util/Toast'
import {FeedPage} from 'view/com/feeds/FeedPage'
import {Pager, PagerRef, RenderTabBarFnProps} from 'view/com/pager/Pager'
import {FollowingEndOfFeed} from 'view/com/posts/FollowingEndOfFeed'
import {NoFeedsPinned} from '#/screens/Home/NoFeedsPinned'
import {TOURS, useTriggerTourIfQueued} from '#/tours'
import {HomeHeader} from '../com/home/HomeHeader'
import {useTelegramLink} from '../com/modals/TelegramModal/hooks'
import {BigFigureProvider} from '../com/posts/BigFigure/BigFigureProvider'
import {CircleProvider} from '../com/posts/CircleProvider'
import {EmptyBox} from '../com/util/EmptyBox'
import {LinkTelegram} from './Telegram/LinkTelegram'

type Props = NativeStackScreenProps<HomeTabNavigatorParams, 'Home' | 'Start'>

// function getHashParams(hash: string) {
//   const params = new URLSearchParams(hash.substring(1))
//   const result: Record<string, string> = {}
//   for (const [key, value] of params.entries()) {
//     result[key] = value
//   }

//   return result
// }
export function HomeScreen(props: Props) {
  const {currentAccount} = useSession()
  const {data: preferences} = usePreferencesQuery()
  const {resumeSession} = useSessionApi()
  const {data: pinnedFeedInfos, isLoading: isPinnedFeedsLoading} =
    usePinnedFeedsInfos()
  const {bind, binding, handleBind} = useTelegramLink()
  const navigation = useNavigation<NavigationProp>()
  const {requestSwitchToAccount} = useLoggedOutViewControls()
  const {_} = useLingui()

  // telegram auth login
  useEffect(() => {
    if ((isWeb || isMobileWeb) && !currentAccount?.accessJwt) {
      const webApp = (window as any).Telegram?.WebApp
      if (webApp) {
        const tgInitData = (window as any).Telegram.WebApp.initData
        const tgUserData = localTgUserData || tgInitData
        const agent = new AtpAgent({service: BSKY_SERVICE})
        let retryTime = 0
        const login = () => {
          agent.com.atproto.server
            .loginUseTg({
              tgLoginType: 2,
              tgUserData: tgUserData,
            })
            .then(res => {
              console.log(res)
              if (res.data.existUser) {
                const account = maskToSessionAccount(res.data, agent)
                resumeSession(account)
              } else {
                const user = webApp.initDataUnsafe?.user
                console.log('tgUser', user)
                const nickname =
                  (user?.first_name || '') + (user?.last_name || '')
                const handle = (user?.id || 0).toString()
                requestSwitchToAccount({
                  requestedAccount: 'new',
                  params: {
                    nickname,
                    handle,
                    tgAuthResult: tgUserData,
                  },
                })
              }
              navigation.navigate('Home')
            })
            .catch(() => {
              if (retryTime++ < 5) {
                setTimeout(login, 1000)
              } else {
                Toast.show(_(msg`The request has been sent, please wait.`))
              }
            })
        }

        login()
      }
    }
  }, [
    _,
    currentAccount?.accessJwt,
    navigation,
    requestSwitchToAccount,
    resumeSession,
  ])

  React.useEffect(() => {
    const params = props.route.params
    if (
      currentAccount &&
      props.route.name === 'Start' &&
      params?.name &&
      params?.rkey
    ) {
      props.navigation.navigate('StarterPack', {
        rkey: params.rkey,
        name: params.name,
      })
    }
  }, [currentAccount, props.navigation, props.route.name, props.route.params])

  React.useEffect(() => {
    try {
      const search = window.location.search
      if (search && search.length) {
        const startParam = search.split('?tgWebAppStartParam=')[1]
        if (startParam) {
          var path = atob(decodeURIComponent(startParam))
          if (!path.startsWith('/')) {
            path = '/' + path
          }
          location.href = location.origin + path
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  if (preferences && pinnedFeedInfos && !isPinnedFeedsLoading) {
    return (
      <HomeScreenReady
        {...props}
        bind={bind}
        // shouldInviteCount={shouldInviteCount}
        binding={binding}
        handleBind={handleBind}
        preferences={preferences}
        pinnedFeedInfos={pinnedFeedInfos}
      />
    )
  } else {
    return (
      <View style={styles.loading}>
        {/* <ActivityIndicator size="large" color={t.palette.primary}/> */}
        <Image
          source={{
            uri: '/sipzloading.gif',
          }}
          style={{width: 80, height: 80, borderRadius: 20}}
          accessibilityIgnoresInvertColors
        />
      </View>
    )
  }
}

function HomeScreenReady({
  preferences,
  pinnedFeedInfos,
  bind,
  binding,
}: // shouldInviteCount,
// handleBind,
Props & {
  bind: boolean
  binding: boolean
  handleBind: (client: TelegramClient) => Promise<void>
  preferences: UsePreferencesQueryResponse
  pinnedFeedInfos: SavedFeedSourceInfo[]
  shouldInviteCount?: number
}) {
  const allFeeds = React.useMemo(
    () => pinnedFeedInfos.map(f => f.feedDescriptor),
    [pinnedFeedInfos],
  )
  const setSelectedFeed = useSetSelectedFeed()
  const rawSelectedFeed = useSelectedFeed() ?? allFeeds[0]
  setSelectedFeed(rawSelectedFeed)
  const maybeFoundIndex = allFeeds.indexOf(rawSelectedFeed)
  const selectedIndex = Math.max(0, maybeFoundIndex)
  const selectedFeed = allFeeds[selectedIndex]
  const requestNotificationsPermission = useRequestNotificationsPermission()
  const triggerTourIfQueued = useTriggerTourIfQueued(TOURS.HOME)
  const gate = useGate()
  // const {bind: hasBindTg} = useTgStore()
  const hasBindTg = true

  useSetTitle(pinnedFeedInfos[selectedIndex]?.displayName)
  useOTAUpdates()

  React.useEffect(() => {
    requestNotificationsPermission('Home')
  }, [requestNotificationsPermission])

  const pagerRef = React.useRef<PagerRef>(null)
  const lastPagerReportedIndexRef = React.useRef(selectedIndex)
  React.useLayoutEffect(() => {
    // Since the pager is not a controlled component, adjust it imperatively
    // if the selected index gets out of sync with what it last reported.
    // This is supposed to only happen on the web when you use the right nav.
    if (selectedIndex !== lastPagerReportedIndexRef.current) {
      lastPagerReportedIndexRef.current = selectedIndex
      pagerRef.current?.setPage(selectedIndex, 'desktop-sidebar-click')
    }
  }, [selectedIndex])

  // Temporary, remove when finished debugging
  const debugHasLoggedFollowingPrefs = React.useRef(false)
  const debugLogFollowingPrefs = React.useCallback(
    (feed: FeedDescriptor) => {
      if (debugHasLoggedFollowingPrefs.current) return
      if (feed !== 'following') return
      logEvent('debug:followingPrefs', {
        followingShowRepliesFromPref: preferences.feedViewPrefs.hideReplies
          ? 'off'
          : preferences.feedViewPrefs.hideRepliesByUnfollowed
          ? 'following'
          : 'all',
        followingRepliesMinLikePref:
          preferences.feedViewPrefs.hideRepliesByLikeCount,
      })
      debugHasLoggedFollowingPrefs.current = true
    },
    [
      preferences.feedViewPrefs.hideReplies,
      preferences.feedViewPrefs.hideRepliesByLikeCount,
      preferences.feedViewPrefs.hideRepliesByUnfollowed,
    ],
  )

  const {hasSession} = useSession()
  const setMinimalShellMode = useSetMinimalShellMode()
  const setDrawerSwipeDisabled = useSetDrawerSwipeDisabled()
  useFocusEffect(
    React.useCallback(() => {
      setMinimalShellMode(false)
      setDrawerSwipeDisabled(selectedIndex > 0)
      triggerTourIfQueued()
      return () => {
        setDrawerSwipeDisabled(false)
      }
    }, [
      setDrawerSwipeDisabled,
      selectedIndex,
      setMinimalShellMode,
      triggerTourIfQueued,
    ]),
  )

  useFocusEffect(
    useNonReactiveCallback(() => {
      if (selectedFeed) {
        logEvent('home:feedDisplayed:sampled', {
          index: selectedIndex,
          feedType: selectedFeed.split('|')[0],
          feedUrl: selectedFeed,
          reason: 'focus',
        })
        debugLogFollowingPrefs(selectedFeed)
      }
    }),
  )

  const mode = useMinimalShellMode()
  const {isMobile} = useWebMediaQueries()
  useFocusEffect(
    React.useCallback(() => {
      if (gate('fixed_bottom_bar')) {
        // Unnecessary because it's always there.
        return
      }
      const listener = AppState.addEventListener('change', nextAppState => {
        if (nextAppState === 'active') {
          if (isMobile && mode.value === 1) {
            // Reveal the bottom bar so you don't miss notifications or messages.
            // TODO: Experiment with only doing it when unread > 0.
            setMinimalShellMode(false)
          }
        }
      })
      return () => {
        listener.remove()
      }
    }, [setMinimalShellMode, mode, isMobile, gate]),
  )

  const onPageSelected = React.useCallback(
    (index: number) => {
      setMinimalShellMode(false)
      setDrawerSwipeDisabled(index > 0)
      const feed = allFeeds[index]
      setSelectedFeed(feed)
      lastPagerReportedIndexRef.current = index
    },
    [setDrawerSwipeDisabled, setSelectedFeed, setMinimalShellMode, allFeeds],
  )

  const onPageSelecting = React.useCallback(
    (
      index: number,
      reason: LogEvents['home:feedDisplayed:sampled']['reason'],
    ) => {
      const feed = allFeeds[index]
      logEvent('home:feedDisplayed:sampled', {
        index,
        feedType: feed.split('|')[0],
        feedUrl: feed,
        reason,
      })
      debugLogFollowingPrefs(feed)
    },
    [allFeeds, debugLogFollowingPrefs],
  )

  const onPressSelected = React.useCallback(() => {
    emitSoftReset()
  }, [])

  const onPageScrollStateChanged = React.useCallback(
    (state: 'idle' | 'dragging' | 'settling') => {
      if (state === 'dragging') {
        setMinimalShellMode(false)
      }
    },
    [setMinimalShellMode],
  )

  const renderTabBar = React.useCallback(
    (props: RenderTabBarFnProps) => {
      return (
        <HomeHeader
          key="FEEDS_TAB_BAR"
          {...props}
          testID="homeScreenFeedTabs"
          onPressSelected={onPressSelected}
          feeds={pinnedFeedInfos}
        />
      )
    },
    [onPressSelected, pinnedFeedInfos],
  )

  const renderFollowingEmptyState = React.useCallback(() => {
    // return <FollowingEmptyState />
    return (
      <EmptyBox
        icon="tea"
        message={'Oops... No tea to spill?  Looks like your cup is empty!'}
      />
    )
  }, [])

  const renderCustomFeedEmptyState = React.useCallback(() => {
    // return <CustomFeedEmptyState />
    return (
      <EmptyBox
        icon="tea"
        message={'Oops...look like your feed is a bit empty!'}
      />
    )
  }, [])

  const homeFeedParams = React.useMemo<FeedParams>(() => {
    return {
      mergeFeedEnabled: Boolean(preferences.feedViewPrefs.lab_mergeFeedEnabled),
      mergeFeedSources: preferences.feedViewPrefs.lab_mergeFeedEnabled
        ? preferences.savedFeeds
            .filter(f => f.type === 'feed' || f.type === 'list')
            .map(f => f.value)
        : [],
    }
  }, [preferences])

  return hasSession ? (
    <Pager
      key={allFeeds.join(',')}
      ref={pagerRef}
      testID="homeScreen"
      initialPage={selectedIndex}
      onPageSelecting={onPageSelecting}
      onPageSelected={onPageSelected}
      onPageScrollStateChanged={onPageScrollStateChanged}
      renderTabBar={renderTabBar}>
      {pinnedFeedInfos.length ? (
        pinnedFeedInfos.map(feedInfo => {
          const feed = feedInfo.feedDescriptor
          const savedFeedConfig = feedInfo.savedFeed
          if (feed === 'following') {
            return (
              <FeedPage
                key={feed}
                testID="followingFeedPage"
                isPageFocused={selectedFeed === feed}
                feed={feed}
                feedParams={homeFeedParams}
                renderEmptyState={renderFollowingEmptyState}
                renderEndOfFeed={FollowingEndOfFeed}
              />
            )
          }
          if (feedInfo.uri === FEED_CIRCLE_URI) {
            return (
              <>
                {hasBindTg ? (
                  <CircleProvider isCircle={true}>
                    <FeedPage
                      key={feed}
                      testID="feedCirclePage"
                      isPageFocused={selectedFeed === feed}
                      feed={feed}
                      feedParams={homeFeedParams}
                      renderEmptyState={renderFollowingEmptyState}
                      renderEndOfFeed={FollowingEndOfFeed}
                    />
                  </CircleProvider>
                ) : (
                  <LinkTelegram
                    // shouldInviteCount={shouldInviteCount}
                    bind={bind}
                    binding={binding}
                    // handleBind={handleBind}
                  />
                )}
              </>
            )
          } else if (feedInfo.uri === SQUARE_FEED_URI) {
            return (
              <BigFigureProvider isBigFigure={true} key={feed}>
                <FeedPage
                  key={feed}
                  testID="customFeedPage"
                  isPageFocused={selectedFeed === feed}
                  feed={feed}
                  renderEmptyState={renderCustomFeedEmptyState}
                  savedFeedConfig={savedFeedConfig}
                />
              </BigFigureProvider>
            )
          }
          return (
            <FeedPage
              key={feed}
              testID="customFeedPage"
              isPageFocused={selectedFeed === feed}
              feed={feed}
              renderEmptyState={renderCustomFeedEmptyState}
              savedFeedConfig={savedFeedConfig}
            />
          )
        })
      ) : (
        <NoFeedsPinned preferences={preferences} />
      )}
    </Pager>
  ) : (
    <Pager
      testID="homeScreen"
      onPageSelected={onPageSelected}
      onPageScrollStateChanged={onPageScrollStateChanged}
      renderTabBar={renderTabBar}>
      <BigFigureProvider isBigFigure={true}>
        <FeedPage
          testID="customFeedPage"
          isPageFocused
          feed={`feedgen|${PROD_DEFAULT_FEED('whats-hot')}`}
          renderEmptyState={renderCustomFeedEmptyState}
        />
      </BigFigureProvider>
    </Pager>
  )
}

const styles = StyleSheet.create({
  loading: {
    display: 'flex',
    // width: '100%',
    height: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    paddingBottom: 100,
  },
})
