import type {Dialog} from 'telegram/tl/custom/dialog'

type TaskType = (dialog: Dialog) => Promise<boolean>

interface IQueue<T extends Dialog> {
  queue: T[]
  task: TaskType
  count?: number
}

export default class DialogQueue<T extends Dialog> {
  count: number
  queue: T[]
  index = 0
  task: TaskType

  constructor(opts: IQueue<T>) {
    this.count = opts.count ?? 2
    this.queue = opts.queue ?? []
    this.task = opts.task
  }

  run() {
    return new Promise<void>(resolve => {
      const len = this.queue.length
      if (len > 0) {
        for (let i = 0; i < Math.min(this.count, len); i++) {
          const item = this.queue[i]
          if (item) {
            this.index = i
            this.next(item, resolve)
          }
        }
      } else {
        resolve()
      }
    })
  }

  next(item: T, resolve: () => void) {
    this.task(item)
      .then(res => {
        if (res) {
        }
        this.index++
        if (this.index >= this.queue.length) {
          resolve()
        }
        const nextTask = this.queue[this.index]
        if (nextTask) {
          this.next(nextTask, resolve)
        }
      })
      .catch(() => {
        this.index++
        if (this.index >= this.queue.length) {
          resolve()
        }
        const nextTask = this.queue[this.index]
        if (nextTask) {
          this.next(nextTask, resolve)
        }
      })
  }
}
