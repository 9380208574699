import React, {useMemo} from 'react'
import {Pressable, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {
  CONNECTIONS_RQKEY,
  useFriendsReqsQuery,
} from '#/state/queries/connections'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {useDatePickerDialog} from '#/components/dialogs/DatePicker'
import {Calendar_Stroke2_Corner0_Rounded2} from '#/components/icons/Calendar'
import {Loader} from '#/components/Loader'
import FriendRequestItem from './FriendRequestItem'

export default function FriendRequestList() {
  const {_} = useLingui()
  const t = useTheme()
  const {isDesktop} = useWebMediaQueries()
  const queryClient = useQueryClient()

  const {control, dialog, hasRange, startDate, endDate} = useDatePickerDialog()
  const {status, data, error} = useFriendsReqsQuery({startDate, endDate})

  const dom = useMemo(() => {
    let content
    if (status === 'pending') {
      content = (
        <View style={[a.align_center, a.justify_center, a.p_5xl]}>
          <Loader size="lg" />
        </View>
      )
    } else if (error) {
      content = (
        <View style={[a.gap_md, a.justify_center, a.align_center]}>
          <EmptyBox icon="heart" message="has no request yet." />
          <Pressable
            accessibilityRole="button"
            style={[
              a.px_lg,
              a.align_center,
              a.justify_center,
              a.rounded_lg,
              {
                backgroundColor: t.palette.primary,
                height: 32,
              },
            ]}
            onPress={() => {
              queryClient.invalidateQueries({
                queryKey: [CONNECTIONS_RQKEY.requestList],
              })
            }}>
            <Text style={[a.text_sm, a.font_semibold]}>
              <Trans>Try again</Trans>
            </Text>
          </Pressable>
        </View>
      )
    } else if (data?.length) {
      content = data.map(item => <FriendRequestItem {...item} key={item?.id} />)
    } else {
      content = <EmptyBox icon="tea" message="has no request yet." />
    }
    return content
  }, [error, status, queryClient, data, t.palette.primary])

  const renderButton = React.useCallback(() => {
    return (
      <Button
        label=""
        onPress={() => {
          control.open()
        }}>
        <Calendar_Stroke2_Corner0_Rounded2
          style={[!hasRange ? t.atoms.text : undefined]}
        />
      </Button>
    )
  }, [control, hasRange, t.atoms.text])

  return (
    <CenteredView
      testID="friendRequestListPage"
      style={[a.flex_1]}
      sideBorders={isDesktop ? true : false}>
      <ViewHeader
        title={_(msg`Request`)}
        showOnDesktop
        renderButton={renderButton}
      />
      <View style={[a.p_lg, a.flex_1, a.gap_md]}>{dom}</View>
      {dialog}
    </CenteredView>
  )
}
