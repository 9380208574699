import React, {useEffect, useState} from 'react'
import {
  FlatList as RNFlatList,
  Image,
  Pressable,
  StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import {ComAtprotoServerDefs} from '@atproto/api'
import {TransRecord} from '@atproto/api/dist/client/types/com/atproto/server/defs'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import dayjs from 'dayjs'
import {ethers} from 'ethers'

import {SUPPORT_CHAINS} from '#/lib/constants'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {
  useBetAvailableWithdrawQuery,
  useStakeAvailableWithdrawQuery,
  useTotalBalanceQuery,
  useUserTransRecordsQuery,
  useWalletListQuery,
} from '#/state/queries/transaction-assets'
import {formatWalletAddress} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {ArrowTriangleBottom_Stroke2_Corner1_Rounded as ArrowTriangleBottom} from '#/components/icons/ArrowTriangle'
import {
  AssetsBetIcon,
  AssetsBetOnAgainstIcon,
  AssetsScanIcon,
  AssetsStakeIcon,
  AssetsWithdrawIcon,
} from '#/components/icons/StakeIcons'
import * as Menu from '#/components/Menu'
import {WithdrawModalListType} from '../com/modals/Withdraw'
import {formatShortOnly} from '../com/util/numeric/format'
import {ViewHeader} from '../com/util/ViewHeader'
import {CenteredView, ScrollView} from '../com/util/Views'

const WalletIcon = require('../../../assets/imgs/metamask.png')

function getTransTitle(type?: number) {
  let title = '',
    iconType = ''
  switch (type) {
    case 1:
      title = 'Stake to Endorse'
      iconType = 'stake'
      break
    case 2:
      title = 'Withdraw for staking'
      iconType = 'withdraw'
      break
    case 3:
      title = 'Pre-betting'
      break
    case 4:
      title = 'Withdraw for pre-betting'
      break
    case 13:
      title = 'Betting penalty for early termination'
      break
    case 14:
      title = 'Staking penalty for content violations'
      break
    case 15:
      title = 'Betting penalty for content violations'
      break
    case 17:
      title = 'Service fee'
      break
    default:
  }
  return {title, iconType}
}

export function TransAssetsScreen() {
  const t = useTheme()
  const {openModal} = useModalControls()
  const {isMobile} = useWebMediaQueries()
  const {data: walletList} = useWalletListQuery()

  const [selectedWallet, setSelectedWallet] = useState<string>('')

  const {data: transList} = useUserTransRecordsQuery(selectedWallet)
  const {data: totalBalance} = useTotalBalanceQuery(selectedWallet)
  const {data: stakeWithdrawBalance} =
    useStakeAvailableWithdrawQuery(selectedWallet)
  const {data: betWithdrawBalance} =
    useBetAvailableWithdrawQuery(selectedWallet)

  const totalWithdrawBalance =
    (stakeWithdrawBalance ?? 0) + (betWithdrawBalance ?? 0)

  const onPressScan = React.useCallback(
    (item: TransRecord) => {
      const chainInfo = SUPPORT_CHAINS.find(o => o?.chainId === item?.chainId)
      const url = `${chainInfo?.explorerUrl}/tx/${item?.transId}`
      openModal({
        name: 'link-warning',
        text: 'View on block explorer',
        href: url,
      })
    },
    [openModal],
  )

  const renderItemInner = React.useCallback(
    ({item}: {item: ComAtprotoServerDefs.UserTransRecordInfo}) => {
      return (
        <View style={[styles.listRecordContaner]} key={item.month}>
          <View style={[a.my_md]}>
            <Text style={[a.text_md, a.font_heavy, t.atoms.text]}>
              {item?.month}
            </Text>
          </View>
          <View style={[a.gap_md]}>
            {item?.records?.map((childItem: TransRecord) => {
              const {title, iconType} = getTransTitle(childItem?.transType)
              return (
                <View
                  style={[a.flex_row, a.justify_between, a.align_center]}
                  key={childItem?.transId}>
                  <View
                    style={[a.gap_xs, a.flex_row, a.align_center, a.gap_sm]}>
                    <View style={[styles.typeIcon]}>
                      {iconType === 'stake' && (
                        <AssetsStakeIcon primaryColor={t.atoms.text.color} />
                      )}
                      {iconType === 'withdraw' && (
                        <AssetsWithdrawIcon primaryColor={t.atoms.text.color} />
                      )}
                      {iconType === 'bet-won' && (
                        <AssetsBetIcon primaryColor={t.atoms.text.color} />
                      )}
                      {iconType === 'bet-on' && (
                        <AssetsBetOnAgainstIcon
                          primaryColor={t.atoms.text.color}
                        />
                      )}
                      {iconType === 'bet-against' && (
                        <AssetsBetOnAgainstIcon
                          primaryColor={t.atoms.text.color}
                        />
                      )}
                    </View>
                    <Text style={[a.text_sm, t.atoms.text]}>{title}</Text>
                  </View>
                  <View style={[a.flex_col, a.align_end]}>
                    <Text
                      style={[
                        a.text_sm,
                        a.font_semibold,
                        {
                          color:
                            childItem?.amountFlag && childItem?.amountFlag > 0
                              ? t.palette.green_11
                              : '#FF6161',
                        },
                      ]}>
                      {childItem?.amountFlag === 1 ? '+' : '-'}
                      {childItem?.amount
                        ? ethers.formatUnits(childItem?.amount, 6)
                        : ''}{' '}
                      USDC{' '}
                      <Pressable
                        accessibilityRole="button"
                        onPress={() => onPressScan(childItem)}>
                        <AssetsScanIcon primaryColor={t.atoms.text.color} />
                      </Pressable>
                    </Text>
                    <Text style={[a.text_xs, {color: t.palette.gray_10}]}>
                      {dayjs(childItem?.updateAt).format('DD MMM, YYYY')}
                    </Text>
                  </View>
                </View>
              )
            })}
          </View>

          <View>
            <Text
              style={[
                a.mb_xs,
                t.atoms.text,
                a.text_sm,
                a.font_bold,
                a.text_right,
              ]}
            />
            <View style={[a.flex_row, a.align_center]}>
              <Text style={[{color: t.palette.gray_10}]} />
            </View>
          </View>
        </View>
      )
    },
    [t.atoms.text, t.palette.gray_10, onPressScan, t.palette.green_11],
  )

  useEffect(() => {
    if (walletList) {
      setSelectedWallet(walletList?.[0])
    }
  }, [walletList])

  return (
    <CenteredView style={[a.flex_1, isMobile && {paddingBottom: 60}]}>
      <StatusBar animated={true} backgroundColor={'#F8EEFF'} />
      <ViewHeader title="Assets" style={{backgroundColor: '#F8EEFF'}} />
      <ScrollView>
        <View
          style={[
            a.p_lg,
            {
              backgroundColor: '#F8EEFF',
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            },
          ]}>
          <View style={[a.flex_row, a.justify_end]}>
            {walletList && walletList?.length > 0 && (
              <AddressDropdownMenu
                selectedWallet={selectedWallet}
                walletList={walletList ?? []}
                setSelectedWallet={setSelectedWallet}
              />
            )}
          </View>
          <View style={[a.mb_lg]}>
            <Text style={[{color: t.palette.black_25}]}>Total balance</Text>
            <Text style={[a.text_4xl, a.font_heavy]}>
              {formatShortOnly(totalBalance ?? 0, 2)}{' '}
              <Text style={[a.text_sm, a.font_heavy]}>USDC</Text>
            </Text>
          </View>
          <View style={[a.flex_row, a.align_center, a.justify_between]}>
            <View>
              <Text style={[{color: t.palette.black_25}]}>
                Available for withdraw
              </Text>
              <Text style={[a.text_4xl, a.font_heavy]}>
                {formatShortOnly(totalWithdrawBalance, 2)}{' '}
                <Text style={[a.text_sm, a.font_heavy]}>USDC</Text>
              </Text>
            </View>
            {!!totalWithdrawBalance && (
              <Pressable
                accessibilityRole="button"
                onPress={() => {
                  const list: WithdrawModalListType[] = []
                  if (stakeWithdrawBalance) {
                    list.push({
                      title: 'For staking',
                      type: 'stake',
                      amount: stakeWithdrawBalance,
                      address: selectedWallet,
                    })
                  }
                  if (betWithdrawBalance) {
                    list.push({
                      title: 'For betting',
                      type: 'bet',
                      amount: betWithdrawBalance,
                      address: selectedWallet,
                    })
                  }
                  openModal({
                    name: 'withdraw',
                    list,
                  })
                }}
                style={[
                  styles.withdrawBtn,
                  {backgroundColor: t.palette.primary},
                ]}>
                <Text style={[a.text_xs, a.font_semibold]}>Withdraw</Text>
              </Pressable>
            )}
          </View>
        </View>

        <View style={[a.p_lg, a.mb_md]}>
          <RNFlatList
            testID={'transassetsflatlist'}
            data={transList}
            renderItem={renderItemInner}
            removeClippedSubviews={true}
            // @ts-ignore our .web version only -prf
            desktopFixedHeight
          />
        </View>
      </ScrollView>
    </CenteredView>
  )
}

const AddressDropdownMenu = ({
  selectedWallet,
  walletList,
  setSelectedWallet,
}: {
  selectedWallet: string
  walletList: string[]
  setSelectedWallet: (value: string) => void
}) => {
  const {_} = useLingui()
  const t = useTheme()

  return (
    <Menu.Root>
      <Menu.Trigger label={_(msg`Open address options menu`)}>
        {({props, state}) => {
          return (
            <Pressable
              {...props}
              testID={'addressChange'}
              style={[
                a.rounded_full,
                (state.hovered || state.pressed) && [t.atoms.bg_contrast_25],
              ]}>
              <View style={[styles.addressContainer]}>
                <Image
                  source={WalletIcon}
                  style={{width: 24, height: 24, marginRight: 8}}
                  accessibilityIgnoresInvertColors
                />
                <Text style={[a.text_xs, {color: t.palette.white}]}>
                  {formatWalletAddress(selectedWallet)}
                </Text>
                <ArrowTriangleBottom
                  size="xxs"
                  style={{
                    color: t.palette.white,
                    width: 6,
                    height: 6,
                    marginTop: 2,
                    marginLeft: 5,
                  }}
                />
              </View>
            </Pressable>
          )
        }}
      </Menu.Trigger>

      <Menu.Outer>
        <Menu.Group>
          {walletList?.map(item => {
            return (
              <Menu.Item
                label={''}
                onPress={() => {
                  setSelectedWallet(item)
                }}
                key={item}>
                <Menu.ItemText>{formatWalletAddress(item)}</Menu.ItemText>
              </Menu.Item>
            )
          })}
        </Menu.Group>
      </Menu.Outer>
    </Menu.Root>
  )
}

const styles = StyleSheet.create({
  listRecordContaner: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: 10,
  },
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 136,
    height: 28,
    borderRadius: 50,
    backgroundColor: 'rgba(51, 51, 51, 0.5)',
    paddingHorizontal: 2,
    overflow: 'hidden',
  },
  withdrawBtn: {
    width: 72,
    height: 29,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  typeIcon: {
    width: 44,
    height: 44,
    borderRadius: 22,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#E8E8E8',
  },
})
