import React, {memo, useMemo} from 'react'
import {StyleSheet, View} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {POST_TOMBSTONE, Shadow, usePostShadow} from '#/state/cache/post-shadow'
import {ThreadPost} from '#/state/queries/post-thread'
import {useThemePrefs} from '#/state/shell'
import {useComposerControls} from '#/state/shell/composer'
import {MAX_POST_LINES, POST_CTRL_HITSLOP} from 'lib/constants'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {makeProfileLink} from 'lib/routes/links'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {sanitizeHandle} from 'lib/strings/handles'
import {countLines} from 'lib/strings/helpers'
import {s} from 'lib/styles'
import {isWeb} from 'platform/detection'
import {PostStakeDonateItem} from 'view/com/post-thread/PostStakeDonateItem'
import {formatHandler} from '#/screens/Onboarding/util'
import {FONT_SIZE_CONFIG} from '#/screens/Settings/TextSizeSettings'
import {atoms as a, useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {RichText} from '#/components/RichText'
import {ContentHider} from '../../../components/moderation/ContentHider'
import {LabelsOnMyPost} from '../../../components/moderation/LabelsOnMe'
import {PostAlerts} from '../../../components/moderation/PostAlerts'
import {PostHider} from '../../../components/moderation/PostHider'
import {CircleProvider} from '../posts/CircleProvider'
import {TargetDisplay} from '../posts/TargetDisplay'
import {
  TranslationWidget,
  useTranslationWidget,
} from '../posts/TranslationWidget/TranslationWidget'
import {getCircleItemCard} from '../util/CircleUtil'
import {ErrorMessage} from '../util/error/ErrorMessage'
import {PostDropdownBtn} from '../util/forms/PostDropdownBtn'
import {Link, TextLink} from '../util/Link'
import {CirclePostCtrls} from '../util/post-ctrls/CirclePostCtrls'
import {PostEmbeds} from '../util/post-embeds'
import {PostMeta} from '../util/PostMeta'
import {Text} from '../util/text/Text'
import {AnonSpillerAvatar, PreviewableUserAvatar} from '../util/UserAvatar'
import {PostBetChallenge} from './PostBetChallenge'

export function CircleThreadItem({
  post,
  record,
  moderation,
  treeView,
  depth,
  prevPost,
  nextPost,
  isHighlightedPost,
  hasMore,
  showChildReplyLine,
  showParentReplyLine,
  hasPrecedingItem,
  overrideBlur,
  onPostReply,
  hideTopBorder,
}: {
  post: AppBskyFeedDefs.PostView
  record: AppBskyFeedPost.Record
  moderation: ModerationDecision | undefined
  treeView: boolean
  depth: number
  prevPost: ThreadPost | undefined
  nextPost: ThreadPost | undefined
  isHighlightedPost?: boolean
  hasMore?: boolean
  showChildReplyLine?: boolean
  showParentReplyLine?: boolean
  hasPrecedingItem: boolean
  overrideBlur: boolean
  onPostReply: (postUri: string | undefined) => void
  hideTopBorder?: boolean
}) {
  const postShadowed = usePostShadow(post)
  const {
    translateTitle,
    hasTranslated,
    displayOriginalText,
    richText,
    translatePost,
    isLanguageConsistent,
    fromMe,
  } = useTranslationWidget({record, post})

  if (postShadowed === POST_TOMBSTONE) {
    return <PostThreadItemDeleted hideTopBorder={hideTopBorder} />
  }
  if (richText && moderation) {
    return (
      <PostThreadItemLoaded
        // Safeguard from clobbering per-post state below:
        key={postShadowed.uri}
        post={postShadowed}
        prevPost={prevPost}
        nextPost={nextPost}
        record={{...record, title: translateTitle || record.title}}
        richText={richText}
        moderation={moderation}
        treeView={treeView}
        depth={depth}
        isHighlightedPost={isHighlightedPost}
        hasMore={hasMore}
        showChildReplyLine={showChildReplyLine}
        showParentReplyLine={showParentReplyLine}
        hasPrecedingItem={hasPrecedingItem}
        overrideBlur={overrideBlur}
        onPostReply={onPostReply}
        hideTopBorder={hideTopBorder}
        translate={{
          displayOriginalText,
          translatePost,
          hasTranslated,
          isLanguageConsistent,
          fromMe,
        }}
      />
    )
  }
  return null
}

function PostThreadItemDeleted({hideTopBorder}: {hideTopBorder?: boolean}) {
  const pal = usePalette('default')
  return (
    <View
      style={[
        styles.outer,
        pal.border,
        pal.view,
        s.p20,
        s.flexRow,
        hideTopBorder && styles.noTopBorder,
      ]}>
      <FontAwesomeIcon icon={['far', 'trash-can']} color={pal.colors.icon} />
      <Text style={[pal.textLight, s.ml10]}>
        <Trans>This post has been deleted.</Trans>
      </Text>
    </View>
  )
}

let PostThreadItemLoaded = ({
  post,
  record,
  richText,
  moderation,
  treeView,
  depth,
  prevPost,
  nextPost,
  isHighlightedPost,
  hasMore,
  showChildReplyLine,
  showParentReplyLine,
  hasPrecedingItem,
  overrideBlur,
  onPostReply,
  hideTopBorder,
  translate,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  moderation: ModerationDecision
  treeView: boolean
  depth: number
  prevPost: ThreadPost | undefined
  nextPost: ThreadPost | undefined
  isHighlightedPost?: boolean
  hasMore?: boolean
  showChildReplyLine?: boolean
  showParentReplyLine?: boolean
  hasPrecedingItem: boolean
  overrideBlur: boolean
  onPostReply: (postUri: string | undefined) => void
  hideTopBorder?: boolean
  translate: {
    displayOriginalText: () => void
    translatePost: () => void
    hasTranslated: boolean
    isLanguageConsistent: boolean
    fromMe: boolean
  }
}): React.ReactNode => {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const {openComposer} = useComposerControls()
  const {fontSizeMode} = useThemePrefs()
  const detailTextFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.detail].detail.circle.text
  }, [fontSizeMode])
  const [limitLines, setLimitLines] = React.useState(
    () => countLines(richText?.text) >= MAX_POST_LINES,
  )
  const rootUri = record.reply?.root?.uri || post.uri
  const postHref = React.useMemo(() => {
    const urip = new AtUri(post.uri)
    return makeProfileLink(post.author, 'post', urip.rkey)
  }, [post.uri, post.author])
  const itemTitle = _(msg`Post by ${post.author.handle}`)
  const authorHref = makeProfileLink(post.author)
  const authorTitle = sanitizeHandle(post.author.handle)
  const onPressReply = React.useCallback(() => {
    openComposer({
      replyTo: {
        uri: post.uri,
        cid: post.cid,
        text: record.text,
        author: post.author,
        embed: post.embed,
        moderation,
      },
      onPost: onPostReply,
    })
  }, [openComposer, post, record, onPostReply, moderation])

  const onPressShowMore = React.useCallback(() => {
    setLimitLines(false)
  }, [setLimitLines])

  const theme = useThemeName()
  const circleItemCard = useMemo(() => {
    let cards = getCircleItemCard(post.author.did)
    return cards[theme === 'light' ? 'light' : 'dark']
  }, [post.author.did, theme])

  if (!record) {
    return <ErrorMessage message={_(msg`Invalid or unsupported post record`)} />
  }

  const targets = record?.finEntities || []
  const isAnonymousPost = record?.hideAuthor === 'true' ? true : false

  if (isHighlightedPost) {
    return (
      <>
        {rootUri !== post.uri && (
          <View style={{paddingLeft: 16, flexDirection: 'row', height: 16}}>
            <View style={{width: 38}}>
              <View
                style={[
                  styles.replyLine,
                  {
                    flexGrow: 1,
                    backgroundColor: pal.colors.replyLine,
                  },
                ]}
              />
            </View>
          </View>
        )}
        <View
          testID={`postThreadItem-by-${post.author.handle}`}
          style={[
            styles.outer,
            styles.outerHighlighted,
            pal.border,
            pal.view,
            rootUri === post.uri && styles.outerHighlightedRoot,
            hideTopBorder && styles.noTopBorder,
            {backgroundColor: circleItemCard.color},
          ]}
          accessible={false}>
          <View
            style={[
              t.atoms.bg,
              {
                position: 'absolute',
                bottom: 0,
                left: 0,
                height: 45,
                width: '100%',
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              },
            ]}
          />
          <View style={[styles.layout]}>
            <View style={[styles.layoutAvi, {paddingBottom: 8}]}>
              {isAnonymousPost ? (
                <AnonSpillerAvatar size={44} profile={post.author} />
              ) : (
                <PreviewableUserAvatar
                  size={44}
                  profile={post.author}
                  moderation={moderation.ui('avatar')}
                  type={post.author.associated?.labeler ? 'labeler' : 'user'}
                />
              )}
            </View>
            <View style={styles.layoutContent}>
              <View
                style={[styles.meta, styles.metaExpandedLine1, {zIndex: 1}]}>
                {isAnonymousPost ? (
                  <View>
                    <Text
                      type="xl-bold"
                      style={[{color: 'black'}, a.self_start]}
                      numberOfLines={1}
                      lineHeight={1.2}>
                      Anonymous
                    </Text>
                  </View>
                ) : (
                  <Link style={s.flex1} href={authorHref} title={authorTitle}>
                    <Text
                      type="xl-bold"
                      style={[{color: 'black'}, a.self_start]}
                      numberOfLines={1}
                      lineHeight={1.2}>
                      {sanitizeDisplayName(
                        post.author.displayName ||
                          sanitizeHandle(formatHandler(post.author.handle)),
                        moderation.ui('displayName'),
                      )}
                    </Text>
                    <Text
                      style={[
                        a.text_sm,
                        {color: t.palette.gray_10, marginTop: 4},
                      ]}>
                      {sanitizeHandle(formatHandler('@' + post.author.handle))}
                    </Text>
                  </Link>
                )}
              </View>
            </View>
            {/* {currentAccount?.did !== post.author.did && ( */}
            <PostDropdownBtn
              testID="postDropdownBtn"
              post={post}
              postFeedContext={''}
              record={record}
              richText={richText}
              style={{padding: 5}}
              hitSlop={POST_CTRL_HITSLOP}
              timestamp={post.indexedAt}
              isCircleThread
            />
            {/* )} */}
          </View>

          <View style={[s.pl10, s.pr10, s.pb10, {paddingTop: 8}]}>
            {targets.length > 0 && <TargetDisplay targets={targets} />}
            <LabelsOnMyPost post={post} />
            {record?.title && (
              <Text
                style={[
                  {color: 'black'},
                  {
                    fontWeight: '700',
                    fontSize: 18,
                    marginTop: 3,
                    marginBottom: 8,
                  },
                ]}>
                {record.title}
              </Text>
            )}
            <View style={{minHeight: 200, paddingTop: 5}}>
              <ContentHider
                modui={moderation.ui('contentView')}
                ignoreMute
                style={styles.contentHider}
                childContainerStyle={styles.contentHiderChild}>
                <PostAlerts
                  modui={moderation.ui('contentView')}
                  size="lg"
                  includeMute
                  style={[a.pt_2xs, a.pb_sm]}
                />
                {richText?.text ? (
                  <>
                    <View
                      style={[
                        styles.postTextContainer,
                        styles.postTextLargeContainer,
                      ]}>
                      <RichText
                        enableTags
                        selectable
                        value={richText}
                        style={[
                          a.flex_1,
                          {fontSize: detailTextFontSize, color: 'black'},
                        ]}
                        authorHandle={sanitizeHandle(post.author.handle)}
                      />
                    </View>
                  </>
                ) : undefined}
                {post.embed && (
                  <View style={[a.pb_sm]}>
                    <CircleProvider isCircle={true}>
                      <PostEmbeds embed={post.embed} moderation={moderation} />
                    </CircleProvider>
                  </View>
                )}
              </ContentHider>
              <TranslationWidget translate={translate} isCircle />
            </View>

            {rootUri === post.uri && (
              <PostStakeDonateItem
                post={post}
                isAnonymousPost={isAnonymousPost}
              />
            )}
            <View style={[s.pl10, s.pr10, {marginTop: 5}]}>
              <View style={{flexDirection: 'row'}}>
                <CirclePostCtrls
                  post={post}
                  record={record}
                  richText={richText}
                  onPressReply={onPressReply}
                  logContext="FeedItem"
                  // feedContext={feedContext}

                  rightButton={() => {
                    return null
                  }}
                />
                <View style={{flex: 1}} />
              </View>
            </View>
          </View>
        </View>
        {rootUri === post.uri && <PostBetChallenge post={post} />}
      </>
    )
  } else {
    const isThreadedChild = treeView && depth > 0
    const isThreadedChildAdjacentTop =
      isThreadedChild && prevPost?.ctx.depth === depth && depth !== 1
    const isThreadedChildAdjacentBot =
      isThreadedChild && nextPost?.ctx.depth === depth
    return (
      <PostOuterWrapper
        post={post}
        depth={depth}
        showParentReplyLine={!!showParentReplyLine}
        treeView={treeView}
        hasPrecedingItem={hasPrecedingItem}
        hideTopBorder={hideTopBorder}>
        <PostHider
          testID={`postThreadItem-by-${post.author.handle}`}
          href={postHref}
          disabled={overrideBlur}
          style={[pal.view]}
          modui={moderation.ui('contentList')}
          iconSize={isThreadedChild ? 26 : 38}
          iconStyles={
            isThreadedChild ? {marginRight: 4} : {marginLeft: 2, marginRight: 2}
          }
          profile={post.author}
          interpretFilterAsBlur>
          <View
            style={{
              flexDirection: 'row',
              gap: 10,
              paddingLeft: 8,
              height: isThreadedChildAdjacentTop ? 8 : 16,
            }}>
            <View style={{width: 38}}>
              {!isThreadedChild && showParentReplyLine && (
                <View
                  style={[
                    styles.replyLine,
                    {
                      flexGrow: 1,
                      backgroundColor: pal.colors.replyLine,
                      marginBottom: 4,
                    },
                  ]}
                />
              )}
            </View>
          </View>

          <View
            style={[
              styles.layout,
              {
                paddingBottom:
                  showChildReplyLine && !isThreadedChild
                    ? 0
                    : isThreadedChildAdjacentBot
                    ? 4
                    : 8,
              },
            ]}>
            {/* If we are in threaded mode, the avatar is rendered in PostMeta */}
            {!isThreadedChild && (
              <View style={styles.layoutAvi}>
                {isAnonymousPost ? (
                  <AnonSpillerAvatar size={38} profile={post.author} />
                ) : (
                  <PreviewableUserAvatar
                    size={38}
                    profile={post.author}
                    moderation={moderation.ui('avatar')}
                    type={post.author.associated?.labeler ? 'labeler' : 'user'}
                  />
                )}

                {showChildReplyLine && (
                  <View
                    style={[
                      styles.replyLine,
                      {
                        flexGrow: 1,
                        backgroundColor: pal.colors.replyLine,
                        marginTop: 4,
                      },
                    ]}
                  />
                )}
              </View>
            )}

            <View
              style={
                isThreadedChild
                  ? styles.layoutContentThreaded
                  : styles.layoutContent
              }>
              <PostMeta
                author={post.author}
                moderation={moderation}
                authorHasWarning={!!post.author.labels?.length}
                timestamp={post.indexedAt}
                postHref={postHref}
                showAvatar={isThreadedChild}
                avatarModeration={moderation.ui('avatar')}
                avatarSize={28}
                isAnonymous={isAnonymousPost}
                displayNameType="md-bold"
                displayNameStyle={isThreadedChild && s.ml2}
                style={
                  isThreadedChild && {
                    alignItems: 'center',
                    paddingBottom: isWeb ? 5 : 2,
                  }
                }
              />
              <LabelsOnMyPost post={post} />
              <PostAlerts
                modui={moderation.ui('contentList')}
                style={[a.pt_2xs, a.pb_2xs]}
              />
              {richText?.text ? (
                <View style={styles.postTextContainer}>
                  <RichText
                    enableTags
                    value={richText}
                    style={[a.flex_1, a.text_md]}
                    numberOfLines={limitLines ? MAX_POST_LINES : undefined}
                    authorHandle={sanitizeHandle(post.author.handle)}
                  />
                </View>
              ) : undefined}
              <TranslationWidget translate={translate} />
              {limitLines ? (
                <TextLink
                  text={_(msg`Show More`)}
                  style={pal.linkActive}
                  onPress={onPressShowMore}
                  href="#"
                />
              ) : undefined}
              {post.embed && (
                <View style={[a.pb_xs]}>
                  <PostEmbeds embed={post.embed} moderation={moderation} />
                </View>
              )}
              <CirclePostCtrls
                post={post}
                record={record}
                richText={richText}
                onPressReply={onPressReply}
                logContext="PostThreadItem"
                // feedContext={feedContext}
                rightButton={() => {
                  return null
                }}
              />
            </View>
          </View>
          {hasMore ? (
            <Link
              style={[
                styles.loadMore,
                {
                  paddingLeft: treeView ? 8 : 70,
                  paddingTop: 0,
                  paddingBottom: treeView ? 4 : 12,
                },
              ]}
              href={postHref}
              title={itemTitle}
              noFeedback>
              <Text type="sm-medium" style={pal.textLight}>
                <Trans>More</Trans>
              </Text>
              <FontAwesomeIcon
                icon="angle-right"
                color={pal.colors.textLight}
                size={14}
              />
            </Link>
          ) : undefined}
        </PostHider>
      </PostOuterWrapper>
    )
  }
}
PostThreadItemLoaded = memo(PostThreadItemLoaded)

function PostOuterWrapper({
  post,
  treeView,
  depth,
  showParentReplyLine,
  hasPrecedingItem,
  hideTopBorder,
  children,
}: React.PropsWithChildren<{
  post: AppBskyFeedDefs.PostView
  treeView: boolean
  depth: number
  showParentReplyLine: boolean
  hasPrecedingItem: boolean
  hideTopBorder?: boolean
}>) {
  const {isMobile} = useWebMediaQueries()
  const pal = usePalette('default')
  if (treeView && depth > 0) {
    return (
      <View
        style={[
          pal.border,
          styles.cursor,
          {
            flexDirection: 'row',
            paddingHorizontal: isMobile ? 10 : 6,
            borderTopWidth: depth === 1 ? StyleSheet.hairlineWidth : 0,
          },
        ]}>
        {Array.from(Array(depth - 1)).map((_, n: number) => (
          <View
            key={`${post.uri}-padding-${n}`}
            style={{
              borderLeftWidth: 2,
              borderLeftColor: pal.colors.border,
              marginLeft: isMobile ? 6 : 12,
              paddingLeft: isMobile ? 6 : 8,
            }}
          />
        ))}
        <View style={{flex: 1}}>{children}</View>
      </View>
    )
  }
  return (
    <View
      style={[
        styles.outer,
        pal.border,
        showParentReplyLine && hasPrecedingItem && styles.noTopBorder,
        hideTopBorder && styles.noTopBorder,
        styles.cursor,
      ]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  outer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingLeft: 8,
  },
  outerHighlighted: {
    borderTopWidth: 0,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  outerHighlightedRoot: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: 16,
  },
  noTopBorder: {
    borderTopWidth: 0,
  },
  layout: {
    flexDirection: 'row',
    paddingHorizontal: 8,
  },
  layoutAvi: {},
  layoutContent: {
    flex: 1,
    marginLeft: 10,
  },
  layoutContentThreaded: {
    flex: 1,
  },
  meta: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  metaExpandedLine1: {
    paddingVertical: 0,
  },
  alert: {
    marginBottom: 6,
  },
  postTextContainer: {
    flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: 4,
    paddingRight: 10,
    overflow: 'hidden',
  },
  postTextLargeContainer: {
    paddingHorizontal: 0,
    paddingRight: 0,
    paddingBottom: 10,
  },
  translateLink: {
    marginBottom: 6,
  },
  contentHider: {
    marginBottom: 6,
  },
  contentHiderChild: {
    marginTop: 6,
  },
  expandedInfo: {
    flexDirection: 'row',
    padding: 10,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 5,
    marginBottom: 10,
  },
  expandedInfoItem: {
    marginRight: 10,
  },
  loadMore: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4,
    paddingHorizontal: 20,
  },
  replyLine: {
    width: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cursor: {
    // @ts-ignore web only
    cursor: 'pointer',
  },
})
