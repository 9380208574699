import React from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {Slider} from '@miblanchard/react-native-slider'

import {CommonNavigatorParams, NativeStackScreenProps} from '#/lib/routes/types'
import * as persisted from '#/state/persisted'
import {useSetThemePrefs, useThemePrefs} from '#/state/shell'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as ToggleButton from '#/components/forms/ToggleButton'
import {CardDemo, DetailDemo} from './components/TextSizeDemo'

export const FONT_SIZE_CONFIG = {
  small: {
    feed: {
      circle: {text: 18},
      public: {title: 18, text: 14},
    },
    detail: {
      circle: {text: 20},
      public: {title: 20, text: 16},
    },
  },
  default: {
    feed: {
      circle: {text: 20},
      public: {title: 20, text: 16},
    },
    detail: {
      circle: {text: 24},
      public: {title: 24, text: 18},
    },
  },
  large: {
    feed: {
      circle: {text: 24},
      public: {title: 24, text: 18},
    },
    detail: {
      circle: {text: 28},
      public: {title: 28, text: 20},
    },
  },
}
export type TextSizeMode = 'small' | 'default' | 'large'
type Props = NativeStackScreenProps<CommonNavigatorParams, 'TextSizeSettings'>
export function TextSizeSettingsScreen({}: Props) {
  const {_} = useLingui()
  const t = useTheme()
  const {fontSizeMode} = useThemePrefs()
  const {setFontSizeMode} = useSetThemePrefs()

  const trackMarksType: {key: number; name: string; value: TextSizeMode}[] =
    React.useMemo(() => {
      return [
        // small
        {key: 1, name: 'A', value: 'small'},
        // default
        {key: 2, name: 'Standard', value: 'default'},
        // large
        {key: 3, name: 'A', value: 'large'},
      ]
    }, [])

  const [cardType, setCardType] = React.useState<string[]>(['Circle'])
  const [currentStep, setCurrentStep] = React.useState<number>(1)

  const [cardSizeMode, setCardSizeMode] = React.useState<number>(
    trackMarksType.find(o => o?.value === fontSizeMode?.feed)?.key ?? 2,
  )
  const [detailSizeMode, setDetailSizeMode] = React.useState<number>(
    trackMarksType.find(o => o?.value === fontSizeMode?.detail)?.key ?? 2,
  )
  const [fontConfig, setFontConfig] =
    React.useState<persisted.Schema['fontSizeMode']>(fontSizeMode)

  function handleFontSizeChange(value: number) {
    let config
    if (currentStep === 1) {
      setCardSizeMode(value)
      config = {
        ...fontConfig,
        feed: trackMarksType.find(o => o?.key === value)?.value ?? 'default',
      }
    } else {
      setDetailSizeMode(value)
      config = {
        ...fontConfig,
        detail: trackMarksType.find(o => o?.key === value)?.value ?? 'default',
      }
    }
    setFontConfig(config)
    setFontSizeMode(config)
  }

  const renderButton = () => {
    return (
      <Button
        color={'primary'}
        style={[{right: 16}, a.absolute]}
        variant="solid"
        size="xsmall"
        label="Add"
        onPress={() => {
          setCurrentStep(currentStep === 1 ? 2 : 1)
        }}>
        <Text style={[{color: t.palette.black}, a.font_bold]}>
          {currentStep === 1 ? <Trans>Next</Trans> : <Trans>Previous</Trans>}
        </Text>
      </Button>
    )
  }

  return (
    <CenteredView style={[a.w_full, a.h_full, a.justify_between]}>
      <ViewHeader
        title={_(msg`Text Size`) + ` (${currentStep}/2)`}
        showOnDesktop
        renderButton={renderButton}
      />
      <View style={[a.pb_4xl, a.flex_1, a.gap_3xl]}>
        <View style={[a.flex_1, a.justify_between]}>
          <View
            style={[
              a.mt_xl,
              a.gap_xl,
              a.flex_col,
              a.align_center,
              a.justify_center,
            ]}>
            <View style={[{width: '50%'}]}>
              <ToggleButton.Group
                label="CardType"
                values={cardType}
                onChange={setCardType}
                style={{
                  paddingVertical: 8,
                  paddingHorizontal: 12,
                  borderRadius: 12,
                }}>
                <ToggleButton.Button name="Circle" label="Circle">
                  <ToggleButton.ButtonText>Circle</ToggleButton.ButtonText>
                </ToggleButton.Button>
                <ToggleButton.Button name="Explore" label="Explore">
                  <ToggleButton.ButtonText>Explore</ToggleButton.ButtonText>
                </ToggleButton.Button>
              </ToggleButton.Group>
            </View>
            {currentStep === 1 ? (
              <CardDemo cardType={cardType[0]} />
            ) : (
              <DetailDemo cardType={cardType[0]} />
            )}
          </View>
          {/* step button */}
          <View
            style={[a.flex_row, a.align_center, a.justify_center, a.gap_4xl]}>
            <Pressable
              accessibilityRole="button"
              role="button"
              disabled={currentStep === 1}
              onPress={() => setCurrentStep(1)}>
              <View
                style={[
                  a.align_center,
                  a.justify_center,
                  {
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    opacity: currentStep === 1 ? 0.5 : 1,
                    backgroundColor: t.palette.gray_18,
                  },
                ]}>
                <FontAwesomeIcon
                  icon={'angle-left'}
                  style={{
                    color: t.palette.white,
                    backgroundColor: 'transparent',
                    pointerEvents: 'none',
                  }}
                />
              </View>
            </Pressable>
            <Pressable
              accessibilityRole="button"
              role="button"
              disabled={currentStep === 2}
              onPress={() => setCurrentStep(2)}>
              <View
                style={[
                  a.align_center,
                  a.justify_center,
                  {
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    opacity: currentStep === 2 ? 0.5 : 1,
                    backgroundColor: t.palette.gray_18,
                  },
                ]}>
                <FontAwesomeIcon
                  icon={'angle-right'}
                  style={{
                    color: t.palette.white,
                    backgroundColor: 'transparent',
                    pointerEvents: 'none',
                  }}
                />
              </View>
            </Pressable>
          </View>
        </View>
        <View style={[a.px_xl, a.gap_xl]}>
          {/* text title */}
          <View
            style={[
              a.w_full,
              a.flex_row,
              a.align_center,
              {paddingHorizontal: 5},
            ]}>
            {trackMarksType?.map((item, index) => {
              return (
                <Text
                  key={item.key}
                  style={[
                    a.flex_1,
                    a.text_sm,
                    a.font_semibold,
                    {color: t.palette.gray_18, fontSize: 14},
                    index === 1 && [a.text_center],
                    index === 2 && [a.text_right, {fontSize: 24}],
                  ]}>
                  {item?.name}
                </Text>
              )
            })}
          </View>
          <View style={[a.w_full, a.relative]}>
            {/* text mark */}
            <View
              style={[
                a.absolute,
                a.w_full,
                a.flex_row,
                a.align_center,
                {top: 20},
              ]}>
              {trackMarksType?.map((item, index) => {
                return (
                  <View
                    style={[index < 2 && a.flex_1, a.relative]}
                    key={item.key}>
                    <View
                      style={[
                        a.absolute,
                        {
                          height: 10,
                          width: 3,
                          bottom: 0,
                          backgroundColor: t.palette.gray_18,
                        },
                        index === 0 && {left: 4},
                        index === 2 && {right: 4},
                      ]}
                    />
                  </View>
                )
              })}
            </View>
            {/* text slider */}
            <Slider
              maximumValue={trackMarksType[trackMarksType?.length - 1]?.key}
              minimumValue={trackMarksType[0]?.key}
              step={1}
              thumbStyle={{
                backgroundColor: t.palette.gray_18,
                width: 26,
                height: 26,
                borderRadius: 13,
              }}
              minimumTrackStyle={{backgroundColor: t.palette.gray_18}}
              trackStyle={{
                backgroundColor: t.palette.gray_18,
                marginHorizontal: 4,
              }}
              value={currentStep === 1 ? cardSizeMode : detailSizeMode}
              onValueChange={values => handleFontSizeChange(values[0])}
              trackMarks={trackMarksType?.map(item => item?.key)}
            />
          </View>
        </View>
      </View>
    </CenteredView>
  )
}
