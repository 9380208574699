import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import ViewShot, {captureRef} from 'react-native-view-shot'
import {LinearGradient} from 'expo-linear-gradient'
import {Trans} from '@lingui/macro'

import {makeProfileLink} from '#/lib/routes/links'
import {toShareUrl} from '#/lib/strings/url-helpers'
import {IShareProfile, useModalControls} from '#/state/modals'
// import {shareUrl} from 'lib/sharing'
import {
  CloseShareIcon,
  DownLoadIcon,
  ShareLinkIcon,
} from '#/view/icons/ModalIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {useTheme} from '#/alf'
import {teleShare} from '#/utils/ShareUtil'
import {UserAvatar} from '../../util/UserAvatar'
import {ShareQRCode} from './ShareQRCode'

export const snapPoints = ['fullscreen']

export function Component({
  profile: {handle, displayName, avatar, description},
}: IShareProfile) {
  const viewRef = useRef()
  const {closeModal} = useModalControls()
  const t = useTheme()

  const captureView = async () => {
    try {
      const uri = await captureRef(viewRef, {
        format: 'png',
        quality: 0.8,
      })
      await DownLoadImage(uri, displayName)
      closeModal()
    } catch (error) {
      console.error('Failed to capture view', error)
    }
  }

  const url = useMemo(() => {
    return toShareUrl(
      makeProfileLink({
        did: '',
        handle: handle,
      }),
    )
  }, [handle])

  // const onPressLink = useCallback(() => {
  //   shareUrl(url)
  //   closeModal()
  // }, [url, closeModal])

  const onPressTele = useCallback(() => {
    teleShare()
    closeModal()
  }, [closeModal])

  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!avatar) return
        const response = await fetch(avatar)
        const blob = await response.blob()
        const imgUrl = URL.createObjectURL(blob)
        setImageUrl(imgUrl)
      } catch (error) {
        console.error('Error fetching image:', error)
      }
    }

    fetchImage()
  }, [avatar])

  return (
    <View style={styles.page}>
      <View style={{position: 'relative', zIndex: 1}}>
        <TouchableOpacity
          accessibilityRole="button"
          style={{position: 'absolute', right: 'calc(9.866vw - 28px)', top: -6}}
          onPress={closeModal}>
          <CloseShareIcon color={t.palette.white} />
        </TouchableOpacity>
      </View>
      <View style={styles.out}>
        <ViewShot ref={viewRef} style={styles.viewShot}>
          <LinearGradient
            colors={['#DBFE0A', '#FCD010']}
            style={styles.outputBox}>
            <View style={{borderWidth: 3, borderRadius: 100, width: 100}}>
              <UserAvatar avatar={imageUrl} size={94} />
            </View>
            <View>
              <Text
                style={{
                  fontSize: 26,
                  fontWeight: 'bold',
                  color: '#000000',
                  paddingVertical: 10,
                }}>
                {displayName}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: '#000000',
                  textAlign: 'center',
                }}>{`@${formatHandler(handle)}`}</Text>
            </View>

            {description ? (
              <View style={styles.content}>
                <Text
                  numberOfLines={2}
                  style={{
                    fontSize: 14,
                    lineHeight: 21,
                    color: '#000000',
                    textAlign: 'center',
                  }}>
                  {description}
                </Text>
              </View>
            ) : (
              <View style={{width: 2, height: 42}} />
            )}

            <View
              style={{
                width: '100%',
                gap: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <View style={{flex: 1, paddingTop: 10}}>
                <Text
                  style={{
                    color: '#000000',
                    fontSize: 16,
                    marginTop: 12,
                    marginBottom: 9,
                    fontWeight: 'bold',
                    fontFamily: 'Damion',
                  }}>
                  <Trans>A realm without secrets</Trans>
                </Text>
                <Text style={{color: '#191916', fontSize: 12}}>
                  https://sipz.io
                </Text>
              </View>

              <View style={{marginTop: 20}}>
                <ShareQRCode url={url} />
              </View>
            </View>
          </LinearGradient>
        </ViewShot>
      </View>

      <View style={styles.ctrl}>
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={captureView}>
          <DownLoadIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Save to Device</Trans>
          </Text>
        </TouchableOpacity>
        {/* <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={onPressLink}>
          <ShareLinkIcon />
          <Text style={{color: t.palette.white}}>
            <Trans>Copy Link</Trans>
          </Text>
        </TouchableOpacity> */}
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.button}
          onPress={onPressTele}>
          <ShareLinkIcon />
          <Text style={styles.text}>
            <Trans>Share</Trans>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: 'auto',
    padding: 10,
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  out: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  outputBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '80.267vw',
    padding: 24,
    paddingTop: 50,
    borderRadius: 16,
  },
  userInfo: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  content: {
    position: 'relative',
    marginTop: 10,
    paddingBottom: 10,
  },
  viewShot: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctrl: {
    padding: 20,
    flexDirection: 'row',
    gap: 50,
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    gap: 10,
  },
  text: {
    color: '#fff',
  },
})

export async function DownLoadImage(uri: string, imgName: string) {
  const getCanvas = (base64: string): Promise<HTMLCanvasElement> => {
    return new Promise(resolve => {
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height

        const ctx = canvas.getContext('2d')
        ctx?.drawImage(image, 0, 0)
        resolve(canvas)
      }
      image.src = base64
    })
  }

  const canvas = await getCanvas(uri)
  const imgHref = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream')

  const link = document.createElement('a')
  link.setAttribute('download', `${imgName.replaceAll(' ', '_')}.png`)
  link.setAttribute('href', imgHref)
  link.click()
}

export async function copyImageToClipboard(uri: string) {
  const getCanvas = (base64: string): Promise<HTMLCanvasElement> => {
    return new Promise(resolve => {
      const image = new Image()
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height

        const ctx = canvas.getContext('2d')
        ctx?.drawImage(image, 0, 0)
        resolve(canvas)
      }
      image.src = base64
    })
  }

  const canvas = await getCanvas(uri)
  canvas.toBlob(async blob => {
    if (blob) {
      const item = new ClipboardItem({'image/png': blob})
      try {
        await navigator.clipboard.write([item])
      } catch (err) {}
    }
  }, 'image/png')
}
