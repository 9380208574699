import React from 'react'
import {TouchableWithoutFeedback, View} from 'react-native'
import Svg, {Defs, Path, Stop} from 'react-native-svg'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {FeedPostSlice} from '#/state/queries/post-feed'
import {usePostPreState} from '#/state/shell/post-pre-data'
// import {useComposerControls} from '#/state/shell'
import {useSelectedFeed} from '#/state/shell/selected-feed'
import {TelegramBindStep} from '../modals/TelegramModal/config'
import {useTelegramLink} from '../modals/TelegramModal/hooks'
import {useTwStore} from '../util/sdlStore/TwStore'
import {BigFigureCardItem} from './BigFigure/BigFigureCard'
import {useBigFigure} from './BigFigure/BigFigureProvider'
import {FeedItem} from './FeedItem'

export function OperationFeedItem({
  slice,
  hideTopBorder,
}: {
  slice: FeedPostSlice
  hideTopBorder: boolean
}) {
  const selectedFeed = useSelectedFeed()
  const {bind: hasBindTelegram} = useTelegramLink()
  const {openModal} = useModalControls()
  const {isBoundTw} = useTwStore()
  // const {openComposer} = useComposerControls()
  const navigation = useNavigation<NavigationProp>()
  const item = slice.items[0]
  const record = item?.record
  const isBigFigure = useBigFigure()
  const {setShowSpillToolTip} = usePostPreState()

  const onPressSpill = React.useCallback(async () => {
    if (selectedFeed?.includes('circle')) {
      if (!hasBindTelegram) {
        openModal({name: 'telegram'})
        return
      }
    } else {
      if (!hasBindTelegram && !isBoundTw) {
        openModal({name: 'link-social'})
        return
      }
    }
    setShowSpillToolTip(true)
    // openComposer({})
  }, [selectedFeed, setShowSpillToolTip, hasBindTelegram, openModal, isBoundTw])

  const onPressInvitation = React.useCallback(() => {
    if (hasBindTelegram) {
      openModal({name: 'telegram', currentStep: TelegramBindStep.SelectContact})
    } else {
      openModal({name: 'telegram'})
    }
  }, [openModal, hasBindTelegram])

  const onPressCircle = () => {
    navigation.navigate('CircleCreate')
  }

  const onPressOperation = () => {
    console.log(':test ', record)
    if (!record) return
    if (record.operationType === '1') {
      onPressSpill()
    } else if (record.operationType === '2') {
      onPressInvitation()
    } else if (record.operationType === '3') {
      onPressCircle()
    }
  }

  return (
    !!record && (
      <View testID="operation-feed-item">
        <TouchableWithoutFeedback
          accessibilityRole="button"
          onPress={onPressOperation}>
          {!!item && (
            <View style={{cursor: 'pointer'}}>
              <View pointerEvents="none">
                {isBigFigure ? (
                  <BigFigureCardItem
                    key={item._reactKey}
                    post={item.post}
                    reason={undefined}
                    feedContext={undefined}
                    record={item.record}
                    moderation={item.moderation}
                    parentAuthor={item.parentAuthor}
                    showReplyTo={false}
                    isThreadParent={false}
                    isThreadChild={false}
                    isThreadLastChild={false}
                    isParentBlocked={item.isParentBlocked}
                    hideTopBorder={hideTopBorder}
                    isOperation={true}
                  />
                ) : (
                  <FeedItem
                    key={item._reactKey}
                    post={item.post}
                    record={item.record}
                    reason={slice.reason}
                    feedContext={slice.feedContext}
                    moderation={item.moderation}
                    parentAuthor={item.parentAuthor}
                    showReplyTo={false}
                    isThreadParent={false}
                    isThreadChild={false}
                    isThreadLastChild={false}
                    isParentBlocked={item.isParentBlocked}
                    hideTopBorder={hideTopBorder}
                    isOperation={true}
                  />
                )}
              </View>
            </View>
          )}
        </TouchableWithoutFeedback>
      </View>
    )
  )
}

export const TipIcon = (props: {color: string; size?: number}) => (
  <Svg
    width={props?.size ?? 16}
    height={props?.size ?? 16}
    viewBox="0 0 16 16"
    fill={props.color}>
    <Path
      fillRule="evenodd"
      d="M5.333 1.833c-2.666 0-4 1.334-4 4V14.5c0 .367.3.667.667.667h8.667c2.666 0 4-1.334 4-4V5.833c0-2.666-1.334-4-4-4H5.333Zm-.666 8.834a.504.504 0 0 1-.5-.5c0-.274.226-.5.5-.5h4.666c.274 0 .5.226.5.5 0 .273-.226.5-.5.5H4.667Zm0-3.334a.504.504 0 0 1-.5-.5c0-.273.226-.5.5-.5h6.666c.274 0 .5.227.5.5 0 .274-.226.5-.5.5H4.667Z"
      clipRule="evenodd"
    />
  </Svg>
)

export const VipIcon = () => (
  // <Svg width={18} height={18} viewBox="0 0 18 18" fill="none">
  //   <Path
  //     fill="#FCD010"
  //     d="M16.526 8.154 14.98 6.608V4.216a1.2 1.2 0 0 0-1.196-1.196h-2.392L9.846 1.474a1.2 1.2 0 0 0-1.692 0L6.608 3.02H4.216A1.2 1.2 0 0 0 3.02 4.216v2.392L1.474 8.154a1.2 1.2 0 0 0 0 1.692l1.546 1.546v2.392a1.2 1.2 0 0 0 1.196 1.196h2.392l1.546 1.546a1.2 1.2 0 0 0 1.692 0l1.546-1.546h2.392a1.2 1.2 0 0 0 1.196-1.196v-2.392l1.546-1.546a1.2 1.2 0 0 0 0-1.692Z"
  //   />
  //   <Path
  //     fill="#fff"
  //     stroke="#fff"
  //     strokeWidth={0.3}
  //     d="m9 12.938-3.375-6.75h1.688L9 9.869l1.688-3.681h1.687L9 12.938Z"
  //   />
  // </Svg>
  <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <Path
      d="M8 0.5L9.8635 1.54533L12 1.5718L13.0912 3.40883L14.9282 4.5L14.9547 6.6365L16 8.5L14.9547 10.3635L14.9282 12.5L13.0912 13.5912L12 15.4282L9.8635 15.4547L8 16.5L6.1365 15.4547L4 15.4282L2.90883 13.5912L1.0718 12.5L1.04533 10.3635L0 8.5L1.04533 6.6365L1.0718 4.5L2.90883 3.40883L4 1.5718L6.1365 1.54533L8 0.5Z"
      fill="url(#paint0_linear_614_54642)"
    />
    <Path
      d="M11.3585 5.56553L9.47084 5.74738C9.35098 5.7589 9.22167 5.86045 9.18211 5.97411L7.97407 9.44265L6.76013 5.97382C6.72057 5.86045 6.59097 5.7589 6.47141 5.74738L4.58377 5.56553C4.46421 5.55401 4.40014 5.63638 4.44059 5.74974L6.81534 12.3529C6.85608 12.466 6.98686 12.5581 7.10701 12.5581H8.95568C8.97723 12.5581 8.99524 12.5522 9.00852 12.5419C9.04539 12.5243 9.07434 12.4935 9.08971 12.4557L9.10122 12.4238L11.5014 5.74974C11.5421 5.63638 11.478 5.55401 11.3585 5.56553Z"
      fill="white"
    />
    <Defs>
      <linearGradient
        id="paint0_linear_614_54642"
        x1="8"
        y1="0.5"
        x2="8"
        y2="16.5"
        gradientUnits="userSpaceOnUse">
        <Stop offset="0.085" stopColor="#FFA800" />
        <Stop offset="1" stopColor="#FF6B00" />
      </linearGradient>
    </Defs>
  </Svg>
)
