import {ComAtprotoServerDefs} from '@atproto/api'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {useAgent, useSession} from '../session'

export const RQKEY_ROOT = 'claimToWallet'
export const RQKEY = () => [RQKEY_ROOT]

export const CLAIMTOWALLET_RQKEY = {
  withdrawRecords: RQKEY_ROOT + '-withdrawRecords',
  withdrawRecord: RQKEY_ROOT + '-withdrawRecord',
  receiveAddress: RQKEY_ROOT + '-receiveAddress',
  withdrawBalance: RQKEY_ROOT + '-withdrawBalance',
  actorMagnification: RQKEY_ROOT + '-actorMagnification',
}

export function useActorMagnificationQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<number, Error>({
    queryKey: [CLAIMTOWALLET_RQKEY.actorMagnification],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getActorMagnification()
      return Number(res.data.magnification ?? 0)
    },
    enabled: !!currentAccount,
  })
}

export function useWithdrawBalanceQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<number, Error>({
    queryKey: [CLAIMTOWALLET_RQKEY.withdrawBalance],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getWithdrawBalance()
      return Number(res.data.withdrawBalance ?? 0)
    },
    enabled: !!currentAccount,
  })
}

export function useReceiveAddressQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<string, Error>({
    queryKey: [CLAIMTOWALLET_RQKEY.receiveAddress],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getReceiveAddress()
      return res.data.receiveAddress ?? ''
    },
    enabled: !!currentAccount,
  })
}

export function useWithdrawRecordsQuery() {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<ComAtprotoServerDefs.WithdrawRecord[], Error>({
    queryKey: [CLAIMTOWALLET_RQKEY.withdrawRecords],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getWithdrawRecords()
      return res.data.withdrawRecords ?? []
    },
    enabled: !!currentAccount,
  })
}

export function useWithdrawRecordQuery(withdrawId: number) {
  const agent = useAgent()
  const {currentAccount} = useSession()
  return useQuery<ComAtprotoServerDefs.WithdrawRecord | undefined, Error>({
    queryKey: [CLAIMTOWALLET_RQKEY.withdrawRecord, withdrawId],
    queryFn: async () => {
      const res = await agent.com.atproto.server.getWithdrawRecord({withdrawId})
      return res.data.withdrawRecord
    },
    enabled: !!currentAccount,
  })
}

export function useSaveReceiveAddressMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async (walletAddress: string) => {
      return await agent.com.atproto.server.saveReceiveAddress({
        walletAddress,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [CLAIMTOWALLET_RQKEY.receiveAddress],
      })
    },
  })
}

export function useWithdrawRewardMutation() {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async () => {
      return await agent.com.atproto.server.withdrawReward({})
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [CLAIMTOWALLET_RQKEY.withdrawRecord],
      })
    },
  })
}
