import React from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'

import {useTheme} from '#/alf'

interface ISdlButton {
  text?: string
  onPress: () => void
  type?: 'primary' | 'secondary'
  disabled?: boolean
  style?: any
  children?: any
  bgStyle?: any
  textStyle?: any
}

export function SdlButton({
  text,
  disabled,
  onPress,
  type = 'primary',
  style,
  children,
  bgStyle,
  textStyle,
}: ISdlButton) {
  const t = useTheme()

  const dynamicStyle = {
    backgroundColor: type === 'primary' ? t.palette.primary : t.palette.gray,
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      accessibilityRole="button"
      style={[{padding: 2, flex: 1}, style]}
      onPress={onPress}>
      <View style={[styles.body, dynamicStyle, bgStyle]}>
        {children}
        <Text style={[styles.text, textStyle]}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: 'black',
  },
  body: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14.5,
    borderRadius: 100,
  },
})
