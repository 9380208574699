import React from 'react'
import {Text, View} from 'react-native'

// import {MemberProps} from '.'
import {StackAvatar} from '#/view/com/post-thread/PostStakeDonateItem'
import {atoms as a, useTheme} from '#/alf'

type Props = {
  count?: number
  members?: string[]
}
export function CircleMembers({count, members}: Props) {
  const t = useTheme()
  return (
    <View style={[a.flex_row, a.align_center, a.gap_xs]}>
      {(members || [])?.length > 0 && (
        <StackAvatar
          data={(members ?? [])?.map(m => m ?? '')}
          maxCount={4}
          bgColor={t.atoms.bg.backgroundColor}
        />
      )}
      {count && count > 0 ? (
        <Text style={[a.text_xs, t.atoms.text_sub]}>{count} Members</Text>
      ) : null}
    </View>
  )
}
