import {createSinglePathSVG} from './TEMPLATE'

export const Person_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM7.5 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM5.678 19h12.644c-.71-2.909-3.092-5-6.322-5s-5.613 2.091-6.322 5Zm-2.174.906C3.917 15.521 7.242 12 12 12c4.758 0 8.083 3.521 8.496 7.906A1 1 0 0 1 19.5 21h-15a1 1 0 0 1-.996-1.094Z',
})

export const PersonCheck_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM7.5 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM5.679 19c.709-2.902 3.079-5 6.321-5a6.69 6.69 0 0 1 2.612.51 1 1 0 0 0 .776-1.844A8.687 8.687 0 0 0 12 12c-4.3 0-7.447 2.884-8.304 6.696-.29 1.29.767 2.304 1.902 2.304H11a1 1 0 1 0 0-2H5.679Zm14.835-4.857a1 1 0 0 1 .344 1.371l-3 5a1 1 0 0 1-1.458.286l-2-1.5a1 1 0 0 1 1.2-1.6l1.113.835 2.43-4.05a1 1 0 0 1 1.372-.342Z',
})

export const PersonX_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM7.5 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM5.679 19c.709-2.902 3.079-5 6.321-5 .302 0 .595.018.878.053a1 1 0 0 0 .243-1.985A9.235 9.235 0 0 0 12 12c-4.3 0-7.447 2.884-8.304 6.696-.29 1.29.767 2.304 1.902 2.304H12a1 1 0 1 0 0-2H5.679Zm9.614-3.707a1 1 0 0 1 1.414 0L18 16.586l1.293-1.293a1 1 0 0 1 1.414 1.414L19.414 18l1.293 1.293a1 1 0 0 1-1.414 1.414L18 19.414l-1.293 1.293a1 1 0 0 1-1.414-1.414L16.586 18l-1.293-1.293a1 1 0 0 1 0-1.414Z',
})

export const PersonPlus_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM7.5 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM5.678 19c.71-2.909 3.092-5 6.322-5 .621 0 1.206.077 1.748.218a1 1 0 1 0 .504-1.936A8.931 8.931 0 0 0 12 12c-4.758 0-8.083 3.521-8.496 7.906A1 1 0 0 0 4.5 21H11a1 1 0 1 0 0-2H5.678ZM18 14a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Z',
})

export const PersonPlus_Filled_Stroke2_Corner0_Rounded = createSinglePathSVG({
  viewBox: '0 0 20 20',
  path: 'M13.52 16.823H2.767A.77.77 0 0 1 2 16.055v-.73c0-2.572 2.152-4.645 4.645-4.645h2.957c2.534 0 4.684 2.073 4.684 4.645v.73a.77.77 0 0 1-.768.768h.001ZM8.142 9.912A3.456 3.456 0 1 1 11.6 6.456a3.44 3.44 0 0 1-3.456 3.456Zm6.35-3.05V5.71a.768.768 0 1 1 1.536 0V6.86h1.152a.768.768 0 0 1 0 1.536h-1.152V9.55a.766.766 0 0 1-.768.77.769.769 0 0 1-.769-.77v-1.15h-1.151a.768.768 0 1 1 0-1.537h1.151Z',
})
export const Person3 = createSinglePathSVG({
  viewBox: '0 0 16 16',
  path: 'M7.20996 7.77H7.22996H7.26996H7.47996C9.43996 7.7 10.98 6.11 10.98 4.14C10.98 2.17 9.34996 0.5 7.33996 0.5C6.36996 0.5 5.44996 0.88 4.76996 1.57C4.07996 2.26 3.70996 3.17 3.70996 4.14C3.70996 6.11 5.24996 7.7 7.20996 7.77ZM5.47996 2.27C5.97996 1.77 6.63996 1.5 7.34996 1.5C8.79996 1.5 9.98996 2.68 9.98996 4.14C9.98996 5.6 8.87996 6.72 7.51996 6.77H7.35996H7.22996C5.81996 6.71 4.71996 5.56 4.71996 4.14C4.71996 3.44 4.98996 2.78 5.48996 2.28L5.47996 2.27ZM13.47 12.5H11.97V11C11.97 10.72 11.75 10.5 11.47 10.5C11.19 10.5 10.97 10.72 10.97 11V12.5H9.46997C9.18997 12.5 8.96997 12.72 8.96997 13C8.96997 13.28 9.18997 13.5 9.46997 13.5H10.97V15C10.97 15.28 11.19 15.5 11.47 15.5C11.75 15.5 11.97 15.28 11.97 15V13.5H13.47C13.75 13.5 13.97 13.28 13.97 13C13.97 12.72 13.75 12.5 13.47 12.5ZM4.08002 13.6097C4.94002 14.1797 6.10002 14.4997 7.34002 14.4997C7.62002 14.4997 7.84002 14.7197 7.84002 14.9997C7.84002 15.2797 7.62002 15.4997 7.34002 15.4997C5.90002 15.4997 4.54002 15.1197 3.52002 14.4397C2.55002 13.7897 2.02002 12.8997 2.02002 11.9397C2.02002 10.9897 2.56002 10.1097 3.52002 9.45967C5.40002 8.19967 8.32002 8.03967 10.47 9.06967C10.72 9.18967 10.83 9.48967 10.71 9.73967C10.59 9.99967 10.28 10.0997 10.04 9.97967C8.22002 9.10967 5.66002 9.24967 4.08002 10.2997C3.40002 10.7497 3.02002 11.3397 3.02002 11.9497C3.02002 12.5597 3.39002 13.1497 4.08002 13.6097Z',
})
