import React, {useEffect} from 'react'
import {
  Image,
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Swiper from 'react-native-web-swiper'
import {LinearGradient} from 'expo-linear-gradient'
import {AppBskyFeedDefs, AtUri} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'

import {Shadow} from '#/state/cache/post-shadow'
import {useBet} from '#/state/transaction/bet'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import {CircleChevronRightIcon, EditIcon} from '#/components/icons/StakeIcons'
import {UserAvatar} from '../util/UserAvatar'

const postChallengeBg = require('../../../../assets/imgs/post_challenge_tab_bg.png')
const challengeTitle = require('../../../../assets/imgs/chanllenge_title.png')

type ListProps = {
  replyAvatar: string
  replyName: string
  replyContent: string
  replyTime: string
  replyLength: number
}
export const PostChallenge = ({
  post,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
}) => {
  const t = useTheme()
  const list: ListProps[] = [
    // {
    //   replyAvatar: '',
    //   replyName: 'Degen Dog',
    //   replyContent:
    //     'Please provide evidence. As far as I know, no one else in the community...',
    //   replyTime: '2m',
    //   replyLength: 0,
    // },
    // {
    //   replyAvatar: '',
    //   replyName: 'Degen Dog',
    //   replyContent:
    //     'Please provide evidence. As far as I know, no one else in the community...',
    //   replyTime: '2m',
    //   replyLength: 2,
    // },
  ]

  const {showBetModal, amount, response} = useBet({
    type: 'challenge',
    rKey: new AtUri(post.uri).rkey,
    transPostAuthor: {
      did: post.author.did,
      payWallet: '',
      avatar: post.author.avatar ?? '',
      displayName:
        post.author.displayName && post.author.displayName !== ''
          ? post.author.displayName
          : formatHandler(post.author.handle ?? ''),
    },
    betOnOwnerTotal: 3000,
  })

  const renderItemInner = React.useCallback(
    ({item}: {item: ListProps}) => {
      return (
        <View
          style={[
            a.pr_xl,
            a.pl_2xl,
            a.mt_lg,
            a.flex_row,
            a.align_center,
            a.justify_center,
            {height: 116, backgroundColor: t.palette.white, borderRadius: 20},
          ]}>
          <View style={[a.align_start, a.justify_start, a.mr_md, a.ml_xl]}>
            <UserAvatar type="user" size={44} avatar={''} />
          </View>
          <View style={[a.flex_col, a.mr_xl, a.gap_xs]}>
            <Text style={[a.text_md, a.font_bold, a.mb_xs]}>
              {item?.replyName}
              <Text
                style={[
                  a.text_xs,
                  a.font_normal,
                  {color: t.palette.contrast_200},
                ]}>
                · {item?.replyTime}
              </Text>
            </Text>
            <Text
              style={[a.flex_1, a.text_sm, a.font_normal, styles.replyContent]}
              numberOfLines={2}>
              {item?.replyContent}
            </Text>
            <Text
              style={[
                a.text_xs,
                {
                  color:
                    item?.replyLength > 0
                      ? t.palette.yellow_25
                      : t.palette.contrast_200,
                },
              ]}>
              {item?.replyLength <= 0
                ? 'No replies yet'
                : `${item?.replyLength} replies`}
            </Text>
          </View>
        </View>
      )
    },
    [t.palette.contrast_200, t.palette.white, t.palette.yellow_25],
  )

  useEffect(() => {
    if (response?.success) {
    }
  }, [response])

  console.log('chaneleltab', amount, response)

  return (
    <View>
      <ImageBackground
        source={postChallengeBg}
        resizeMode="cover"
        style={styles.postBetBg}>
        <LinearGradient
          colors={['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0)']}
          style={[a.absolute, a.inset_0, {height: '100%'}]}
        />
        {list?.length > 0 ? (
          <View style={[a.p_lg]}>
            <View style={[a.flex_row, a.justify_between, a.align_center]}>
              <Image
                source={challengeTitle}
                style={[{width: 93, height: 33}]}
                accessibilityIgnoresInvertColors
              />
              <View style={[a.flex_row, a.align_center, a.gap_md]}>
                <View
                  style={[
                    styles.challengeTotal,
                    a.flex_row,
                    a.gap_xs,
                    {backgroundColor: t.palette.black},
                  ]}>
                  <Text
                    style={[
                      a.text_sm,
                      a.font_semibold,
                      {color: t.palette.white},
                    ]}>
                    Total:{' '}
                    <Text style={[a.text_md, a.font_heavy]}>{list.length}</Text>
                  </Text>
                  <FontAwesomeIcon
                    icon={'angle-right'}
                    style={{color: t.palette.white}}
                  />
                </View>
                <Pressable accessibilityRole="button" onPress={showBetModal}>
                  <EditIcon />
                </Pressable>
              </View>
            </View>
            <Text style={[a.text_sm, a.font_semibold]}>
              Question or present evidence to force the spiller to respond.
            </Text>
            {/* @ts-ignore  */}
            <Swiper
              from={0}
              minDistanceForAction={0.2}
              controlsProps={{
                dotsTouchable: true,
                dotsPos: false,
                prevPos: 'left',
                nextPos: 'right',
                /* eslint-disable */
                // @ts-ignore
                PrevComponent: ({onPress}) => (
                  <TouchableOpacity onPress={onPress}>
                    <CircleChevronRightIcon
                      size={20}
                      style={{transform: 'rotate(180deg)'}}
                    />
                  </TouchableOpacity>
                ),
                /* eslint-disable */
                // @ts-ignore
                NextComponent: ({onPress}) => (
                  <TouchableOpacity onPress={onPress}>
                    <CircleChevronRightIcon size={20} />
                  </TouchableOpacity>
                ),
              }}
              innerContainerStyle={[
                {
                  height: 130,
                },
              ]}>
              {list?.map((item: ListProps) => {
                return renderItemInner({item})
              })}
            </Swiper>
          </View>
        ) : (
          <View style={[a.flex_col, a.justify_center, a.align_center, a.mt_md]}>
            <Image
              source={challengeTitle}
              style={[{width: 160, height: 58}]}
              accessibilityIgnoresInvertColors
            />
            <View>
              <Text
                style={[a.text_md, a.font_semibold, a.text_center, a.mb_md]}>
                Question or present evidence to force the spiller to respond.
              </Text>
            </View>
            <Pressable onPress={showBetModal} style={[a.mb_lg]}>
              <EditIcon />
            </Pressable>
          </View>
        )}
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  postBetBg: {
    flex: 1,
    justifyContent: 'center',
  },
  typeIcon: {
    width: 30,
    height: 30,
    position: 'absolute',
  },
  arrowIcon: {
    width: 10,
    height: 10,
    marginLeft: 2,
    zIndex: 2,
  },
  dseliIcon: {
    position: 'absolute',
    zIndex: 2,
  },
  challengeTotal: {
    paddingHorizontal: 7,
    borderRadius: 30,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  replyContent: {
    textAlign: 'auto',
  },
})
