import React, {useState} from 'react'
import {Pressable, Text, TextInput, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {Tag} from '#/view/com/tags/TagItem'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {useDialogControl} from '#/components/Dialog'
import * as Dialog from '#/components/Dialog'
import * as Toggle from '#/components/forms/Toggle'
import {PencilLine_Stroke2_Corner0_Rounded as Pencil} from '#/components/icons/Pencil'

export function TagListItem({
  selected,
  tag,
  onSelected,
}: {
  selected?: boolean
  tag: Tag
  onSelected?: (selected: boolean, tag: Tag) => void
}) {
  const t = useTheme()
  const editControl = useDialogControl()
  const {isDesktop} = useWebMediaQueries()

  const values = selected ? [tag?.name] : []

  const [selectedRecord, setSelectedRecord] = useState<Tag | undefined>(
    undefined,
  )
  const [name, setName] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleSelected = (value: Tag) => {
    onSelected?.(!selected, value)
  }

  const handlerEdit = () => {
    console.log('handlerEdit', selectedRecord)
  }

  return (
    <View style={[a.flex_row, a.align_center, a.justify_between, {height: 44}]}>
      <View style={[a.flex_row, a.align_center, a.gap_md]}>
        <Toggle.Group
          type="checkbox"
          label="Tag"
          values={values}
          onChange={() => handleSelected(tag)}>
          <Toggle.Item name={tag.name} label="">
            <Toggle.Checkbox
              style={[t.atoms.checkbox_border, {borderWidth: 1.2}]}
            />
          </Toggle.Item>
        </Toggle.Group>
        <View
          style={[
            a.flex_1,
            a.flex_row,
            a.align_center,
            a.justify_center,
            a.gap_md,
          ]}>
          <Text style={[t.atoms.text, a.text_md, a.text_center]}>
            <Trans>{tag.name}</Trans>
          </Text>
        </View>
      </View>
      <View style={[a.flex_row, a.align_center, a.gap_lg]}>
        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => {
            setSelectedRecord(tag)
            setName(tag?.name)
            editControl.open()
          }}>
          <Pencil size="sm" style={t.atoms.text_sub} />
        </Pressable>
      </View>

      {/* Edit-Dialog */}
      <Dialog.Outer control={editControl}>
        <Dialog.Inner
          label=""
          style={[{minHeight: 250}, a.justify_between, a.flex_col, a.p_lg]}>
          <View
            style={[a.flex_row, a.align_center, a.justify_center, a.mb_2xl]}>
            <Text style={[a.text_lg, a.font_bold, t.atoms.text]}>
              <Trans>Rename</Trans>
            </Text>
          </View>
          <View style={[a.flex_1]}>
            <View
              style={[
                a.rounded_sm,
                a.px_md,
                t.atoms.input_border,
                t.atoms.input_bg,
                {borderWidth: 1},
              ]}>
              <TextInput
                testID="tagRename"
                style={[t.atoms.text, a.text_md, {height: 48, lineHeight: 48}]}
                placeholderTextColor={t.atoms.text_sub.color}
                multiline
                value={name}
                onChangeText={v => {
                  setName(v)
                  setError('')
                }}
                accessible={true}
              />
            </View>
            {error && (
              <Text
                style={[error ? t.atoms.text_danger : t.atoms.text, a.m_md]}>
                <Trans>{error}</Trans>
              </Text>
            )}
          </View>
          <View style={[a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
            <Button
              style={[a.p_md, isDesktop ? a.flex_1 : {}]}
              color="primary"
              onPress={() => {
                handlerEdit()
                editControl.close()
              }}
              label="Confirm"
              variant="solid"
              size="large">
              <ButtonText>Confirm</ButtonText>
            </Button>
            <Button
              style={[a.p_md, isDesktop ? a.flex_1 : {}]}
              color="secondary"
              onPress={() => {
                editControl.close()
              }}
              label="reset"
              variant="solid"
              size="large">
              <ButtonText>Cancel</ButtonText>
            </Button>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
    </View>
  )
}
