import React from 'react'
import {ScrollView, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {RouteProp, useRoute} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {CommonNavigatorParams} from '#/lib/routes/types'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {Loader} from '#/components/Loader'
import CircleRequestItem from './CircleRequestItem'
import {Circle, useCircleRequestList} from './hooks'

export default function CircleRequestList() {
  const {_} = useLingui()
  const queryClient = useQueryClient()
  const route =
    useRoute<RouteProp<CommonNavigatorParams, 'CircleRequestList'>>()
  const {
    data: requestList = [],
    isFetching,
    error,
  } = useCircleRequestList(route?.params?.id)

  let content
  if (isFetching) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_5xl]}>
        <Loader size="lg" />
      </View>
    )
  } else if (error) {
    content = (
      <View style={[a.gap_md, a.justify_center, a.align_center]}>
        <EmptyBox icon="heart" message="has no request yet." />
        <Button
          style={{width: 180, marginTop: 'auto', marginBottom: 10}}
          label="circle detail"
          color="primary"
          variant="solid"
          size="medium"
          onPress={() =>
            queryClient.invalidateQueries({queryKey: [Circle.Request]})
          }>
          <ButtonText>
            <Trans>Try again</Trans>
          </ButtonText>
        </Button>
      </View>
    )
  } else if (requestList?.length) {
    content = requestList.map(item => (
      <CircleRequestItem key={item.approveId} {...item} />
    ))
  } else {
    content = <EmptyBox icon="tea" message="has no request yet." />
  }

  // console.log('requestList', requestList)

  return (
    <CenteredView>
      <ViewHeader title={_(msg`Circle Request`)} showOnDesktop />
      <ScrollView>
        <View style={[a.py_lg, a.gap_md]}>{content}</View>
      </ScrollView>
    </CenteredView>
  )
}
