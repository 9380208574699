import React from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {ComAtprotoServerGetCircleUsers} from '@atproto/api'
import {Trans} from '@lingui/macro'

import {UserAvatar} from '#/view/com/util/UserAvatar'
import {atoms as a, useTheme} from '#/alf'
import {IconCircle} from '#/components/IconCircle'
import {Ellipsis} from '#/components/icons/Ellipsis'
import {ArrowUpLeftIcon} from '#/components/icons/StakeIcons'
import {Telegram} from '#/components/icons/Telegram'

export function CircleMemberItem({
  item,
  onDelete,
  isOwner,
  isCircleOwner,
}: {
  item: ComAtprotoServerGetCircleUsers.User
  onDelete?: (value: ComAtprotoServerGetCircleUsers.User) => void
  isOwner: boolean
  isCircleOwner?: boolean
}) {
  const t = useTheme()
  const joinPending = item?.status !== 'join'
  const isOpacity = joinPending

  return (
    <View style={[styles.page, a.gap_md]}>
      <View style={[a.flex_1, a.flex_row, a.align_center]}>
        <View>
          <UserAvatar
            size={44}
            avatar={(item.avatar as {url: string})?.url}
            type="user"
            name={item.user_name}
            style={isOpacity && {opacity: 0.4}}
          />
          {item.source_type === 'telegram' && (
            <IconCircle
              icon={Telegram}
              size="sm"
              iconStyle={[
                {color: '#fff', padding: 2, marginLeft: -1, marginBottom: -1},
              ]}
              style={[
                {
                  width: 16,
                  height: 16,
                  borderWidth: 1,
                  borderColor: t.atoms.text.color,
                  backgroundColor: '#25a3e1',
                  right: -2,
                  bottom: 0,
                  position: 'absolute',
                },
                isOpacity && {opacity: 0.4},
              ]}
            />
          )}
        </View>

        <View style={[a.flex_1, styles.userInfo]}>
          <View style={[a.flex_row, a.align_center]}>
            <Text style={[styles.userName, t.atoms.text]} numberOfLines={1}>
              {item.user_name}
            </Text>
            {isOwner && (
              <View style={[styles.tag]}>
                <Text style={[a.text_xs, {color: t.palette.white}]}>
                  <Trans>Owner</Trans>
                </Text>
              </View>
            )}
          </View>
          <Text style={[styles.userHandle, t.atoms.text_sub]} numberOfLines={1}>
            @{item.user_name}
          </Text>
        </View>
      </View>
      {joinPending ? (
        <View style={[a.flex_row, a.gap_xs]}>
          <ArrowUpLeftIcon style={{marginTop: 2}} />
          <Text
            style={[a.text_xs, a.font_semibold, {color: t.palette.gray_10}]}>
            <Trans>Join pending</Trans>
          </Text>
        </View>
      ) : (
        isCircleOwner && (
          <TouchableOpacity
            accessibilityRole="button"
            onPress={() => onDelete?.(item)}>
            <Ellipsis style={[t.atoms.text]} />
          </TouchableOpacity>
        )
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    // paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  userInfo: {
    marginLeft: 10,
    paddingVertical: 3,
    justifyContent: 'space-between',
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  userHandle: {
    fontSize: 12,
  },
  tag: {
    marginLeft: 5,
    backgroundColor: '#57bc5f',
    padding: 4,
    borderRadius: 4,
  },
})
