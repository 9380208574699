import React, {memo, useMemo} from 'react'
import {Pressable, StyleSheet, View} from 'react-native'
import {
  AppBskyEmbedImages,
  AppBskyEmbedRecord,
  AppBskyEmbedRecordWithMedia,
  AppBskyFeedDefs,
  AppBskyFeedPost,
  AtUri,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {isReasonFeedSource} from '#/lib/api/feed/types'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {POST_TOMBSTONE, Shadow, usePostShadow} from '#/state/cache/post-shadow'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {useRequireAuth, useSession} from '#/state/session'
import {useThemePrefs} from '#/state/shell'
import {useComposerControls} from '#/state/shell/composer'
import {usePalette} from 'lib/hooks/usePalette'
import {makeAnonymousProfileLink, makeProfileLink} from 'lib/routes/links'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {sanitizeHandle} from 'lib/strings/handles'
import {precacheProfile} from 'state/queries/profile'
import {BigFigureHotIcon} from '#/view/icons/FeedIcons'
import {CircleMemberStackAvatar} from '#/view/screens/Circle/CircleMemberStackAvatar'
import {formatHandler} from '#/screens/Onboarding/util'
import {FONT_SIZE_CONFIG} from '#/screens/Settings/TextSizeSettings'
import {atoms as a, useTheme} from '#/alf'
import {Repost_Stroke2_Corner2_Rounded as Repost} from '#/components/icons/Repost'
import {ProfileHoverCard} from '#/components/ProfileHoverCard'
import {RichText} from '#/components/RichText'
import {IGuarantors} from '../../post-thread/PostStakeDonateItem'
import {getIsOpreration} from '../../util/access/OperationUtil'
import {FeedNameText} from '../../util/FeedInfoText'
import {Link, TextLinkOnWebOnly} from '../../util/Link'
import {BigFigurePostCtrls} from '../../util/post-ctrls/BigFigurePostCtrls'
import {Text} from '../../util/text/Text'
import {BigFigurePostEmbeds} from '../BigFigure/BigFigurePostEmbed'
import {FeedItemProps} from '../FeedItem'
import {TipIcon} from '../OperationFeedItem'
import {TargetDisplay} from '../TargetDisplay'
import {useTranslationWidget} from '../TranslationWidget/TranslationWidget'
import {BigFigurePostMeta} from './BigFigurePostMeta'

export type BigFigureSizeMode = 'larger' | 'middle' | 'small'
export function BigFigureCardItem({
  post,
  record,
  reason,
  feedContext,
  moderation,
  parentAuthor,
  showReplyTo,
  isThreadChild,
  isThreadLastChild,
  isThreadParent,
  isParentBlocked,
  isOperation,
}: FeedItemProps & {
  post: AppBskyFeedDefs.PostView
}): React.ReactNode {
  const postShadowed = usePostShadow(post)
  const {translateTitle, richText, hasMore, showAutoTranslate} =
    useTranslationWidget({
      record,
      shouldTrimArticle: true,
      post,
    })

  if (postShadowed === POST_TOMBSTONE) {
    return null
  }
  if (record.operation) {
  }
  if (richText && moderation) {
    return (
      <BigFigureCard
        key={postShadowed.uri}
        post={postShadowed}
        record={{...record, title: translateTitle || record.title}}
        reason={reason}
        feedContext={feedContext}
        richText={richText}
        parentAuthor={parentAuthor}
        showReplyTo={showReplyTo}
        moderation={moderation}
        isThreadChild={isThreadChild}
        isThreadLastChild={isThreadLastChild}
        isThreadParent={isThreadParent}
        isParentBlocked={isParentBlocked}
        hasMore={hasMore}
        isOperation={isOperation}
        showAutoTranslate={showAutoTranslate}
      />
    )
  }
  return null
}

export function BigFigureCard({
  post,
  record,
  reason,
  feedContext,
  richText,
  moderation,
  // parentAuthor,
  // showReplyTo,
  isThreadChild,
  isThreadLastChild,
  isThreadParent,
  // isParentBlocked,
  // hasMore,
  isOperation,
  showAutoTranslate,
}: FeedItemProps & {
  richText: RichTextAPI
  hasMore: boolean
  showAutoTranslate: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
}) {
  const queryClient = useQueryClient()
  const {openComposer} = useComposerControls()
  const pal = usePalette('default')
  const t = useTheme()
  const {_} = useLingui()
  const {isDesktop} = useWebMediaQueries()

  // @ts-ignore
  const isAnonymousPost = post?.record?.hideAuthor === 'true' ? true : false
  const href = useMemo(() => {
    const urip = new AtUri(post.uri)
    if (isAnonymousPost) {
      return makeAnonymousProfileLink(post.author, 'post', urip.rkey)
    } else {
      return makeProfileLink(post.author, 'post', urip.rkey)
    }
  }, [post.uri, post.author, isAnonymousPost])
  const {sendInteraction} = useFeedFeedbackContext()

  const onPressReply = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#interactionReply',
      feedContext,
    })
    openComposer({
      replyTo: {
        uri: post.uri,
        cid: post.cid,
        text: record.text || '',
        author: post.author,
        embed: post.embed,
        moderation,
      },
    })
  }, [post, record, openComposer, moderation, sendInteraction, feedContext])

  const onBeforePress = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughItem',
      feedContext,
    })
    precacheProfile(queryClient, post.author)
  }, [queryClient, post, sendInteraction, feedContext])

  const outerStyles = [
    isDesktop ? styles.outer : a.px_lg,
    {
      paddingBottom:
        isThreadLastChild || (!isThreadChild && !isThreadParent)
          ? 8
          : undefined,
    },
  ]

  let sizeMode: BigFigureSizeMode = 'small'
  const maxLikeNum = Number(post?.maxLikeNumThreshold ?? 0)
  const minLikeNum = Number(post?.minLikeNumThreshold ?? 0)
  if (post.likeCount) {
    if (post.likeCount >= maxLikeNum) {
      sizeMode = 'larger'
    } else if (post.likeCount < maxLikeNum && post.likeCount >= minLikeNum) {
      sizeMode = 'middle'
    }
  }

  const requireAuth = useRequireAuth()
  const navigation = useNavigation<NavigationProp>()
  const guarantors = React.useMemo(
    () => (post?.guarantors as Array<IGuarantors>) || [],
    [post?.guarantors],
  )
  const onPressGuarantors = React.useCallback(() => {
    requireAuth(() => {
      navigation.navigate('BetList', {
        did: post.author.did,
        dataType: 'guarantors',
        rkey: new AtUri(post.uri).rkey,
      })
    })
  }, [navigation, requireAuth, post?.uri, post?.author?.did])

  const {currentAccount} = useSession()
  const isOwner =
    AppBskyFeedDefs.isReasonRepost(reason) &&
    reason.by.did === currentAccount?.did
  const isOperater = getIsOpreration(post.author.did)

  const onOpenReposter = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughReposter',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])

  const onOpenEmbed = React.useCallback(() => {
    sendInteraction({
      item: post.uri,
      event: 'app.bsky.feed.defs#clickthroughEmbed',
      feedContext,
    })
  }, [sendInteraction, post, feedContext])
  const targets = record?.finEntities || []

  return (
    <Link
      testID={`feedItem-by-${post.author.handle}`}
      style={outerStyles}
      href={href}
      noFeedback
      accessible={false}
      onBeforePress={onBeforePress}
      dataSet={{feedContext}}>
      <View style={{paddingTop: 16, flexShrink: 1}}>
        {isReasonFeedSource(reason) ? (
          <Link href={reason.href}>
            <Text
              type="sm-bold"
              style={pal.textLight}
              lineHeight={1.2}
              numberOfLines={1}>
              <Trans context="from-feed">
                From{' '}
                <FeedNameText
                  type="sm-bold"
                  uri={reason.uri}
                  href={reason.href}
                  lineHeight={1.2}
                  numberOfLines={1}
                  style={pal.textLight}
                />
              </Trans>
            </Text>
          </Link>
        ) : AppBskyFeedDefs.isReasonRepost(reason) ? (
          <Link
            style={styles.includeReason}
            href={makeProfileLink(reason.by)}
            title={
              isOwner
                ? _(msg`Reposted by you`)
                : _(
                    msg`Reposted by ${sanitizeDisplayName(
                      reason.by.displayName || sanitizeHandle(reason.by.handle),
                    )}`,
                  )
            }
            onBeforePress={onOpenReposter}>
            <Repost
              style={{color: pal.colors.textLight, marginRight: 3}}
              width={14}
              height={14}
            />
            <Text
              type="sm-bold"
              style={pal.textLight}
              lineHeight={1.2}
              numberOfLines={1}>
              {isOwner ? (
                <Trans>Reposted by you</Trans>
              ) : (
                <Trans>
                  Reposted by{' '}
                  <ProfileHoverCard inline did={reason.by.did}>
                    <TextLinkOnWebOnly
                      type="sm-bold"
                      style={pal.textLight}
                      lineHeight={1.2}
                      numberOfLines={1}
                      text={sanitizeDisplayName(
                        reason.by.displayName ||
                          formatHandler(sanitizeHandle(reason.by.handle)),
                        moderation.ui('displayName'),
                      )}
                      href={makeProfileLink(reason.by)}
                      onBeforePress={onOpenReposter}
                    />
                  </ProfileHoverCard>
                </Trans>
              )}
            </Text>
          </Link>
        ) : null}
      </View>

      <View
        style={[
          a.flex_col,
          a.p_lg,
          t.atoms.input_border,
          t.atoms.big_figure_bg,
          {borderWidth: 0, borderRadius: 30},
        ]}>
        <BigFigurePostHeaderContent
          post={post}
          record={record}
          moderation={moderation}
          sizeMode={sizeMode}
        />
        <View style={[styles.layout]}>
          <View style={styles.layoutContent}>
            {/* content */}
            <BigFigurePostContent
              moderation={moderation}
              richText={richText}
              postEmbed={post.embed}
              postAuthor={post.author}
              onOpenEmbed={onOpenEmbed}
              sizeMode={sizeMode}
              record={record}
            />
            <View
              style={[a.flex_row, a.align_center, a.justify_between, a.mb_sm]}>
              {/* Spill about */}
              {targets.length > 0 && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <TargetDisplay
                    targets={[targets[0]]}
                    isFromFeed
                    previewMode
                  />
                  {targets.length > 1 ? (
                    <Text
                      style={[
                        a.text_sm,
                        a.font_heavy,
                        t.atoms.text,
                        {color: '#696969'},
                      ]}>
                      +{targets.length - 1}
                    </Text>
                  ) : (
                    ''
                  )}
                </View>
              )}
              {/* guarantors */}
              {!!guarantors.length && (
                <Pressable
                  accessibilityRole="button"
                  style={[a.flex_row, a.align_center, a.gap_xs]}
                  onPress={onPressGuarantors}>
                  <CircleMemberStackAvatar
                    data={
                      guarantors?.map(m => {
                        return {
                          id: m?.did,
                          avatar: m?.avatar,
                        }
                      }) ?? []
                    }
                    size={24}
                    emptyIcon={<></>}
                    bgColor={t.atoms.bg.backgroundColor}
                    type="user"
                  />
                  <Text style={[a.text_xs, a.font_heavy, {color: '#696969'}]}>
                    {guarantors.length} <Trans>Guarantors</Trans>
                  </Text>
                </Pressable>
              )}
            </View>

            {(isOperation || isOperater) && (
              <View
                style={[
                  a.flex_row,
                  a.align_center,
                  a.gap_sm,
                  !isOperation && isOperater ? a.mb_lg : {},
                ]}>
                <View
                  style={[
                    a.flex_row,
                    a.align_center,
                    a.justify_center,
                    {width: 24, height: 24, backgroundColor: '#C28C00'},
                    a.rounded_full,
                  ]}>
                  <TipIcon {...t.atoms.text} size={14} />
                </View>
                <Text style={[t.atoms.text, a.font_heavy]}>
                  <Trans>Topic</Trans>
                </Text>
              </View>
            )}

            {isOperation ? null : (
              <BigFigurePostCtrls
                post={post}
                record={record}
                richText={richText}
                onPressReply={onPressReply}
                logContext="FeedItem"
                feedContext={feedContext}
                showAutoTranslate={showAutoTranslate}
                isFromFeed={true}
              />
            )}
          </View>
        </View>
      </View>
    </Link>
  )
}

let BigFigurePostHeaderContent = ({
  post,
  record,
  moderation,
  sizeMode,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  moderation: ModerationDecision
  sizeMode: BigFigureSizeMode
}): React.ReactNode => {
  const t = useTheme()
  const isAnonymousPost = record?.hideAuthor === 'true' ? true : false

  const {fontSizeMode} = useThemePrefs()
  const cardTitleFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.feed].feed.public.title
  }, [fontSizeMode])

  return (
    <View style={[a.relative]}>
      <BigFigurePostMeta
        author={post.author}
        moderation={moderation}
        showAvatar
        authorHasWarning={false}
        isQuoted={AppBskyEmbedRecord.isView(post.embed)}
        isAnonymous={isAnonymousPost}
      />
      {/* Hot Icon */}
      {sizeMode === 'larger' && (
        <View style={[a.absolute, {right: -16, top: -16}]}>
          <BigFigureHotIcon />
        </View>
      )}
      {/* Title */}
      {record.title && (
        <Text
          style={[
            a.mt_lg,
            t.atoms.text,
            sizeMode === 'larger' && styles.largerTitle,
            sizeMode === 'middle' && styles.middleTitle,
            sizeMode === 'small' && [
              styles.smallTitle,
              {fontSize: cardTitleFontSize},
            ],
            {backgroundColor: 'transparent', fontFamily: 'BigFigure'},
          ]}
          numberOfLines={2}>
          {record.title}
        </Text>
      )}
    </View>
  )
}
BigFigurePostHeaderContent = memo(BigFigurePostHeaderContent)
export {BigFigurePostHeaderContent}

let BigFigurePostContent = ({
  moderation,
  richText,
  postEmbed,
  postAuthor,
  onOpenEmbed,
  sizeMode,
  record,
}: {
  moderation?: ModerationDecision
  richText?: RichTextAPI
  postEmbed: AppBskyFeedDefs.PostView['embed']
  postAuthor: AppBskyFeedDefs.PostView['author']
  onOpenEmbed?: () => void
  sizeMode: BigFigureSizeMode
  record?: AppBskyFeedPost.Record
}): React.ReactNode => {
  const t = useTheme()
  const hasImage = AppBskyEmbedImages.isView(postEmbed)
  const isQuote =
    AppBskyEmbedRecord.isView(postEmbed) ||
    AppBskyEmbedRecordWithMedia.isView(postEmbed)

  const {fontSizeMode} = useThemePrefs()
  const cardTextFontSize = React.useMemo(() => {
    return FONT_SIZE_CONFIG[fontSizeMode.feed].feed.public.text
  }, [fontSizeMode])

  return (
    <View
      style={[
        sizeMode === 'larger' || isQuote
          ? [a.flex_col]
          : [a.align_center, a.flex_row_reverse, a.gap_md, a.justify_between],
      ]}>
      {/* Image */}
      {postEmbed && hasImage ? (
        <View
          style={[
            sizeMode === 'larger'
              ? [a.pt_lg, a.flex_1, {maxHeight: 350, overflow: 'hidden'}]
              : [a.flex_1, {maxHeight: 60, overflow: 'hidden'}],
          ]}>
          <BigFigurePostEmbeds
            embed={postEmbed}
            record={record}
            moderation={moderation}
            onOpen={onOpenEmbed}
          />
        </View>
      ) : null}
      {richText && richText.text ? (
        <View
          style={[
            styles.postTextContainer,
            sizeMode === 'larger' ? {} : {flex: 3},
          ]}>
          <RichText
            enableTags
            testID="postText"
            value={richText}
            numberOfLines={3}
            style={[
              a.flex_1,
              // a.text_md,
              t.atoms.post_content_feed,
              {fontSize: cardTextFontSize},
            ]}
            authorHandle={sanitizeHandle(postAuthor.handle)}
          />
        </View>
      ) : undefined}
      {/* quote post */}
      {postEmbed && isQuote ? (
        <View style={[a.w_full, a.overflow_hidden, a.mb_md]}>
          <BigFigurePostEmbeds
            embed={postEmbed}
            moderation={moderation}
            onOpen={onOpenEmbed}
            sizeMode={sizeMode}
          />
        </View>
      ) : null}
    </View>
  )
}
BigFigurePostContent = memo(BigFigurePostContent)
export {BigFigurePostContent}

const styles = StyleSheet.create({
  outer: {
    paddingHorizontal: 8,
    // @ts-ignore web only -prf
    cursor: 'pointer',
  },
  largerTitle: {
    // fontSize: 32,
    fontSize: 26,
    fontWeight: '900',
  },
  middleTitle: {
    fontSize: 26,
    fontWeight: '900',
  },
  smallTitle: {
    fontSize: 20,
    fontWeight: '900',
  },
  layout: {
    flexDirection: 'row',
    // marginTop: 1,
    // gap: 10,
  },
  layoutAvi: {
    position: 'relative',
    zIndex: 999,
  },
  layoutContent: {
    position: 'relative',
    flex: 1,
    zIndex: 0,
  },
  postTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 16,
    overflow: 'hidden',
  },
  embed: {
    marginBottom: 6,
  },
  includeReason: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 2,
    marginLeft: -18,
  },
})
