import {EmailInfo} from '@atproto/api/dist/client/types/com/atproto/server/getUserEmails'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'

import {useAgent} from '#/state/session'

export type AuditStatus = 0 | 1 | 2

export const useAuditQuery = () => {
  const agent = useAgent()
  const res = useQuery({
    queryKey: ['getUserEmails'],
    queryFn: async () => {
      return agent.com.atproto.server.getUserEmails({
        limit: 1000,
        offset: 0,
      })
    },
  })
  let list: any[] = []
  const emailInfo = res.data?.data.emailInfo
  if (emailInfo) {
    list = emailInfo.map((item: EmailInfo) => {
      return {
        id: item.did,
        email: item.email,
        name: item.company,
        jobType: item.jobType,
        status: item.status,
        createdAt: item.createdAt,
      }
    })
  }

  return {data: list, isLoading: res.isLoading}
}

export const useUpdateAuditStatusMutation = () => {
  const queryClient = useQueryClient()
  const agent = useAgent()
  return useMutation({
    mutationFn: async ({
      did,
      grade,
      status,
    }: {
      did: string
      grade: any
      status: AuditStatus
    }) => {
      await agent.com.atproto.server.updateUserEmailStatus({
        did,
        grade,
        status,
      })
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['getUserEmails'],
      })
    },
  })
}
