import React, {createContext, ReactNode, useContext} from 'react'

interface BigFigureContextType {
  isBigFigure: boolean
}

const BigFigureContext = createContext<BigFigureContextType | undefined>(
  undefined,
)

interface BigFigureProviderProps {
  children: ReactNode
  isBigFigure: boolean
}

export const BigFigureProvider: React.FC<BigFigureProviderProps> = ({
  children,
  isBigFigure,
}) => {
  return (
    <BigFigureContext.Provider value={{isBigFigure}}>
      {children}
    </BigFigureContext.Provider>
  )
}

export const useBigFigure = (): boolean => {
  const context = useContext(BigFigureContext)
  if (!context) {
    return false
  }
  return context.isBigFigure
}
