import {createSinglePathSVG} from './TEMPLATE'

export const Bell_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M4.216 8.815a7.853 7.853 0 0 1 15.568 0l1.207 9.053A1 1 0 0 1 20 19h-3.354c-.904 1.748-2.607 3-4.646 3-2.039 0-3.742-1.252-4.646-3H4a1 1 0 0 1-.991-1.132l1.207-9.053ZM9.778 19c.61.637 1.399 1 2.222 1s1.613-.363 2.222-1H9.778ZM12 4a5.853 5.853 0 0 0-5.802 5.08L5.142 17h13.716l-1.056-7.92A5.853 5.853 0 0 0 12 4Z',
})

export const Bell_Filled_Corner0_Rounded = createSinglePathSVG({
  path: 'M12 2a7.853 7.853 0 0 0-7.784 6.815l-1.207 9.053A1 1 0 0 0 4 19h3.354c.904 1.748 2.607 3 4.646 3 2.039 0 3.742-1.252 4.646-3H20a1 1 0 0 0 .991-1.132l-1.207-9.053A7.853 7.853 0 0 0 12 2Zm2.222 17H9.778c.61.637 1.399 1 2.222 1s1.613-.363 2.222-1Z',
})

export const BellOpen = createSinglePathSVG({
  path: 'M20.6499 15.29L21.4699 17.74L21.4599 17.75C21.6699 18.36 21.5699 19.03 21.1899 19.55C20.8199 20.07 20.2099 20.38 19.5699 20.38H15.3199C15.2599 20.97 15.0899 21.52 14.7999 22.01C14.0899 23.21 12.7699 24.01 11.2699 24.01C9.76992 24.01 8.45992 23.2 7.74992 22.01C7.46992 21.52 7.28992 20.97 7.22992 20.38H2.40992C1.76992 20.38 1.17992 20.09 0.799917 19.57C0.419917 19.05 0.309917 18.4 0.499917 17.79L1.26992 15.29V9.24C1.26992 4.86 4.39992 1.15 8.86992 0.21C10.1999 -0.07 11.7099 -0.07 13.0299 0.21C17.4499 1.14 20.6499 4.94 20.6499 9.24V15.29ZM9.29992 2.17C5.74992 2.91 3.26992 5.82 3.26992 9.24V14.44H18.6599V9.24C18.6599 5.88 16.1199 2.9 12.6299 2.17C11.5899 1.95 10.3399 1.95 9.29992 2.17ZM9.21992 20.37C9.25992 21.43 10.1299 22.3 11.2099 22.3H11.2199C12.2999 22.3 13.1699 21.44 13.2099 20.37H9.21992ZM2.99992 16.44L2.40992 18.37H19.5699L18.9799 16.44H2.99992ZM10.21 5.44043C10.21 4.89043 10.66 4.44043 11.21 4.44043C11.76 4.44043 12.21 4.89043 12.21 5.44043V8.77043C12.21 9.32043 11.76 9.77043 11.21 9.77043C10.66 9.77043 10.21 9.32043 10.21 8.77043V5.44043Z',
})

export const BellOpen_Fill = createSinglePathSVG({
  path: 'M20.6499 15.29L21.4699 17.74L21.4599 17.75C21.6699 18.36 21.5699 19.03 21.1899 19.55C20.8199 20.07 20.2099 20.38 19.5699 20.38H15.3199C15.2599 20.97 15.0899 21.52 14.7999 22.01C14.0899 23.21 12.7699 24.01 11.2699 24.01C9.76992 24.01 8.45992 23.2 7.74992 22.01C7.46992 21.52 7.28992 20.97 7.22992 20.38H2.40992C1.76992 20.38 1.17992 20.09 0.799917 19.57C0.419917 19.05 0.309917 18.4 0.499917 17.79L1.26992 15.29V9.24C1.26992 4.86 4.39992 1.15 8.86992 0.21C10.1999 -0.07 11.7099 -0.07 13.0299 0.21C17.4499 1.14 20.6499 4.94 20.6499 9.24V15.29ZM9.21992 20.37C9.25992 21.43 10.1299 22.3 11.2099 22.3H11.2199C12.2999 22.3 13.1699 21.44 13.2099 20.37H9.21992ZM10.21 5.44043C10.21 4.89043 10.66 4.44043 11.21 4.44043C11.76 4.44043 12.21 4.89043 12.21 5.44043V8.77043C12.21 9.32043 11.76 9.77043 11.21 9.77043C10.66 9.77043 10.21 9.32043 10.21 8.77043V5.44043Z',
})
