/*
 * Note: the dataSet properties are used to leverage custom CSS in public/index.html
 */

import React, {useEffect, useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import Svg, {ClipPath, Defs, G, Path, Rect} from 'react-native-svg'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
  Props as FontAwesomeProps,
} from '@fortawesome/react-native-fontawesome'

import {useTheme} from '#/alf'

const DURATION = 3500

interface ActiveToast {
  text: string
  icon: FontAwesomeProps['icon']
  extra?: any
}
type GlobalSetActiveToast = (_activeToast: ActiveToast | undefined) => void

// globals
// =
let globalSetActiveToast: GlobalSetActiveToast | undefined
let toastTimeout: NodeJS.Timeout | undefined

// components
// =
type ToastContainerProps = {}
export const ToastContainer: React.FC<ToastContainerProps> = ({}) => {
  const [activeToast, setActiveToast] = useState<ActiveToast | undefined>()
  const t = useTheme()

  useEffect(() => {
    globalSetActiveToast = (t: ActiveToast | undefined) => {
      setActiveToast(t)
    }
  })
  const getIcon = (icon: FontAwesomeProps['icon']) => {
    if (icon === 'check') {
      return <CheckIcon />
    } else if (icon === 'xmark') {
      return <XmarkIcon />
    } else {
      return (
        <FontAwesomeIcon
          icon={icon}
          size={16}
          style={[
            styles.icon as FontAwesomeIconStyle,
            {color: t.atoms.text.color},
          ]}
        />
      )
    }
  }
  return (
    <>
      {activeToast && (
        <View style={[styles.container]}>
          <View
            style={[
              styles.bg,
              {backgroundColor: t.name === 'light' ? '#fff' : '#262627'},
            ]}>
            {getIcon(activeToast.icon)}
            <Text style={[styles.text, t.atoms.text]}>{activeToast.text}</Text>
            {activeToast.extra}
          </View>
        </View>
      )}
    </>
  )
}

// methods
// =

export function show(
  text: string,
  icon: FontAwesomeProps['icon'] = 'check',
  duration?: number,
  extra?: any,
) {
  if (toastTimeout) {
    clearTimeout(toastTimeout)
  }
  globalSetActiveToast?.({text, icon, extra})
  toastTimeout = setTimeout(
    () => {
      globalSetActiveToast?.(undefined)
    },
    duration ? duration : DURATION,
  )
}

export function hide() {
  if (toastTimeout) {
    clearTimeout(toastTimeout)
  }
  globalSetActiveToast?.(undefined)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexShrink: 0,
    // @ts-ignore web only
    position: 'fixed',
    top: 20,
    left: 0,
    right: 0,
  },
  bg: {
    margin: 'auto',
    maxWidth: 350,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    // @ts-ignore web only
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    // @ts-ignore web only
    boxShadow: '0px 4px 8px 0px #00000040',
  },
  icon: {
    color: '#fff',
    flexShrink: 0,
  },
  text: {
    color: '#fff',
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 10,
  },
})

const CheckIcon = () => (
  <Svg width={16} height={17} fill="none" style={{flexShrink: 0}}>
    <Rect width={16} height={16} y={0.5} fill="#0ABF52" rx={8} />
    <Path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
      d="m3.667 8.5 2.666 2.666 5.334-5.333"
    />
  </Svg>
)
const XmarkIcon = () => (
  <Svg width={16} height={17} fill="none" style={{flexShrink: 0}}>
    <G clipPath="url(#a)">
      <Path
        fill="#FF543D"
        d="M16 8.5a8 8 0 1 1-16.001 0A8 8 0 0 1 16 8.5Zm-8.8.8v2.4a.8.8 0 1 0 1.6 0V9.3a.8.8 0 0 0-1.6 0ZM8 4.9a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4Z"
      />
      <Path
        fill="#fff"
        d="M7.2 11.7V9.3a.8.8 0 1 1 1.6 0v2.4a.8.8 0 0 1-1.6 0ZM7.152 5.252a1.2 1.2 0 1 1 1.697 1.697 1.2 1.2 0 0 1-1.697-1.697Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 .5h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)
