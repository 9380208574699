import React, {useEffect, useMemo, useState} from 'react'
import {SafeAreaView, StyleSheet, Text, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import dayjs from 'dayjs'

import {isIOS, isWeb} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {DateTimeInput} from '#/view/com/util/forms/DateTimeInput'
import {atoms as a, useTheme} from '#/alf'
// import {Link} from '#/components/Link'
import {BetProgress} from '../post-transaction/BetProgress'
import {Button} from '../util/forms/Button'
// import { JudgeModeType } from '#/state/transaction/bet'
// import * as Toggle from '#/components/forms/Toggle'
import {ToggleButton} from '../util/forms/ToggleButton'
import {BetToEarnModalProps} from './BetToEarn'
// import {ScrollView} from 'view/com/util/Views'

export const snapPoints = ['99']
export function Component(props: BetToEarnModalProps) {
  const {setParentEndTime, setParentPublicSide} = props
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {_} = useLingui()
  const t = useTheme()
  const {openModal, closeModal} = useModalControls()
  const initDate = useMemo(
    () =>
      new Date(dayjs().add(2, 'day').toISOString().split(':')[0] + ':00:00'),
    [],
  )
  const [date, setDate] = React.useState<Date>(initDate)
  // const [judgeMode, setJudgeMode] = useState<JudgeModeType>('popularity')
  const [publicSide, setPublicSide] = useState<boolean>(false)

  useEffect(() => {
    setParentEndTime?.(dayjs(initDate).unix())
  }, [setParentEndTime, initDate])

  return (
    <SafeAreaView
      testID="setBetModal"
      style={[isMobile && {marginHorizontal: 18}]}>
      <Text
        style={[
          a.text_xl,
          a.font_bold,
          a.flex,
          a.justify_center,
          a.text_center,
          t.atoms.text,
        ]}>
        Set a bet
      </Text>
      <View style={[a.relative]}>
        <View style={[a.mt_md, a.gap_md]}>
          <View>
            <LabelTitle title="Subject of the bet" />
            <Text style={[a.text_xs, a.ml_sm, {color: t.palette.black_25}]}>
              The content of the post is more likely to be considered true or
              false before the betting deadline.
            </Text>
          </View>
          <View>
            <LabelTitle title="Betting Deadline" />
            <View
              style={[a.pl_sm, a.pb_md, isIOS && [a.w_full, a.align_center]]}>
              {!isWeb ? (
                <DateTimeInput
                  handleAsUTC
                  testID="bettingDeadlineInput"
                  value={date}
                  onChange={value => {
                    setDate(value)
                    setParentEndTime?.(dayjs(value).unix())
                  }}
                  buttonType="default-light"
                  buttonStyle={[a.rounded_sm]}
                  buttonLabelType="lg"
                  accessibilityLabel={_(msg`Betting Deadline`)}
                  accessibilityHint={_(msg`Enter betting deadline`)}
                  accessibilityLabelledBy="bettingDeadline"
                />
              ) : (
                <View style={{height: 48}} />
              )}
            </View>
            <Text style={[a.text_xs, a.ml_sm, {color: t.palette.black_25}]}>
              Select a time between{' '}
              <Text style={[a.font_bold, {color: t.palette.black_50}]}>
                2 and 7 days from now
              </Text>
            </Text>
          </View>
          <View>
            <LabelTitle title="Prize pool distribution" />
            <Text style={[a.text_xs, a.ml_sm, {color: t.palette.black_25}]}>
              The winners divide 90% of the losers' bets according to the
              betting ratio, Poster 7%, Service fee 3%
            </Text>
          </View>
          {/* <View>
              <LabelTitle title="Result Determination" />
              <Toggle.Group
                label={_(msg``)}
                type="radio"
                values={[judgeMode]}
                onChange={(keys: string[]) => {
                  const key = keys[0]
                  if (!key) return
                  setJudgeMode(key as JudgeModeType)
                  setParentJudgeMode?.(key as JudgeModeType)
                }}>
                <View style={[a.flex, a.flex_row, a.align_center, a.justify_center]}>
                  <Toggle.Item
                    name="committee_ruling"
                    label={_(msg`Committee ruling`)}
                    disabled
                    style={[a.flex_1, a.pl_md]}>
                    <Toggle.Radio />
                    <Toggle.LabelText style={[a.text_xs,a.font_semibold]}>Committee ruling</Toggle.LabelText>
                  </Toggle.Item>
                  <Toggle.Item
                    name="popularity"
                    label={_(msg`Popularity`)}
                    style={[a.flex_1]}>
                    <Toggle.Radio />
                    <Toggle.LabelText style={[a.text_xs,a.font_semibold]}>Popularity</Toggle.LabelText>
                  </Toggle.Item>
                </View>
              </Toggle.Group>
              <Text style={[a.text_xs, a.ml_sm, a.mt_sm, {color: t.palette.black_25}]}>
                Based on the evidence, the committee will submit a result (True, False, Indeterminable) within 24 hours after the betting deadline.
              </Text>
            </View> */}
          <View>
            <View style={[a.flex_row, a.align_center, a.justify_between]}>
              <LabelTitle title="Public the bet amounts for each side" />
              <ToggleButton
                type="primary"
                label=""
                isSelected={publicSide}
                style={[
                  a.p_0,
                  a.justify_between,
                  a.align_center,
                  {
                    backgroundColor: publicSide
                      ? t.palette.primary
                      : t.palette.gray_10,
                    padding: 0,
                  },
                ]}
                removeOuterStyle={true}
                onPress={() => {
                  let publicSideTemp = !publicSide
                  setPublicSide(publicSideTemp)
                  setParentPublicSide?.(publicSideTemp)
                }}
              />
            </View>
            <View
              style={[
                a.relative,
                a.flex_col,
                a.pb_sm,
                a.pt_xs,
                a.px_3xl,
                a.mt_sm,
                {
                  borderColor: '#D4DBE2',
                  borderWidth: 1,
                  borderRadius: 6,
                  height: 70,
                  marginLeft: 7,
                },
              ]}>
              <View
                style={[
                  a.flex_row,
                  a.align_center,
                  a.justify_between,
                  a.mb_xs,
                ]}>
                <Text
                  style={[a.text_xs, a.font_bold, {color: t.palette.black_25}]}>
                  Prize Pool:
                </Text>
                <Text style={[a.text_xl, a.font_heavy, t.atoms.text]}>
                  5,000 <Text style={[a.text_xs]}>USDC</Text>
                </Text>
              </View>
              <BetProgress
                onValue={3000}
                againstValue={2000}
                isMask={!publicSide}
                size="small"
                isPreview={true}
              />
            </View>
          </View>
          <View style={[a.pt_xs]}>
            {/* <Link action="navigate" to={''} label=""> */}
            <Text
              style={[
                a.text_xs,
                a.font_bold,
                {color: '#FF6B00', textDecorationLine: 'underline'},
              ]}>
              Learn more about the rules
            </Text>
            {/* </Link> */}
          </View>
        </View>
        {isWeb && (
          <View style={[a.absolute, a.w_full, {top: 125, left: 7}]}>
            <DateTimeInput
              handleAsUTC
              testID="bettingDeadlineInput"
              value={date}
              onChange={value => {
                setDate(value)
                setParentEndTime?.(dayjs(value).unix())
              }}
              buttonType="default-light"
              buttonStyle={[a.rounded_sm]}
              buttonLabelType="lg"
              accessibilityLabel={_(msg`Betting Deadline`)}
              accessibilityHint={_(msg`Enter betting deadline`)}
              accessibilityLabelledBy="bettingDeadline"
            />
          </View>
        )}
      </View>
      <View style={[a.mt_xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          accessibilityLabel={_(msg`Next`)}
          accessibilityHint=""
          label={_(msg`Next`)}
          onPress={() => {
            // closeModal()
            openModal({
              ...props,
              type: 'create-bet',
              name: 'earn-bet',
              endTime: dayjs(date).unix(),
              publicSide,
            })
          }}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </SafeAreaView>
  )
}

const LabelTitle = ({title}: {title: string}) => {
  const t = useTheme()
  return (
    <View style={[a.flex_row, a.my_sm, a.align_center]}>
      <View style={styles.dot} />
      <Text style={[a.text_sm, a.font_bold, t.atoms.text]}>{title}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 5,
    height: 5,
    backgroundColor: '#666',
    borderRadius: 5,
    marginRight: 5,
  },
  publicSide: {
    borderWidth: 1,
  },
})
