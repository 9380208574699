// export const apiId = 21128146
// export const apiHash = '1b581823236a757741e9672bdfaf4e6e'

export const apiId = 22456775 //21128146
export const apiHash = '5d3e9b8a27841354537894a4644a9859' //'1b581823236a757741e9672bdfaf4e6e'

export const TELEGRAM_SESSION_KEY = '__tele_session_key__'

export const GROUP_MEMBER_LIMIT = 200

export const NEED_INVITE_TELE_USER_COUNT = 2

export enum TelegramBindStep {
  Init = 1,
  PhoneCode,
  Password,
  Authorize,
  SelectContact,
  SendMessage,
  TeleShare,
}
