import React from 'react'
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native'

import {choose} from 'lib/functions'
import {colors} from 'lib/styles'
import {useTheme} from 'lib/ThemeContext'
import {TypographyVariant} from 'lib/ThemeContext'
import {useTheme as useNewTheme} from '#/alf'
import {Text} from '../text/Text'
import {Button, ButtonType} from './Button'

export function ToggleButton({
  testID,
  type = 'default-light',
  label,
  isSelected,
  style,
  labelType,
  onPress,
  removeOuterStyle,
}: {
  testID?: string
  type?: ButtonType
  label: string
  isSelected: boolean
  style?: StyleProp<ViewStyle>
  labelType?: TypographyVariant
  onPress?: () => void
  removeOuterStyle?: boolean
}) {
  const theme = useTheme()
  const newTheme = useNewTheme()

  const circleStyle = choose<TextStyle, Record<ButtonType, TextStyle>>(type, {
    primary: {
      borderColor: theme.palette.primary.text,
    },
    secondary: {
      borderColor: theme.palette.secondary.text,
    },
    inverted: {
      borderColor: theme.palette.inverted.text,
    },
    'primary-outline': {
      borderColor: theme.palette.primary.border,
    },
    'secondary-outline': {
      borderColor: theme.palette.secondary.border,
    },
    'primary-light': {
      borderColor: theme.palette.primary.border,
    },
    'secondary-light': {
      borderColor: theme.palette.secondary.border,
    },
    default: {
      borderColor: theme.palette.default.border,
    },
    'default-light': {
      borderColor: theme.palette.default.border,
    },
    sdl_light: {
      borderColor: theme.palette.default.border,
    },
  })
  const circleFillStyle = choose<TextStyle, Record<ButtonType, TextStyle>>(
    type,
    {
      primary: {
        backgroundColor: theme.palette.primary.text,
        opacity: 1,
      },
      sdl_light: {
        backgroundColor: theme.palette.primary.text,
        opacity: 1,
      },
      secondary: {
        backgroundColor: theme.palette.secondary.text,
        opacity: isSelected ? 1 : 0.33,
      },
      inverted: {
        backgroundColor: theme.palette.inverted.text,
        opacity: isSelected ? 1 : 0.33,
      },
      'primary-outline': {
        backgroundColor: theme.palette.primary.background,
        opacity: isSelected ? 1 : 0.5,
      },
      'secondary-outline': {
        backgroundColor: theme.palette.secondary.background,
        opacity: isSelected ? 1 : 0.5,
      },
      'primary-light': {
        backgroundColor: theme.palette.primary.background,
        opacity: isSelected ? 1 : 0.5,
      },
      'secondary-light': {
        backgroundColor: theme.palette.secondary.background,
        opacity: isSelected ? 1 : 0.5,
      },
      default: {
        backgroundColor: isSelected ? newTheme.palette.primary : colors.gray3,
      },
      'default-light': {
        backgroundColor: isSelected ? newTheme.palette.primary : colors.gray3,
      },
    },
  )
  const labelStyle = choose<TextStyle, Record<ButtonType, TextStyle>>(type, {
    primary: {
      color: theme.palette.primary.text,
      fontWeight: theme.palette.primary.isLowContrast ? '500' : undefined,
    },
    secondary: {
      color: theme.palette.secondary.text,
      fontWeight: theme.palette.secondary.isLowContrast ? '500' : undefined,
    },
    inverted: {
      color: theme.palette.inverted.text,
      fontWeight: theme.palette.inverted.isLowContrast ? '500' : undefined,
    },
    'primary-outline': {
      color: theme.palette.primary.textInverted,
      fontWeight: theme.palette.primary.isLowContrast ? '500' : undefined,
    },
    'secondary-outline': {
      color: theme.palette.secondary.textInverted,
      fontWeight: theme.palette.secondary.isLowContrast ? '500' : undefined,
    },
    'primary-light': {
      color: theme.palette.primary.textInverted,
      fontWeight: theme.palette.primary.isLowContrast ? '500' : undefined,
    },
    'secondary-light': {
      color: theme.palette.secondary.textInverted,
      fontWeight: theme.palette.secondary.isLowContrast ? '500' : undefined,
    },
    default: {
      color: theme.palette.default.text,
      fontWeight: theme.palette.default.isLowContrast ? '500' : undefined,
    },
    'default-light': {
      color: theme.palette.default.text,
      fontWeight: theme.palette.default.isLowContrast ? '500' : undefined,
    },
  })
  return (
    <Button
      testID={testID}
      type={type}
      onPress={onPress}
      style={style}
      removeOuterStyle={removeOuterStyle}>
      <View style={styles.outer}>
        <View
          style={[
            circleStyle,
            styles.circle,
            isSelected
              ? {backgroundColor: '#0ABF52', alignItems: 'flex-end'}
              : {backgroundColor: '#979797'},
          ]}>
          <View
            style={[
              circleFillStyle,
              styles.circleFill,
              isSelected ? styles.circleFillSelected : undefined,
            ]}
          />
        </View>
        {label === '' ? null : (
          <Text type={labelType || 'button'} style={[labelStyle, styles.label]}>
            {label}
          </Text>
        )}
      </View>
    </Button>
  )
}

const styles = StyleSheet.create({
  outer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  circle: {
    width: 46,
    height: 28,
    borderRadius: 15,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 2,
  },
  circleFill: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: 'white',
  },
  circleFillSelected: {
    marginLeft: 1,
  },
  label: {
    flex: 1,
  },
})
