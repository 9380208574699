/* eslint-disable react-native-a11y/has-valid-accessibility-descriptors */
import React, {useCallback, useMemo, useState} from 'react'
import {
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {ComAtprotoServerCreateSubAccount} from '@atproto/api'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'
import {ethers} from 'ethers'

import {useSubAccounts} from '#/lib/hooks/useIsMe'
import {usePalette} from '#/lib/hooks/usePalette'
import {NavigationProp} from '#/lib/routes/types'
import {validateHandle} from '#/lib/strings/handles'
import {logger} from '#/logger'
import {isWeb} from '#/platform/detection'
import {useAgent, useSession} from '#/state/session'
import {usePostPreState} from '#/state/shell/post-pre-data'
import * as Toast from '#/view/com/util/Toast'
// import { TextInput } from '#/view/com/modals/util'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as TextField from '#/components/forms/TextField'
import {At_Stroke2_Corner0_Rounded as At} from '#/components/icons/At'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
// import {Envelope_Stroke2_Corner0_Rounded as Envelope} from '#/components/icons/Envelope'
// import {Lock_Stroke2_Corner0_Rounded as Lock} from '#/components/icons/Lock'
import {TimesLarge_Stroke2_Corner0_Rounded as Times} from '#/components/icons/Times'
import {Loader} from '#/components/Loader'

type Props = {
  displayName?: string
  handle?: string
  bio?: string
}

export const SavedMask = (props: Props) => {
  const pal = usePalette('default')
  const {_} = useLingui()
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {currentAccount} = useSession()
  const {subAccounts} = useSubAccounts()
  const queryClient = useQueryClient()
  const {updatePostMaskAccounts} = usePostPreState()
  const [nickname, setNickname] = useState(props.displayName || '')
  const agent = useAgent()
  const [handle, setHandle] = useState(() => {
    return props.handle ?? ''
  })
  const [loading, setLoading] = useState(false)
  const [error] = useState('')

  const onSave = useCallback(async () => {
    if (!handle) {
      Toast.show(_('User handle is empty'), 'xmark')
      return false
    }

    if (!currentAccount?.mnemonic) {
      Toast.show(_('mnemonic is empty'), 'xmark')
      return false
    }

    setLoading(true)
    let handlePass = false
    try {
      const uri = new URL(currentAccount.service)
      const res = await agent.com.atproto.server.validateHandle({
        handle: handle + '.' + uri.hostname,
      })
      if (res.success && res.data.success) {
        handlePass = true
      }
    } catch (error) {
      handlePass = false
    } finally {
      setLoading(false)
    }

    if (!handlePass) {
      Toast.show(_(msg`This user handle is already taken.`), 'xmark')
      return false
    }

    try {
      const {service} = currentAccount
      let index = subAccounts[subAccounts.length - 1]?.index
      if (index) {
        index = +index + 1
      } else {
        index = 1
      }

      const mnemonic = ethers.Mnemonic.fromPhrase(currentAccount.mnemonic)
      const wallet = ethers.HDNodeWallet.fromMnemonic(
        mnemonic,
        `m/44'/60'/0'/0/${index}`,
      )
      const address = wallet.address

      const uri = new URL(service)

      const data: ComAtprotoServerCreateSubAccount.InputSchema = {
        handle: handle + '.' + uri.hostname,
        walletAddress: address,
        name: nickname,
      }

      const res = await agent.com.atproto.server.createSubAccount(data)
      const subAccount = {
        did: res.data.did,
        handle: res.data.handle,
        wallet,
        address,
      }
      if (currentAccount.subAccounts) {
        currentAccount.subAccounts.push(subAccount)
      } else {
        currentAccount.subAccounts = [subAccount]
      }
      await queryClient.invalidateQueries({
        queryKey: ['SubAccounts'],
      })
      updatePostMaskAccounts({
        did: res.data.did,
        handle: res.data.handle,
        name: nickname,
        parentDid: currentAccount.did,
      })
      Toast.show('Successfully created mask')
      navigation.navigate('Mask', {time: Date.now()})
    } catch (error: any) {
      logger.error('Failed to create mask')
      Toast.show(error?.message ?? 'Failed to create mask', 'xmark')
      // throw error
    } finally {
      setLoading(false)
    }
  }, [
    _,
    agent.com.atproto.server,
    currentAccount,
    handle,
    navigation,
    nickname,
    queryClient,
    subAccounts,
    updatePostMaskAccounts,
  ])

  const handleBlur = () => {
    Keyboard.dismiss()
  }

  const validCheck = useMemo(() => {
    if (handle) {
      return validateHandle(
        handle ?? '',
        currentAccount?.service
          ? new URL(currentAccount?.service).hostname
          : '',
      )
    }
    return {
      frontLength: false,
      handleChars: false,
      hyphenStartOrEnd: false,
      overall: false,
      totalLength: false,
    }
  }, [currentAccount?.service, handle])

  return (
    <TouchableWithoutFeedback onPress={!isWeb ? handleBlur : undefined}>
      <View style={[a.flex_1]}>
        <ViewHeader title={_(msg`Create Mask`)} showOnDesktop canGoBack />
        <CenteredView
          style={[
            // styles.header,
            a.flex_1,
            pal.border,
            pal.view,
            a.mt_2xl,
            a.p_lg,
            a.gap_lg,
            a.justify_between,
            a.flex_col,
            // isTabletOrDesktop && {paddingTop: 10},
          ]}
          // sideBorders={isTabletOrDesktop}
        >
          <View style={[a.gap_lg]}>
            <View>
              <View>
                <TextField.LabelText style={[t.atoms.text]}>
                  <Trans>User Name</Trans>
                </TextField.LabelText>
                <TextField.SubLabelText style={[t.atoms.text_sub]}>
                  <Trans>This can be modified later in the setting page.</Trans>
                </TextField.SubLabelText>
                <TextInput
                  value={nickname}
                  onChangeText={setNickname}
                  style={[
                    {borderWidth: 2},
                    a.relative,
                    a.p_xs,

                    a.rounded_md,
                    t.atoms.text,
                    a.text_md,
                    a.pl_sm,
                    a.pr_md,
                    t.atoms.border_contrast_low,
                    {height: 44},
                  ]}
                />
              </View>
            </View>
            {nickname.length > 20 && (
              <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                <IsValidIcon valid={!(nickname.length > 20)} />
                {nickname.length > 20 && (
                  <Text style={[a.text_md, a.flex_1]}>
                    <Trans>No longer than 20 characters</Trans>
                  </Text>
                )}
              </View>
            )}

            <View>
              <TextField.LabelText style={[t.atoms.text]}>
                <Trans>User Handle</Trans>
              </TextField.LabelText>
              <TextField.SubLabelText style={[t.atoms.text_sub]}>
                <Trans>
                  You won't be able to make changes after your account is set
                  up.
                </Trans>
              </TextField.SubLabelText>

              <View
                style={[
                  styles.inputContainer,
                  {borderWidth: 2},
                  a.relative,
                  a.p_xs,
                  a.rounded_md,
                  a.pl_sm,
                  a.pr_md,
                  t.atoms.border_contrast_low,
                  {height: 44},
                ]}>
                <TextField.Icon icon={At} style={[t.atoms.text]} />
                <TextInput
                  style={[a.flex_1, t.atoms.text, a.text_md]}
                  value={handle}
                  onChangeText={(value: string) => {
                    setHandle(value.trimStart().trimEnd())
                  }}
                />
              </View>
            </View>

            {handle !== '' && (
              <View
                style={[
                  a.w_full,
                  a.rounded_sm,
                  a.border,
                  a.p_md,
                  a.gap_sm,
                  t.atoms.border_contrast_low,
                ]}>
                {error ? (
                  <View
                    style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                    <IsValidIcon valid={false} />
                    <Text style={[a.text_md, a.flex_1]}>{error}</Text>
                  </View>
                ) : undefined}
                {validCheck.hyphenStartOrEnd ? (
                  <View
                    style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                    <IsValidIcon valid={validCheck.handleChars} />
                    <Text style={[a.text_md, t.atoms.text, a.flex_1]}>
                      <Trans>
                        Only supports letters, numbers, and hyphens.
                      </Trans>
                    </Text>
                  </View>
                ) : (
                  <View
                    style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                    <IsValidIcon valid={validCheck.hyphenStartOrEnd} />
                    <Text style={[a.text_md, t.atoms.text, a.flex_1]}>
                      <Trans>Doesn't begin or end with a hyphen</Trans>
                    </Text>
                  </View>
                )}
                <View style={[a.w_full, a.flex_row, a.align_center, a.gap_sm]}>
                  <IsValidIcon
                    valid={validCheck.frontLength && validCheck.totalLength}
                  />
                  {!validCheck.totalLength ? (
                    <Text style={[a.text_md, t.atoms.text, a.flex_1]}>
                      <Trans>No longer than 20 characters</Trans>
                    </Text>
                  ) : (
                    <Text style={[a.text_md, t.atoms.text, a.flex_1]}>
                      <Trans>Contains at least 3 characters.</Trans>
                    </Text>
                  )}
                </View>
              </View>
            )}
          </View>
          <Button
            size="medium"
            color="primary"
            variant="solid"
            label="Primary solid"
            style={[styles.buttonStyles]}
            disabled={loading}
            onPress={onSave}>
            <View>
              <Text
                style={[
                  t.atoms.text_inverted,
                  a.text_md,
                  a.font_bold,
                  {color: t.palette.black},
                ]}>
                Create
              </Text>
            </View>
            {loading && <Loader />}
          </Button>
        </CenteredView>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  prefix: {
    paddingHorizontal: 10,
    fontWeight: 'bold',
    color: 'black',
  },
  buttonStyles: {
    marginBottom: 100,
  },
})

function IsValidIcon({valid}: {valid: boolean}) {
  const t = useTheme()
  if (!valid) {
    return <Times size="md" style={{color: t.palette.negative_500}} />
  }
  return <Check size="md" style={{color: t.palette.positive_700}} />
}
