import React from 'react'
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  KeyboardAvoidingView,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {LinearGradient} from 'expo-linear-gradient'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'
import dayjs from 'dayjs'
import {ethers} from 'ethers'

import {PUBLIC_ENV_CHAINID, SUPPORT_CHAINS} from '#/lib/constants'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {isIOS} from '#/platform/detection'
import {useModalControls} from '#/state/modals'
import {
  CLAIMTOWALLET_RQKEY,
  useActorMagnificationQuery,
  useReceiveAddressQuery,
  useSaveReceiveAddressMutation,
  useWithdrawBalanceQuery,
  useWithdrawRecordsQuery,
  useWithdrawRewardMutation,
} from '#/state/queries/claim-to-wallet'
import {useAgent} from '#/state/session'
import * as Toast from '#/view/com/util/Toast'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {AddEditIcon, AssetsScanIcon} from '#/components/icons/StakeIcons'
import {Loader} from '#/components/Loader'

const usdcIcon = require('../../../../assets/imgs/usdc.png')

export const snapPoints = ['90%']

export interface ClaimToWalletModalProps {}
export const Component = function ClaimToWalletModalComponent() {
  const confettiRef = React.useRef(null)
  const t = useTheme()
  const {_} = useLingui()
  const agent = useAgent()
  const queryClient = useQueryClient()
  let timeoutTimer = React.useRef<any>(null)
  let claimTimer = React.useRef<any>(null)
  let claimId = React.useRef<any>(null)

  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const saveWalletMutation = useSaveReceiveAddressMutation()
  const withdrawRewardMutation = useWithdrawRewardMutation()

  const {data: walletAddress} = useReceiveAddressQuery()
  const {data: balance = 0} = useWithdrawBalanceQuery()
  // const balance = 1
  const {data: times = 0} = useActorMagnificationQuery()
  const {
    status: claimRecordStatus,
    data: record = [],
    error: claimRecordError,
  } = useWithdrawRecordsQuery()

  const totalBalance = (balance * times).toFixed(2)

  const [wallet, setWallet] = React.useState<string>(walletAddress ?? '')
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [claimWallet, setClaimWallet] = React.useState<string>(
    walletAddress ?? '',
  )
  const [isClaiming, setIsClaiming] = React.useState<boolean>(false)
  const [isClaimSuccess, setIsClaimSuccess] = React.useState<boolean>(false)
  const [isConfeting, setIsConfeting] = React.useState(false)
  const [seconds, setSeconds] = React.useState(0)
  const [isTimeout, setIsTimeout] = React.useState<boolean>(false)

  async function getWithdrawRecordQuery() {
    // console.log('getWithdrawRecordQuery', claimId.current)
    if (claimId.current) {
      const res = await agent.com.atproto.server.getWithdrawRecord({
        withdrawId: claimId.current,
      })
      if (
        res.data.withdrawRecord?.txId &&
        res.data.withdrawRecord?.txId !== ''
      ) {
        clearInterval(claimTimer?.current)
        claimTimer.current = undefined
        claimId.current = undefined
        setIsClaimSuccess(true)
        setIsClaiming(false)
        if (confettiRef.current) {
          // @ts-ignore
          confettiRef.current?.start()
        }
        queryClient.invalidateQueries({
          queryKey: [CLAIMTOWALLET_RQKEY.withdrawRecords],
        })
      }
    } else {
      clearInterval(claimTimer?.current)
      claimTimer.current = undefined
    }
  }

  const startTimer = () => {
    if (!timeoutTimer.current) {
      timeoutTimer.current = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1)
      }, 1000)
    }
  }

  function onClaim() {
    if (!isClaiming) {
      setIsClaiming(true)
      withdrawRewardMutation
        .mutateAsync()
        .then(res => {
          // console.log('onClaim', res)
          if (res?.data?.withdrawId) {
            claimId.current = res?.data?.withdrawId
            claimTimer.current = setInterval(() => {
              getWithdrawRecordQuery()
            }, 3000)
            startTimer()
          }
        })
        .catch(res => {
          // for test
          // claimId.current = 15
          // claimTimer.current = setInterval(() => {
          //   getWithdrawRecordQuery()
          // }, 3000)
          // startTimer()

          setIsClaiming(false)
          Toast.show(
            res?.message ?? _(msg`An issue occurred, please try again.`),
            'xmark',
          )
        })
    }
  }

  async function onConfirm() {
    if (wallet && ethers.isAddress(wallet) && !isSaving) {
      setIsSaving(true)
      saveWalletMutation
        .mutateAsync(wallet)
        .then(() => {
          setClaimWallet(wallet)
        })
        .finally(() => {
          setIsSaving(false)
        })
        .catch(() => {
          Toast.show(_(msg`An issue occurred, please try again.`), 'xmark')
        })
    } else {
      Toast.show(_(msg`Invalid address`), 'xmark')
    }
  }

  const onPressScan = React.useCallback((value: string) => {
    const chainInfo = SUPPORT_CHAINS.find(
      o => o?.chainId === PUBLIC_ENV_CHAINID,
    )
    const url = `${chainInfo?.explorerUrl}/tx/${value}`
    Linking.openURL(url)
  }, [])

  let claimRecordContent = null
  if (claimRecordStatus === 'pending') {
    claimRecordContent = (
      <View
        style={[a.flex_1, a.h_full, a.align_center, a.justify_center, a.p_2xl]}>
        <Loader size="xl" />
      </View>
    )
  } else if (claimRecordError) {
    claimRecordContent = (
      <View style={[a.gap_md, a.justify_center, a.align_center, a.mb_md]}>
        <Pressable
          accessibilityRole="button"
          style={[
            a.px_lg,
            a.align_center,
            a.justify_center,
            a.rounded_lg,
            {backgroundColor: t.palette.primary, height: 32},
          ]}
          onPress={() => {
            queryClient.invalidateQueries({
              queryKey: [CLAIMTOWALLET_RQKEY.withdrawRecords],
            })
          }}>
          <Text style={[a.text_sm, a.font_semibold]}>
            <Trans>Try again</Trans>
          </Text>
        </Pressable>
      </View>
    )
  } else if (record?.length > 0) {
    claimRecordContent = (
      <View style={[a.w_full, a.flex_col, a.align_start, a.px_xl, a.mb_xl]}>
        <Text style={[a.text_sm, a.font_semibold, {color: '#824800'}]}>
          <Trans>Claim Record</Trans>
        </Text>
        <View
          style={[
            a.w_full,
            a.mt_sm,
            a.pt_sm,
            a.gap_sm,
            {borderTopWidth: 1, borderColor: '#0000001A'},
            // @ts-ignore
            {maxHeight: 120, overflow: 'auto'},
          ]}>
          {record?.map(item => {
            return (
              <View
                key={item?.txId}
                style={[a.flex_row, a.align_center, a.justify_between]}>
                <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                  <Image
                    testID="usdcLogo"
                    source={usdcIcon}
                    // @ts-ignore
                    style={styles.transBtnIcon}
                    accessibilityIgnoresInvertColors
                  />
                  <Text style={[a.text_sm, a.font_bold, {color: '#824800'}]}>
                    +{item?.amount} {item?.tokenSymbol}
                  </Text>
                </View>
                <View style={[a.align_end]}>
                  <Text style={[a.text_xs, {color: '#824800'}]}>
                    {item.createAt
                      ? dayjs(item.createAt).format('MMM DD, YYYY HH:mm')
                      : ''}
                  </Text>
                  {item?.txId && (
                    <Pressable
                      accessibilityRole="button"
                      onPress={() => onPressScan(item?.txId ?? '')}>
                      <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                        <Text
                          style={[
                            a.text_xs,
                            a.font_semibold,
                            {color: '#824800'},
                          ]}>
                          <Trans>View on block explorer</Trans>
                        </Text>
                        <AssetsScanIcon primaryColor="#824800" />
                      </View>
                    </Pressable>
                  )}
                </View>
              </View>
            )
          })}
        </View>
      </View>
    )
  }

  React.useEffect(() => {
    if (walletAddress && walletAddress !== '') {
      setClaimWallet(walletAddress)
      setWallet(walletAddress)
    }
  }, [walletAddress])

  React.useEffect(() => {
    if (seconds >= 5) {
      setIsTimeout(true)
      if (timeoutTimer.current) {
        clearInterval(timeoutTimer.current)
        timeoutTimer.current = null
      }
    }
  }, [seconds])

  React.useEffect(() => {
    return () => {
      clearInterval(claimTimer.current)
      claimTimer.current = null

      if (timeoutTimer.current) {
        clearInterval(timeoutTimer.current)
        timeoutTimer.current = null
      }
    }
  }, [claimTimer])

  function useKeyboardVerticalOffset() {
    const {top} = useSafeAreaInsets()
    console.log('useKeyboardVerticalOffset', top)
    if (!isIOS) return 25
    if (top === 20) return 60
    return top + 30
  }
  const keyboardVerticalOffset = useKeyboardVerticalOffset()

  return (
    <KeyboardAvoidingView
      behavior={isIOS ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={[
        a.flex_1,
        isMobile && {paddingHorizontal: 18},
        isDesktop && {marginHorizontal: -24, marginVertical: -20},
      ]}>
      <ScrollView testID="claimToWalletModal">
        <View
          style={[
            a.align_center,
            a.justify_center,
            {borderRadius: 16, minHeight: 500},
          ]}>
          <View
            style={[
              styles.confetIcon,
              isMobile ? {position: 'absolute'} : styles.confetIconWeb,
              isConfeting ? {zIndex: 2} : {zIndex: 0},
            ]}>
            <ConfettiCannon
              ref={confettiRef}
              count={350}
              explosionSpeed={200}
              fallSpeed={3000}
              origin={{x: -200, y: 0}}
              fadeOut={true}
              autoStart={false}
              testID="claimToWalletConfetti"
              onAnimationStart={() => {
                setIsConfeting(true)
              }}
              onAnimationEnd={() => {
                setIsConfeting(false)
              }}
            />
          </View>
          <View style={[{width: 343}]}>
            <LinearGradient
              colors={['#FCD010', '#FFA332']}
              start={{x: 0, y: 0}}
              end={{x: 0, y: 1}}
              style={[a.absolute, a.inset_0, {borderRadius: 16, zIndex: 0}]}
            />
            {/* Close */}
            <TouchableOpacity
              accessibilityRole="button"
              style={{position: 'absolute', right: 16, top: 16, zIndex: 1}}
              onPress={closeModal}>
              <CloseIcon circleColor={'#292D32'} size={26} opacity={1} />
            </TouchableOpacity>
            <ImageBackground
              source={require('../../../../assets/imgs/claim_to_wallet.png')}
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <View
                style={[
                  a.w_full,
                  a.flex_1,
                  {borderRadius: 16},
                  a.flex_col,
                  a.justify_between,
                ]}>
                <View style={[a.flex_col, a.align_center, a.justify_center]}>
                  <Text
                    style={[
                      {fontSize: 19, marginTop: 220, marginBottom: 10},
                      a.font_bold,
                      a.text_center,
                    ]}>
                    <Trans>
                      Congratulations, verification
                      <br /> successful!
                    </Trans>
                  </Text>
                  <Text style={[a.text_sm, a.font_semibold]}>
                    Total: {balance}{' '}
                    <Text style={[a.text_md, a.font_extrabold]}>
                      X {times} times
                    </Text>
                  </Text>
                  <Text
                    style={[
                      a.my_md,
                      {
                        color: '#824800',
                        fontSize: 40,
                        fontWeight: '700',
                        borderRadius: 16,
                        paddingHorizontal: 16,
                        paddingVertical: 3,
                        backgroundColor: '#FFFFFF66',
                      },
                    ]}>
                    {totalBalance?.toLocaleString()} USDC
                  </Text>
                </View>
                <View style={[a.align_center, a.justify_center]}>
                  {/* input wallet address */}
                  {!isClaimSuccess && (!claimWallet || claimWallet === '') && (
                    <View style={[a.flex_col, a.w_full]}>
                      <View
                        style={[a.flex_row, a.align_center, a.justify_center]}>
                        <Text
                          style={[
                            a.text_sm,
                            a.mt_sm,
                            a.align_center,
                            {color: t.palette.black, height: 24},
                          ]}>
                          Submit your address on
                        </Text>
                        <Image
                          source={require('../../../../assets/imgs/logo_base.png')}
                          style={{width: 24, height: 24, marginHorizontal: 4}}
                          accessibilityIgnoresInvertColors
                        />
                        <Text
                          style={[
                            a.text_sm,
                            a.mt_sm,
                            a.align_center,
                            {color: t.palette.black, height: 24},
                          ]}>
                          Base to receive
                        </Text>
                      </View>

                      <View
                        style={[
                          a.w_full,
                          a.px_lg,
                          a.mt_md,
                          a.mb_2xl,
                          a.flex_row,
                          a.align_center,
                          a.justify_between,
                        ]}>
                        <TextInput
                          accessibilityLabel="Text input field"
                          value={wallet}
                          // multiline={true}
                          // numberOfLines={2}
                          textAlignVertical={'center'}
                          accessibilityHint={'wallet address'}
                          onChangeText={setWallet}
                          placeholder="0x..."
                          placeholderTextColor={t.palette.gray_16}
                          style={[
                            {
                              marginLeft: 8,
                              width: 206,
                              height: 50,
                              borderWidth: 1,
                              borderRadius: 25,
                              backgroundColor: t.palette.white,
                              color: t.palette.black,
                              // textAlign: 'center',
                              paddingVertical: 8,
                            },
                            a.text_sm,
                            a.px_md,
                          ]}
                        />
                        <Pressable
                          accessibilityRole="button"
                          role="button"
                          disabled={!wallet || wallet === ''}
                          onPress={onConfirm}
                          style={[
                            a.align_center,
                            a.justify_center,
                            {borderRadius: 25, height: 50, width: 85},
                            !wallet || wallet === ''
                              ? {opacity: 0.5}
                              : {opacity: 1},
                          ]}>
                          <LinearGradient
                            colors={['#FFE36B', '#FFBF34']}
                            start={{x: 0, y: 0}}
                            end={{x: 0, y: 1}}
                            style={[
                              a.absolute,
                              a.inset_0,
                              {borderRadius: 25, zIndex: 0},
                            ]}
                          />
                          {isSaving ? (
                            <ActivityIndicator
                              color={t.palette.black}
                              size="small"
                            />
                          ) : (
                            <Text
                              style={[
                                a.text_lg,
                                a.font_bold,
                                {color: t.palette.black},
                              ]}>
                              Confirm
                            </Text>
                          )}
                        </Pressable>
                      </View>
                    </View>
                  )}
                  {/* claim to wallet */}
                  {!isClaimSuccess &&
                  claimWallet &&
                  claimWallet !== '' &&
                  balance > 0 ? (
                    <View
                      style={[
                        a.mb_lg,
                        a.flex_col,
                        a.justify_center,
                        a.align_center,
                      ]}>
                      <View
                        style={[
                          a.px_lg,
                          a.flex_col,
                          a.w_full,
                          a.justify_center,
                          a.align_center,
                        ]}>
                        <View
                          style={[
                            a.flex_row,
                            a.align_center,
                            a.gap_md,
                            {width: 300},
                          ]}>
                          <Text
                            style={{
                              flexWrap: 'wrap',
                              lineHeight: 20,
                              textAlign: 'left',
                              // @ts-ignore
                              wordBreak: 'break-all',
                            }}>
                            <Text
                              style={[
                                a.text_sm,
                                a.font_semibold,
                                {color: t.palette.black},
                                a.mr_sm,
                              ]}>
                              <Trans>To: </Trans>
                            </Text>
                            {claimWallet}
                            <Pressable
                              accessibilityRole="button"
                              style={[a.ml_sm]}
                              onPress={() => {
                                setClaimWallet('')
                              }}>
                              <AddEditIcon size={10} />
                            </Pressable>
                          </Text>
                        </View>
                        <Pressable
                          accessibilityRole="button"
                          role="button"
                          onPress={onClaim}
                          style={[
                            a.w_full,
                            a.align_center,
                            a.justify_center,
                            a.mt_md,
                            {borderRadius: 25, height: 50},
                          ]}>
                          <LinearGradient
                            colors={['#FFE36B', '#FFBF34']}
                            start={{x: 0, y: 0}}
                            end={{x: 0, y: 1}}
                            style={[
                              a.absolute,
                              a.inset_0,
                              {borderRadius: 25, zIndex: 0},
                            ]}
                          />
                          {isClaiming ? (
                            <ActivityIndicator
                              color={t.palette.black}
                              size="small"
                            />
                          ) : (
                            <Text
                              style={[
                                a.text_lg,
                                a.font_bold,
                                {color: t.palette.black},
                              ]}>
                              Claim to wallet
                            </Text>
                          )}
                        </Pressable>
                      </View>
                      {isTimeout && (
                        <Text
                          style={[
                            a.text_sm,
                            a.font_semibold,
                            {color: '#824800'},
                            a.text_center,
                            a.mt_md,
                          ]}>
                          <Trans>
                            Come back later to check the on-chain record
                          </Trans>
                        </Text>
                      )}
                    </View>
                  ) : (
                    ''
                  )}
                  {/* claim record */}
                  {claimRecordContent}
                </View>
              </View>
            </ImageBackground>
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  confetIcon: {
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  confetIconWeb: {
    // @ts-ignore
    position: 'fixed',
  },
  transBtnIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
})
