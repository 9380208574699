import React, {useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {RouteProp, useRoute} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'

import {CommonNavigatorParams} from '#/lib/routes/types'
import {updatePostShadow} from '#/state/cache/post-shadow'
import {useProfileQuery} from '#/state/queries/profile'
import {useAgent, useSession} from '#/state/session'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {Loader} from '#/components/Loader'

type Props = {
  uri?: string
  cid?: string
  onDone?: (status: number) => void
}
export default function Confirm({uri, cid, onDone}: Props) {
  const t = useTheme()
  const agent = useAgent()
  const {currentAccount} = useSession()
  const queryClient = useQueryClient()
  const {data: currentProfile} = useProfileQuery({did: currentAccount?.did})

  const [loading, setLoading] = useState(false)
  const [rejecting, setRejecting] = useState(false)

  const onConfirm = async () => {
    setLoading(true)
    await agent.com.atproto.repo.createRecord({
      repo: currentAccount?.did ?? '',
      collection: 'app.bsky.graph.guaranteeUpdate',
      record: {
        subject: {
          uri: uri,
          cid: cid,
        },
        $type: 'app.bsky.graph.guaranteeUpdate',
        status: 1,
        createdAt: new Date().toISOString(),
      },
    })
    if (uri) {
      updatePostShadow(queryClient, uri, {
        guarantor: {
          did: currentAccount?.did ?? '',
          avatar: currentProfile?.avatar ?? '',
          displayName: currentProfile?.displayName ?? '',
          handle: currentAccount?.handle ?? '',
        },
      })
    }
    onDone?.(1)
    setLoading(false)
  }
  const onReject = async () => {
    setRejecting(true)
    await agent.com.atproto.repo.createRecord({
      repo: currentAccount?.did ?? '',
      collection: 'app.bsky.graph.guaranteeUpdate',
      record: {
        subject: {
          uri: uri,
          cid: cid,
        },
        $type: 'app.bsky.graph.guaranteeUpdate',
        status: 2,
        createdAt: new Date().toISOString(),
      },
    })
    onDone?.(2)
    setRejecting(false)
  }

  return (
    <View style={styles.wrap}>
      <View
        style={[
          a.flex_1,
          a.justify_center,
          styles.container,
          a.shadow_sm,
          t.atoms.modalBg,
          {borderColor: t.palette.contrast_50, minHeight: 110},
        ]}>
        <Text style={[a.text_center, a.text_md, t.atoms.text]}>
          <Trans>The spiller invites you to be the guarantor.</Trans>
        </Text>
        <View style={[a.flex_1, a.flex_row, a.justify_center, a.gap_md]}>
          <Button
            size="small"
            color="secondary"
            variant="solid"
            label="Reject"
            disabled={loading || rejecting}
            onPress={onReject}
            style={[a.flex_1]}>
            <Text style={[t.atoms.text]}>
              <Trans>Reject</Trans>
            </Text>
            {rejecting && <Loader />}
          </Button>
          <Button
            size="small"
            color="primary"
            variant="solid"
            label="Confirm"
            disabled={loading || rejecting}
            onPress={onConfirm}
            style={[a.flex_1]}>
            <Text style={[{color: t.palette.black}]}>
              <Trans>Confirm</Trans>
            </Text>
            {loading && <Loader />}
          </Button>
        </View>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  wrap: {
    flex: 1,
  },
  container: {
    flex: 1,
    height: 130,
    margin: 20,
    padding: 20,
    gap: 10,
    borderRadius: 10,
  },
})

export const useGuarantorConfirm = ({
  uri,
  cid,
}: {
  uri?: string
  cid?: string
}) => {
  const {hasSession} = useSession()
  const route = useRoute<RouteProp<CommonNavigatorParams, 'PostThread'>>()
  const [status, setStatus] = useState<number>(0)
  const [hasGuarantee, setHasGuarantee] = useState<boolean>(false)
  const agent = useAgent()
  React.useEffect(() => {
    if (uri && hasSession) {
      agent.app.bsky.graph
        .getGuarantee({
          subjectUri: uri,
        })
        .then(res => {
          setStatus(res.data.status)
          setHasGuarantee(res.data.haveGuarantee)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [agent, hasSession, route.params.rkey, uri])

  const onDone = (value: number) => {
    setStatus(value)
  }

  return {
    content:
      hasGuarantee && status === 0 ? (
        <Confirm uri={uri} cid={cid} onDone={onDone} />
      ) : null,
  }
}
