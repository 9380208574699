import {createMultiPathSVG} from './TEMPLATE'

export const GroupFill_Stroke2_Corner0_Rounded = createMultiPathSVG({
  viewBox: '0 0 21 21',
  paths: [
    'M3.90527 6.38216C3.90527 8.52383 5.58027 10.2572 7.76361 10.3322C7.83027 10.3238 7.89694 10.3238 7.94694 10.3322C7.96361 10.3322 7.97194 10.3322 7.98861 10.3322C7.99694 10.3322 7.99694 10.3322 8.00527 10.3322C10.1386 10.2572 11.8136 8.52383 11.8219 6.38216C11.8219 4.19883 10.0469 2.42383 7.86361 2.42383C5.68027 2.42383 3.90527 4.19883 3.90527 6.38216Z',
    'M3.63874 12.5482C2.5804 13.2566 1.99707 14.2149 1.99707 15.2399C1.99707 16.2649 2.5804 17.2149 3.6304 17.9149C4.79707 18.6982 6.3304 19.0899 7.86374 19.0899C9.39707 19.0899 10.9304 18.6982 12.0971 17.9149C13.1471 17.2066 13.7304 16.2566 13.7304 15.2232C13.7221 14.1982 13.1471 13.2482 12.0971 12.5482C9.77207 10.9982 5.9804 10.9982 3.63874 12.5482Z',
    'M14.4128 10.0974C14.4044 10.0974 14.4044 10.0974 14.3961 10.0974H14.3711C14.3211 10.0974 14.2711 10.0974 14.2294 10.1141C13.4211 10.1558 12.6794 9.89745 12.1211 9.42245C12.9794 8.65578 13.4711 7.50578 13.3711 6.25578C13.3128 5.58078 13.0794 4.96411 12.7294 4.43911C13.0461 4.28078 13.4128 4.18078 13.7878 4.14745C15.4211 4.00578 16.8794 5.22245 17.0211 6.87245C17.1544 8.48911 16.0044 9.90578 14.4128 10.0974Z',
    'M17.2372 16.5658C16.4038 17.0242 15.3538 17.2408 14.3122 17.2158C14.9122 16.6742 15.2622 15.9992 15.3288 15.2825C15.4122 14.2492 14.9205 13.2575 13.9372 12.4658C13.3788 12.0242 12.7288 11.6742 12.0205 11.4158C13.8622 10.8825 16.1788 11.2408 17.6038 12.3908C18.3705 13.0075 18.7622 13.7825 18.6872 14.5825C18.6205 15.3908 18.1038 16.0908 17.2372 16.5658Z',
  ],
})
