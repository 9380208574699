import React from 'react'
import {View} from 'react-native'

import {useTwitter} from '#/lib/hooks/useSocial'
import {usePalette} from 'lib/hooks/usePalette'
import * as Toast from '#/view/com/util/Toast'
import {Loader} from '#/components/Loader'
import {Text} from '../com/util/text/Text'

export const AuthorizeScreen = () => {
  const pal = usePalette('default')
  const {bindTwitter} = useTwitter()

  React.useEffect(() => {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    if (state === 'task_x_bind' && code) {
      const channel = new BroadcastChannel('channel')
      bindTwitter(code)
        .then(res => {
          channel.postMessage(res)
          window.close()
        })
        .catch(err => {
          Toast.show(JSON.stringify(err))
        })
      return
    }
  }, [bindTwitter])

  return (
    <View
      testID="authorizeView"
      style={{
        ...pal.view,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
      <Loader />
      <Text>Loading...</Text>
    </View>
  )
}
