import React, {createContext, ReactNode, useContext} from 'react'

interface CircleContextType {
  isCircle: boolean
}

const CircleContext = createContext<CircleContextType | undefined>(undefined)

interface CircleProviderProps {
  children: ReactNode
  isCircle: boolean
}

export const CircleProvider: React.FC<CircleProviderProps> = ({
  children,
  isCircle,
}) => {
  return (
    <CircleContext.Provider value={{isCircle}}>
      {children}
    </CircleContext.Provider>
  )
}

export const useCircle = (): boolean => {
  const context = useContext(CircleContext)
  if (!context) {
    return false
  }
  return context.isCircle
}
