import React, {useState} from 'react'
import {Image, Pressable, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {NavigationProp} from '#/lib/routes/types'
import {RightIcon} from '#/view/icons/ModalIcons'
import {CircleFromType} from '#/view/screens/Circle'
import {useTagList} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {FilterNormal_Stroke2_Corner0_Rounded as FilterNormal} from '#/components/icons/FilterNormal'
import {SettingsOpen as Settings} from '#/components/icons/SettingsGear2'
import {TelegramLogo} from '#/components/icons/StakeIcons'
import {EmptyBox} from '../util/EmptyBox'
import TagItem, {Tag} from './TagItem'

type Props = {
  selectedFromType?: CircleFromType
  onSelected?: (value: CircleFromType) => void
  onConfirm?: (tag: Tag[], teles?: string[]) => void
  renderExtra?: () => React.ReactNode
}

export const FROM_TYPE_OPTIONS = [
  {
    index: 0,
    key: 'telegram',
    name: 'Telegram',
    icon: <TelegramLogo size={20} />,
  },
  // {
  //   key: 'twitter',
  //   name: 'X(Twitter)',
  //   icon: <TelegramLogo size={20} />,
  // },
  {
    index: 1,
    key: 'sipz',
    name: 'SipZ',
    icon: (
      <Image
        source={require('../../../../assets/icon.png')}
        style={[{width: 20, height: 20, borderRadius: 10}]}
        accessibilityIgnoresInvertColors
      />
    ),
  },
]

export const TELE_FILTER_OPTIONS = [
  {
    key: 'registered',
    name: 'Registered sipz',
  },
  {
    key: 'unregistered',
    name: 'Unregistered sipz',
  },
]

export default function TagFilter({
  selectedFromType,
  onSelected,
  onConfirm,
  renderExtra,
}: Props) {
  const t = useTheme()
  const basic = Dialog.useDialogControl()
  const switchType = Dialog.useDialogControl()
  const {isDesktop} = useWebMediaQueries()
  const navigation = useNavigation<NavigationProp>()

  const {data: tags = []} = useTagList()
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [expands, setExpands] = useState<{[key: string]: boolean}>({
    tags: true,
  })
  const [selectedTeles, setSelectedTeles] = useState<string[]>(
    TELE_FILTER_OPTIONS?.map(o => o?.key),
  )

  const selectedHandle = (selected: boolean, tag: Tag) => {
    if (selected) {
      setSelectedTags(s => [...s, tag])
    } else {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
    }
  }

  const hasFilterOptions = selectedTags.length > 0 || selectedTeles.length === 1

  let content
  if (tags.length === 0) {
    content = (
      <View style={[a.align_center, a.pb_lg]}>
        <EmptyBox
          icon="notifications"
          message={'No tags yet'}
          viewStyles={{paddingTop: 0}}
        />
      </View>
    )
  } else {
    content = (
      <View style={[a.flex_row, a.gap_md, a.flex_wrap]}>
        <TagItem
          key={'all'}
          tag={{
            id: -1,
            name: 'All',
            selected: false,
          }}
          // showCheckbox
          selected={!!selectedTags.find(item => item.name === 'All')}
          onSelected={selectedHandle}
        />
        {tags.map(tag => (
          <TagItem
            key={tag.name}
            tag={tag}
            // showCheckbox
            selected={!!selectedTags.find(item => item.name === tag.name)}
            onSelected={selectedHandle}
          />
        ))}
      </View>
    )
  }

  return (
    <>
      <View style={[a.flex_row, a.align_center, a.justify_between, a.gap_md]}>
        <View style={[a.flex_row, a.align_center, a.gap_2xl, a.my_lg]}>
          {FROM_TYPE_OPTIONS?.map((o, index) => {
            const selected = selectedFromType === o?.key
            return (
              <Pressable
                accessibilityRole="button"
                onPress={() =>
                  onSelected?.(FROM_TYPE_OPTIONS[index]?.key as CircleFromType)
                }
                key={o?.key}
                style={[a.flex_col, a.align_center, a.justify_center]}>
                <Text
                  style={
                    selected
                      ? [a.text_xl, a.font_bold, t.atoms.text]
                      : [a.text_md, a.font_normal, t.atoms.text_sub]
                  }>
                  {o?.name}
                </Text>
                <View
                  style={[
                    a.mt_sm,
                    {
                      width: 30,
                      borderBottomWidth: 3,
                      borderBottomColor: 'transparent',
                    },
                    selected && {borderBottomColor: t.palette.primary},
                  ]}
                />
              </Pressable>
            )
          })}
        </View>

        <Pressable
          accessibilityRole="button"
          role="button"
          onPress={() => basic.open()}>
          <View
            style={[a.flex_row, a.gap_md, a.align_center, a.justify_center]}>
            {renderExtra?.()}
            <FilterNormal
              size="md"
              style={[
                {
                  color: !hasFilterOptions
                    ? t.atoms.text.color
                    : t.palette.primary_active,
                },
              ]}
            />
          </View>
        </Pressable>
      </View>
      {/* TagFilter-Dialog */}
      <Dialog.Outer control={basic}>
        <Dialog.Inner
          label="filter"
          style={[{minHeight: 360}, a.flex_col, a.p_lg, a.justify_between]}>
          {/* Header */}
          <View style={[a.flex_row, a.align_center, a.justify_center, a.pb_md]}>
            <Button
              label={'close'}
              style={[a.p_sm, a.absolute, {left: -5}]}
              onPress={() => basic.close()}>
              <Close fill={t.atoms.text.color} />
            </Button>
            <Text
              style={[
                a.font_semibold,
                a.text_center,
                {fontSize: 17},
                t.atoms.text,
              ]}>
              Filter
            </Text>
            {/* <Button
              style={[a.p_md, {width: 50}]}
              color="secondary"
              onPress={() => setCreateStatus('open')}
              label="Create"
              variant="solid"
              shape="square"
              size="tiny">
              <ButtonText>Create</ButtonText>
            </Button> */}
            <View />
          </View>
          {/* Content */}
          <View style={[a.flex_1]}>
            {/* Tags Title */}
            <Pressable
              accessibilityRole="button"
              style={[a.flex_row, a.align_center, a.justify_between]}
              role="button"
              onPress={() => {
                setExpands({...expands, tags: !expands.tags})
              }}>
              <Text style={[t.atoms.text, a.text_md, a.font_semibold, a.py_md]}>
                Tags
              </Text>
              <View style={[a.flex_row, a.align_center, a.gap_md]}>
                {false && (
                  <Pressable
                    accessibilityRole="button"
                    style={[a.flex_row, a.align_center, a.gap_sm]}
                    role="button"
                    onPress={() => {
                      navigation.navigate('TagList')
                    }}>
                    <Settings width={20} style={t.atoms.text} />
                    <Text style={[t.atoms.text, a.font_semibold]}>
                      Manage tags
                    </Text>
                  </Pressable>
                )}
                <RightIcon
                  style={[
                    !expands.tags
                      ? {transform: 'scale(1.2) rotate(90deg)', marginTop: 6}
                      : {
                          transform: 'scale(1.2) rotate(270deg)',
                          marginBottom: 6,
                        },
                  ]}
                  color={t.atoms.text.color}
                />
              </View>
            </Pressable>
            {/* Tags Content */}
            {expands.tags && (
              <View style={[a.flex_1, a.flex_wrap]}>{content}</View>
            )}
            <View style={[a.mb_lg]}>
              {/* Telegram Title */}
              <View style={[a.flex_row, a.align_center, a.justify_between]}>
                <Text
                  style={[t.atoms.text, a.text_md, a.font_semibold, a.py_md]}>
                  <Trans>Telegram</Trans>
                </Text>
              </View>
              {/* Telegram Content */}
              <View style={[a.flex_row, a.align_center, a.gap_md, a.flex_wrap]}>
                {TELE_FILTER_OPTIONS?.map(item => {
                  const isSelected = selectedTeles?.indexOf(item?.key) >= 0
                  return (
                    <Pressable
                      accessibilityRole="button"
                      role="button"
                      key={item?.key}
                      onPress={() => {
                        const index = selectedTeles?.indexOf(item?.key)
                        const data =
                          index >= 0
                            ? selectedTeles.filter(o => o !== item?.key)
                            : selectedTeles.concat([item?.key])
                        setSelectedTeles(data)
                      }}
                      style={[
                        a.flex_row,
                        a.border,
                        a.px_md,
                        a.align_center,
                        {height: 36, borderRadius: 8},
                        isSelected
                          ? {
                              backgroundColor: t.palette.yellow_30,
                              borderColor: t.palette.primary_active,
                            }
                          : {
                              ...t.atoms.bg_btn_default,
                              borderColor: 'transparent',
                            },
                      ]}>
                      <Text
                        style={[
                          t.atoms.text_sub,
                          a.text_xs,
                          a.text_center,
                          isSelected
                            ? [a.font_semibold, {color: t.palette.black_50}]
                            : a.font_normal,
                        ]}>
                        <Trans>{item?.name}</Trans>
                      </Text>
                    </Pressable>
                  )
                })}
              </View>
            </View>
          </View>
          {/* Footer Action */}
          <View
            style={[
              a.w_full,
              a.mt_lg,
              a.gap_md,
              isDesktop ? a.flex_row_reverse : {},
            ]}>
            <Button
              style={[a.p_md, isDesktop ? {flex: 2} : {}]}
              color="primary"
              onPress={() => {
                onConfirm?.(
                  selectedTags?.findIndex(o => o?.id === -1) >= 0
                    ? []
                    : selectedTags,
                  selectedTeles,
                )
                basic.close()
              }}
              label="Confirm"
              variant="solid"
              size="large">
              <ButtonText>Confirm</ButtonText>
            </Button>
            <Button
              style={[a.p_md, isDesktop ? {flex: 1} : {}]}
              color="secondary"
              onPress={() => {
                onConfirm?.([])
                setSelectedTags([])
                setSelectedTeles(TELE_FILTER_OPTIONS?.map(o => o?.key))
                basic.close()
              }}
              label="reset"
              variant="solid"
              size="large">
              <ButtonText>Reset</ButtonText>
            </Button>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
      {/* FromType-Dialog */}
      <Dialog.Outer control={switchType}>
        <Dialog.Inner
          label=""
          style={[{minHeight: 360}, a.justify_between, a.flex_col, a.p_lg]}>
          <View
            style={[
              a.flex_1,
              a.flex_col,
              a.align_center,
              a.justify_center,
              a.pb_3xl,
              a.gap_md,
            ]}>
            <Pressable
              accessibilityRole="button"
              style={[
                {height: 56, borderRadius: 6},
                a.w_full,
                a.flex_row,
                a.align_center,
                a.justify_center,
                t.atoms.input_border,
                t.atoms.input_bg,
              ]}>
              <Text style={[a.text_md, a.font_semibold, t.atoms.text]}>
                Telegram
              </Text>
            </Pressable>
            <Pressable
              accessibilityRole="button"
              style={[
                {height: 56, borderRadius: 6},
                a.w_full,
                a.flex_row,
                a.align_center,
                a.justify_center,
                t.atoms.input_border,
                t.atoms.input_bg,
              ]}>
              <Text style={[a.text_md, a.font_semibold, t.atoms.text]}>
                SipZ
              </Text>
            </Pressable>
          </View>
          <View style={[a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
            <Button
              style={[a.p_md, isDesktop ? a.flex_1 : {}]}
              color="secondary"
              onPress={() => {
                switchType.close()
              }}
              label="reset"
              variant="solid"
              size="large">
              <ButtonText>Cancel</ButtonText>
            </Button>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
    </>
  )
}
