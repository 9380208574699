import React, {useEffect, useMemo} from 'react'
import {Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'
import {useQueryClient} from '@tanstack/react-query'
import dayjs from 'dayjs'

import {
  CommonNavigatorParams,
  NativeStackScreenProps,
  NavigationProp,
} from '#/lib/routes/types'
import {useSession} from '#/state/session'
import {getJobTypes} from '#/view/com/modals/VerifyEmail'
import {getIsOpreration} from '#/view/com/util/access/OperationUtil'
import {List} from '#/view/com/util/List'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {ScrollView} from '#/view/com/util/Views'
import {atoms as a, Theme, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Toggle from '#/components/forms/Toggle'
import {Refresh} from '#/components/icons/Refresh'
import {Loader} from '#/components/Loader'
import {useAuditDialog} from './dialog'
import {useAuditQuery} from './hooks'
// import { useNavigation } from '@react-navigation/native'
// import { getIsOpreration } from '#/view/com/util/access/OperationUtil'
// import { useSession } from '#/state/session'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'AuditScreen'>

const getStatue = (status: number, t: Theme) => {
  if (status === 0) {
    return <Text style={[{color: t.palette.primary}]}>Pending</Text>
  } else if (status === 1) {
    return <Text style={[{color: t.palette.green_500}]}>Approved</Text>
  } else if (status === 2) {
    return <Text style={[t.atoms.text_danger]}>Rejected</Text>
  } else {
    return 'Unknown'
  }
}

export function AuditScreen({}: Props) {
  const {data, isLoading} = useAuditQuery()
  const {control, dialog, setCurrent} = useAuditDialog()
  const queryClient = useQueryClient()
  const t = useTheme()
  const [toggleGroupBValues, setToggleGroupBValues] = React.useState([
    '0',
    '1',
    '2',
  ])

  const {currentAccount} = useSession()
  const navigation = useNavigation<NavigationProp>()
  useEffect(() => {
    // TODO: scroll to bottom
    if (getIsOpreration(currentAccount?.did || '')) {
      navigation.navigate('NotFound')
    }
  }, [currentAccount?.did, navigation])

  const renderItem = ({item}: {item: any}) => {
    const job = getJobTypes().find(j => j.id === item.jobType)
    const status = getStatue(item.status, t)
    return (
      <Button
        label=""
        key={item.id}
        onPress={() => {
          if (item.status === 0) {
            setCurrent(item)
            control.open()
          }
        }}>
        <View style={[a.p_md]}>
          <View style={[a.gap_xs]}>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <Text style={[t.atoms.text]}>
                <Trans>Name:</Trans>
              </Text>
              <Text style={[t.atoms.text]}>{item.name}</Text>
            </View>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <Text style={[t.atoms.text]}>
                <Trans>Job:</Trans>
              </Text>
              <Text style={[t.atoms.text]}>{job?.title}</Text>
            </View>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <Text style={[t.atoms.text]}>
                <Trans>Email:</Trans>
              </Text>
              <Text style={[t.atoms.text]}>{item.email}</Text>
            </View>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <Text style={[t.atoms.text]}>
                <Trans>Time:</Trans>
              </Text>
              {item.createdAt && (
                <Text style={[t.atoms.text]}>
                  {dayjs(item.createdAt).format('MMM/DD/YYYY HH:ss')}
                </Text>
              )}
            </View>
            <View style={[a.flex_row, a.align_center, a.gap_sm]}>
              <Text style={[t.atoms.text]}>
                <Trans>Status:</Trans>
              </Text>
              {<Text style={[t.atoms.text]}>{status}</Text>}
            </View>
          </View>
        </View>
      </Button>
    )
  }

  const keyExtractor = (item: any) => {
    return item.id
  }

  const onRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ['getUserEmails'],
    })
  }

  const filters = useMemo(() => {
    const list = toggleGroupBValues.map(val => +val)
    return data?.filter(item => {
      return list.includes(item.status)
    })
  }, [data, toggleGroupBValues])

  const renderButton = () => {
    if (isLoading) {
      return <Loader size="sm" />
    }
    return (
      <Button label="refresh" onPress={onRefresh}>
        <Refresh width={24} height={24} style={[t.atoms.text]} />
      </Button>
    )
  }

  return (
    <CenteredView>
      <ViewHeader canGoBack title="Audit" renderButton={renderButton} />
      <View>
        <Toggle.Group
          label="Toggle"
          type="checkbox"
          values={toggleGroupBValues}
          onChange={setToggleGroupBValues}>
          <View
            style={[
              a.flex_row,
              a.flex_wrap,
              a.justify_center,
              a.p_md,
              a.gap_md,
            ]}>
            <Toggle.Item name="0" label="Pending">
              <Toggle.Checkbox />
              <Toggle.LabelText style={[{color: t.palette.primary}]}>
                Pending
              </Toggle.LabelText>
            </Toggle.Item>
            <Toggle.Item name="1" label="Approved">
              <Toggle.Checkbox />
              <Toggle.LabelText style={[{color: t.palette.green_500}]}>
                Approved
              </Toggle.LabelText>
            </Toggle.Item>
            <Toggle.Item name="2" label="Rejected">
              <Toggle.Checkbox />
              <Toggle.LabelText style={[t.atoms.text_danger]}>
                Rejected
              </Toggle.LabelText>
            </Toggle.Item>
          </View>
        </Toggle.Group>
      </View>
      <ScrollView>
        <List
          testID="AuditList"
          data={filters}
          onRefresh={onRefresh}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </ScrollView>
      {dialog}
    </CenteredView>
  )
}
