import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {LayoutChangeEvent, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

// import {useTheme} from '#/alf'

interface ICredibility {
  array: any[]
  keyName: string
  renderItem: React.FC<any>
  height: number
  gap?: number
  isTarget?: boolean
  moreContent?: React.ReactNode
  isFromFeed?: boolean
}

export function EllipsisBox({
  array,
  keyName,
  renderItem,
  height,
  gap = 5,
  isTarget,
  moreContent,
  isFromFeed,
}: ICredibility) {
  const ref = useRef(0)
  // const t = useTheme()
  const [pos, setPos] = useState({
    x: 0,
    y: 0,
  })
  const [firstRowCount, setFirstRowCount] = useState(0)
  const [freshKey, setFreshKey] = useState(0)

  const handleItemLayout = () => (event: LayoutChangeEvent) => {
    const {y, x, width} = event.nativeEvent.layout
    if (y < height && (!isTarget || ref.current === 0)) {
      setFirstRowCount(pre => pre + 1)
      setPos({
        x: x + width + 5,
        y: y,
      })
    }
  }

  useEffect(() => {
    setFirstRowCount(0)
    setFreshKey(pre => pre + 1)
  }, [array])

  const residue = useMemo(() => {
    const number = array.length - firstRowCount
    if (number > 0) {
      return number
    } else {
      return 0
    }
  }, [array.length, firstRowCount])

  useLayoutEffect(() => {
    setTimeout(() => {
      ref.current = 1
    }, 500)
  }, [])

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxHeight: height,
        gap: gap,
        paddingRight: isFromFeed ? 0 : 40,
        overflow: 'hidden',
        position: 'relative',
        paddingTop: isFromFeed ? 10 : 5,
      }}>
      {isTarget && !isFromFeed && (
        <Text
          style={{
            fontSize: 14,
            fontWeight: '600',
            color: '#979797',
            height: 25,
            lineHeight: 23,
            marginRight: 10,
          }}>
          <Trans>Spill about:</Trans>
        </Text>
      )}
      {array.map(item => (
        <View
          key={isTarget ? item[keyName] : item[keyName] + freshKey}
          onLayout={handleItemLayout()}>
          {renderItem(item)}
        </View>
      ))}
      {!!residue &&
        !isTarget &&
        (moreContent ? (
          <View style={{position: 'absolute', left: pos.x, top: pos.y}}>
            {moreContent}
          </View>
        ) : (
          <View
            style={{
              position: 'absolute',
              left: pos.x,
              top: pos.y,
              height: 23,
              borderRadius: 100,
              paddingHorizontal: 5,
              backgroundColor: '#E8E8E8',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text
              style={{
                fontSize: 12,
                color: '#5E6272',
                fontWeight: '500',
              }}>
              +{residue}
            </Text>
          </View>
        ))}
    </View>
  )
}
