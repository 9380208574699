import {createMultiPathSVG, createSinglePathSVG} from './TEMPLATE'

export const ArrowTriangleBottom_Stroke2_Corner1_Rounded = createSinglePathSVG({
  path: 'M4.213 6.886c-.673-1.35.334-2.889 1.806-2.889H17.98c1.472 0 2.479 1.539 1.806 2.89l-5.982 11.997c-.74 1.484-2.87 1.484-3.61 0L4.213 6.886Z',
})

export const ArrowTriangleBottom_Stroke2_Corner1 = createMultiPathSVG({
  viewBox: '0 0 16 16',
  paths: [
    'M7.32018 10.3623L4.79672 7.72464C4.48938 7.40338 4.42079 7.0358 4.59097 6.62188C4.76114 6.20797 5.06428 6.00068 5.50038 6H10.4988C10.9355 6 11.239 6.20729 11.4092 6.62188C11.5793 7.03647 11.5104 7.40406 11.2024 7.72464L8.67897 10.3623C8.58191 10.4638 8.47677 10.5399 8.36354 10.5906C8.2503 10.6413 8.12898 10.6667 7.99958 10.6667C7.87017 10.6667 7.74885 10.6413 7.63562 10.5906C7.52238 10.5399 7.41724 10.4638 7.32018 10.3623Z',
  ],
})
