import React, {useCallback, useState} from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  Pressable,
  StyleSheet,
  View,
} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useFocusEffect} from '@react-navigation/native'
import dayjs from 'dayjs'
import {ethers} from 'ethers'

import {usePalette} from '#/lib/hooks/usePalette'
import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {ScrollProvider} from '#/lib/ScrollContext'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {useModalControls} from '#/state/modals'
import {usePayWalletQuery} from '#/state/queries/link-wallet'
import {useGuarantorListQuery} from '#/state/queries/post-thread'
import {useProfileQuery} from '#/state/queries/profile'
import {
  BetListProps,
  StakeDonateListProps,
  useBetAmountListQuery,
  useDonateListQuery,
  useStakeListQuery,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import {useStake} from '#/state/transaction/stake'
import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {Button} from '#/view/com/util/forms/Button'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {Text} from 'view/com/util/text/Text'
import {CenteredView} from 'view/com/util/Views'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useBreakpoints, useTheme} from '#/alf'
import {ExpandIcon} from '#/components/icons/StakeIcons'
import {ListHeaderDesktop} from '#/components/Lists'
import {EmptyState} from '../com/util/EmptyState'
import {formatShortOnly} from '../com/util/numeric/format'
import {useBottomBarStore} from '../com/util/sdlStore/BottomBarStore'
import {PostGuarantorList} from './GuarantorList'

export type BetListType =
  | 'stake'
  | 'donate'
  | 'bet-on'
  | 'bet-against'
  | 'guarantors'
type Props = NativeStackScreenProps<CommonNavigatorParams, 'BetList'>
export function BetListScreen(props: Props) {
  /* eslint-disable */
  const {did, dataType, rkey} = props.route.params
  const {hideBottomBar} = useBottomBarStore()

  const {_} = useLingui()
  const t = useTheme()
  const pal = usePalette('default')
  const {gtTablet} = useBreakpoints()
  const {data: postProfile} = useProfileQuery({did})
  const {currentWallet} = useWallet()
  const uri = makeRecordUri(did ?? '', 'app.bsky.feed.post', rkey)
  const {isDesktop} = useWebMediaQueries()

  const {openModal} = useModalControls()
  const {currentAccount} = useSession()

  const {data: payWalletAddress} = usePayWalletQuery(
    did,
    dataType === 'donate' ? true : false,
    1,
  )
  const {data: stakeList, isLoading: stakeLoading} = useStakeListQuery({
    uri,
    dataType,
    did: currentAccount?.did,
    postDid: did,
  })
  const {data: donateList, isLoading: donateLoading} = useDonateListQuery({
    uri,
    dataType,
    did: currentAccount?.did,
    postDid: did,
  })
  const {data: betOnList, isLoading: betOnLoading} = useBetAmountListQuery({
    uri,
    dataType,
    did: currentAccount?.did,
    postDid: did,
  })
  const {data: betAgainstList, isLoading: betAgainstLoading} =
    useBetAmountListQuery({
      uri,
      dataType,
      did: currentAccount?.did,
      postDid: did,
    })
  const {data: post, isLoading: guarantorLoading} = useGuarantorListQuery({
    uri,
    dataType,
  })

  const transLoading = React.useMemo(() => {
    return dataType === 'stake'
      ? stakeLoading
      : dataType === 'bet-on'
      ? betOnLoading
      : dataType === 'bet-against'
      ? betAgainstLoading
      : dataType === 'guarantors'
      ? guarantorLoading
      : donateLoading
  }, [
    dataType,
    stakeLoading,
    betOnLoading,
    betAgainstLoading,
    guarantorLoading,
    donateLoading,
  ])

  const transList = React.useMemo(() => {
    return dataType === 'stake'
      ? stakeList
      : dataType === 'bet-on'
      ? betOnList
      : dataType === 'bet-against'
      ? betAgainstList
      : donateList
  }, [dataType, stakeList, betOnList, betAgainstList, donateList])

  useFocusEffect(
    useCallback(() => {
      hideBottomBar()
    }, [hideBottomBar]),
  )

  const pageConfig = {
    stake: {
      title: _(msg`Endorsed by`),
    },
    donate: {
      title: _(msg`Tipped by`),
    },
    'bet-on': {
      title: _(msg`Bet on`),
    },
    'bet-against': {
      title: _(msg`Bet against`),
    },
    guarantors: {
      title: _(msg`Guarantors`),
    },
  }

  const {showStakeModal} = useStake({
    type: 'stake',
    rKey: rkey,
    transPostAuthor: {
      did: postProfile?.did ?? '',
      payWallet: payWalletAddress,
      avatar: postProfile?.avatar ?? '',
      displayName:
        postProfile?.displayName && postProfile?.displayName !== ''
          ? postProfile?.displayName
          : formatHandler(postProfile?.handle ?? ''),
    },
  })

  const [expanded, setExpanded] = useState<{[key: number]: boolean}>({})
  const toggleExpand = (index: number) => {
    setExpanded(prev => ({
      ...prev,
      [index]: !prev[index],
    }))
  }

  const renderItemInner = React.useCallback(
    ({
      item,
      index,
    }: {
      item: StakeDonateListProps | BetListProps
      index: number
    }) => {
      const currency = item?.trans?.[0]?.currency
      const lastTime = item?.trans?.[0]?.updateAt
      const isYou = item?.sortKey === 2
      const isSpiller = item.sortKey === 3
      return (
        <View style={[styles.listRecordContaner]} key={item.payer}>
          <View style={[styles.listRecord]}>
            <View>
              <UserAvatar type="user" size={44} avatar={item.profile?.avatar} />
            </View>
            <View style={styles.listUser}>
              <View style={[a.mb_xs, a.flex_row, a.align_center, a.gap_sm]}>
                {item?.profile?.displayName && (
                  <Text style={[t.atoms.text, a.text_md, a.font_semibold]}>
                    {item?.profile?.displayName}
                  </Text>
                )}
                {isSpiller ? (
                  <View
                    style={[
                      a.px_xs,
                      {
                        paddingVertical: 2,
                        backgroundColor: t.palette.primary,
                        borderRadius: 4,
                      },
                    ]}>
                    <Text style={[a.text_xs, a.font_semibold]}>Spiller</Text>
                  </View>
                ) : (
                  isYou && (
                    <View
                      style={[
                        a.px_xs,
                        {
                          paddingVertical: 2,
                          backgroundColor: t.palette.green_500,
                          borderRadius: 4,
                        },
                      ]}>
                      <Text
                        style={[
                          a.text_xs,
                          a.font_semibold,
                          {color: t.palette.white},
                        ]}>
                        You
                      </Text>
                    </View>
                  )
                )}
              </View>
              <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                {item?.profile?.handle
                  ? `@${formatHandler(item?.profile?.handle)}`
                  : ''}
              </Text>
            </View>
            <View>
              <Text
                style={[
                  a.mb_xs,
                  t.atoms.text,
                  a.text_sm,
                  a.font_bold,
                  a.text_right,
                  dataType === 'bet-on' ? {color: t.palette.primary} : {},
                  dataType === 'bet-against'
                    ? {color: t.palette.yellow_25}
                    : {},
                ]}>
                {formatShortOnly(item?.total, 2)} {currency?.toString()}
              </Text>
              <Pressable onPress={() => toggleExpand(index)}>
                <View style={[a.flex_row, a.align_center]}>
                  <Text style={[{color: t.palette.gray_10}]}>
                    {lastTime
                      ? dayjs(lastTime).format('MMM DD, YYYY HH:mm')
                      : ''}
                  </Text>
                  {item?.trans?.length > 1 && (
                    <ExpandIcon
                      primaryColor={t.palette.black_25}
                      style={[
                        {
                          transform: expanded[index] ? '' : 'rotate(180deg)',
                        },
                      ]}
                    />
                  )}
                </View>
              </Pressable>
            </View>
          </View>
          {expanded[index]
            ? item?.trans?.map(child => {
                return (
                  <View style={[styles.childRecord, a.mt_md]}>
                    <Text style={{color: t.palette.gray_10}}>
                      {dayjs(child?.updateAt).format('MMM DD, YYYY HH:mm')}
                    </Text>
                    <Text
                      style={[
                        t.atoms.text,
                        a.text_xs,
                        a.font_bold,
                        a.text_right,
                      ]}>
                      {formatShortOnly(
                        Number(ethers.formatUnits(child?.amount, 6)),
                        2,
                      )}{' '}
                      {currency?.toString()}
                    </Text>
                  </View>
                )
              })
            : null}
        </View>
      )
    },
    [t.atoms.text, expanded],
  )

  if (dataType === 'guarantors' && post) {
    return (
      <PostGuarantorList
        did={did}
        rkey={rkey}
        loading={transLoading}
        post={post}
      />
    )
  }

  return (
    <CenteredView
      testID="betListPage"
      style={[a.flex_1]}
      sideBorders={isDesktop ? true : false}>
      <ViewHeader title={pageConfig?.[dataType]?.title} showBorder />
      <ListHeaderDesktop title={pageConfig?.[dataType]?.title} />
      <ScrollProvider>
        <View
          style={[
            styles.contentContainer,
            pal.border,
            {borderTopWidth: gtTablet ? 1 : 0},
          ]}>
          {transLoading ? (
            <ActivityIndicator size="large" color={t.palette.primary} />
          ) : transList && transList?.length > 0 ? (
            // @ts-ignore
            <RNFlatList
              testID={dataType ? `${dataType}-flatlist` : undefined}
              data={transList}
              renderItem={renderItemInner}
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <View>
              <EmptyState
                icon="list-ul"
                message={_(msg`List is empty.`)}
                style={[{borderWidth: 0}]}
              />
            </View>
          )}
        </View>
      </ScrollProvider>
      {dataType === 'stake' && (
        <View style={[styles.actionContainer]}>
          <Button
            testID="withdrawBtn"
            type="default"
            accessibilityLabel={_(msg`Withdraw`)}
            accessibilityHint=""
            label={_(msg`Withdraw`)}
            onPress={() => {
              if (!currentWallet?.publicKey) {
                openModal({name: 'connect-wallet', type: 'connect'})
                return
              }
              openModal({
                name: 'withdraw-preview',
                rKey: rkey,
                transPostAuthor: {
                  did: postProfile?.did ?? '',
                  payWallet: payWalletAddress,
                  avatar: postProfile?.avatar ?? '',
                  displayName:
                    postProfile?.displayName && postProfile?.displayName !== ''
                      ? postProfile?.displayName
                      : formatHandler(postProfile?.handle ?? ''),
                },
              })
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, a.font_semibold]}
            style={[styles.actionBtn]}
          />
          <Button
            testID="confirmBtn"
            type="default"
            accessibilityLabel={_(msg`Stake`)}
            accessibilityHint=""
            label={_(msg`Stake`)}
            onPress={() => {
              showStakeModal()
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, a.font_semibold, {color: t.palette.black}]}
            style={[styles.actionBtn, {backgroundColor: t.palette.primary}]}
          />
        </View>
      )}
      {dataType === 'donate' && (
        <View style={[styles.actionContainer]}>
          <Button
            testID="confirmBtn"
            type="primary"
            accessibilityLabel={_(msg`Tipping`)}
            accessibilityHint=""
            label={_(msg`Tipping`)}
            onPress={() => {
              // openModal({
              //   name: 'stake-donate',
              //   type: 0,
              //   rKey: rkey,
              //   transPostAuthor: {
              //     did: profile?.did ?? '',
              //     payWallet: '',
              //     avatar: profile?.avatar ?? '',
              //     displayName: profile?.displayName ?? '',
              //   },
              // })
              showStakeModal('donate')
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, a.font_semibold, {color: t.palette.black}]}
            style={[styles.actionBtn, {backgroundColor: t.palette.primary}]}
          />
        </View>
      )}
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 15,
  },
  actionContainer: {
    paddingHorizontal: 10,
    height: 100,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#97979733',
  },
  actionBtn: {
    flex: 1,
  },
  listRecordContaner: {
    marginBottom: 15,
  },
  listRecord: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  childRecord: {
    marginLeft: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listUser: {
    flex: 1,
  },
})
