import React, {useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {PUBLIC_ENV_CHAINID} from '#/lib/constants'
import {donate} from '#/lib/ethers/donate'
import {makeRecordUri} from '#/lib/strings/url-helpers'
import {logger} from '#/logger'
import {useModalControls} from '#/state/modals'
import {
  useCreateTransMutation,
  useUpdateTransMutation,
} from '#/state/queries/transaction-bet'
import {useSession} from '#/state/session'
import {useWallet} from '#/state/shell/wallet'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {cleanError} from 'lib/strings/errors'
import * as Toast from 'view/com/util/Toast'
import {atoms as a, useTheme} from '#/alf'
import {TokenInput} from '../auth/util/TextInput'
import {StakeDonateModalProps} from '../modals/StakeDonate'
import {ErrorMessage} from '../util/error/ErrorMessage'
import {Button} from '../util/forms/Button'
import {simplifyMsg} from '../util/Transaction'
import {AnonSpillerAvatar, UserAvatar} from '../util/UserAvatar'

export const DonateTab = (props: StakeDonateModalProps) => {
  const {transPostAuthor} = props
  const {_} = useLingui()
  const t = useTheme()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {openModal, closeModal} = useModalControls()
  const {provider, currentWallet, onDisConnectWallet} = useWallet()
  const createTransMutation = useCreateTransMutation()
  const updateTransMutation = useUpdateTransMutation()
  const {currentAccount} = useSession()

  const postId = `${transPostAuthor?.did?.split(':')?.[2]}/${props.rKey}`
  const uri = makeRecordUri(
    transPostAuthor?.did ?? '',
    'app.bsky.feed.post',
    props.rKey ?? '',
  )

  const [amount, setAmount] = useState<string | undefined>()
  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  async function onDonate() {
    console.log('onDonate-click', currentWallet, provider)
    try {
      if (!transPostAuthor?.payWallet) {
        setError('The receiver cannot be empty.')
        return
      }
      if (!currentWallet?.publicKey) {
        openModal({name: 'connect-wallet', type: 'connect'})
        return
      }
      console.log('onDonate', currentWallet)
      if (!amount) {
        setError('Input amount')
        return
      }
      setPending(true)
      const tx = await donate(
        provider,
        Number(amount),
        PUBLIC_ENV_CHAINID,
        postId,
        transPostAuthor?.payWallet,
      )
      console.log('onDonateHandler', tx)
      if (tx) {
        try {
          const res = await createTransMutation.mutateAsync({
            param: {
              id: tx?.hash,
              payer: currentAccount?.did ?? '',
              payload: JSON.stringify({receiver: transPostAuthor.did}),
              method: 'insider-donation.donate',
            },
          })
          console.log('createTransMutation', res)
        } catch (error) {}
        const reception = await tx.wait()
        console.log('unStakeHandler-reception', reception)
        // if (reception?.transactionHash) { // ethers5
        if (reception?.hash) {
          // ethers6
          try {
            const res = await updateTransMutation.mutateAsync({
              param: {
                repo: currentAccount?.did ?? '',
                collection: 'app.bsky.feed.donate',
                record: {
                  uri: uri,
                  transId: reception?.hash,
                  method: 'insider-donation.donate',
                  amount: Number(amount) * 1000000,
                },
              },
            })
            console.log('updateTransMutation', res)
          } catch (error) {}
          setPending(false)
          closeModal()
          openModal({name: 'trans-result', type: 'donate', amount})
        }
      }
    } catch (err) {
      logger.error(`donate:`, {message: err})
      setPending(false)
      let reason =
        (err as any)?.reason ?? (err as any)?.message ?? (err as any)?.error
      reason = simplifyMsg(reason)
      if (reason) {
        if (reason.indexOf('connect() before request()') >= 0) {
          await onDisConnectWallet()
        } else {
          Toast.show(`Transaction failed: ${reason}`, 'xmark')
        }
      } else {
        Toast.show(
          _(msg`Transaction failed: this operation caused some error`),
          'xmark',
        )
      }
    }
  }

  return (
    <View style={[a.mt_xl, isMobile && {paddingHorizontal: 18}]}>
      <TokenInput
        testID="donateAmount"
        tokenSymbol="USDC"
        placeholder="0.00"
        defaultValue=""
        value={amount}
        onChange={value => {
          setAmount(value)
          setError(undefined)
        }}
      />
      {error && error !== '' ? (
        <ErrorMessage message={cleanError(error)} style={styles.error} />
      ) : undefined}
      <View style={[a.pt_xl, a.pb_xl, a.flex_row, styles.donateTo]}>
        <Text style={[a.text_sm, a.pr_md, t.atoms.text]}>To:</Text>
        {transPostAuthor?.isAnonymousPost ? (
          <AnonSpillerAvatar size={24} profile={transPostAuthor} />
        ) : (
          <UserAvatar avatar={transPostAuthor?.avatar} size={24} />
        )}
        <Text style={[a.ml_xs, a.text_sm, a.font_semibold, t.atoms.text]}>
          {transPostAuthor?.isAnonymousPost
            ? 'Anonymous'
            : transPostAuthor?.displayName}
        </Text>
      </View>
      <View style={[a.mt_xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="default"
          disabled={pending}
          withLoading={true}
          accessibilityLabel={_(msg`${pending ? 'Pending' : 'Confirm'}`)}
          accessibilityHint=""
          label={_(msg`${pending ? 'Pending' : 'Confirm'}`)}
          onPress={onDonate}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Cancel`)}
          accessibilityHint=""
          label={_(msg`Cancel`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 5,
    height: 5,
    backgroundColor: '#666',
    borderRadius: 5,
    marginRight: 5,
  },
  donateTo: {
    color: '#666',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  error: {
    borderRadius: 6,
    marginTop: 10,
  },
})
