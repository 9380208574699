import {useCallback, useEffect, useState} from 'react'
import axios from 'axios'

import {useAgent, useSession} from '#/state/session'
import {GA} from '#/utils/AnalyticsService'
export function fuzzyMatch(matchText: string, searchInput: string): boolean {
  if (!searchInput) return false
  const lowerMatchText = matchText.toLowerCase()
  const lowerSearchInput = searchInput.toLowerCase()
  let matchIndex = 0
  let searchInputIndex = 0

  while (
    matchIndex < lowerMatchText.length &&
    searchInputIndex < lowerSearchInput.length
  ) {
    if (lowerMatchText[matchIndex] === lowerSearchInput[searchInputIndex]) {
      searchInputIndex++
    }
    matchIndex++
  }
  return searchInputIndex === lowerSearchInput.length
}

const googleKey = 'AIzaSyAD6G43xYxViKYXw4JcmunKzH6cy3jRyXo'

export async function TranslateText(str: string | string[], target?: string) {
  const sourceType = typeof str
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${googleKey}`,
      {
        q: str || '',
        target: target || 'en',
        format: 'text',
      },
    )
    if (sourceType === 'string') {
      return response.data.data.translations[0].translatedText
    } else {
      return response.data.data.translations.map(
        (item: any) => item.translatedText,
      )
    }
  } catch (error) {
    console.error('Error translating text:', error.message)
    return ''
  }
}

export const DetectLanguage = async (text: string) => {
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2/detect?key=${googleKey}`,
      null,
      {
        params: {
          q: text,
        },
      },
    )

    const language = response.data.data.detections[0][0].language
    return language
  } catch (error) {
    console.error('Error detecting language:', error.message)
    return ''
  }
}

export const getTgInitData = function (initData: string) {
  let parsedObject
  if (initData) {
    const startParam = getStartParamFromUrl(initData)
    if (startParam) {
      parsedObject = parseQueryString(atob(startParam))
    }
  }
  return parsedObject || {}
}

function getStartParamFromUrl(url: string): string | null {
  const regex = /[?&]start_param=([^&]+)/
  const match = url.match(regex)
  if (match && match[1]) {
    return decodeURIComponent(match[1])
  }
  return null
}

function parseQueryString(queryString: string): {[key: string]: string} {
  if (queryString.startsWith('/?')) {
    queryString = queryString.substring(2)
  }
  const params = queryString.split('&')
  const result: {[key: string]: string} = {}
  params.forEach(param => {
    const [key, value] = param.split('=')
    if (key && value) {
      let invite = ''
      if (key.includes('invite_handler')) {
        invite = 'invite_handler'
      }
      result[invite ? invite : key] = decodeURIComponent(value)
    }
  })
  return result
}
export function useCheckVersion() {
  const agent = useAgent()
  const [newVersion, setNewVersion] = useState('')
  const [isVersionOutdated, setIsVersionOutdated] = useState(false)

  const refreshPage = useCallback(() => {
    localStorage.setItem('sipz_version', newVersion)
    window.location.href = window.location.origin + '/'
  }, [newVersion])

  useEffect(() => {
    const checkVersion = () => {
      agent.com.atproto.server
        .getTranslation({
          uri: 'sipzVersion',
          lang: 'en',
        })
        .then(sdlTranslate => {
          if (sdlTranslate.data.ok) {
            const version = sdlTranslate.data.translation as 'string'
            setNewVersion(version)
            const storedVersion = localStorage.getItem('sipz_version')
            if (storedVersion && storedVersion !== version) {
              setIsVersionOutdated(true)
            }
            if (!storedVersion) {
              localStorage.setItem('sipz_version', version)
            }
          }
        })
        .catch(error => {
          console.error('Error reading version.txt:', error)
        })
    }
    checkVersion()
    const timer = setInterval(() => {
      checkVersion()
    }, 1000 * 60 * 5)
    return () => {
      clearInterval(timer)
    }
  }, [agent.com.atproto.server])

  return {isVersionOutdated, refreshPage}
}

export const useLoginTrack = () => {
  const agent = useAgent()
  const {currentAccount} = useSession()

  const storeLastLoginTime = useCallback(() => {
    if (!currentAccount?.handle) return
    agent.com.atproto.server
      .storeTranslation({
        uri: currentAccount?.handle,
        cid: currentAccount?.handle,
        lang: 'en',
        translation: new Date().toISOString(),
      })
      .catch(() => {})
  }, [agent.com.atproto.server, currentAccount?.handle])

  const storeFirstLogin = useCallback(() => {
    GA.loginEvent(currentAccount?.handle || 'unknownPerson')
    storeLastLoginTime()
  }, [currentAccount?.handle, storeLastLoginTime])

  const checkFirstLogin = useCallback(() => {
    if (!currentAccount?.handle) return
    agent.com.atproto.server
      .getTranslation({
        uri: currentAccount?.handle,
        lang: 'en',
      })
      .then(sdlTranslate => {
        if (sdlTranslate.data.ok) {
          const now = new Date().toISOString()
          const date = sdlTranslate.data.translation as 'string'
          const date1 = new Date(now)
          const date2 = new Date(date)
          const diffInMilliseconds = date1.getTime() - date2.getTime()
          const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)
          if (Math.abs(diffInDays) > 1) {
            storeFirstLogin()
          }
        } else {
          storeFirstLogin()
        }
      })
      .catch(error => {
        console.error('Error reading version.txt:', error)
      })
  }, [agent.com.atproto.server, currentAccount?.handle, storeFirstLogin])

  useEffect(() => {
    checkFirstLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useFreeSave = () => {
  const agent = useAgent()
  const {currentAccount} = useSession()

  const saveFreeValue = useCallback(
    (key: string, value: string) => {
      if (!currentAccount?.handle) return
      agent.com.atproto.server
        .storeTranslation({
          uri: `${currentAccount?.handle}${key}`,
          cid: currentAccount?.handle,
          lang: 'en',
          translation: value,
        })
        .catch(() => {})
    },
    [agent.com.atproto.server, currentAccount?.handle],
  )

  const getFreeValue = (key: string) => {
    return new Promise<string>(resolve => {
      if (!currentAccount?.handle) resolve('')
      agent.com.atproto.server
        .getTranslation({
          uri: `${currentAccount?.handle}${key}`,
          lang: 'en',
        })
        .then(sdlTranslate => {
          if (sdlTranslate.data.ok) {
            resolve(sdlTranslate.data.translation as 'string')
          } else {
            resolve('')
          }
        })
        .catch(() => {
          resolve('')
        })
    })
  }

  return {
    saveFreeValue,
    getFreeValue,
  }
}
