import {BskyAgent} from '@atproto/api'

export enum FriendTag {
  self = '1st',
  first = '1st',
  second = '2nd',
}

const relationship = new Map<string, FriendTag>()

const loading = new Map<string, boolean>()

const eventsMap = new Map<string, Function[]>()

export const clearRelationship = () => {
  relationship.clear()
}

export const getRelationship = async (
  key: string,
  agent: BskyAgent,
): Promise<FriendTag> => {
  return new Promise<FriendTag>(async resolve => {
    let result = relationship.get(key)
    if (result) {
      resolve(result)
    } else if (loading.get(key)) {
      const events = eventsMap.get(key)
      if (events) {
        events.push(resolve)
      } else {
        eventsMap.set(key, [resolve])
      }
    } else {
      try {
        loading.set(key, true)
        const rl = await agent.com.atproto.server.getConnections({
          sipz_id_other: key,
        })
        if (eventsMap.get(key)) {
          const events = eventsMap.get(key)
          if (events) {
            events.forEach(event => event(handleResult(rl.data.relation)))
          }
          eventsMap.delete(key)
        }
        loading.delete(key)
        if (rl?.data?.relation) {
          relationship.set(key, handleResult(rl.data.relation))
          resolve(handleResult(rl.data.relation))
        } else {
          resolve(FriendTag.second)
        }
      } catch (error) {
        loading.delete(key)
        console.error(error)
        if (eventsMap.get(key)) {
          const events = eventsMap.get(key)
          if (events) {
            events.forEach(event => event([]))
          }
          eventsMap.delete(key)
        }
        resolve(FriendTag.second)
      }
    }
  })
}

function handleResult(params: string[]) {
  if (params.includes('itself')) {
    return FriendTag.self
  }
  if (params.length === 0) {
    return FriendTag.second
  } else {
    return FriendTag.first
  }
}
