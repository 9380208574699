import {createMultiPathSVG, createSinglePathSVG} from './TEMPLATE'

export const Group3_Stroke2_Corner0_Rounded = createSinglePathSVG({
  path: 'M8 5a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM4 7a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm13-1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3.5 1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm5.826 7.376c-.919-.779-2.052-1.03-3.1-.787a1 1 0 0 1-.451-1.949c1.671-.386 3.45.028 4.844 1.211 1.397 1.185 2.348 3.084 2.524 5.579a1 1 0 0 1-.997 1.07H18a1 1 0 1 1 0-2h3.007c-.29-1.47-.935-2.49-1.681-3.124ZM3.126 19h9.747c-.61-3.495-2.867-5-4.873-5-2.006 0-4.263 1.505-4.873 5ZM8 12c3.47 0 6.64 2.857 6.998 7.93A1 1 0 0 1 14 21H2a1 1 0 0 1-.998-1.07C1.36 14.857 4.53 12 8 12Z',
})

export const Group2_Stroke2_Corner0_Rounded = createMultiPathSVG({
  paths: [
    'M9 1.25a5.186 5.186 0 0 0-5.19 5.19 5.17 5.17 0 0 0 4.995 5.18c.031 0 .063 0 .093-.003.068-.006.14-.006.188 0a.756.756 0 0 0 .1.003c2.786-.094 4.993-2.376 5.005-5.177V6.44A5.193 5.193 0 0 0 9 1.25ZM5.31 6.44A3.686 3.686 0 0 1 9 2.75a3.686 3.686 0 0 1 .177 7.369 2.59 2.59 0 0 0-.362 0A3.67 3.67 0 0 1 5.311 6.44Z',
    'M16.41 3.25a.75.75 0 0 0 0 1.5 2.747 2.747 0 0 1 2.75 2.75 2.755 2.755 0 0 1-2.6 2.748 1.916 1.916 0 0 0-.362.007.75.75 0 1 0 .165 1.49.385.385 0 0 1 .084 0c.04.004.081.006.121.005A4.255 4.255 0 0 0 20.66 7.5a4.247 4.247 0 0 0-4.25-4.25Z',
    'M9.172 12.438c1.928 0 3.895.483 5.414 1.498 1.366.91 2.149 2.184 2.149 3.555 0 1.372-.783 2.648-2.148 3.562-1.524 1.02-3.493 1.507-5.422 1.507-1.929 0-3.897-.487-5.421-1.506-1.367-.91-2.15-2.184-2.15-3.555 0-1.372.783-2.648 2.149-3.562l.002-.002c1.529-1.014 3.499-1.498 5.427-1.498Zm-4.596 2.746c-1.054.706-1.481 1.559-1.481 2.315 0 .755.427 1.606 1.48 2.307h.002c1.226.82 2.887 1.254 4.588 1.254 1.7 0 3.362-.433 4.588-1.253 1.054-.706 1.482-1.56 1.482-2.316 0-.756-.427-1.606-1.48-2.307h-.002c-1.22-.815-2.879-1.246-4.58-1.246-1.702 0-3.366.43-4.597 1.246Z',
    'M18.535 13.268a.75.75 0 0 0-.33 1.464c.63.142 1.194.384 1.64.724l.005.004c.617.463.87 1.027.87 1.535s-.253 1.072-.87 1.535l-.007.005c-.458.352-1.03.6-1.656.73a.75.75 0 1 0 .306 1.47c.812-.17 1.599-.5 2.26-1.007.941-.707 1.467-1.692 1.467-2.733 0-1.04-.526-2.026-1.467-2.733-.654-.498-1.429-.816-2.218-.994Z',
  ],
})
