import React from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  Image,
  Pressable,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native'
import * as Clipboard from 'expo-clipboard'
import {BindWalletAddress} from '@atproto/api/dist/client/types/com/atproto/server/defs'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {usePalette} from '#/lib/hooks/usePalette'
import {useModalControls} from '#/state/modals'
import {
  useLinkedWalletsQuery,
  usePaymentMutation,
} from '#/state/queries/link-wallet'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {ToggleButton} from 'view/com/util/forms/ToggleButton'
import {Text} from 'view/com/util/text/Text'
import * as Toast from 'view/com/util/Toast'
import {CenteredView} from 'view/com/util/Views'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {PlusLarge_Stroke2_Corner0_Rounded as Plus} from '#/components/icons/Plus'
import {
  CopyIcon,
  EmptyWalletIcon,
  LinkedIcon,
} from '#/components/icons/StakeIcons'
import {EmptyState} from '../com/util/EmptyState'

// type Props = NativeStackScreenProps<CommonNavigatorParams, 'LinkedWallet'>
export function LinkedWalletScreen() {
  const {_} = useLingui()
  const t = useTheme()
  const pal = usePalette('default')
  const {openModal} = useModalControls()

  const {data: list, isLoading} = useLinkedWalletsQuery()
  const paymentMutation = usePaymentMutation()

  const onChangeReceive = React.useCallback(
    async (index: number) => {
      if (list?.[index]) {
        if (list?.[index].isReceiving) {
          Toast.show(
            'Please keep at least one wallet as the default receiving account!',
            'xmark',
          )
        } else {
          await paymentMutation.mutateAsync({
            walletAddress: list?.[index]?.walletAddress,
          })
        }
      }
    },
    [list, paymentMutation],
  )

  const renderItemInner = React.useCallback(
    ({item, index}: {item: BindWalletAddress; index: number}) => {
      return (
        <View
          style={[
            a.flex_row,
            a.px_lg,
            a.py_2xl,
            a.mb_lg,
            a.rounded_md,
            {backgroundColor: t.palette.primary, width: '100%'},
          ]}>
          <View style={[a.relative]}>
            <Image
              testID="metamaskLogo"
              source={{uri: item?.verifySourceLogo}}
              style={styles.connectBtnIcon}
              accessibilityIgnoresInvertColors
            />
            <View style={[a.absolute, {right: 8, top: 18}]}>
              <LinkedIcon />
            </View>
          </View>
          <View style={[a.flex_1]}>
            <Text style={[a.text_xl, a.font_semibold]}>{item?.name}</Text>
            <View style={[a.flex_row, a.align_center]}>
              <Text style={[a.text_xs, a.py_xs, a.pr_xs]}>
                {`${item?.walletAddress?.slice(
                  0,
                  6,
                )}...${item?.walletAddress?.substring(
                  item?.walletAddress?.length - 6,
                )}`}
              </Text>
              <Pressable
                accessible={false}
                onPress={e => {
                  e.stopPropagation()
                  Clipboard.setStringAsync(item?.walletAddress)
                  Toast.show(_(msg`Copied to clipboard`))
                }}>
                <CopyIcon />
              </Pressable>
            </View>

            <View style={[a.flex_row, a.align_center, a.gap_md, a.justify_end]}>
              <Text style={[a.text_xs]}>Receiving Wallet</Text>
              <ToggleButton
                type="primary"
                label=""
                isSelected={item?.paymentWallet === 1}
                style={[
                  a.p_0,
                  a.justify_between,
                  a.align_center,
                  {
                    backgroundColor:
                      item?.paymentWallet === 1
                        ? t.palette.green_11
                        : t.palette.gray_10,
                    padding: 0,
                  },
                ]}
                removeOuterStyle={true}
                onPress={() => onChangeReceive(index)}
              />
            </View>
          </View>
        </View>
      )
    },
    [
      _,
      onChangeReceive,
      t.palette.gray_10,
      t.palette.primary,
      t.palette.green_11,
    ],
  )

  return (
    <CenteredView
      testID="linkedwalletPage"
      style={[t.atoms.bg_gray, a.flex, {height: '100%'}]}>
      <StatusBar
        animated={true}
        backgroundColor={t.atoms.bg_gray.backgroundColor}
      />
      <ViewHeader
        title="Linked Wallets"
        style={{
          backgroundColor: t.atoms.bg_gray.backgroundColor,
        }}
        showHorizontalBorder
        showOnDesktop
      />
      {/* <ScrollView style={[a.flex_1, pal.border]}> */}
      <View
        style={[
          a.flex_1,
          pal.border,
          {borderLeftWidth: 1, borderRightWidth: 1},
        ]}>
        <View style={[styles.contentContainer]}>
          {isLoading ? (
            <View style={[a.pt_5xl]}>
              <ActivityIndicator size="large" color={t.palette.primary} />
            </View>
          ) : list && list?.length > 0 ? (
            <RNFlatList
              testID={`linked-wallet-flatlist`}
              data={list ?? []}
              renderItem={renderItemInner}
              removeClippedSubviews={true}
              // @ts-ignore our .web version only -prf
              desktopFixedHeight
            />
          ) : (
            <View style={[a.pt_5xl]}>
              <EmptyState
                message={_(msg`You haven't linked your wallet yet.`)}
                style={[{borderWidth: 0}]}>
                <EmptyWalletIcon />
              </EmptyState>
            </View>
          )}
          <View style={[a.flex_row, a.align_center, a.justify_center]}>
            {!isLoading && (
              <Button
                testID="add-new-wallet"
                label={_(msg`Add New Wallet`)}
                style={[
                  list && list?.length > 0
                    ? styles.addNewLink
                    : styles.addNewLinkEmpty,
                  list && list?.length > 0
                    ? {backgroundColor: t.palette.white}
                    : {backgroundColor: t.palette.primary},
                ]}
                hoverStyle={
                  list && list?.length > 0
                    ? {backgroundColor: t.palette.white}
                    : {
                        backgroundColor: t.palette.primary,
                        opacity: 0.7,
                      }
                }
                size="large"
                variant="ghost"
                color="secondary"
                shape="square"
                onPress={() => {
                  openModal({name: 'connect-wallet', type: 'link'})
                }}>
                {list && list?.length > 0 && <Plus fill={t.palette.black_50} />}
                <ButtonText style={{color: t.palette.black_50}}>
                  Add New Wallet
                </ButtonText>
              </Button>
            )}
          </View>
        </View>
      </View>
      {/* </ScrollView> */}
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    // height: '100%',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    padding: 16,
  },
  connectBtnIcon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  addNewLink: {
    width: '100%',
    height: 50,
    gap: 10,
    marginTop: 16,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
  addNewLinkEmpty: {
    width: 180,
    height: 48,
    gap: 10,
    marginTop: 16,
    paddingHorizontal: 20,
    borderRadius: 50,
    justifyContent: 'center',
  },
})
