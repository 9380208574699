import React from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {CircleItemProps} from '.'
import {CircleAvatar} from './CircleAvatar'
import {CircleMembers} from './CircleMembers'

type Props = {
  circle: CircleItemProps
  onEdit?: (circle: CircleItemProps) => void
  onInvite?: (circle: CircleItemProps) => void
}

export function CircleItem({circle}: Props) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  return (
    <View
      style={[
        a.flex_1,
        a.w_full,
        a.flex_row,
        a.align_center,
        a.justify_between,
      ]}>
      <Button
        label="circle detail"
        onPress={() => {
          navigation.navigate('CircleMembers', {id: circle.id})
        }}
        style={[
          a.flex_1,
          a.flex_row,
          a.align_center,
          a.justify_start,
          a.p_md,
          a.gap_md,
          {paddingHorizontal: 4},
        ]}>
        <CircleAvatar size={56} circle={circle} />
        <View
          style={[
            a.flex_col,
            (circle?.members ?? [])?.length === 0 ? a.gap_sm : a.gap_xs,
          ]}>
          <Text style={[a.text_md, a.font_bold, t.atoms.text]}>
            {circle.name}
          </Text>
          <CircleMembers count={circle.memberCount} members={circle.members} />
        </View>
      </Button>
      <View style={[a.align_center, a.justify_center, a.flex_row, a.gap_lg]}>
        <Pressable
          accessibilityRole="button"
          style={[
            {width: 32, height: 32},
            t.atoms.modalBg2,
            a.align_center,
            a.justify_center,
            a.rounded_full,
          ]}
          onPress={() => {
            navigation.navigate('CircleDetail', {id: circle.id})
          }}>
          <FontAwesomeIcon
            icon={'house'}
            size={16}
            color={t.atoms.text_sub.color}
          />
        </Pressable>
      </View>
    </View>
  )
}
