import {BrowserProvider, Contract, ethers} from 'ethers'

import betContractABI from '../abi/bet_abi.json'
import {SUPPORT_CHAINS} from '../constants'
import {handleApprove, switchChain} from './index'

export const BET_TYPE_CONTRACT = {
  on: 1,
  against: 2,
  private: 3,
}

async function getBetContract(provider: any, chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.BET_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }
  console.log('joinBet--', contractAddress)

  // const web3Provider = new ethers.providers.Web3Provider(provider) // ethers5
  // const signer = web3Provider.getSigner() // ethers5
  const web3Provider = new BrowserProvider(provider) // ethers6
  const signer = await web3Provider.getSigner() // ethers6
  const contract = new Contract(contractAddress, betContractABI as any, signer)

  return {
    contract,
    signer,
    contractAddress,
    payTokenAddress: chainInfoValue?.payTokenAddress,
  }
}

async function getBetContractReadOnly(chainId: number) {
  const chainInfoValue = SUPPORT_CHAINS?.find(o => o?.chainId === chainId)
  const contractAddress = chainInfoValue?.contract?.BET_ADDRESS
  if (!contractAddress) {
    throw new Error('Missing contract address.')
  }

  const provider = ethers.getDefaultProvider(chainInfoValue?.rpcUrl)
  const contract = new Contract(
    contractAddress,
    betContractABI as any,
    provider,
  )

  return contract
}

// 5. joinBet
export const joinBet = async (
  provider: any,
  amount: number,
  chainId: number,
  postId: string,
  betType: 'on' | 'against' | 'private',
) => {
  const {contract, signer, contractAddress, payTokenAddress} =
    await getBetContract(provider, chainId)

  await switchChain(provider, chainId)

  const tradeAmount = ethers.parseUnits(amount + '', 6) // ethers6
  // const tradeAmount = ethers.utils.parseUnits(amount + '', 6) // ethers5
  console.log('joinBet--tradeAmount', tradeAmount)
  // approve
  await handleApprove(contractAddress, signer, payTokenAddress, tradeAmount)
  console.log('joinBet-postId', postId)
  return await contract.joinBet(postId, tradeAmount, BET_TYPE_CONTRACT[betType])
}

// 4. createBet
export const createBet = async (
  provider: any,
  amount: number,
  chainId: number,
  postId: string,
  applier: string,
  betType: 'on' | 'against' | 'private',
  endTime: number,
) => {
  const {contract, signer, contractAddress, payTokenAddress} =
    await getBetContract(provider, chainId)

  await switchChain(provider, chainId)

  const tradeAmount = ethers.parseUnits(amount + '', 6) // ethers6
  // const tradeAmount = ethers.utils.parseUnits(amount + '', 6) // ethers5
  const tradeEndTime = ethers.parseUnits(endTime + '', 0)
  console.log('createBet--tradeAmount', tradeAmount)
  // approve
  await handleApprove(contractAddress, signer, payTokenAddress, tradeAmount)
  console.log('createBet-postId', postId, BET_TYPE_CONTRACT[betType])
  return await contract.createBet(
    postId,
    applier,
    tradeAmount,
    BET_TYPE_CONTRACT[betType],
    tradeEndTime,
  )
}

export const getBetAvailableWithdraw = async (
  chainId: number,
  address?: string,
  provider?: any,
) => {
  let contract: any, ownerAddress
  if (provider) {
    const contractRes = await getBetContract(provider, chainId)
    contract = contractRes?.contract
    ownerAddress = contractRes?.signer.address
  } else {
    contract = await getBetContractReadOnly(chainId)
    ownerAddress = address
  }
  if (!ownerAddress) {
    throw new Error('Missing owner address.')
  }
  const res = await contract.getBalance(address)
  console.log('getBetAvailableWithdraw--res', res)
  return Number(res?.[1] ? ethers.formatUnits(res?.[1], 6) : 0)
}

export const claimAll = async (provider: any, chainId: number) => {
  const {contract, signer} = await getBetContract(provider, chainId)
  const ownerAddress = await signer?.getAddress()
  return await contract.claimAll(ownerAddress)
}
