import React from 'react'
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native'
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated'

import {useWebBodyScrollLock} from '#/lib/hooks/useWebBodyScrollLock'
import type {Modal as ModalIface} from '#/state/modals'
import {useModalControls, useModals} from '#/state/modals'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {useTheme} from '#/alf'
import * as JoinGuarModal from '../post-thread/PostGuarantor/JoinGuar'
import * as ActionMenuModal from './ActionMenu'
import * as AddAppPassword from './AddAppPasswords'
import * as AltTextImageModal from './AltImage'
import * as BetToEarnModal from './BetToEarn'
import * as ChangeEmailModal from './ChangeEmail'
import * as ChangeHandleModal from './ChangeHandle'
import * as ChangePasswordModal from './ChangePassword'
import * as CircleAuthorization from './CircleAuthorization'
import * as CircleConfigMenuModal from './CircleModal/ConfigMenu'
import * as SendFriendsRequestModal from './CircleModal/SendFriendsRequest'
import * as ClaimToWalletModal from './ClaimToWallet'
import * as ConnnectWalletModal from './ConnnectWallet'
import * as CreateOrEditListModal from './CreateOrEditList'
import * as CropImageModal from './crop-image/CropImage.web'
import * as DeleteAccountModal from './DeleteAccount'
import * as DetectLinkedWalletModal from './DetectLinkedWallet'
import * as EditImageModal from './EditImage'
import * as EditProfileModal from './EditProfile'
import * as InviteCodesModal from './InviteCodes'
import * as ContentLanguagesSettingsModal from './lang-settings/ContentLanguagesSettings'
import * as PostLanguagesSettingsModal from './lang-settings/PostLanguagesSettings'
import * as LinkSocialModal from './LinkSocialModal'
import * as LinkWarningModal from './LinkWarning'
import * as ListAddUserModal from './ListAddRemoveUsers'
import * as Operation from './PostModal/Operation'
import * as PostSelectCircleModal from './PostModal/PostSelectCircle'
import * as PostSelectTagFriendModal from './PostModal/PostSelectTagFriend'
import * as SelectCircleListModal from './PostModal/SelectCircleList'
import * as Visible from './PostModal/Visible'
import * as PostStep from './PostStep'
import * as PostTarget from './PostTarget'
import * as SelfLabelModal from './SelfLabel'
import * as SetBetModal from './SetBet'
import * as ShareProfile from './ShareModal/ShareProfile'
import * as ShareTea from './ShareModal/ShareTea'
import * as StakeDonateModal from './StakeDonate'
import * as SwitchMaskModal from './SwitchMask'
import * as TeaVisible from './TeaVisible'
import * as TelegramModal from './TelegramModal/TelegramModal.web'
import * as TranstionResultModal from './TranstionResult'
import * as UserAddRemoveLists from './UserAddRemoveLists'
import * as VerifyEmailModal from './VerifyEmail'
import * as WithdrawModal from './Withdraw'
import * as WithdrawPreviewModal from './WithdrawPreview'

export function ModalsContainer() {
  const {isModalActive, activeModals} = useModals()
  useWebBodyScrollLock(isModalActive, 'ModalsContainer')

  if (!isModalActive) {
    return null
  }

  return (
    <>
      {activeModals.map((modal, i) => (
        <Modal key={`modal-${i}`} modal={modal} />
      ))}
    </>
  )
}

function Modal({modal}: {modal: ModalIface}) {
  const {isModalActive} = useModals()
  const {closeModal} = useModalControls()
  const pal = usePalette('default')
  const {isMobile} = useWebMediaQueries()
  const t = useTheme()

  if (!isModalActive) {
    return null
  }

  const onPressMask = () => {
    if (
      modal.name === 'crop-image' ||
      modal.name === 'edit-image' ||
      modal.name === 'alt-text-image' ||
      modal.name === 'stake-donate' ||
      modal.name === 'earn-bet' ||
      modal.name === 'withdraw'
    ) {
      return // dont close on mask presses during crop
    }
    closeModal()
  }
  const onInnerPress = () => {
    // TODO: can we use prevent default?
    // do nothing, we just want to stop it from bubbling
  }

  let teleStyle = {}
  let element
  if (modal.name === 'edit-profile') {
    element = <EditProfileModal.Component {...modal} />
  } else if (modal.name === 'circle-link') {
    element = <CircleAuthorization.Component />
  } else if (modal.name === 'create-or-edit-list') {
    element = <CreateOrEditListModal.Component {...modal} />
  } else if (modal.name === 'user-add-remove-lists') {
    element = <UserAddRemoveLists.Component {...modal} />
  } else if (modal.name === 'list-add-remove-users') {
    element = <ListAddUserModal.Component {...modal} />
  } else if (modal.name === 'crop-image') {
    element = <CropImageModal.Component {...modal} />
  } else if (modal.name === 'delete-account') {
    element = <DeleteAccountModal.Component />
  } else if (modal.name === 'self-label') {
    element = <SelfLabelModal.Component {...modal} />
  } else if (modal.name === 'change-handle') {
    element = <ChangeHandleModal.Component {...modal} />
  } else if (modal.name === 'invite-codes') {
    element = <InviteCodesModal.Component />
  } else if (modal.name === 'add-app-password') {
    element = <AddAppPassword.Component />
  } else if (modal.name === 'content-languages-settings') {
    element = <ContentLanguagesSettingsModal.Component />
  } else if (modal.name === 'post-languages-settings') {
    element = <PostLanguagesSettingsModal.Component />
  } else if (modal.name === 'alt-text-image') {
    element = <AltTextImageModal.Component {...modal} />
  } else if (modal.name === 'edit-image') {
    element = <EditImageModal.Component {...modal} />
  } else if (modal.name === 'verify-email') {
    element = <VerifyEmailModal.Component {...modal} />
  } else if (modal.name === 'change-email') {
    element = <ChangeEmailModal.Component />
  } else if (modal.name === 'change-password') {
    element = <ChangePasswordModal.Component />
  } else if (modal.name === 'link-warning') {
    element = <LinkWarningModal.Component {...modal} />
  } else if (modal.name === 'connect-wallet') {
    element = <ConnnectWalletModal.Component {...modal} />
  } else if (modal.name === 'stake-donate') {
    element = <StakeDonateModal.Component {...modal} />
  } else if (modal.name === 'trans-result') {
    element = <TranstionResultModal.Component {...modal} />
  } else if (modal.name === 'earn-bet') {
    element = <BetToEarnModal.Component {...modal} />
  } else if (modal.name === 'set-bet') {
    element = <SetBetModal.Component {...modal} />
  } else if (modal.name === 'detect-link-wallet') {
    element = <DetectLinkedWalletModal.Component />
  } else if (modal.name === 'post-target') {
    element = <PostTarget.Component />
  } else if (modal.name === 'tea-visible') {
    element = <TeaVisible.Component {...modal} />
  } else if (modal.name === 'post-step') {
    element = <PostStep.Component />
  } else if (modal.name === 'telegram') {
    element = <TelegramModal.Component {...modal} />
  } else if (modal.name === 'share-profile') {
    teleStyle = styles.portraitShare
    element = <ShareProfile.Component {...modal} />
  } else if (modal.name === 'share-tea') {
    teleStyle = styles.portraitShare
    element = <ShareTea.Component {...modal} />
  } else if (modal.name === 'withdraw') {
    element = <WithdrawModal.Component {...modal} />
  } else if (modal.name === 'visible') {
    element = <Visible.Component {...modal} />
  } else if (modal.name === 'operation') {
    element = <Operation.Component {...modal} />
  } else if (modal.name === 'withdraw-preview') {
    element = <WithdrawPreviewModal.Component {...modal} />
  } else if (modal.name === 'link-social') {
    element = <LinkSocialModal.Component {...modal} />
  } else if (modal.name === 'switch-mask') {
    element = <SwitchMaskModal.Component {...modal} />
  } else if (modal.name === 'post-select-cricle') {
    element = <PostSelectCircleModal.Component {...modal} />
  } else if (modal.name === 'post-select-tag-friend') {
    element = <PostSelectTagFriendModal.Component {...modal} />
  } else if (modal.name === 'select-circle-list') {
    element = <SelectCircleListModal.Component {...modal} />
  } else if (modal.name === 'circle-config-menu') {
    element = <CircleConfigMenuModal.Component {...modal} />
  } else if (modal.name === 'send-friends-request') {
    element = <SendFriendsRequestModal.Component {...modal} />
  } else if (modal.name === 'claim-to-wallet') {
    element = <ClaimToWalletModal.Component />
  } else if (modal.name === 'action-menu') {
    element = <ActionMenuModal.Component {...modal} />
  } else if (modal.name === 'join-guar') {
    element = <JoinGuarModal.Component {...modal} />
  } else {
    return null
  }

  const isTransparent =
    ['share-profile', 'share-tea', 'claim-to-wallet'].indexOf(modal.name) >= 0

  return (
    // eslint-disable-next-line react-native-a11y/has-valid-accessibility-descriptors
    <TouchableWithoutFeedback onPress={onPressMask}>
      <Animated.View
        style={styles.mask}
        entering={FadeIn.duration(150)}
        exiting={FadeOut}>
        {/* eslint-disable-next-line react-native-a11y/has-valid-accessibility-descriptors */}
        <TouchableWithoutFeedback onPress={onInnerPress}>
          <View
            style={[
              styles.container,
              isMobile && styles.containerMobile,
              // pal.view,
              !isTransparent && t.atoms.modalBg,
              isTransparent ? {borderColor: 'transparent'} : pal.border,
              teleStyle,
            ]}>
            {element}
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  mask: {
    // @ts-ignore
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000c',
    alignItems: 'center',
    paddingTop: 40,
  },
  container: {
    width: 500,
    // @ts-ignore web only
    maxWidth: '100vw',
    // @ts-ignore web only
    maxHeight: '90vh',
    paddingVertical: 20,
    paddingHorizontal: 24,
    borderRadius: 8,
    borderWidth: 1,
  },
  containerMobile: {
    borderRadius: 0,
    paddingHorizontal: 0,
  },
  portraitShare: {
    // @ts-ignore web only
    maxHeight: '100vh',
    width: '100%',
    backgroundColor: '#00000000',
    borderRadius: 0,
    borderWidth: 0,
  },
})
