import React from 'react'
import {Image, StyleSheet, Text, View} from 'react-native'
import {PullStatus, PullToRefreshify} from 'react-pull-to-refreshify'

export function ReFreshContainer({
  children,
  refreshing,
  onRefresh,
}: {
  children: React.ReactNode
  refreshing: boolean
  onRefresh: () => void
}) {
  function renderText(status: PullStatus) {
    switch (status) {
      case 'pulling':
        return <Text style={styles.text}>Pull to refresh</Text>

      case 'canRelease':
        return <Text style={styles.text}>Release to refresh</Text>

      case 'refreshing':
        return (
          <View
            style={{
              height: 100,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 15,
            }}>
            <Image
              source={{
                uri: '/sipzloading.gif',
              }}
              style={{width: 60, height: 60, borderRadius: 8}}
              accessibilityIgnoresInvertColors
            />
          </View>
        )

      case 'complete':
        return 'Refresh succeed'

      default:
        return ''
    }
  }

  return (
    <PullToRefreshify
      refreshing={refreshing}
      headHeight={80}
      startDistance={1}
      onRefresh={() => onRefresh()}
      renderText={renderText}>
      {children}
    </PullToRefreshify>
  )
}

const styles = StyleSheet.create({
  text: {
    color: '#fff',
  },
})
