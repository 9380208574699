import React from 'react'
import {View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {SimpleTag, Tag} from '#/view/com/tags/TagItem'
import TagList from '#/view/com/tags/TagList'
import {CircleFromType, ContactItemProps} from '#/view/screens/Circle'
import {useUpdateUserTagsMutation} from '#/view/screens/Circle/hooks'
import ContactItem from '#/view/screens/Telegram/ContactItem'
import {atoms as a, useTheme} from '#/alf'
import * as Dialog from '#/components/Dialog'
import {Button} from '../Button'
import {Loader} from '../Loader'
import {Text} from '../Typography'

type CircleUserInfoDialogParams = {
  selected?: ContactItemProps
  selectedTags?: Tag[]
  tags: Tag[]
  fromType: CircleFromType
}

export function CircleUserInfoDialog({
  control,
  createTagControl,
  params,
}: {
  control: Dialog.DialogControlProps
  createTagControl: Dialog.DialogControlProps
  params: CircleUserInfoDialogParams
}) {
  const t = useTheme()
  const {_} = useLingui()
  const {selected, tags, fromType} = params

  const updateUserTags = useUpdateUserTagsMutation()

  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([])

  const onSelectTag = (_selected: boolean, tag: Tag) => {
    if (_selected) {
      setSelectedTags(prev => [...prev, tag])
    } else {
      setSelectedTags(prev => prev.filter(item => item.id !== tag.id))
    }
  }

  const onPressSelectTags = async () => {
    if (!selected?.id) {
      return
    }
    await updateUserTags.mutateAsync({
      userId: selected?.id,
      tags: selectedTags,
      fromType,
    })

    control.close()
  }

  React.useEffect(() => {
    if (params?.selectedTags) {
      setSelectedTags(params?.selectedTags)
    }
  }, [params?.selectedTags])

  return (
    <Dialog.Outer control={control}>
      {/* <Dialog.Handle /> */}
      <Dialog.ScrollableInner
        label={_(msg`New user info dialog`)}
        style={[a.w_full]}>
        <View style={[a.flex_col, a.gap_md]}>
          {selected && <ContactItem user={selected} showTag={false} />}
          <View style={[a.flex_row, a.flex_wrap, a.gap_sm]}>
            {selectedTags &&
              selectedTags.map(_tag => {
                return (
                  <SimpleTag
                    key={_tag.name}
                    tag={_tag}
                    style={{height: 32}}
                    showClose={true}
                    onDelete={(value: Tag) => {
                      onSelectTag(false, value)
                    }}
                  />
                )
              })}
            <Button
              color="secondary"
              variant="solid"
              size="xsmall"
              label="Create tags"
              onPress={() => {
                createTagControl.open()
              }}
              style={[
                a.rounded_xs,
                {backgroundColor: '#FCD0101A', height: 32},
              ]}>
              <FontAwesomeIcon icon="plus" style={{color: '#C28C00'}} />
              <Text style={[{color: '#C28C00', fontSize: 12}]}>
                <Trans>Create tags</Trans>
              </Text>
            </Button>
          </View>
        </View>

        <View
          style={[
            {borderTopWidth: 1, borderColor: '#97979733', marginVertical: 20},
          ]}
        />
        <Text style={[a.font_bold, a.text_sm, t.atoms.text, a.mb_md]}>
          Select tags:
        </Text>
        <TagList
          // showCheckbox
          tags={tags}
          selected={selectedTags}
          onSelected={onSelectTag}
        />
        <Button
          color="primary"
          variant="solid"
          size="medium"
          label="Confirm"
          onPress={onPressSelectTags}
          style={[a.mt_3xl]}>
          <Text style={[a.text_md, a.font_bold, {color: t.palette.black}]}>
            <Trans>Confirm</Trans>
          </Text>
          {updateUserTags.isPending && (
            <Loader size="sm" style={[t.atoms.text]} />
          )}
        </Button>
        <Button
          style={[t.atoms.bg_btn_default, a.mt_md]}
          variant="solid"
          size="medium"
          label="Cancel"
          onPress={() => {
            control.close()
            setSelectedTags(params?.selectedTags ?? [])
          }}>
          <Text style={[t.atoms.text_sub, a.text_md, a.font_bold]}>
            <Trans>Cancel</Trans>
          </Text>
        </Button>

        {/* <Dialog.Close /> */}
      </Dialog.ScrollableInner>
    </Dialog.Outer>
  )
}
