import {useQuery} from '@tanstack/react-query'
import debounce from 'lodash.debounce'

import {ENTITY_URL, TELEGRAM_BOT_URL} from '#/lib/constants'
import {useSession} from '#/state/session'

export const useTelegramBotUrl = (source: number = 1) => {
  const {currentAccount} = useSession()

  const path = `/?invite_handler=${currentAccount?.handle}&source=${source}&redirectPath=/task`

  const link = `${TELEGRAM_BOT_URL}?startapp=${btoa(path)}`
  return link
}

export const useCompanies = (query?: string): any[] => {
  const debounceSearch = debounce(
    () => {
      if (!query) {
        return
      }
      return fetch(`${ENTITY_URL}/get/all?query=${query}`).then(result => {
        return result.json()
      })
    },
    500,
    {leading: true},
  )
  const res = useQuery({
    queryKey: ['Company', query],
    queryFn: debounceSearch,
  })
  let list = []
  if (res.data) {
    list = res.data.filter((item: any) => {
      return item.type === 1 || item.type === 2
    })
  }
  return list
}
