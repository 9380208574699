import React from 'react'
import {StyleSheet, View} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  ModerationDecision,
  RichText as RichTextAPI,
} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {Shadow} from '#/state/cache/post-shadow'
import {ThreadPost} from '#/state/queries/post-thread'
import {MAX_POST_LINES} from 'lib/constants'
import {usePalette} from 'lib/hooks/usePalette'
import {sanitizeHandle} from 'lib/strings/handles'
import {s} from 'lib/styles'
import {isWeb} from 'platform/detection'
import {atoms as a} from '#/alf'
import {RichText} from '#/components/RichText'
import {LabelsOnMyPost} from '../../../components/moderation/LabelsOnMe'
import {PostAlerts} from '../../../components/moderation/PostAlerts'
import {PostHider} from '../../../components/moderation/PostHider'
import {TranslationWidget} from '../posts/TranslationWidget/TranslationWidget'
import {Link, TextLink} from '../util/Link'
import {PostCtrls} from '../util/post-ctrls/PostCtrls'
import {PostEmbeds} from '../util/post-embeds'
import {PostMeta} from '../util/PostMeta'
import {Text} from '../util/text/Text'
import {AnonSpillerAvatar, PreviewableUserAvatar} from '../util/UserAvatar'
import {PostOuterWrapper} from './PostThreadItem'

export function BigFigureChildThreadItem({
  post,
  record,
  richText,
  moderation,
  treeView,
  depth,
  prevPost,
  nextPost,
  hasMore,
  showChildReplyLine,
  showParentReplyLine,
  hasPrecedingItem,
  overrideBlur,
  hideTopBorder,
  translate,
  postHref,
  isAnonymousPost,
  limitLines,
  onPressShowMore,
  onPressReply,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  moderation: ModerationDecision
  treeView: boolean
  depth: number
  prevPost: ThreadPost | undefined
  nextPost: ThreadPost | undefined
  hasMore?: boolean
  showChildReplyLine?: boolean
  showParentReplyLine?: boolean
  hasPrecedingItem: boolean
  overrideBlur: boolean
  hideTopBorder?: boolean
  translate: {
    displayOriginalText: () => void
    translatePost: () => void
    hasTranslated: boolean
    isLanguageConsistent: boolean
    fromMe: boolean
  }
  postHref: string
  isAnonymousPost?: boolean
  limitLines: boolean
  onPressShowMore: () => void
  onPressReply: () => void
}) {
  const pal = usePalette('default')
  const {_} = useLingui()

  const isThreadedChild = treeView && depth > 0
  const isThreadedChildAdjacentTop =
    isThreadedChild && prevPost?.ctx.depth === depth && depth !== 1
  const isThreadedChildAdjacentBot =
    isThreadedChild && nextPost?.ctx.depth === depth

  return (
    <PostOuterWrapper
      post={post}
      depth={depth}
      showParentReplyLine={!!showParentReplyLine}
      treeView={treeView}
      hasPrecedingItem={hasPrecedingItem}
      hideTopBorder={isThreadedChildAdjacentTop ? true : hideTopBorder}>
      <PostHider
        testID={`postThreadItem-by-${post.author.handle}`}
        href={postHref}
        disabled={overrideBlur}
        style={[pal.view]}
        modui={moderation.ui('contentList')}
        iconSize={isThreadedChild ? 26 : 38}
        iconStyles={
          isThreadedChild ? {marginRight: 4} : {marginLeft: 2, marginRight: 2}
        }
        profile={post.author}
        interpretFilterAsBlur>
        <View
          style={{
            flexDirection: 'row',
            gap: 10,
            paddingLeft: 6,
            height: isThreadedChildAdjacentTop ? 8 : 16,
          }}>
          <View style={{width: 38}}>
            {!isThreadedChild && showParentReplyLine && (
              <View
                style={[
                  styles.replyLine,
                  {
                    flexGrow: 1,
                    backgroundColor: pal.colors.replyLine,
                    marginBottom: 4,
                  },
                ]}
              />
            )}
          </View>
        </View>

        <View
          style={[
            styles.layout,
            {
              paddingBottom:
                showChildReplyLine && !isThreadedChild
                  ? 0
                  : isThreadedChildAdjacentBot
                  ? 4
                  : 8,
            },
          ]}>
          {/* If we are in threaded mode, the avatar is rendered in PostMeta */}
          {!isThreadedChild && (
            <View style={styles.layoutAvi}>
              {isAnonymousPost ? (
                <AnonSpillerAvatar size={34} profile={post.author} />
              ) : (
                <PreviewableUserAvatar
                  size={34}
                  profile={post.author}
                  moderation={moderation.ui('avatar')}
                  type={post.author.associated?.labeler ? 'labeler' : 'user'}
                />
              )}

              {showChildReplyLine && (
                <View
                  style={[
                    styles.replyLine,
                    {
                      flexGrow: 1,
                      backgroundColor: pal.colors.replyLine,
                      marginTop: 4,
                    },
                  ]}
                />
              )}
            </View>
          )}

          <View
            style={
              isThreadedChild
                ? styles.layoutContentThreaded
                : styles.layoutContent
            }>
            <PostMeta
              author={post.author}
              moderation={moderation}
              authorHasWarning={!!post.author.labels?.length}
              timestamp={post.indexedAt}
              postHref={postHref}
              showAvatar={isThreadedChild}
              avatarModeration={moderation.ui('avatar')}
              avatarSize={28}
              displayNameType="md-bold"
              isAnonymous={isAnonymousPost}
              displayNameStyle={isThreadedChild && s.ml2}
              style={
                isThreadedChild && {
                  alignItems: 'center',
                  paddingBottom: isWeb ? 5 : 2,
                }
              }
            />
            <LabelsOnMyPost post={post} />
            <PostAlerts
              modui={moderation.ui('contentList')}
              style={[a.pt_2xs, a.pb_2xs]}
            />
            {richText?.text ? (
              <View style={styles.postTextContainer}>
                <RichText
                  enableTags
                  value={richText}
                  style={[a.flex_1, a.text_sm]}
                  numberOfLines={limitLines ? MAX_POST_LINES : undefined}
                  authorHandle={sanitizeHandle(post.author.handle)}
                />
              </View>
            ) : undefined}
            <TranslationWidget
              translate={translate}
              textStyle={[a.text_xs, {color: '#8D8D8D'}]}
            />
            {limitLines ? (
              <TextLink
                text={_(msg`Show More`)}
                style={pal.linkActive}
                onPress={onPressShowMore}
                href="#"
              />
            ) : undefined}
            {post.embed && (
              <View style={[a.pb_xs]}>
                <PostEmbeds embed={post.embed} moderation={moderation} />
              </View>
            )}
            <View style={[a.mt_sm]}>
              <PostCtrls
                post={post}
                record={record}
                richText={richText}
                onPressReply={onPressReply}
                logContext="PostThreadItem"
                showRightBt
              />
            </View>
          </View>
        </View>
        {hasMore ? (
          <Link
            style={[
              styles.loadMore,
              {
                paddingLeft: treeView ? 8 : 70,
                paddingTop: 0,
                paddingBottom: treeView ? 4 : 12,
              },
            ]}
            href={postHref}
            title={_(msg`Post by ${post.author.handle}`)}
            noFeedback>
            <Text type="sm-medium" style={pal.textLight}>
              <Trans>More</Trans>
            </Text>
            <FontAwesomeIcon
              icon="angle-right"
              color={pal.colors.textLight}
              size={14}
            />
          </Link>
        ) : undefined}
      </PostHider>
    </PostOuterWrapper>
  )
}

const styles = StyleSheet.create({
  outer: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingLeft: 8,
  },
  outerHighlighted: {
    borderTopWidth: 0,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },
  outerHighlightedRoot: {
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: 16,
  },
  noTopBorder: {
    borderTopWidth: 0,
  },
  layout: {
    flexDirection: 'row',
    paddingHorizontal: 8,
  },
  layoutAvi: {},
  layoutContent: {
    flex: 1,
    marginLeft: 10,
  },
  layoutContentThreaded: {
    flex: 1,
  },
  meta: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  metaExpandedLine1: {
    paddingVertical: 0,
  },
  alert: {
    marginBottom: 6,
  },
  postTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 10,
    overflow: 'hidden',
  },
  postTextLargeContainer: {
    paddingHorizontal: 0,
    paddingRight: 0,
    paddingBottom: 10,
  },
  translateLink: {
    marginBottom: 6,
  },
  contentHider: {
    marginBottom: 6,
  },
  contentHiderChild: {
    marginTop: 6,
  },
  expandedInfo: {
    flexDirection: 'row',
    padding: 10,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 5,
    marginBottom: 10,
  },
  expandedInfoItem: {
    marginRight: 10,
  },
  loadMore: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4,
    paddingHorizontal: 20,
  },
  replyLine: {
    width: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cursor: {
    // @ts-ignore web only
    cursor: 'pointer',
  },
})
