import React, {useEffect, useState} from 'react'
import {Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'

export function CountdownTimer({
  onResend,
  sendTime,
}: {
  onResend: () => void
  sendTime: number
}) {
  const [timeLeft, setTimeLeft] = useState(0)
  const [init, setInit] = useState(true)
  const t = useTheme()

  useEffect(() => {
    let timer: NodeJS.Timeout
    const lastTime = Math.floor((Date.now() - sendTime) / 1000)
    if (lastTime <= 60) {
      timer = setTimeout(() => {
        setInit(false)
        setTimeLeft(60 - lastTime)
      }, 1000)
    } else {
      setInit(false)
    }

    // 清除定时器
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [sendTime, timeLeft])

  return (
    <View style={[a.absolute, {right: 10, zIndex: 999}]}>
      {timeLeft > 0 ? (
        <Text style={[{color: t.palette.gray_16}]}>{timeLeft}s</Text>
      ) : (
        !init && (
          <Button variant="ghost" label="Resend" onPress={onResend}>
            <Text style={[{color: t.palette.primary}]}>
              <Trans>Resend</Trans>
            </Text>
          </Button>
        )
      )}
    </View>
  )
}
