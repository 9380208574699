import React, {useEffect, useMemo, useState} from 'react'
import {Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import * as Toast from '#/view/com/util/Toast'
import {atoms as a} from '#/alf'
import {Button} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import * as Toggle from '#/components/forms/Toggle'
import {useUpdateAuditStatusMutation} from './hooks'

export function useAuditDialog() {
  //   const t = useTheme()
  const [current, setCurrent] = useState<any>()
  const control = Dialog.useDialogControl()
  const [toggleGroupCValues, setToggleGroupCValues] = useState(['1'])
  const update = useUpdateAuditStatusMutation()

  useEffect(() => {
    return () => {
      setToggleGroupCValues(['1'])
    }
  }, [current])

  const onClear = React.useCallback(() => {
    if (current) {
      const [grade] = toggleGroupCValues.map(val => +val)
      const did = current?.id
      update
        .mutateAsync({did, status: 2, grade})
        .then(() => {
          Toast.show('Rejected successful')
        })
        .catch(err => {
          Toast.show(err.message, 'xmark')
        })
        .finally(() => {
          control.close()
        })
    }
  }, [control, current, toggleGroupCValues, update])

  const onConfirm = React.useCallback(() => {
    if (current) {
      const [grade] = toggleGroupCValues.map(val => +val)
      const did = current?.id
      update
        .mutateAsync({did, status: 1, grade})
        .then(() => {
          Toast.show('Approval successful')
        })
        .catch(err => {
          Toast.show(err.message, 'xmark')
        })
        .finally(() => {
          control.close()
        })
    }
  }, [control, current, toggleGroupCValues, update])

  const dialog = useMemo(() => {
    return (
      <Dialog.Outer
        key={current?.id}
        control={control}
        nativeOptions={{sheet: {snapPoints: ['80%']}}}>
        <Dialog.Handle />
        <Dialog.Close />
        <Dialog.ScrollableInner
          accessibilityDescribedBy="dialog-description"
          accessibilityLabelledBy="dialog-title"
          style={[a.flex_1, a.align_center, a.justify_center, a.gap_md]}>
          <View style={[{height: 300}]}>
            <Toggle.Group
              label="Toggle"
              type="radio"
              values={toggleGroupCValues.map(val => val.toString())}
              onChange={(val: any) => {
                setToggleGroupCValues(val)
              }}>
              <View style={[a.gap_md]}>
                <Toggle.Item name="1" label="Click me">
                  <Toggle.Radio />
                  <Toggle.LabelText>Level 1</Toggle.LabelText>
                </Toggle.Item>
                <Toggle.Item name="2" label="Click me">
                  <Toggle.Radio />
                  <Toggle.LabelText>Level 2</Toggle.LabelText>
                </Toggle.Item>
                <Toggle.Item name="3" label="Click me">
                  <Toggle.Radio />
                  <Toggle.LabelText>Level 3</Toggle.LabelText>
                </Toggle.Item>
                <Toggle.Item name="4" label="Click me">
                  <Toggle.Radio />
                  <Toggle.LabelText>Level 4</Toggle.LabelText>
                </Toggle.Item>
                <Toggle.Item name="5" isInvalid label="Click me">
                  <Toggle.Radio />
                  <Toggle.LabelText>Level 5</Toggle.LabelText>
                </Toggle.Item>
              </View>
            </Toggle.Group>
          </View>

          <Button
            variant="solid"
            size="medium"
            color="primary"
            label="Approved"
            onPress={onConfirm}
            style={[{width: '80%'}]}>
            <Text>
              <Trans>Approved</Trans>
            </Text>
          </Button>
          <Button
            variant="solid"
            size="medium"
            color="secondary_inverted"
            label="Rejected"
            onPress={onClear}
            style={[{width: '80%'}]}>
            <Text>
              <Trans>Rejected</Trans>
            </Text>
          </Button>
        </Dialog.ScrollableInner>
      </Dialog.Outer>
    )
  }, [control, current?.id, onClear, onConfirm, toggleGroupCValues])

  return {
    dialog,
    control,
    setCurrent,
  }
}
