import React, {useMemo} from 'react'
import {Text, TouchableOpacity, View} from 'react-native'
import {FinEntity} from '@atproto/api/dist/client/types/app/bsky/feed/post'
import {Trans} from '@lingui/macro'

import {TeaLogoIcon} from '#/view/icons/FeedIcons'
import {CloseGrayIcon, CloseIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {useThemeName} from '#/alf/util/useColorModeTheme'
import {EllipsisBox} from '../composer/postBox/EllipsisContainer'
import {UserAvatar} from '../util/UserAvatar'

export function TargetDisplay({
  targets,
  previewMode = false,
  isFromFeed,
  isFromShare,
}: {
  targets: FinEntity[]
  previewMode?: boolean
  isFromFeed?: boolean
  isFromShare?: boolean
}) {
  if (previewMode) {
    return (
      <>
        <EllipsisBox
          array={targets}
          keyName="did"
          height={60}
          gap={2}
          isTarget
          isFromFeed={isFromFeed}
          renderItem={tar => (
            <Capsule
              name={tar.name}
              avatar={tar.avatar}
              type={tar.type}
              isFromFeed={isFromFeed}
              key={tar.did}
              isFromShare={isFromShare}
            />
          )}
        />
        <View style={{height: 5, width: 1}} />
      </>
    )
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'hidden',
      }}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 5,
          marginRight: 10,
        }}>
        <Text style={{color: '#979797', fontSize: 14, fontWeight: '800'}}>
          <Trans>Spill about:</Trans>
        </Text>
      </View>
      {targets.map((tar, index) => {
        return (
          <Capsule
            key={String(index)}
            name={tar.name || ''}
            avatar={tar.avatar || ''}
            type={tar.type as 'person'}
          />
        )
      })}
    </View>
  )
}

interface ICapsuleProps {
  name: string
  avatar: string
  key?: string
  handle?: string
  type?: 'organization' | 'person' | 'guarantors'
  onDelete?: () => void
  isFromFeed?: boolean
  isFromShare?: boolean
}

export function Capsule({
  key,
  name,
  type,
  avatar,
  onDelete,
  handle,
  isFromFeed,
  isFromShare,
}: ICapsuleProps) {
  const t = useTheme()
  const themeName = useThemeName()

  const config = useMemo(() => {
    const styleConfig = {
      background: '#F0DDFF',
      height: 32,
      borderWidth: 1,
      avatarSize: 24,
    }
    if (type !== 'organization' && type !== 'person') return styleConfig

    styleConfig.avatarSize = 16
    styleConfig.height = 24
    styleConfig.borderWidth = 0
    styleConfig.background = type === 'organization' ? '#E8E8E8' : '#FFF3BD'
    return styleConfig
  }, [type])

  return (
    <View
      key={key}
      style={[
        {
          flexDirection: 'row',
          height: config.height,
          padding: 2,
          paddingRight: onDelete ? 5 : 10,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isFromFeed
            ? 'transparent'
            : t.atoms.bg_capsule.backgroundColor, //config.background,
          borderRadius: 18,
          marginRight: 10,
          marginBottom: 5,
          borderWidth: isFromFeed ? 1 : config.borderWidth,
          borderColor: isFromFeed
            ? isFromShare || themeName === 'light'
              ? t.palette.black
              : '#98989F'
            : t.palette.primary,
          maxWidth: '100%',
        },
      ]}>
      <UserAvatar
        avatar={avatar}
        type={avatar ? 'user' : (type as 'organization')}
        // size={config.avatarSize}
        size={20}
      />
      <View>
        <Text
          style={{
            color: isFromFeed
              ? isFromShare || themeName === 'light'
                ? t.palette.black
                : '#98989F'
              : t.atoms.text_capsule.color, //'#5E6272',
            fontSize: 12,
            marginLeft: 6,
          }}>
          {name}
        </Text>
        {handle && (
          <Text
            style={{
              color: 'white',
              fontSize: 12,
              marginLeft: 6,
            }}>
            {handle}
          </Text>
        )}
      </View>
      {onDelete && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            marginLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onDelete}>
          {type !== 'guarantors' ? (
            <CloseIcon circleColor={'#000'} />
          ) : (
            <CloseGrayIcon />
          )}
        </TouchableOpacity>
      )}
      {isFromFeed && (
        <View
          style={[
            a.flex_row,
            a.align_center,
            a.justify_between,
            isFromShare ? {backgroundColor: '#EFE20E'} : t.atoms.big_figure_bg,
            {
              position: 'absolute',
              right: -10,
              top: -10,
              borderRadius: 10,
            },
          ]}>
          <TeaLogoIcon
            fixedColor={
              isFromShare || themeName === 'light' ? t.palette.black : undefined
            }
          />
        </View>
      )}
    </View>
  )
}
