import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
// import {useSDK} from '@metamask/sdk-react'
import Provider, {EthereumProvider} from '@walletconnect/ethereum-provider'

import {RPC_MAP, WALLETCONNECT_PROJECT_ID} from '#/lib/constants'
import {logger} from '#/logger'
import * as persisted from '#/state/persisted'

type currentWalletProps = {
  chainId: number
  publicKey: string
  walletName: string
}

type StateContext = {
  currentWallet: currentWalletProps
  provider: any
  onSelectConnectWallet: (value: string, type?: 'link') => void
  onDisConnectWallet: () => void
  onSelectAccount: (value: string) => any
}

export const WalletContext = createContext<StateContext>({} as StateContext)

export function useWallet() {
  return useContext(WalletContext)
}

export function WalletProvider({children}: React.PropsWithChildren<{}>) {
  const [provider, setProvider] = useState<any>()
  const [currentWallet, setCurrentWallet] = useState<any>()

  const walletConnectProvider = useWalletConnecProvider()
  // const {sdk, provider: ethereum} = useSDK()

  async function getConnectedInfo(providerParam: any, walletName: string) {
    try {
      if (providerParam) {
        let account, chainId
        // console.log('setEthInfo providerType', walletName, providerParam)
        if (walletName === 'walletconnect') {
          account = providerParam?.accounts?.[0]
          chainId = providerParam?.chainId
        } else {
          const accounts = await providerParam?.request({
            method: 'eth_requestAccounts',
          })
          account = accounts?.[0]
          // accounts = (await sdk?.connect()) as string[]
          // console.log('setEthInfo accounts', accounts)
          const chainIdHex = await providerParam?.request({
            method: 'eth_chainId',
          })
          // console.log('setEthInfo chainId', chainIdHex)
          chainId = parseInt(chainIdHex + '')
        }
        setProvider(providerParam)
        setCurrentWallet({
          chainId,
          publicKey: account,
          walletName: walletName,
        })
        persisted.write('web3', {payWallet: walletName})
      }
    } catch (error) {
      logger.error(`getConnectedInfo: `, {message: error})
      setProvider(null)
      setCurrentWallet(null)
      persisted.write('web3', {payWallet: ''})
    }
  }

  const onDisConnectWallet = useCallback(async () => {
    if (provider?.close) {
      await provider?.close()
    }
    if (provider?.disconnect) {
      await provider.disconnect()
    }
    setCurrentWallet(null)
    setProvider(null)
    persisted.write('web3', {payWallet: ''})
  }, [provider])

  async function subscribeEthProvider(providerParam: any, walletName: string) {
    try {
      if (!providerParam?.on) {
        return
      }
      providerParam.on('connect', async () => {})
      providerParam.on('close', () => onDisConnectWallet())
      providerParam.on('accountsChanged', async (accounts: string[]) => {
        if (accounts?.length > 0) {
          getConnectedInfo(providerParam, walletName)
        } else {
          onDisConnectWallet()
        }
      })
      providerParam.on('chainChanged', async () => {
        getConnectedInfo(providerParam, walletName)
      })
    } catch (error) {
      console.log('subscribeEthProvider-error', error)
    }
  }

  async function onSelectConnectWallet(walletName: string, type?: 'link') {
    console.log('onSelectConnectWallet', walletName)
    try {
      let providerParam
      if (walletName === 'walletconnect') {
        providerParam = walletConnectProvider
        if (!currentWallet || type === 'link') {
          await providerParam?.disconnect()
        }
        await providerParam?.enable()
      } else {
        if (typeof window.ethereum !== 'undefined') {
          providerParam = window?.ethereum
        } else {
          window.open('https://metamask.io/download/')
        }
      }
      if (type === 'link') {
        return providerParam
      } else {
        getConnectedInfo(providerParam, walletName)
        await subscribeEthProvider(providerParam, walletName)
      }
    } catch (error) {
      console.log('onSelectConnectWallet-error', error)
    }
  }

  async function onSelectAccount(walletName: string) {
    console.log('onSelectAccount', walletName)
    let providerParam
    if (walletName === 'walletconnect') {
      providerParam = walletConnectProvider
    } else {
      providerParam = window?.ethereum
    }
    return providerParam
  }

  const autoConnect = useCallback(async () => {
    const providerType = persisted.get('web3').payWallet
    // console.log('autoConnect', providerType, window?.ethereum);
    if (
      providerType &&
      ['walletconnect', 'metamask'].indexOf(providerType) >= 0
    ) {
      getConnectedInfo(
        providerType === 'walletconnect'
          ? walletConnectProvider
          : window?.ethereum,
        providerType,
      )
    }
  }, [walletConnectProvider])

  useEffect(() => {
    autoConnect()
  }, [autoConnect])

  // console.log('walletprovider', provider)

  return (
    <WalletContext.Provider
      value={{
        currentWallet,
        provider,
        onSelectConnectWallet,
        onDisConnectWallet,
        onSelectAccount,
      }}>
      {children}
    </WalletContext.Provider>
  )
}

export function useWalletConnecProvider(): Provider | undefined {
  const [provider, setProvider] = useState<Provider>()

  async function onInitializeprovider() {
    try {
      const provider = await EthereumProvider.init({
        projectId: WALLETCONNECT_PROJECT_ID,
        showQrModal: true,
        qrModalOptions: {themeMode: 'light'},
        chains: [1],
        optionalChains: [1, 137, 8453, 84532],
        methods: [
          'personal_sign',
          'eth_sendTransaction',
          'eth_accounts',
          'eth_requestAccounts',
          'eth_call',
          'eth_getBalance',
          'eth_sendRawTransaction',
          'eth_sign',
          'eth_signTransaction',
          'eth_signTypedData',
          'eth_signTypedData_v3',
          'eth_signTypedData_v4',
          'wallet_switchEthereumChain',
          'wallet_addEthereumChain',
          'wallet_getPermissions',
          'wallet_requestPermissions',
          'wallet_registerOnboarding',
          'wallet_watchAsset',
          'wallet_scanQRCode',
        ],
        events: [
          'accountsChanged',
          'chainChanged',
          'message',
          'disconnect',
          'connect',
          'session_update',
          'session_delete',
        ],
        rpcMap: RPC_MAP,
      })
      provider.on('session_delete', () => {
        console.log('session_delete')
        setProvider(undefined)
      })
      setProvider(provider)
    } catch (error) {}
  }

  useEffect(() => {
    onInitializeprovider()
  }, [])

  return provider
}
