import React, {useEffect, useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {useQueryClient} from '@tanstack/react-query'
import dayjs from 'dayjs'

import {BET_RQKEY} from '#/state/queries/transaction-bet'
import {atoms as a} from '#/alf'

export const BetCountDown = ({endTime}: {endTime: number}) => {
  const queryClient = useQueryClient()
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime()
      const distance = endTime - now

      if (distance < 0) {
        clearInterval(timer)
        // refetch bet summary
        queryClient.invalidateQueries({queryKey: BET_RQKEY()})
        return
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setDays(days)
      setHours(hours)
      setMinutes(minutes)
      setSeconds(seconds)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [endTime, queryClient])

  return (
    <View>
      {days > 0 ||
      endTime < new Date().getTime() ||
      (!hours && !minutes && !seconds) ? (
        <Text style={[a.text_sm, a.font_bold]}>
          {dayjs(endTime).format('HH:mm on MMM DD, YYYY')}
        </Text>
      ) : (
        <View style={[a.flex_row, a.align_center, a.gap_xs]}>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{hours || '00'}</Text>
          </View>
          <View>
            <Text style={styles.dot}>:</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{minutes || '00'}</Text>
          </View>
          <View>
            <Text style={styles.dot}>:</Text>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{seconds || '00'}</Text>
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  textContainer: {
    height: 20,
    width: 29,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: '#000',
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'heavy',
    color: '#fff',
  },
  dot: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'heavy',
    color: '#333',
  },
})
