import React, {useMemo} from 'react'
import {StyleSheet, View} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {RouteProp, useRoute} from '@react-navigation/native'

import {FEED_CIRCLE_URI} from '#/lib/constants'
import {CommonNavigatorParams} from '#/lib/routes/types'
import {FeedDescriptor} from '#/state/queries/post-feed'
import {FeedPage} from '#/view/com/feeds/CircleFeedPage'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import * as Toast from '#/view/com/util/Toast'
import {CenteredView} from '#/view/com/util/Views'
import CircleHeader from './CircleHeader'
import {GuestCircleDetail, GuestCircleInstruction} from './GuestCircleDetail'
import {
  useApplyToCircleMutation,
  useCircleAgreeOrRejectMutation,
  useCircleItem,
} from './hooks'

export function Detail() {
  const route = useRoute<RouteProp<CommonNavigatorParams, 'CircleDetail'>>()
  const applyToCircleMutation = useApplyToCircleMutation()
  const agreeCircleMutation = useCircleAgreeOrRejectMutation()
  const {data, isLoading} = useCircleItem(+route.params.id)

  const feed: FeedDescriptor = `feedgen|${FEED_CIRCLE_URI}`

  const {_} = useLingui()

  const [nextStep, setNextStep] = React.useState(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const onApply = async () => {
    if (!loading) {
      try {
        setLoading(true)
        applyToCircleMutation.mutateAsync({
          circleId: +route.params.id,
        })
        Toast.show(_(msg`The request has been sent, please wait.`))
      } catch (err: any) {
        Toast.show(_(msg`${err?.message}`))
        console.log(err?.message)
      } finally {
        setLoading(false)
        setNextStep(false)
      }
    }
  }

  const onAccept = async () => {
    if (!loading && route.params?.approveId) {
      try {
        setLoading(true)
        await agreeCircleMutation.mutateAsync({
          approveId: +route.params?.approveId,
          circleId: +route.params.id,
        })
        let suMsg = _(msg`Welcome to the`) + ' ' + data?.name
        if (route.params?.approveType === 'invite_sipz') {
          suMsg = _(msg`The request has been sent, please wait.`)
        }
        Toast.show(suMsg)
      } catch (error: any) {
        Toast.show(
          error?.errorMessage ||
            error?.message ||
            _(msg`cause some error, please try again later.`),
          'xmark',
        )
      } finally {
        setLoading(false)
        setNextStep(false)
      }
    }
  }

  const isCircleMember = useMemo(() => {
    return data?.inCircle
  }, [data])

  return (
    <CenteredView
      style={
        (!isCircleMember || nextStep) && {height: '100%', position: 'relative'}
      }>
      {!nextStep ? (
        <>
          <CircleHeader
            circleId={route.params.id}
            data={data}
            isLoading={isLoading}
            onJoin={() => setNextStep(true)}
            isCircleMember={!!isCircleMember}
          />
          {isCircleMember ? (
            <FeedPage
              testID="customFeedPage"
              isPageFocused={true}
              feed={feed}
              feedParams={{circleids: route.params.id}}
              renderEndOfFeed={() => (
                <View style={styles.emptyBox}>
                  <EmptyBox
                    isMe
                    icon={'tea'}
                    message={_(
                      msg`Oops! No More tea yet.Invite your Telegram contacts or spill some tea now.`,
                    )}
                  />
                </View>
              )}
              renderEmptyState={() => (
                <View style={styles.emptyBox}>
                  <EmptyBox
                    isMe
                    icon={'tea'}
                    message={_(
                      msg`Oops! No tea yet.Invite your Telegram contacts or spill some tea now.`,
                    )}
                  />
                </View>
              )}
            />
          ) : (
            <GuestCircleDetail
              onPress={() => setNextStep(true)}
              status={data?.status || ''}
            />
          )}
        </>
      ) : (
        <GuestCircleInstruction
          onClose={() => setNextStep(false)}
          onConfirm={() => {
            if (data?.status && data?.status?.indexOf('invite') >= 0) {
              onAccept()
            } else {
              onApply()
            }
          }}
          loading={loading}
          status={data?.status || ''}
        />
      )}
    </CenteredView>
  )
}

const styles = StyleSheet.create({
  emptyBox: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginTop: -80,
  },
})
