export const firebaseConfig = {
  apiKey: 'AIzaSyAkPKqvDFcQZysDTcdRr9qgrmDCw36fREs',
  authDomain: 'sipz-8fca1.firebaseapp.com',
  projectId: 'sipz-8fca1',
  storageBucket: 'sipz-8fca1.appspot.com',
  messagingSenderId: '239253038385',
  appId: '1:239253038385:web:8d3f82d9e407b92080bc10',
  measurementId: 'G-3PZZ9Y6R1Z',
}

export const vapidKey =
  'BFtgHSeOY2LN9pohuyMSKrgj0CdxlyVCMfyU3rGg3YwVlc791mUXsQReS5Remep1eKoabVNPaMLhfNsTEZ2nHIA'
