import React, {useEffect, useRef} from 'react'
import {
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native'

// import {EditIcon} from '#/view/icons/ProfileIcons'
import {atoms as a, useTheme} from '#/alf'

interface ISdlTextInput {
  text: string
  setText: (text: string) => void
  placeholder: string
  style?: ViewStyle
  multiline?: boolean
  amxLength?: number
}

export function SdlTextInput({
  text,
  setText,
  placeholder,
  style,
  multiline,
  amxLength,
}: ISdlTextInput) {
  const t = useTheme()
  const [showPlaceholder, setShowPlaceholder] = React.useState(true)

  return (
    <View style={[styles.page, style, t.atoms.line]}>
      <View
        style={{
          flexDirection: 'row',
        }}>
        <View style={{flex: 1, paddingRight: 10}}>
          <TextInput
            accessibilityRole="none"
            style={[styles.input, t.atoms.text]}
            value={text}
            onFocus={() => setShowPlaceholder(false)}
            onBlur={() => setShowPlaceholder(true)}
            onChangeText={newText => setText(newText.substring(0, 100))}
            keyboardType="default"
            multiline={multiline}
            textAlignVertical="top"
          />
        </View>
        <Text
          style={[
            t.atoms.text,
            {transform: [{translateY: 2}]},
            text.length === amxLength && {color: 'red'},
          ]}>
          {text.length} / {amxLength}
        </Text>
      </View>
      {showPlaceholder && !text && (
        <View style={[styles.placeholderBox, multiline && styles.multiline]}>
          <Text style={styles.placeholder}>{placeholder}</Text>
          {/* <EditIcon size={19} style={{marginLeft: 10}} primaryColor="#979797" /> */}
        </View>
      )}
    </View>
  )
}

export function SdlInput(
  props: TextInputProps & {
    setValue: (newValue: string) => void
    passWord?: boolean
    focus?: boolean
  },
) {
  const {focus, passWord = false, setValue, style, placeholder, ...rest} = props
  const t = useTheme()
  const ref = useRef<TextInput>(null)

  const [isFocused, setIsFocused] = React.useState(false)

  useEffect(() => {
    if (focus) {
      ref.current?.focus()
    }
  }, [focus])

  return (
    <TextInput
      ref={ref}
      onChangeText={setValue}
      accessibilityLabel="Text input field"
      accessibilityHint="Enter text to search"
      placeholder={placeholder ?? 'Enter...'}
      placeholderTextColor="#979797"
      secureTextEntry={passWord}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      focusable
      style={[
        {borderWidth: 1, height: 44},
        a.relative,
        a.p_xs,
        a.rounded_md,
        t.atoms.text,
        a.text_md,
        a.pl_sm,
        a.pr_md,
        t.atoms.input_border,
        t.atoms.input_bg,
        style,
        isFocused && {borderColor: t.palette.primary},
      ]}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: 35,
    borderWidth: 0,
    borderColor: '#E5E5E5',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 6,
  },
  input: {
    height: '100%',
    fontSize: 18,
    fontWeight: 'bold',
  },
  placeholderBox: {
    position: 'absolute',
    top: 2,
    left: 0,
    pointerEvents: 'none',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  placeholder: {
    color: '#979797',
    fontSize: 14,
  },
  multiline: {
    alignItems: 'flex-start',
    paddingTop: 5,
  },
})
