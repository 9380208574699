import React, {useEffect, useMemo, useState} from 'react'
import {
  ImageBackground,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import {Trans} from '@lingui/macro'

import {emitter} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useAgent} from '#/state/session'
import {isWeb} from 'platform/detection'
import * as Toast from '#/view/com/util/Toast'
import {useCompanies} from '#/view/screens/Activity/hooks'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as TextField from '#/components/forms/TextField'
import {ChevronRight_Stroke2_Corner0_Rounded as ChevronRight} from '#/components/icons/Chevron'
import {Company} from '#/components/icons/Company'
import {Email} from '#/components/icons/Email'
import {Portfolio} from '#/components/icons/Portfolio'
import {List} from '../util/List'
import {UserAvatar} from '../util/UserAvatar'
import {CountdownTimer} from './CountdownTimer'
import {
  Business_and_Expansion,
  Development_and_Technical,
  Management,
  Medal,
  Operations_and_Community,
  Product_and_Design,
} from './JobIcons'
import {ScrollView} from './util'

export const snapPoints = ['90%']

type EntityItemProps = {
  id: number
  introduce: string
  logo: string
  name: string
  rootdataurl: string
  type: number
}

export enum Stages {
  HomePanel = 'home',
  CompanyPanel = 'company',
  JobPanel = 'job',
  CodePanel = 'code',
  DonePanel = 'done',
}

export const getJobTypes = (): JobProps[] => {
  return [
    {
      id: 1,
      icon: <Development_and_Technical />,
      title: (
        <Text>
          <Trans>Development and Technical</Trans>
        </Text>
      ),
    },
    {
      id: 2,
      icon: <Product_and_Design />,
      title: (
        <Text>
          <Trans>Product and Design</Trans>
        </Text>
      ),
    },
    {
      id: 3,
      icon: <Operations_and_Community />,
      title: (
        <Text>
          <Trans>Operations and Community</Trans>
        </Text>
      ),
    },
    {
      id: 4,
      icon: <Business_and_Expansion />,
      title: (
        <Text>
          <Trans>Business and Expansion</Trans>
        </Text>
      ),
    },
    {
      id: 5,
      icon: <Management />,
      title: (
        <Text>
          <Trans>Management</Trans>
        </Text>
      ),
    },
  ]
}

export function Component({stage: index}: {stage?: Stages}) {
  const t = useTheme()
  const agent = useAgent()
  const {closeModal} = useModalControls()
  const [email, setEmail] = useState('')
  const [query, setQuery] = useState('')
  const [stage, setStage] = useState<Stages>(index || Stages.HomePanel)
  const [company, setCompany] = useState<EntityItemProps>()
  const [jobType, setJobType] = useState<number>()
  const [code, setCode] = useState<string>('')
  const [sendTime, setSendTime] = useState<number>(Date.now())
  const [histories] = useState([])

  const companies = useCompanies(query)

  const jobTypes: JobProps[] = useMemo(getJobTypes, [])

  const selectJob = useMemo(() => {
    return jobTypes.find(j => j.id === jobType)
  }, [jobType, jobTypes])

  useEffect(() => {
    const str = localStorage.getItem('VerifyEmail')
    if (str) {
      const data = JSON.parse(str)
      if (data) {
        setJobType(data.job)
        setEmail(data.email)
        setCompany(data.company)
        setSendTime(data.sendTime)
      }
    }
  }, [])

  const renderItem = ({item}: {item: EntityItemProps}) => {
    return (
      <Button
        style={[a.justify_start, a.pl_lg]}
        label={item.name}
        onPress={() => {
          setCompany(item)
          setStage(Stages.HomePanel)
          setQuery('')
        }}>
        <EntityItem key={item.id} item={item} />
      </Button>
    )
  }

  const keyExtractor = (item: EntityItemProps) => {
    return item.id + ''
  }

  const onNext = async () => {
    if (!company) {
      Toast.show('Company or Project is required', 'xmark')
      return
    }
    if (!selectJob) {
      Toast.show('Job is required', 'xmark')
      return
    }
    if (!email) {
      Toast.show('Email is required', 'xmark')
      return
    }
    if (!isValidEmail(email)) {
      Toast.show('Invalid email', 'xmark')
      return
    }

    await agent.com.atproto.server
      .sendUserEmailCode({
        email,
      })
      .then(() => {
        const now = Date.now()
        if (now - sendTime > 60 * 1000) {
          const data = {
            email,
            company,
            job: selectJob?.id,
            sendTime: now,
          }
          localStorage.setItem('VerifyEmail', JSON.stringify(data))
          setSendTime(now)
        }
        setStage(Stages.CodePanel)
      })
      .catch((error: any) => {
        Toast.show(error.message, 'xmark')
      })
  }

  const onConfirm = async () => {
    if (!code) {
      Toast.show('Code is required', 'xmark')
      return
    }
    if (!company) {
      return
    }
    if (!jobType) {
      return
    }
    await agent.com.atproto.server
      .checkUserEmailCode({
        companyId: company.id + '',
        company: company.name,
        email: email,
        jobType: jobType,
        code,
      })
      .then(() => {
        setStage(Stages.DonePanel)
        emitter.emit('refreshTaskList')
      })
      .catch((error: any) => {
        Toast.show(error.message, 'xmark')
      })

    // setStage(Stages.DonePanel)
  }

  const onResend = () => {
    onNext()
  }

  return (
    <SafeAreaView style={[a.flex_1]}>
      <ScrollView testID="verifyEmailModal">
        {stage === Stages.HomePanel && (
          <View>
            <View style={styles.titleSection}>
              <Text style={[t.atoms.text, styles.title]}>
                <Trans>Verified Company Email</Trans>
              </Text>
            </View>
            <View style={[a.gap_md]}>
              <View style={[a.pl_sm, a.pr_sm]}>
                <Text style={[t.atoms.text_sub]}>
                  {'\u2022'}
                  <Trans>
                    Verify for exclusive company and investment insights.
                  </Trans>
                </Text>
                <Text style={[t.atoms.text_sub]}>
                  {'\u2022'}
                  <Trans>Your privacy is guaranteed—only you can see it.</Trans>
                </Text>
              </View>

              <View style={[a.gap_md]}>
                <Button
                  variant="solid"
                  color="secondary"
                  label="Company Profile"
                  size="medium"
                  style={[
                    a.flex_1,
                    a.justify_between,
                    a.p_md,
                    a.rounded_sm,
                    a.ml_sm,
                    a.mr_sm,
                  ]}
                  onPress={() => {
                    setStage(Stages.CompanyPanel)
                  }}>
                  {company ? (
                    <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                      <UserAvatar
                        shape="circle"
                        avatar={company.logo}
                        size={24}
                      />
                      <Text style={[t.atoms.grayText]}>
                        <Trans>{company.name}</Trans>
                      </Text>
                    </View>
                  ) : (
                    <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                      <Company style={[{color: t.palette.gray_10}]} />
                      <Text style={[t.atoms.grayText]}>
                        <Trans>Company</Trans>
                      </Text>
                    </View>
                  )}
                  <ChevronRight
                    size="xs"
                    style={[{color: t.palette.gray_10}]}
                  />
                </Button>
                <Button
                  variant="solid"
                  color="secondary"
                  label="Job type"
                  size="medium"
                  style={[
                    a.flex_1,
                    a.justify_between,
                    a.p_md,
                    a.rounded_sm,
                    a.ml_sm,
                    a.mr_sm,
                  ]}
                  onPress={() => {
                    setStage(Stages.JobPanel)
                  }}>
                  {jobType ? (
                    <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                      <JobIcon icon={selectJob?.icon} />
                      <Text style={[t.atoms.grayText]}>{selectJob?.title}</Text>
                    </View>
                  ) : (
                    <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                      <Portfolio style={[{color: t.palette.gray_10}]} />
                      <Text style={[t.atoms.grayText]}>
                        <Trans>Job type</Trans>
                      </Text>
                    </View>
                  )}
                  <ChevronRight
                    size="xs"
                    style={[{color: t.palette.gray_10}]}
                  />
                </Button>
                <View
                  style={[
                    {
                      backgroundColor: t.palette.gray_15,
                      overflow: 'hidden',
                      height: 48,
                    },
                    a.ml_sm,
                    a.mr_sm,
                    a.rounded_sm,
                  ]}>
                  <TextField.Root style={[t.atoms.bg_gray]}>
                    <TextField.Icon
                      icon={Email}
                      style={[{color: t.palette.gray_10}]}
                    />
                    <TextField.Input
                      label="Company official email..."
                      value={email}
                      keyboardType="email-address"
                      textContentType="emailAddress"
                      onChangeText={setEmail}
                      placeholderTextColor={t.atoms.grayText.color}
                      style={[{fontSize: 14}]}
                      autoCapitalize="none"
                      autoCorrect={false}
                      autoFocus
                      autoComplete="off"
                    />
                  </TextField.Root>
                </View>
                <Button
                  color="primary"
                  variant="solid"
                  size="medium"
                  label="Next"
                  style={[a.ml_sm, a.mr_sm]}
                  onPress={onNext}>
                  <Text>Next</Text>
                </Button>
                <Button
                  color="secondary"
                  variant="solid"
                  size="medium"
                  label="Cancel"
                  style={[a.ml_sm, a.mr_sm]}
                  onPress={() => {
                    closeModal()
                  }}>
                  <Text style={[{color: t.palette.gray_16}]}>Cancel</Text>
                </Button>
              </View>
            </View>
          </View>
        )}
        {stage === Stages.CompanyPanel && (
          <View style={[a.p_md, a.gap_md]}>
            <View style={styles.titleSection}>
              <Text style={[t.atoms.text, styles.title]}>
                <Trans>Company or Project</Trans>
              </Text>
            </View>
            <View
              style={[
                {backgroundColor: t.palette.gray_15, overflow: 'hidden'},
                a.rounded_sm,
              ]}>
              <TextField.Root style={{backgroundColor: t.palette.gray_15}}>
                <TextField.Icon
                  icon={Company}
                  style={[{color: t.palette.gray_10}]}
                />
                <TextField.Input
                  label="Enter Company Name..."
                  value={query}
                  onChangeText={setQuery}
                  placeholderTextColor={t.atoms.grayText.color}
                  style={[{fontSize: 16, borderColor: t.palette.gray_15}]}
                />
              </TextField.Root>
            </View>
            {histories.length > 0 && (
              <View>
                <View style={[a.justify_between, a.flex_row, a.align_center]}>
                  <View>
                    <Text style={[t.atoms.text]}>
                      <Trans>Search History</Trans>
                    </Text>
                  </View>
                  <View>
                    <Text style={[t.atoms.text_contrast_low]}>
                      <Trans>Clear</Trans>
                    </Text>
                  </View>
                </View>
              </View>
            )}
            <List
              contentContainerStyle={[a.gap_md]}
              data={companies}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
            />
            {/* <View>
              <Text style={[t.atoms.text]}>
                🔥 <Trans>Hot</Trans>
              </Text>
            </View> */}
            <Button
              color="secondary"
              variant="solid"
              size="medium"
              label="Cancel"
              onPress={() => {
                setStage(Stages.HomePanel)
              }}>
              <Text style={[{color: t.palette.gray_16}]}>Cancel</Text>
            </Button>
          </View>
        )}
        {stage === Stages.JobPanel && (
          <View style={[a.p_md, a.gap_md]}>
            {jobTypes.map((job: JobProps) => {
              return (
                <Button
                  key={job.id}
                  label=""
                  color="secondary"
                  variant="solid"
                  size="medium"
                  onPress={() => {
                    setJobType(job.id)
                    setStage(Stages.HomePanel)
                  }}
                  style={[
                    a.flex_1,
                    a.justify_start,
                    a.flex_row,
                    a.align_center,
                    a.rounded_sm,
                    {backgroundColor: t.palette.gray_15},
                  ]}>
                  <JobIcon icon={job.icon} />
                  <Text style={[{color: t.palette.gray_16}]}>{job.title}</Text>
                </Button>
              )
            })}

            <Button
              color="secondary"
              variant="solid"
              size="medium"
              label="Cancel"
              onPress={() => {
                setStage(Stages.HomePanel)
              }}>
              <Text>Cancel</Text>
            </Button>
          </View>
        )}
        {stage === Stages.CodePanel && (
          <View style={[a.flex_1, a.gap_md, a.p_md]}>
            <View style={styles.titleSection}>
              <Text style={[t.atoms.text, styles.title]}>
                <Trans>Input code</Trans>
              </Text>
            </View>
            <View>
              <Text style={[t.atoms.text_sub]}>
                <Trans>
                  Send the code to the Telegram app on your other device
                </Trans>
              </Text>
            </View>
            <View
              style={[
                {backgroundColor: t.palette.gray_15, overflow: 'hidden'},
                a.rounded_sm,
              ]}>
              <TextField.Root style={{backgroundColor: t.palette.gray_15}}>
                {/* <TextField.Icon
                  icon={Email}
                  style={[{color: t.palette.gray_10}]}
                /> */}
                <TextField.Input
                  label="Enter Code..."
                  value={code}
                  onChangeText={setCode}
                  placeholderTextColor={t.atoms.grayText.color}
                  style={[{fontSize: 14, borderColor: t.palette.gray_15}]}
                />
                <CountdownTimer onResend={onResend} sendTime={sendTime} />
              </TextField.Root>
            </View>
            <Button
              color="primary"
              variant="solid"
              size="medium"
              label="Confirm"
              style={[a.ml_sm, a.mr_sm]}
              onPress={onConfirm}>
              <Text>Confirm</Text>
            </Button>
            <Button
              color="secondary"
              variant="solid"
              size="medium"
              label="Previous"
              style={[a.ml_sm, a.mr_sm]}
              onPress={() => {
                setStage(Stages.HomePanel)
              }}>
              <Text style={[{color: t.palette.gray_16}]}>Previous</Text>
            </Button>
          </View>
        )}
        {stage === Stages.DonePanel && (
          <View style={[a.flex_1, a.gap_md, a.p_md]}>
            <ImageBackground
              source={require('../../../../assets/imgs/sun_light_bg.png')}
              style={{
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 240,
              }}>
              <View style={[a.absolute, {top: '35%'}]}>
                <Medal />
              </View>
              <View style={[a.justify_center, a.align_center]}>
                <Text style={[t.atoms.text, {fontSize: 19, fontWeight: 600}]}>
                  <Trans>Email verified, pending</Trans>
                </Text>
                <Text style={[t.atoms.text, {fontSize: 19, fontWeight: 600}]}>
                  <Trans>manual review</Trans>
                </Text>
              </View>
              <View style={[a.flex_row, a.justify_center, a.mt_lg]}>
                <Text style={[t.atoms.text]}>
                  <Trans>Results will be sent within</Trans>
                </Text>
                <Text style={[{color: t.palette.primary}]}>
                  {' '}
                  <Trans>24 hours.</Trans>
                </Text>
              </View>
              <Button
                color="secondary"
                variant="solid"
                size="medium"
                label="Close"
                style={[a.ml_sm, a.mr_sm, a.mt_lg, a.w_full]}
                onPress={() => {
                  closeModal()
                }}>
                <Text style={[{color: t.palette.gray_16}]}>Close</Text>
              </Button>
            </ImageBackground>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

function EntityItem({item}: {item: EntityItemProps}) {
  const t = useTheme()
  return (
    <View style={[a.flex_row, a.align_center, a.gap_sm]}>
      <UserAvatar shape="circle" avatar={item.logo} size={32} />
      <Text style={[t.atoms.text]}>{item.name}</Text>
    </View>
  )
}

type JobProps = {
  id: number
  icon: any
  title?: string | JSX.Element
  width?: number
  height?: number
}

function JobIcon({icon, width = 24, height = 24}: Omit<JobProps, 'id'>) {
  return (
    <View
      style={[
        a.flex_row,
        a.align_center,
        a.gap_sm,
        [{width: width, height: height}],
      ]}>
      {icon}
    </View>
  )
}

const styles = StyleSheet.create({
  titleSection: {
    paddingTop: isWeb ? 0 : 4,
    paddingBottom: isWeb ? 14 : 10,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: 5,
  },
  error: {
    borderRadius: 6,
    marginTop: 10,
  },
  emailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    paddingHorizontal: 14,
    marginTop: 10,
  },
  changeEmailLink: {
    marginHorizontal: 12,
    marginBottom: 12,
  },
  textInput: {
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 14,
    paddingVertical: 10,
    fontSize: 16,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    padding: 14,
  },
  btnContainer: {
    paddingTop: 20,
  },
})

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const isValidEmail = (email: string): boolean => {
  return emailRegex.test(email)
}
